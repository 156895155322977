export default {
	appName: "Equidox",
	mainMenu: {
		home: "Accueil",
		documents: "Documents",
		importDocuments: "Importer Document(s)",
		notifications: "Notifications",
		settings: "Paramètres",
		help: "Aide",
		signOut: "Déconnexion"
	},
	userMenu: {
		profile: "Profile",
		language: "Langage",
		darkTheme: "Thème sombre",
		darkThemeHint: "Activer le thème sombre"
	},
	barTitle: {
		dashboard: "Accueil",
		documents: "Documents",
		document: "Détail du document",
		editor: "Remediation Page",
		import: "Importer document(s)",
		notifications: "Notifications",
		settings: "Réglages",
		help: "Aide",
		signOut: "Se déconnecter",
		profile: "Profil"
	},
	login: {
		signIn: "Se connecter",
		email: "Courriel",
		password: "Mot de passe",
		logIn: "Connecter",
		forgotPassword: "Oublié votre mot de passe ?",
		signInWith: "Se connecter avec",
		equidoxMainPage: "Page d'accueil Equidox",
		equidoxTutorials: "Vidéos sur Equidox",
		equidoxNewsBlog: "Blog Equidox",
		dontHaveAnAccount: "Vous n'avez pas de compte Equidox? ",
		requestADemo: "Demander une démo",
		msg: {
			invalidUserPwd: "Nom d'usager ou mot de passed incorrect",
			errorLoginGoogle: "Une erreur est survenu lors de l'utilisation d'un compte Google: {0}"
		}
	},
	profile: {
		firstName: "Prénom",
		lastName: "Nom",
		email: "Courriel",
		phone: "Téléphone",
		address: "Adresse (y compris la ville et le pays)",
		description: "Description",
		zoneHighlight: "Largeur de surbrillance de la zone",
		autosummarizeMode: "Mode de synthèse automatique",
		outputDefault: "Onglet par défaut",
		customerInstance: "Instance client",
		save: "Garder le profile",
		msg: {
			profileSaved: "Profile sauvegardé"
		}
	},
	documents: {
		recent: "Documents récents",
		list: "Liste des Documents",
		resultPerPage: "Résultat par page",
		sortBy: "Trié par",
		sortItems: ["Date importé", "Titre", "Status"],
		filterSearch: "Filtré la recherche",
		documentTitle: "Titre du document",
		importedBy: "Importé par",
		addLabel: "Étiquetté",
		edit: "Editer",
		gridView: "Afficher en grille",
		listView: "Afficher en liste",
		delete: "Supprimer",
		zoneTransfer: "Transfert de zone(s)",
		headerConfirmDelete: "Etes-vous sûr que vous voulez supprimer",
		buttonCancel: "Annuler",
		buttonOk: "OK",
		headerArchiveOrPermanentDelete: "Voulez-vous archiver ou supprimer définitivement",
		buttonArchive: "Archive",
		buttonPermanentDelete: "Supprimer définitivement",
		buttonReset: "Réinitialiser",
	},
	document: {
		gotoPage: "Aller a la page",
		gotoOf: "de",
		showUncheckedPages: "Afficher les pages non validé",
		exportToPDF: "Extraire PDF",
		share: "Partager",
		delete: "Effacer",
		shareDocument: "Share Document ",
		cancel: "Annuler",
		done: "Fait",
		shareWithOthers: "Partagez avec les autres",
		selectPeople: "Sélectionnez les personnes avec lesquelles vous souhaitez partager ce document",
		shareWithGroup: "Partager avec un groupe",
		selectGroup: "Sélectionnez le groupe avec lequel vous souhaitez partager ce document",
		tabProperties: {
			tabTitle: "Propriétés",
			original: "Document original",
			downloadOriginalDocument: "Download original document",
			title: "Titre",
			author: "Auteur",
			createdDate: "Date de création",
			lastModificationDate: "Dernière modification",
			producer: "Produit par",
			language: "Langage",
			addLabel: "Étiquetté",
			pages: "Pages",
			images: "Images",
			metadata: "Métadonnées",
			metadataName: "Nom",
			metadataValue: "Valeur",
			addDelete: "Ajouter ou effacer Métadonnées",
			deleteMetadata: "Effacer Métadonnées",
			addNewMetadata: "Ajouter Métadonnées",
			saveProperties: "Sauvegarder",
			msg: {
				metadataSaved: "Métadonnées {0} sauvegardé",
				metadataRemoved: "Métadonnées {0} supprimé",
			}
		},
		tabImages: {
			tabTitle: "Images",
			hideAllZones: "Masquer toutes les zones",
			allVisible: "Toute visible",
			saveImageProperties: "Sauvegarder les images",
			hideThisZone: "Masquer cette zone dans l'éditeur",
			visible: "Visible",
			imageDescription: "Entrez la description de l'image",
			previousPage: "Préc.",
			nextPage: "Svte",
			imagesTo: "à",
			imagesMany: " de plusieurs",
			msg: {
				errorGettingImages: "Erreur lors de la récupération des images",
			}
		},
		tabUnicodeMapping: {
			tabTitle: "Mapper les caractères Unicode",
			labelUnicodeCharsRequire: "les caractères nécessitent un mappage unicode sur la page",
			labelUnicodeMappingOnPage: "Mappage unicode sur la page ",
			labelOcrAll: "OCR Tout",
			labelSave: "Sauvegarder",
			msg: {
				mappingSavedForPage: "Mappage de caractères enregistré pour la page {0}"
			}
		},
		tabZoneTransfer: {
			tabTitle: "Transfert de zone",
			sourceDocumentID: "Identifiant du document source",
			destinationDocumentID: "Identifiant du document de destination",
			sourcePage: "Page Source",
			destinationPage: "Page de Destination",
			transferDocumentZones: "Transférer les zones du document",
			cancelTransfer: "Annuler le transfert",
		},
		tabPageNotes: {
			tabTitle: "Notes de page",
			headerPageNo: "Numéro de Page",
			headerNote: "Note",
			headerResolved: "Résolue",
			rowsCount: "Résultat par page",
			pageOf: "de",
		},
		tabHistory: {
			tabTitle: "Historique",
			viewWarnings: "Voir",
			warnings: "Mises en garde",
			warningsOnPage: "Mises en garde pour la Page ",
			msg: {
				errorGettingHistory: "Impossible d'obtenir l'historique des pages",
				errorLoadingWarnings: "Impossible de charger les avertissements",
			}
		},
		tabExport: {
			tabTitle: "Exportation",
			htmlDocument: "Document HTML",
			selectPageToDownload: "Sélectionnez les pages de document à télécharger",
			allPages: "All",
			generatePageIDS: "Générer les identifiants de page",
			fixNoSpace: "Correction pour aucun espace",
			flattenFormX: "Aplatir les objets formX",
			pdfOutputEngine: "PDF Output Engine",
			filename: "Nom de fichier",
			previewHtml: "Aperçu du document HTML",
			downloadHtml: "Télécharger le HTML",
			downloadZones: "Télécharger le Zones",
			pdfDocument: "Document PDF",
			generatePdf: "Générer le PDF",
			epubDocument: "Document EPUB",
			downloadEpub: "Télécharger le fichier EPub",
			css: "CSS",
			documentZones: "Document Zones",
			msg: {
				unableToExport: "Impossible d'exporter pour le moment, veuillez réessayer dans quelques minutes",
				errorHtml: "Erreur lors de la génération du code HTML",
				errorEpub: "Erreur lors de la génération du code EPUB",
				errorPDF: 'Erreur lors de la génération du fichier PDF',
			}
		},
		msg: {
			updated: "Document mis à jour",
			errorUpdating: "Erreur lors de la mise à jour du document",
			documentSaved: "Document sauvegarder",
			permanentlyDeleted: "Document supprimé définitivement",
			errorPermanentlyDeleted: "Erreur lors de la suppression définitive du document",
			archived: "Document archivé",
			deleted: "Document supprimé",
			errorDelete: "Erreur lors de la suppression du document",
			imagesSaved: "Images sauvegarder",
			queuingPermanentDelete: "Mise en file d'attente pour la suppression permanente de documents",
			sharedUpdated: "Partages du document mis à jour",
			errorSharesUpdates: "Erreur lors de la mise à jour des partages de documents",
		}
	},
	import: {
		import: "Importer",
		inProgress: "En cours:",
		documentReady: "Le document est prêt",
		couldNotImport: "Impossible d'importer ce document:",
		importMessages: "Message(s) d'importation",
		recently: "Documents récemment importés:",
		dragFilesOrClickHere: "Faites glisser des fichiers ou cliquez ici...",
		enablePageMatch: "Enable ML Page Match",
		msg: {
			maxFileNameLengthMsg: "Erreur lors du téléchargement du fichier. La longueur du nom de fichier dépasse {0}. Veuillez renommer le fichier avant de le télécharger. Fichier: ",
			maxFileSizeMsg: "La taille totale du fichier dépasse la taille d'importation autorisée de {0} Mo",
			maxFileCountMsg: "Total file count exceeds allowed import count of {0}",
			onlyPDFZip: "Vous ne pouvez pas télécharger de fichiers de ce type. Seuls les fichiers PDF ou ZIP sont acceptés",
			invalidFileType: "Type de fichier invalide",
			importing: "Importation de {0} document(s)"
		}
	},
	page: {
		confirmSaveTitle: "Enregistrer cette page?",
		confirmChangedSaveMsg: "Cette page n'a pas été enregistrée. Voulez-vous enregistrer cette page avant de quitter la page?",

		confirmIgnoreZoneTitle: "Ignore (repeated) zone",
		confirmIgnoreZoneChangeMsg: "The ignore zone(s) changed. It will update the zone(s) on all pages. Do you want to commit changes?",

		tableEditor: {
			title: "Éditeur de table",
			msg: {
				errorNoTable: "Aucune Table",
				tableSaved: "Table sauvegardé",
				columnAlreadySpanned: "La colonne {0} contient déja des elements unifiés",
				rowAlreadySpanned: "La ligne {0} contient déja des elements unifiés",
				tableCellsOCRUpdated: "Cellules du tableau mises à jour avec le contenu ocr",
				unableOCRTable: "Impossible de détecter l'ocr pour la table",
				cellsCustomText: 'Cellules du tableau mises à jour avec du texte personnalisé',
				unableTextContent: "Impossible d'obtenir le contenu du texte",
				unableTextContentTable: "Impossible d'obtenir le contenu du texte pour le tableau",
				unableToOCR: "Impossible de détecter ocr",
			}
		},
		sideBarTable: {
			name: "Nom",
			caption: "Légende",
			summary: "Résumé",
			columnHeader: "En-tête de colonne",
			rowHeader: "En-tête de ligne",
			column: "Colonne",
			row: "Ligne",
			tableInformation: "Informations de la table",
			tableDetector: "Détecteur de table",
			opticalCharacterRecognition: "Reconnaissance optique de caractères",
			htmlGeneration: "Génération HTML",
			rowscolsAttributes: "Attributs de lignes/cols",
			ocrAllCells: "OCR toutes les cellules",
			customAllCells: "Personnaliser toutes les cellules",
			sensitivity: "Sensibilité",
			autoSummarize: "Résumé automatique",
		},
		sideBarCells: {
			noCellSelected: "Aucune cellule selectionné",
			nudgeLeft: "Pousser tout à gauche",
			nudgeRight: "Pousser tout à droite",
			nudgeUp: "Pousser tout vers le haut",
			nudgeDown: "Pousser tout vers le bas",
			source: "Source",
			cellSource: "Source de la cellule",
			columnAdjustments: "Ajustements de colonne",
			rowAdjustments: "Ajustements de ligne",
			columnOperations: "Opérations sur les colonnes",
			spanOperations: "Operations 'Span'",
			rowOperations: "Opérations sur les lignes",
			splitColumn: "Fractionner la colonne",
			mergeRight: "Fusionner à droite",
			splitRow: "Fractionner la rangée",
			mergeDown: "Fusionner vers le bas",
			spanColumns: "Étendue des colonnes",
			spanRows: "Étendue des rangées",
			removeSpan: "Supprimer l'étendue",
			convertToText: "Convertir en texte",
			ocrContent: "Texte OCR",
			customContent: "Texte personnalisé",
			actualContent: "Texte réel",
		},
		tableToolBar: {
			row: "Ligne",
			column: "colonne",
			splitRow: "Diviser Ligne (R)",
			deleteRow: "Supprimer la ligne (D)",
			splitColumn: "Diviser colonne (C)",
			removeColumn: "Supprimer la colonne (M)",
			removeSpan: "Supprimer l'étendue",
			spanColumns: "Étendre la colonne",
			spanColumnsS: "Étendre les colonnes (S)",
			spanRows: "Étendre lignes",
			previewTable: "Aperçu de ce tableau",
			saveTable: "Enregistrer la table?",
			tableNotSaved: "Cette table n'a pas été enregistré. Voulez-vous enregistrer cette table avant de quitter l'éditeur de table?",
			yes: "Oui",
			no: "Non",
		},
		toolbar: {
			gotoPage: "Aller à la page",
			pageOf: "de",
			sensitivity: "Sensibilité",
			readingOrder: "Ordre de lecture",
			readingOrders: ["1 Colonne", "2 Colonnes", "3 Colonnes", "4 Colonnes"],
			reOrder: "Réorganiser",
			undo: "Undo",
			redo: "Redo",
			toggleZoneInfo: "Basculer les informations de zone",
			save: "Sauvegarder",
			preview: "Aperçu de cette page",
			fixedPreview: "(Fixe) Aperçu de cette page",
			zoomIn: "Zoom",
			zoomOut: "Dézoomer",
			close: "Fermer",
			validate: "Validé",
			shortcuts: "Raccourcis",
			extension: {
				name: "Nom",
				order: "Ordre",
				removeZone: "Supprimer la zone",
				newZone: "Nouv. zone",
				zoneTag: "Balise",
				zoneSource: "Source",
				left: "Gauche",
				width: "Largeur",
				top: "Haut",
				height: "Hauteur",
				l: "L",
				w: "W",
				t: "T",
				h: "H",
			},
			messageZoneInfo: "Informations sur la zone mises à jour",
			msg: {
				default: "Ordre de lecture changée pour {0}",
				reordered: "La page a été réorganisée - {0}",
				markedValidated: "La page a été marquée comme validée",
				unmarkedValidated: "La page n'a pas été marquée comme validée",
			}
		},
		sidebarPage: {
			pageInformation: "Informations de la page",
			zoneDetector: "Détecteur de zone",
			readingOrder: "Ordre de lecture",
			page: "Page",
			lastSaved: "Sauvegardé",
			validatedOn: "Validé le",
			validatedBy: "Validé par",
			sensitivity: "Sensibilité",
			fields: "Champs",
			images: "Images",
			keepZoneTypes: "Keep Zone Types",
			mlPageMatch: "ML Page Match",
			enable: "Enable",
			applyToAll: "Appliquer à toutes les pages",
			automatic: "Automatique",
			reOrder: "Réorganiser",
			ocr: "Reconnaissance Optique de Caractères",
			ocr_all: "OCR toutes les zones",
			msg: {
				default: "Ordre de lecture changée pour {0}",
				reordered: "La page a été réorganisée - {0}",
				ocrAllProgress: {
					preparing: "Préparation des zones...",
					processing: "zone(s) en traitement",
					alreadyProcessed: "Toutes les zones déjà traitées",
				}				
			}
		},
		sidebarZone: {
			properties: "Propriétés",
			name: "Nom",
			type: "Type",
			language: "Langue",
			order: "Ordre",
			removeZone: "Supprimer",
			tag: "Balise",
			indentation: "Échancrure",
			merge: "Fusionner",
			breakLines: "Saut de ligne",
			anchorProperties: "Propriétés d'ancrage",
			anchorID: "Identifiant",
			blockquoteProperties: "Propriétés citation en bloc",
			citeProperties: "Propriétés de la citation",
			divProperties: "Ordre de sous-lecture",
			divReorderAuto: "Automatique",
			divReOrderButton: "Réorganiser cette div",
			fieldProperties: "Propriétés du champ",
			fieldType: "Type de champ",
			fieldTooltip: "Info-bulle",
			fieldLabelZone: "Zone d'étiquette",
			fieldGroupName: "Nom de groupe",
			fieldVisibility: "Visibilité",
			fieldReadOnly: "Lecture seulement",
			fieldRequired: "Obligatoire",
			buttonLabel: "Étiquette du bouton",
			fieldAlignment: "Alignement",
			checkSpelling: "Vérifier l'orthographe",
			multiline: "Multi ligne",
			scrollLongText: "Faire défiler le texte long",
			richText: "Formatage de texte enrichi",
			maxCharacters: "Caractères maximum",
			checkboxStyle: "Style",
			exportValue: "Valeur d'exportation",
			checkedByDefault: "Coché par défaut",
			options: "Options",
			editValues: "Modifier les valeurs",
			optionSort: "Trier",
			multiSelect: "Sélection multiple",
			footnoteProperties: "Propriétés de la note",
			footnoteLinkProperties: "Propriétés du lien de note",
			toFootnote: "Aller a la note",
			description: "Description",
			method: "Methode",
			action: "Action",
			visible: "Visible",
			hideZone: "Cacher Zone",
			descriptionAlt: "Description (Alt)",
			longDescription: "Longue description",
			level: "Niveau",
			useAsTemplate: "Utiliser comme modèle",
			enabled: "Activée",
			removeUnderlyingZones: "Supprimer les zone(s) sous-jacente",
			title: "Titre",
			toAnchor: "URL/ancre",
			caption: "Légende",
			summary: "Résumé",
			listType: "Type de liste",
			listUnordered: "Non ordonné",
			listOrdered: "Ordonné",
			styleType: "Type de style",
			startAt: "Commencer à",
			listDetector: "Détecteur de liste",
			tocDetector: "TOC Detector",
			listSensitivity: "Sensibilité",
			listItems: "Éléments de la liste",
			tocItems: "TOC Items",
			showListLabel: "Afficher les étiquettes",
			showArtifacts: "Show Artifacts",
			listLabel: "List Label",
			pdfAllItems: "PDF tous les éléments",
			ocrAllItems: "OCR tous les éléments",
			customAllItems: "Tous les éléments personnalisé",
			tableEditor: "Éditeur de table",
			formProperties: "Propriétés du formulaire",
			graphicProperties: "Propriétés graphiques",
			headingProperties: "Propriétés du titre",
			ignoreProperties: "Propriétés de la zone vide",
			imageProperties: "Propriétés de l'image",
			linkProperties: "Propriétés du lien",
			listProperties: "Propriétés de la liste",
			quoteProperties: "Propriétés de la citation",
			sensitivityProperties: "Propriétés de la Sensibilité",
			tableProperties: "Propriétés de la table",
			textProperties: "Propriétés du texte",
			tocProperties: "Propriétés de la table des matières",
			zoneSource: "Source de la zone",
			source: "Source",
			zonePosition: "Emplacement",
			left: "Gauche",
			width: "Largeur",
			top: "Haut",
			height: "Hauteur",
			ocrConvert: "Convertir en text",
			list: "List",
			toc: "Table des matières",
			with: "Avec",
			zone: "Zone",
			page: "Page",
			mergeItem: "Fusionner l'élément",
			linkToPage: "Lien vers la page",
			pageNumber: "Numéro de la Page",
			artifactPosition: "Emplacement de l'artefact",
			msg: {
				listItemSourcePDF: "La source des éléments de liste est désormais définie sur PDF",
				listItemSourceCustom: "La source des éléments de liste est désormais définie sur Personnalisé",
				unableDetectOCR: "Impossible de détecter ocr",
				ocrResultsReturned: "Résultats de l'OCR retournées",
				//zone reorder under div
				zonesReordered2Col: "Zones under this div were reordered - 2 columns",
				zonesReordered3Col: "Zones under this div were reordered - 3 columns",
				zonesReordered4Col: "Zones under this div were reordered - 4 columns",
				zonesReorderedDefault: "Zones under this div were reordered - default",
			}
		},
		msg: {
			saved: "La page a été enregistrée"
		}
	},
	settings: {
		tabLabels: {
			tabTitle: "Étiquettes",
			headerColor: "Couleur",
			headerName: "Nom",
			headerDescription: "Description",
			headerGlobal: "Globale",
			headerCreateNewLabel: "Créer une nouvelle étiquette",
			headerEditDelete: "Modifier ou supprimer l'étiquette",
			headerExistingLabels: "Étiquette existantes",
			buttonNewLabel: "Nouvelle étiquette",
			buttonCancel: "Annuler",
			buttonSave: "Sauvegarder",
			buttonReset: "Réinitialiser",
			buttonOk: "OK",
			newLabel: "Nouvelle étiquette",
			editLabel: "Modifier l'étiquette",
			required: "Requis",
			max20Chars: "20 caractères maximum",
			max40Chars: "40 caractères maximum",
			min1Char: "Minimun 1 caractère",
			headerConfirmDelete: "Voulez-vous vraiment supprimer cette étiquette?",
			labelName: "Nom",
			labelDescription: "Description",
			labelColor: "Couleur",
			labelColorPicker: "Label Color Picker",
			edit: "Edit",
			delete: "Delete",
			msg: {
				labelDeleted: "Étiquette supprimé",
				labelUpdated: "Étiquette mise à jour",
				labelCreated: "Étiquette a été créer",
				errorSavingLabel: "Erreur lors de l'enregistrement de l'étiquette: {0}",
				unableToGetLabels: "Impossible d'obtenir les étiquettes",
			}
		},
		tabGroups: {
			tabTitle: "Groupes",
			headerName: "Nom",
			headerDescription: "Description",
			headerUserCount: "Nombre d'utilisateurs",
			headerUsers: "Utilisateurs",
			headerCreateNewGroup: "Créer un nouveau groupe",
			headerEditDelete: "Modifier ou supprimer un groupe",
			headerExistingGroups: "Groupes existants",
			buttonNewGroup: "Nouveau groupe",
			buttonAddUser: "Ajouter un utilisateur",
			buttonCancel: "Annuler",
			buttonSave: "Sauvegarder",
			buttonReset: "Réinitialiser",
			buttonOk: "OK",
			headerConfirmDelete: "Voulez-vous vraiment supprimer ce groupe ??",
			labelName: "Nom",
			labelDescription: "Description",
			labelUser: "Utilisateur",
			msg: {
				groupDeleted: "Groupe supprimé",
				groupUpdated: "Groupe mis à jour",
				groupCreated: "Group Created",
				errorSavingGroup: "Erreur lors de l'enregistrement du groupe : {0}",
				unabletoGetGroups: "Impossible d'obtenir les groupes",
			}
		},
		tabAccounts: {
			tabTitle: "Comptes",
			headerName: "Nom",
			headerCreateNewAccount: "Créer un nouveau compte",
			headerAccounts: "Comptes",
			headerEditDelete: "Modifier ou supprimer un compte",
			headerExistingAccounts: "Comptes existants",
			buttonNewAccount: "Nouveau compte",
			buttonAddAccount: "Ajouter un compte",
			buttonCancel: "Annuler",
			buttonSave: "Sauvegarder",
			buttonReset: "Réinitialiser",
			buttonOk: "OK",
			headerConfirmDelete: "Êtes-vous sûr de vouloir supprimer ce compte?",
			headerEmail: "Courriel",
			headerGroup: "Groupe",
			headerActivated: "Activé",
			headerGoogleAccount: "Compte google",
			headerAdministrator: "Administrateur",
			headerResetPasswordFor: "Réinitialiser le mot de passe pour",
			headerResetPassword: "Réinitialiser le mot de passe",
			labelPassword: "Mot de passe",
			labelConfirmPassword: "Confirmer mot de passe",
			labelEmail: "Courriel",
			labelGroup: "Groupe",
			msg: {
				accountDeleted: "Compte supprimé",
				accountUpdated: "Compte mis à jour",
				accountAdded: "Compte ajouté",
				passwordChanged: "Mot de passe changé",
				errorAddingAccount: "Erreur lors de l'ajout du compte: {0}",
				errorSavingAccount: "Erreur lors de l'enregistrement du compte: {0}",
				errorSavingPassword: "Erreur lors de l'enregistrement du mot de passe: {0}",
				unableToGetAccount: "Impossible d'obtenir des comptes",
				unableToGetGroups: "Impossible d'obtenir des groupes",
			}
		},
		tabCss: {
			tabTitle: "CSS",
			headerName: "Nom",
			headerUrl: "Url",
			headerDefault: "Défaut",
			headerCreateCss: "Créer un nouveau css",
			headerEditDelete: "Modifier ou supprimer CSS",
			headerExistingCss: "CSS existant",
			buttonNewCss: "Nouveau CSS",
			buttonCancel: "Cancel",
			buttonSave: "Annuler",
			buttonReset: "Réinitialiser",
			buttonOk: "OK",
			headerConfirmDelete: "Êtes-vous sûr de vouloir supprimer ce css?",
			labelName: "Nom",
			labelUrl: "Url",
			msg: {
				deleted: "CSS supprimé",
				updated: "CSS mis à jour",
				created: "CSS créé",
				errorSaving: "Erreur lors de l'enregistrement du css: {0}",
				errorCreating: "Erreur lors de la création du css: {0}",
				unableToGetCSS: "Impossible d'obtenir la liste des CSS",
			}
		},
		tabDocumentExport: {
			tabTitle: "Exportation de documents"
		},
		tabZoneTransfer: {
			tabTitle: "Transfert de zone",
			headerDownloadDocumentZones: "Télécharger les zones de documents",
			headerUploadDocumentZones: "Charger des zones de document",
			headerDocumentZoneTransfer: "Transfert de zone de document",
			headerPageZoneTransfer: "Transfert de zone de la page",
			buttonTransferDocumentZones: "Zones de transfert des documents",
			buttonCancel: "Annuler",
			buttonClose: "Fermer",
			buttonLoadPageOptions: "Chargement de des options",
			buttonTransferPageZones: "Transfert des zones de la page",
			buttonUploadZones: "Téléchargement des zones",
			buttonDownloadZones: "Recevoir les Zones",
			documentId: "Identifiant du document",
			labelSourceDocumentId: "ID du document source",
			labelDestinationDocumentId: "ID du document de destination",
			labelZoneTransferType: "Type de transfert de zone",
			labelSourcePage: "Page Sources",
			labelDestinationPages: "Pages de destination",
			labelChooseFile: "Choisir le fichier",
			labelSelectSourcePage: "Sélectionner la page source",
			labelSelectDestinationPage: "Sélectionner page(s) de destination",
			labelSelectLayoutZipFile: "Sélectionnez le fichier zip de mise en page",
			labelDestinationDocumentName: "Nom du document de destination",
			msg: {
				docInitiated: "Transfert de zone de document lancé",
				errorDocTransferring: "Erreur lors du transfert des zones de document: {0}",
				pageInitiated: "Transfert de zone de page lancé",
				errorPageTransferring: "Erreur lors du transfert des zones de page: {0}",
				zonesInitiated: "Transfert des zones téléchargées lancé",
				errorZonesTransferring: "Erreur lors du transfert des zones téléchargées: {0}",
				downloadInitiated: "Zones de téléchargement lancées",
				errorDownload: "Erreur lors du téléchargement des zones: {0}"
			}
		},
	},
	dashboard: {
		groups: "Groupes",
		allGroups: "Tous les groupes",
		activities: "Activités",
		users: "Usagers",
		usersActive: "Actif",
		documents: "Documents",
		documentsImported: "Importé",
		documentsActive: "Actif",
		documentsDeleted: "Supprimé",
		pages: "Pages",
		pagesActive: "Actif",
		pagesModified: "Modifié",
		pagesValidated: "Validé",
		progress: "Progrès",
		exportedPDF: "PDFs exportés",
		validatedPercentage: "Pourcentage validé",
		reportTypes: {
			thisWeek: "Cette Semaine",
			lastWeek: "Semaine Dernière",
			thisMonth: "Ce mois-ci",
			lastMonth: "Mois Dernier",
			thisYear: "Cette Année",
			lastYear: "Année Dernière",
			last30Days: "30 derniers jours",
			last60Days: "60 derniers jours",
			last90Days: "90 derniers jours",
			last180Days: "180 derniers jours",
			last365Days: "365 derniers jours",
			sinceOnline: "Depuis en ligne",
		},
		months: {
			jan: "Jan",
			feb: "Fev",
			mar: "Mar",
			apr: "Avr",
			may: "Mai",
			jun: "Jun",
			jul: "Jul",
			aug: "Aout",
			sep: "Sep",
			oct: "Oct",
			nov: "Nov",
			dec: "Dec"
		}
	}
}