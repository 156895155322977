<template>
	<v-tab-item key="tabAccounts" class="pa-10">
		<v-container class="tab-settings">
			<template>
				<v-toolbar flat>
					<v-dialog v-model="passwordResetDialog" max-width="850px">
						<v-card>
							<v-card-title>
								<span class="text-h5">{{ $t("settings.tabAccounts.headerResetPasswordFor") + ' ' + accountEmail
								}}</span>
							</v-card-title>
							<v-card-text>
								<v-container>
									<v-row>
										<v-col cols="12" sm="6" md="4">
											<v-text-field v-model.trim="password" :rules="[rules.required, rules.min]" type="password"
												:label="$t('settings.tabAccounts.labelPassword')">
											</v-text-field>
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="12" sm="6" md="4">
											<v-text-field v-model.trim="passwordConfirm" :rules="[rules.required, rules.min, rules.match]"
												type="password" :label="$t('settings.tabAccounts.labelConfirmPassword')">
											</v-text-field>
										</v-col>
									</v-row>
								</v-container>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="closeResetPassword">
									{{ $t("settings.tabAccounts.buttonCancel") }}
								</v-btn>
								<v-btn color="blue darken-1" text @click="saveResetPassword"
									:disabled="passwordResetDialog == true && passwordInvalid == true">
									{{ $t("settings.tabAccounts.buttonSave") }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>


					<v-dialog v-model="dialog" max-width="850px">
						<template v-slot:activator="{ on, attr }">
							<v-card class="d-flex mb-10" title flat>
								<h2 class="h2-title">{{ $t("settings.tabAccounts.headerCreateNewAccount") }}</h2>
								<v-btn color="primary" class="mb-2 ml-10" v-bind="[attr]" v-on="on">
									<!--<v-icon v-if="buttonStyle.icons.display" class="mr-1">mdi-plus</v-icon>-->
									<span>{{ $t("settings.tabAccounts.buttonNewAccount") }}</span>
								</v-btn>
							</v-card>
						</template>

						<v-card>
							<v-card-title>
								<span class="text-h5">{{ formTitle }}</span>
							</v-card-title>
							<v-card-text>
								<v-container>
									<v-row>
										<v-col cols="16" sm="6" md="4">
											<v-text-field v-model="editedItem.email" :label="$t('settings.tabAccounts.labelEmail')"
												:readonly="this.editedIndex !== -1" :rules="[rules.required, rules.email]">
											</v-text-field>
										</v-col>

										<v-col cols="12" sm="6" md="4" class="">
											<v-label id="google-checkbox">{{ $t("settings.tabAccounts.headerGoogleAccount") }}</v-label>
											<v-checkbox v-model="editedItem.force_google" :disabled="user.email == editedItem.email" aria-labelledby="google-checkbox">
											</v-checkbox>
										</v-col>

										<v-col cols="12" sm="6" md="4">
											<v-label id="admin-checkbox">{{ $t("settings.tabAccounts.headerAdministrator") }}</v-label>
											<v-checkbox v-model="editedItem.customer_admin" :disabled="user.email == editedItem.email" aria-labelledby="admin-checkbox">
											</v-checkbox>
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="12" sm="6" md="4">
											<v-autocomplete v-model="editedItem.group" :items="groups" item-text="name" item-value="id"
												:label="$t('settings.tabAccounts.labelGroup')">
											</v-autocomplete>
										</v-col>
									</v-row>
								</v-container>
							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="close">
									{{ $t("settings.tabAccounts.buttonCancel") }}
								</v-btn>
								<v-btn color="blue darken-1" text @click="save"
									:disabled="validate == false">
									{{ $t("settings.tabAccounts.buttonSave") }}
								</v-btn>
							</v-card-actions>
						</v-card>

					</v-dialog>

					<v-dialog v-model="dialogDelete" max-width="550px">
						<v-card>
							<v-card-title class="text-h5">
								{{ $t("settings.tabAccounts.headerConfirmDelete") + ' ' + editedItem.email }}
							</v-card-title>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="closeDelete">
									{{ $t("settings.tabAccounts.buttonCancel") }}
								</v-btn>
								<v-btn color="blue darken-1" text @click="deleteItemConfirm">
									{{ $t("settings.tabAccounts.buttonOk") }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>

				</v-toolbar>
			</template>

			<v-data-table :headers="headers" :items="accounts" :items-per-page="10" class="">
				<template v-slot:top>
					<v-toolbar flat>
						<v-toolbar-title> 
							<h2 class="h2-title">{{ $t("settings.tabAccounts.headerExistingAccounts") }}</h2>
						</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</template>

				<template v-slot:item.activated="{ item }">
					<v-simple-checkbox disabled v-model="item.activated" key="item.key">
					</v-simple-checkbox>
				</template>

				<template v-slot:item.force_google="{ item }">
					<v-simple-checkbox disabled v-model="item.force_google" key="item.key">
					</v-simple-checkbox>
				</template>

				<template v-slot:item.customer_admin="{ item }">
					<v-simple-checkbox disabled v-model="item.customer_admin" key="item.key">
					</v-simple-checkbox>
				</template>

				<template v-slot:item.group="{ item }">
					<span>{{ getGroupName(item.group) }}</span>
				</template>

				<template v-slot:item.resetPassword="{ item }">
					<v-icon class="mr-2" @click="resetPasswordItem(item)">
						mdi-lock-reset
					</v-icon>
				</template>

				<template v-slot:item.actions="{ item }">
					<v-icon small class="mr-2" @click="editItem(item)">
						mdi-pencil
					</v-icon>
					<v-icon small class="mr-2" @click="deleteItem(item)" :disabled="user.email == item.email">
						mdi-delete
					</v-icon>

				</template>

				<!--
				<template v-slot:no-data>
					<v-btn color="primary" @click="fetchAccounts">
						{{ $t("settings.tabAccounts.buttonReset") }}
					</v-btn>
				</template>
        -->
			</v-data-table>
		</v-container>


	</v-tab-item>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import i18n from "@/plugins/i18n"

export default {
	data() {
		return {
			account: null,
			dialog: false,
			password: "",
			accountEmail: null,
			passwordConfirm: "",
			passwordResetDialog: false,
			dialogDelete: false,
			editedIndex: -1,
			addedAccounts: [],
			deletedAccounts: [],
			editedItem: {
				email: '',
				group: '',
				googleAccount: false,
				administrator: false,
			},
			defaultItem: {
				email: '',
				group: '',
				googleAccount: false,
				administrator: false,
			},
			rules: {
				required: (value) => !!value || "Required.",
				min: (v) => v.length >= 8 || "Min 8 characters",
				match: (val) => val == this.password || "Must match password",
				email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
				},
			},
			headers: [
				{
					text: this.$t("settings.tabAccounts.headerEmail"),
					value: "email"
				},
				{
					text: this.$t("settings.tabAccounts.headerGroup"),
					value: "group"
				},
				{
					text: this.$t("settings.tabAccounts.headerActivated"),
					value: "activated"
				},
				{
					text: this.$t("settings.tabAccounts.headerGoogleAccount"),
					value: "force_google"
				},
				{
					text: this.$t("settings.tabAccounts.headerAdministrator"),
					value: "customer_admin"
				},
				{
					text: this.$t("settings.tabAccounts.headerResetPassword"),
					value: "resetPassword",
					align: "center"
				},
				{
					text: this.$t("settings.tabAccounts.headerEditDelete"),
					value: "actions",
					align: "end"
				}
			]
		}

	},
	computed: {
		...mapGetters({
			language: "language/getLanguage",
			accounts: "settings/getAccounts",
			groups: "settings/getGroups",
			user: "user/userProfile",
			options: "application/getOptions",
			buttonStyle: "application/getButtonStyle",
		}),
		formTitle() {
			return this.editedIndex === -1 ? 'New Account' : 'Edit Account'
		},
		passwordInvalid() {
			console.log('validating passwords')
			console.log('password: ' + this.password)
			console.log('passwordConfirm: ' + this.passwordConfirm)
			if (this.password.length < 8 ||
				this.passwordConfirm.length < 8 ||
				this.password != this.passwordConfirm) {
				return true
			}
			return false
		},
		validate(){
			if(this.editedItem.email.length < 4){
				return false
			}
			return true
		}

	},
	watch: {
		dialog(val) {
			val || this.close()
		},
		dialogDelete(val) {
			val || this.closeDelete()
		}
	},
	methods: {
		...mapActions({
			fetchAccounts: "settings/fetchAccounts",
			addAccount: "settings/addAccount",
			updateAccountForceGoogle: "settings/updateAccountForceGoogle",
			deleteAccount: "settings/deleteAccount",
			updateAccountGroup: "settings/updateAccountGroup",
			resetAccountPassword: "settings/resetAccountPassword",
			fetchGroups: "settings/fetchGroups",
			updateAccountAdministrator: "settings/updateAccountAdministrator",
		}),
		getGroupName(groupId) {
			var retval = null
			for (var i = 0; i < this.groups.length; i++) {
				if (this.groups[i].id == groupId) {
					retval = this.groups[i].name
					break
				}
			}
			return retval
		},
		getGroupKey(groupId) {
			var retval = null
			for (var i = 0; i < this.groups.length; i++) {
				if (this.groups[i].id == groupId) {
					retval = this.groups[i].key
					break
				}
			}
			return retval
		},
		resetPasswordItem(item) {
			this.editedIndex = this.accounts.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.passwordResetDialog = true
			this.accountEmail = this.editedItem.email
		},
		editItem(item) {
			this.editedIndex = this.accounts.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialog = true
		},
		deleteItem(item) {
			this.editedIndex = this.accounts.indexOf(item)
			this.editedItem = Object.assign({}, item)
			if (this.user.email != this.editedItem.email) {
				this.dialogDelete = true
			}

		},
		deleteItemConfirm() {
			if (this.user.email != this.editedItem.email) {
				this.accounts.splice(this.editedIndex, 1)
				this.deleteAccount({
					payload: { userKey: this.editedItem.key }
				}).then((response) => {
					this.$snackbar.showMessage({ content: this.$t("settings.tabAccounts.msg.accountDeleted"), color: 'success' })
				})
			}

			this.closeDelete()
		},
		closeResetPassword() {
			this.passwordResetDialog = false
			//reset values
			this.password = ""
			this.passwordConfirm = ""
			
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		close() {
			this.dialog = false

			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		closeDelete() {
			this.dialogDelete = false

			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},

		saveResetPassword() {

			this.resetAccountPassword({
				payload: {
					key: this.editedItem.key,
					password: this.password,
					confirmPassword: this.passwordConfirm
				}
			}).then((response) => {
				if (response) {
					this.$snackbar.showMessage({ content: this.$t("settings.tabAccounts.msg.passwordChanged"), color: 'success' })
				}
			}).catch((error) => {
				console.log('password save error', error)
				this.$snackbar.showMessage({ content: this.$t("settings.tabAccounts.msg.errorSavingPassword", [error]), color: 'error' })
			})
			this.closeResetPassword()
		},
		/* 
		The update and add is a two-part operation because the setting of customer admin is 
		a separate api call.  so editing an account only lets you change the group, so if the 
		customer admin status is changed, that's a second call.
		*/
		save() {
			//need to define these because outer scope lost in 'then'
			var customerAdmin = this.editedItem.customer_admin
			var userKey = this.editedItem.key
			var userEmail = this.editedItem.email
			var force_google = this.editedItem.force_google
			//edit
			if (this.editedIndex > -1) {
				Object.assign(this.accounts[this.editedIndex], this.editedItem)
				console.log('updating existing account')
				//we're not going to know if these values actually changed, so just update
				this.updateAccountGroup({
					payload: {
						userKey: this.editedItem.key,
						groupKey: this.getGroupKey(this.editedItem.group),
					}
				}).then((response) => {
					//update the admin status
					
					//don't try to update admin status if user is self
					if(this.user.email == userEmail){
						this.$snackbar.showMessage({ content: this.$t("settings.tabAccounts.msg.accountUpdated") , color: 'success' })
						return
					}
					this.updateAccountAdministrator({
						payload: {
							userKey: userKey,
							customer_admin: customerAdmin,
						}
					}).then((response) => {
						this.$snackbar.showMessage({ content: this.$t("settings.tabAccounts.msg.accountUpdated"), color: 'success' })

						this.updateAccountForceGoogle({
							payload: {
								userKey: userKey,
								force_google: force_google,
							}
						}).then((response) => {
							this.$snackbar.showMessage({ content: this.$t("settings.tabAccounts.msg.accountUpdated"), color: 'success' })
		
						}).catch((error) => {
							this.$snackbar.showMessage({ content: this.$t("settings.tabAccounts.msg.errorSavingAccount", [error]), color: 'error' })
						})
					
					}).catch((error) => {
						this.$snackbar.showMessage({ content: this.$t("settings.tabAccounts.msg.errorSavingAccount", [error]), color: 'error' })
					})
				}).catch((error) => {
					this.$snackbar.showMessage({ content: this.$t("settings.tabAccounts.msg.errorSavingAccount", [error]), color: 'error' })
				})
			}
			else { //add
				console.log('saving new account')
				this.addAccount({
					payload: {
						email: this.editedItem.email,
						groupKey: this.getGroupKey(this.editedItem.group),
						groupName: this.getGroupName(this.editedItem.group),
						customer_admin: this.editedItem.customer_admin,
						force_google: this.editedItem.force_google,
					}
				}).then((response) => {
					//this.accounts.push(this.editedItem)
					this.fetchAccounts()
					this.$snackbar.showMessage({ content: this.$t("settings.tabAccounts.msg.accountAdded"), color: 'success' })
				}).catch((error) => {
					console.log('add account error', error)
					this.$snackbar.showMessage({ content: this.$t("settings.tabAccounts.msg.errorAddingAccount", [error]), color: 'error' })				
				})		
			}
			//fetch the groups after making the changes to update the cache
			this.fetchGroups()
			this.close()
			
		},

	},
	mounted() {
		if(this.options.is_admin){
			console.log('loading accounts')
			this.fetchAccounts()
			.then((response) => {
				this.isLoading = false
			})
			.catch((error) => {
				this.isLoading = false
				this.$snackbar.showMessage({ content: this.$t("settings.tabAccounts.msg.unableToGetAccount"), color: 'error' })
			})

		this.fetchGroups()
			.then((response) => {
				this.isLoading = false
			})
			.catch((error) => {
				this.isLoading = false
				this.$snackbar.showMessage({ content: this.$t("settings.tabAccounts.msg.unableToGetGroups"), color: 'error' })
			})
		}
	}

}

</script>

<style>
</style>