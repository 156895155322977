
export default {
	page: {},
	layout: {},
	contrastZones: {},
	detectedLayouts: [],
	selectedZone: null,
	selectedZoneList: null,
	selectedListItem: null,
	focusedZone: null,
	sensitivity: null,
	mlTables: [],
	tableZone: null,
	showTableEditor: false,
	initialLayout: {},
	previousLayout: {},
	lastSavedLayout: {},
	undoredoDone: [],
	undoredoUndone: [],
	selectedTableCell: {
		id: null,
		height: null,
		width: null,
		x: null,
		y: null,
		source: "PDF",
		customText: null,
		actual_text: null,
		ocrText: null,
		callingOcr: false
	},
	ocrAllZonesStatus: {
		inProgress: false,
		zonesProcessed: 0,
		totalZones: 0,
		pageProgress: 0,
		pageProgressMessage: null
	},
	pageOCRProgressMessage: null
}