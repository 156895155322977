<template>
	<v-row justify="center">
		<v-dialog v-model="dialog" width="600px">

			<!--for the dialog open-->
			<template v-slot:activator="{ on, attrs }">
				<v-btn id="shortcuts" large icon :ripple=defaultRipple v-bind="attrs" v-on="on" class="pt-5 ml-1">
					<v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
				</v-btn>
			</template>

			<v-card>
				<v-card-title>
					<span class="text-h5">Shortcut Menu</span>
				</v-card-title>
				<v-card-text>
					<div><kbd>c</kbd> Change the source of a Zone to Custom</div>
					<div><kbd>p</kbd> Change Selected Zone to a default Text</div>
					<div><kbd>a</kbd> Change Selected Zone to an Anchor</div>
					<div><kbd>h</kbd> Change Selected Zone to a Heading</div>
					<div><kbd>i</kbd> Change Selected Zone to Ignore (hide)</div>
					<div><kbd>f</kbd> Change Selected Zone to Field </div>
					<div><kbd>u</kbd>Change Selected Zone to a URL (link)</div>
					<div><kbd>l</kbd> Change Selected Zone to a List</div>
					<div><kbd>t</kbd> Change Selected Zone to a Table</div>
					<div><kbd>b</kbd> Change Selected Zone to a Blockquote container</div>
					<div><kbd>q</kbd> Change Selected Zone to a Quote</div>
					<div><kbd>g</kbd> Change Selected Zone to a Graphic</div>
					<div><kbd>e</kbd> Change Selected Zone to a Citation</div>
					<div><kbd>y</kbd> Change Selected Zone to a Sensitivity</div>
					<div><kbd>d</kbd> Change Selected Zone to a Div</div>
					<div><kbd>delete</kbd> or <kbd>x</kbd> Remove the selected zone</div>
					<div><kbd>m</kbd> Toggle the Merge Attribute</div>
					<div><kbd>k</kbd> Toggle the Break Lines Attribute</div>
					<div><kbd>v</kbd> Toggle the visible Attribute</div>
					<div><kbd>
							<v-icon>mdi-arrow-up-thin</v-icon>
						</kbd> Move Selected Zone up</div>
					<div><kbd>
							<v-icon>mdi-arrow-down-thin</v-icon>
						</kbd> Move Selected Zone down</div>
					<div><kbd>
							<v-icon>mdi-arrow-left-thin</v-icon>
						</kbd> Move Selected Zone left</div>
					<div><kbd>
							<v-icon>mdi-arrow-right-thin</v-icon>
						</kbd> Move Selected Zone right</div>
					<div><kbd>ctrl</kbd> <kbd>s</kbd> Save page</div>
					<div><kbd>ctrl</kbd> <kbd>p</kbd> Preview page</div>
					<div><kbd>ctrl</kbd> <kbd>i</kbd> Zoom in</div>
					<div><kbd>ctrl</kbd> <kbd>o</kbd> Zoom out</div>
					<div><kbd>ctrl</kbd> <kbd>m</kbd> Mark page as validated</div>
					<div><kbd>ctrl</kbd> <kbd>h</kbd> Show this help window</div>
					<div><kbd>alt</kbd> <kbd>b</kbd> Show Page options</div>
					<div><kbd>alt</kbd> <kbd>z</kbd> Show Zone options</div>
					<div><kbd>alt</kbd> <kbd>p</kbd> Show List of pages</div>



				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="dialog = false">
						Close
					</v-btn>
				</v-card-actions>

			</v-card>

		</v-dialog>

	</v-row>
</template>

<script>
import Vue from "vue"
import { mapGetters, mapActions, mapMutations } from "vuex"
import EventBus from "@/eventBus"
import helpers from "@/helpers/common"
import TabUnicodeMapping from "../views/document/components/tabs/TabUnicodeMapping.vue"

export default {
	name: "Shortcuts",
	props: [],
	data() {
		return {
			defaultRipple: { center: true },
			dialog: false,
			focusedZone: null,
		}
	},
	components: {
		//ShortcutEvents,
	},
	computed: {
		...mapGetters({
			selectedZone: "page/getSelectedZone",
			showTableEditor: "page/getShowTableEditor",
			options: "application/getOptions",
			page: "page/getPage",
		})
	},
	methods: {
		...mapActions({
			previewPage: "page/previewPage",
			zoomIn: "application/zoomIn",
			zoomOut: "application/zoomOut",
		}),
		...mapMutations({
			setSelectedZone: "page/SET_SELECTED_ZONE",
		}),
		isContrastZone(zone) {
			return helpers.isContrastZone(zone)
		},
		deleteZone(){
			//new.  emit REMOVE_ZONE to be picked up on Page component
			EventBus.$emit("REMOVE_ZONE", this.selectedZone)
		},
		validateTarget(event){
			/*
			target must be an svg, which has target.localName 'svg' or
			the svg editor container, which has target.className 'svg'
			*/
			if(event.target.localName == 'svg' || 
				event.target.localName == 'body' ||
				event.target.className == 'svg' || 
				event.target.id == 'svgTable'){
				return true
			}
			return false
		},
		//target function for keydown event
		keydown(event) {
			// console.log('keydown event:', event)
			// console.log('target', event.target)

			//event.altKey event.ctrlKey event.keyCode
			//event.preventDefault() //**** this prevents typing in the editor input fields!!! ****
			if(! this.validateTarget(event)){
				return
			}

			this.handleBasicKeydownEvent(event)
			this.handleSelectedZoneKeydownEvent(event)
			this.preventArrowKeyScrolling(event)
			this.handleAdditionalKeydownEvents(event)

		},
		//handle basic keydown event
		handleBasicKeydownEvent(event) {
			var ctrl = event.ctrlKey
			var alt = event.altKey
			switch (event.keyCode) {
				case 83: //s
					//saveLayout
					if(ctrl){
						this.saveLayout()
					}
					break
				case 80: // ctrl-p
					if (ctrl) {
						this.previewPage()
					}
					else if (alt) {
						//tab select pages
						this.showPagesSideBar()
					}
					break
				case 73: //ctrl-i
					if (ctrl) {
						this.zoomIn()
					}
					break
				case 79: //ctrl-o
					if (ctrl) {
						this.zoomOut()
					}
					break
				case 72: //ctrl-h
					if (ctrl) {
						//help
						//show keyboard shortcuts
						this.showShortcuts()
					}
					else if (alt) {
						//tab select history
					}
					break
				case 37: //ctrl - <
					if (ctrl) {
						this.previousPage()
					}
					break
				case 39: //ctrl - >
					if (ctrl) {
						this.nextPage()
					}
					break
				case 86: //alt - v
					if (alt) {
						//validate/markdone
						this.markDone()
					}
					break
				case 90: //alt - z
					if (alt) {
						//tab zones
						this.tabZones()
					}
					break
				case 66: //alt - b
					if (alt) {
						//tab page properties
						this.tabPage()
					}
					break
				case 27: // esc
					/*
					if showing table preview, stop and show page preview
					*/
					
					break
				default:
				//nothing here
			}
		},
		/*
			This section is taken from document_controllers.js #1023
		*/
		handleAdditionalKeydownEvents(event) {
			// console.log('in handle additional keydown events. event: ', event)
			if (event.target.nodeName == 'TEXTAREA' || event.target.nodeName == 'INPUT') {
				return true
			}
			if (this.showTableEditor == false && this.selectedZone != null && !this.isContrastZone(this.selectedZone)) {
				//image
				if (this.selectedZone.type == 'image') {
					if (event.keyCode == 86) { //v
						this.selectedZone.visible = !this.selectedZone.visible
						return true
					}
					else if (event.keyCode == 90) {  //z
						if (this.selectedZone.hideZone != null) {
							this.selectedZone.hideZone = !this.selectedZone.hideZone
						} else {
							this.selectedZone.hideZone = true
						}
					}
					else if (event.keyCode == 8 || event.keyCode == 46) { // del keys
						this.selectedZone.hideZone = true
						this.selectedZone.visible = false
						this.setSelectedZone(this.selectedZone)
					}
					else {
						return false
					}
					
				}
				//can't change type for field
				if(this.selectedZone.type == 'field'){
					return false
				}

				//This statement has no effect because of the previous one.  Leaving for history.
				if (!this.options.pro_cust && this.selectedZone.type == 'field') {
					return false
				}

				if (this.focusedZone == null) {
					this.focusedZone = this.selectedZone
				}

				switch (event.keyCode) {
					case 37: //arrow left
						if (this.focusedZone) {
							if (event.shiftKey) {
								if (event.altKey) {
									if (this.selectedZone.w > 10) {
										this.selectedZone.w = this.selectedZone.w - 10
									}
								}
								else {
									if (this.selectedZone.w > 1) {
										this.selectedZone.w = this.selectedZone.w - 1
									}
								}
							}
							else {
								if (event.altKey) {
									if (this.selectedZone.x > 10) {
										this.selectedZone.x = this.selectedZone.x - 10
									}
								}
								else {
									if (this.selectedZone.x > 1) {
										this.selectedZone.x = this.selectedZone.x - 1
									}
								}
							}
							event.preventDefault()
						}
						break

					case 38: //arrow up
						if (this.focusedZone) {
							if (event.shiftKey) {
								if (event.altKey) {
									if (this.selectedZone.h > 10) {
										this.selectedZone.h = this.selectedZone.h - 10
									}
								} else {
									if (this.selectedZone.h > 1) {
										this.selectedZone.h = this.selectedZone.h - 1
									}
								}
							}
							else {
								if (event.altKey) {
									if (this.selectedZone.y > 10) {
										this.selectedZone.y = this.selectedZone.y - 10
									}
								}
								else {
									if (this.selectedZone.y > 1) {
										this.selectedZone.y = this.selectedZone.y - 1
									}
								}
							}
							event.preventDefault()
						}
						break

					case 39: //arrow right
						if (this.focusedZone) {
							if (event.shiftKey) {
								if (event.altKey) {
									if (this.selectedZone.x + this.selectedZone.w + 10 < this.page.width) {
										this.selectedZone.w = this.selectedZone.w + 10
									}
								}
								else {
									if (this.selectedZone.x + this.selectedZone.w + 1 < this.page.width) {
										this.selectedZone.w = this.selectedZone.w + 1
									}
								}
							}
							else {
								if (event.altKey) {
									if (this.selectedZone.x + this.selectedZone.w + 10 < this.page.width) {
										this.selectedZone.x = this.selectedZone.x + 10
									}
								}
								else {
									if (this.selectedZone.x + this.selectedZone.w + 1 < this.page.width) {
										this.selectedZone.x = this.selectedZone.x + 1
									}
								}
							}
							event.preventDefault()
						}
						break

					case 40: //arrow down
						console.log('in arrow down. selected zone is: ', this.selectedZone)
						if (this.focusedZone) {
							if (event.shiftKey) {
								if (event.altKey) {
									if (this.selectedZone.y + this.selectedZone.h + 10 < this.page.height) {
										this.selectedZone.h = this.selectedZone.h + 10
									}
								}
								else {
									if (this.selectedZone.y + this.selectedZone.h + 1 < this.page.height) {
										this.selectedZone.h = this.selectedZone.h + 1
									}
								}
							}
							else {
								if (event.altKey) {
									if (this.selectedZone.y + this.selectedZone.h + 10 < this.page.height) {
										this.selectedZone.y = this.selectedZone.y + 10
									}
								}
								else {
									if (this.selectedZone.y + this.selectedZone.h + 1 < this.page.height) {
										this.selectedZone.y = this.selectedZone.y + 1
									}
								}
							}
							event.preventDefault()
						}
						break

					case 8: //del
						this.deleteZone(this.selectedZone)
						event.preventDefault()
						break
					case 46: //del
						this.deleteZone(this.selectedZone)
						event.preventDefault()
						break
					case 88: //x
						this.deleteZone(this.selectedZone)
						event.preventDefault()
						break
					case 65: // a
						this.selectedZone.type = 'anchor'
						this.typeChanged(this.selectedZone)
						break
					case 66: // b
						this.selectedZone.type = 'blockquote'
						this.typeChanged(this.selectedZone)
						break
					case 67: // c
						// Now switch to custom source
						this.selectedZone.source = 'Custom'
						this.sourceChanged(this.selectedZone)
						break
					case 68: // d
						this.selectedZone.type = 'div'
						this.typeChanged(this.selectedZone)
						break
					case 69: // e
						this.selectedZone.type = 'cite'
						this.typeChanged(this.selectedZone)
						break
					case 70: // f
						// do this only if user is a part of pro customer
						if (this.options.pro_cust) {
							this.selectedZone.type = 'field'
							this.typeChanged(this.selectedZone)
						}
						break
					case 71: // g
						this.selectedZone.type = 'graphic'
						this.typeChanged(this.selectedZone)
						break
					case 72: // h
						this.selectedZone.type = 'heading'
						this.typeChanged(this.selectedZone)
						break
					case 73: // i
						this.selectedZone.type = 'ignore'
						this.typeChanged(this.selectedZone)
						break
					case 75: // k
						this.selectedZone.breakLine = !this.selectedZone.breakLine
						break
					case 76: // l
						if (this.selectedZone.type == 'list') {
							if (this.selectedListItem !== undefined) {
								this.selectedListItem.uselbl = !this.selectedListItem.uselbl
							}
						}
						else {
							this.selectedZone.type = 'list'
							this.typeChanged(this.selectedZone)
						}
						break
					case 77: // m
						if (event.ctrlKey) {
							this.saveLayout(false)
						}
						else {
							this.selectedZone.merge = !this.selectedZone.merge
						}
						break
					case 79: // o
						this.selectedZone.type = 'OCR'
						break
					case 80: // p
						this.selectedZone.type = 'text'
						this.typeChanged(this.selectedZone)
						break
					case 81: // q
						this.selectedZone.type = 'quote'
						this.typeChanged(this.selectedZone)
						break
					case 83: // s
						this.selectedZone.type = 'sensitivity'
						this.typeChanged(this.selectedZone)
						break
					case 84: // t
						console.log('picked up keyCode 84 for t')
						if (this.previous_key == 70) {
							this.selectedZone.fieldType_str = 'e_text'
						} else {
							this.selectedZone.type = 'table'
							this.typeChanged(this.selectedZone)
						}
						break
					case 85: // u
						this.selectedZone.type = 'link'
						this.typeChanged(this.selectedZone)
						break
					case 89: // y
						this.selectedZone.type = 'sensitivity'
						this.typeChanged(this.selectedZone)
						break
					case 49:
					case 97:
						this.selectedZone.type = 'heading'
						this.selectedZone.headerlevel = 1
						this.typeChanged(this.selectedZone)
						break
					case 50:
					case 98:
						this.selectedZone.type = 'heading'
						this.selectedZone.headerlevel = 2
						this.typeChanged(this.selectedZone)
						break
					case 51:
					case 99:
						this.selectedZone.type = 'heading'
						this.selectedZone.headerlevel = 3
						this.typeChanged(this.selectedZone)
						break
					case 52:
					case 100:
						this.selectedZone.type = 'heading'
						this.selectedZone.headerlevel = 4
						this.typeChanged(this.selectedZone)
						break
					case 53:
					case 101:
						this.selectedZone.type = 'heading'
						this.selectedZone.headerlevel = 5
						this.typeChanged(this.selectedZone)
						break
					case 54:
					case 102:
						this.selectedZone.type = 'heading'
						this.selectedZone.headerlevel = 6
						this.typeChanged(this.selectedZone)
						break
					case 187: // +
						if (this.selectedZone.type == 'heading' && this.selectedZone.headerlevel < 8) {
							this.selectedZone.headerlevel = this.selectedZone.headerlevel + 1
						}
						break
					case 189: // -
						if (this.selectedZone.type == 'heading' && this.selectedZone.headerlevel > 1) {
							this.selectedZone.headerlevel = this.selectedZone.headerlevel - 1
						}
						break

					default:
						console.log(' keydown:' + event.keyCode)
						return false
				}
			}
			//testing
			// console.log('preventing default and stopping propagation')
			event.preventDefault()
			event.stopPropagation()

			//finally, we want to set the selected zone so the changes are reflected in the editor
			this.setSelectedZone(this.selectedZone)

		},
		//handle keydown event for a selected zone
		handleSelectedZoneKeydownEvent(event) {
			this.handleImageZoneKeydownEvent(event)
		},
		//selected zone is image
		handleImageZoneKeydownEvent(event) {
			if (this.selectedZone != null && this.selectedZone.type == "image") {
				if (event.keyCode == 86) { //v
					console.log('zone is image and v key pressed')
					//this doesn't make sense for changing one attribute of a complex object!
					//var selectedZone = this.selectedZone
					//selectedZone.visible = !this.selectedZone.visible
					//this.setSelectedZone(selectedZone)

					//use this to manipulate property on object in store directly
					this.$store.state.page.selectedZone.visible = !this.selectedZone.visible
				}
			}
		},

		//prevent arrow keys to scroll instead of moving zones
		preventArrowKeyScrolling(event) {
			var arrowKeys = new Array(37, 38, 39, 40)
			var key = event.keyCode
			if (arrowKeys.includes(key)) {
				console.log('arrow key down')
				var anythingFocused = (document.activeElement !== null &&
					document.activeElement !== document.body &&
					document.activeElement !== document.documentElement)

				if (anythingFocused) {
					if (['INPUT', 'SELECT', 'TEXTAREA'].indexOf(document.activeElement.tagName) >= 0) {
						console.log('arrow key down in form input')
						return true
					}
				}
				console.log('preventing default')
				event.preventDefault()
				return false
			}
			return true
		},
		typeChanged() {
			EventBus.$emit('type-changed')
		},
		sourceChanged(){
			EventBus.$emit('source-changed')
		},
		previousPage(){
			EventBus.$emit('previous-page')
		},
		nextPage(){
			EventBus.$emit('next-page')
		},
		markDone(){
			EventBus.$emit('mark-done')
		},
		tabZones(){
			EventBus.$emit("tabZones", {})
		},
		tabPage(){
			EventBus.$emit("tabPage", {})
		},
		previewTable(){
			EventBus.$emit("preview-table-layout", {})
		},
		showPagesSideBar(){
			EventBus.$emit("show-pages-sidebar", {})
		},
		saveLayout(){
			EventBus.$emit("save-page")
		},
		showShortcuts(){
			console.log('emitting showshortcuts')
			document.getElementById('shortcuts').click()
		},


	},
	created() {
		//window.removeEventListener("keydown", this.keydown)
		//window.addEventListener("keydown", this.keydown)

		//confirmed that this is basically same as keydown event listener on window ie no diff
		//window.document.addEventListener("keydown", this.docKeydown)


	},
	mounted() {
		window.addEventListener("keydown", this.keydown)
	},
	destroyed(){
		window.removeEventListener("keydown", this.keydown)
	}
}


</script>

<style scoped>
div {
	margin-bottom: 10px;
}

kbd {
	padding: .1em .6em;
	border: 1px solid #ccc;
	font-size: 15px;
	font-family: Arial, Helvetica, sans-serif;
	background-color: #fff !important;
	color: #333 !important;
	-moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
	-webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
	border-radius: 3px;
	display: inline-block;
	margin: 0 .1em;
	text-shadow: 0 1px 0 #fff;
	line-height: 1.4;
	white-space: nowrap;
}
</style>