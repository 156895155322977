<template>
    <div :class="showSecondToolbar ? 'side-bar' : 'side-bar-up'">
      <v-tabs fixed-tabs v-model="selectedTab" height=40 style="max-height:40px;">
        <v-tab key="Page">Page</v-tab>
        <v-tab key="Zones">Zones</v-tab>
				<v-tab key="Contrast" v-if="contrastZones && showContrastTab">Contrast</v-tab>
      </v-tabs>

      <v-tabs-items v-model="selectedTab" style="overflow: hidden !important;">
          <SideBarPage v-if="page && document" :page=page :document="document" />
          <SideBarZones v-if="zones" :zones=zones :page=page :document="document" />
					<SideBarContrast v-if="contrastZones && showContrastTab" :contrastZones="contrastZones" :page=page :document="document" />
      </v-tabs-items>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import SideBarPage from "./SideBarPage"
import SideBarZones from "./SideBarZones"
import SideBarContrast from "./SideBarContrast"
import EventBus from "@/eventBus"
import helpers from "@/helpers/common"


export default {
  props: ['page', 'document', 'zones', 'contrastZones'],
   data() {
    return {
      isLoading: true,
      errors: null,
      sensitivity: 12,
      selectedReadingOrder: "1 column",
      selectedTab: undefined,
    }
  },
	computed: {
		...mapGetters({
			showSecondToolbar: "application/getShowSecondToolbar",
			selectedZone: "page/getSelectedZone",
		}),
		//hide contrast zones.
		showContrastTab(){
			return false
			/*
			if(process.env.VUE_APP_ENV === 'qa'){
				return true
			}
			return false
			*/
		}
	},
  components: {
      SideBarPage,
      SideBarZones,
			SideBarContrast,
  },
	methods: {
		isContrastZone(zone) {
			return helpers.isContrastZone(zone)
		},
	},
	mounted(){
		EventBus.$on('tabContrast', (msg) => {
			this.selectedTab = 2
		})

		EventBus.$on('tabZones', (msg) => {
			this.selectedTab = 1
		})

		EventBus.$on('tabPage', (msg) => {
			this.selectedTab = 0
		})
	},
	beforeDestroy(){
		EventBus.$off('tabContrast')
		EventBus.$off('tabZones')
		EventBus.$off('tabPage')
	}
}
</script>
<style lang="scss" scoped>

.short-text {
  width: 50px;
  max-width: 50px;
}

.side-bar {
  position: fixed;
  top: 204px;  //testing top: 230px  then 212 then 204;
  left: 80px;
  width: 320px;
  padding: 0;
  height: 100% !important;
  border-right: 1px solid lightgray;
  display:flex; 
  flex-direction: column;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
}

.side-bar-up {
  position: fixed;
  top: 138px;  //testing top: 230px  then 212 then 204;
  left: 80px;
  width: 320px;
  padding: 0;
  height: 100% !important;
  border-right: 1px solid lightgray;
  display:flex; 
  flex-direction: column;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
}

.side-bar > div.v-window {
  overflow: auto !important;
}

.side-bar >>> .v-window__container,
.side-bar >>> .v-window-item  {
  height: 100%;
}

/* customise the dimensions of the card content here */
.side-bar >>> .v-card {
  height: 100%;
}

.side-bar-tab {
  padding: 6px;
  & > * {
    font-size: 14px;
    .col {
      padding: 2px 10px;
      min-height: 55px;
    }

    .v-btn {
      margin: 10px;
    }
  }
}

.v-tab {
  border: 1px solid lightgray;
  font-weight: bold;
  color: var(--v-primary-base);
}
.v-tab.v-tab--active {
  background-color: lightgray;
}
// .v-tabs-items
//     {
//         min-height: 100vh;
//     }

.btn-group-item {
  margin: 0 !important;
  padding: 0 !important
}

.section-divider {
  margin-bottom: 5px;
  margin-top: -31px;
  padding-bottom: 20px;
}
</style>