<template>
	<v-tab-item key="tabImages">

		<v-container fluid class="pa-10">
			<v-row class="pa-5" v-if="images">
				<v-col cols="5">
					<template v-if="images.results">
						<v-btn class="ma-2" tile @click="prevImages" v-if="images.pager.has_prev">
							<v-icon left>
								mdi-arrow-left-bold
							</v-icon>
							{{ $t("document.tabImages.previousPage") }}
						</v-btn>

						<span>
							Images {{ imageCountStart }} {{ $t("document.tabImages.imagesTo") }} {{ imageCountEnd }} {{
								images.pager.has_next ? $t("document.tabImages.imagesMany") : '' }}
						</span>

						<v-btn class="ma-2" tile @click="nextImages" v-if="images.pager.has_next">
							{{ $t("document.tabImages.nextPage") }}
							<v-icon right>
								mdi-arrow-right-bold
							</v-icon>

						</v-btn>
					</template>
				</v-col>
				<v-col cols="2">
					<v-checkbox v-model="checkHideAll" :label="$t('document.tabImages.hideAllZones')"
						@click="toggleAllZone"></v-checkbox>
				</v-col>
				<v-col cols="2">
					<v-checkbox v-model="checkAllVisible" :label="$t('document.tabImages.allVisible')"
						@click="toggleAllVisible"></v-checkbox>
				</v-col>
				<v-col cols="3">
					<v-btn class="ma-2" color="primary" @click=saveImages>
						<v-icon class="mr-3">mdi-content-save-outline</v-icon>
						{{ $t("document.tabImages.saveImageProperties") }}
					</v-btn>
				</v-col>
			</v-row>

			<template v-for="item in images.results">

				<v-row :key="item.key" class="mb-10 pa-5 image-row">

					<v-col cols="2" class="pa-6">
						<v-img class="img-border" :src="'/api/v1/thumbnail/' + item.image_url"></v-img>
					</v-col>

					<v-col cols="10" class="pl-8">
						<v-row no>
							<v-col cols="6" class="pt-8">
								<span>Page <b>{{ item.page_no }} </b> / {{ item.name }}</span>
							</v-col>
							<v-col cols="3">
								<v-checkbox v-model="item.hideZone" :label="$t('document.tabImages.hideThisZone')"></v-checkbox>

							</v-col>
							<v-col cols="3">
								<v-checkbox v-model="item.visible" :label="$t('document.tabImages.visible')"></v-checkbox>
							</v-col>
						</v-row>
						<v-row>
							<v-textarea v-if="item.visible" outlined v-model="item.description" name="input-7-4"
								:label="$t('document.tabImages.imageDescription')"></v-textarea>
							<span v-if="!item.visible" color="red">
								Not used
							</span>
						</v-row>
					</v-col>
				</v-row>

			</template>

			<v-row class="pa-5" v-if="images">
				<v-col cols="3" offset="9">
					<v-btn class="ma-2" color="primary" @click=saveImages>
						<v-icon class="mr-3">mdi-content-save-outline</v-icon>
						{{ $t("document.tabImages.saveImageProperties") }}
					</v-btn>
				</v-col>
			</v-row>

		</v-container>
	</v-tab-item>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import i18n from "@/plugins/i18n"

export default {
	data() {
		return {
			checkHideAll: false,
			checkAllVisible: false,
			imageCountStart: undefined,
			imageCountEnd: undefined
		}
	},
	components: {},
	computed: {
		...mapGetters({
			images: "document/getDocumentImages",
		}),
	},
	methods: {
		...mapActions({
			fetchDocumentImages: "document/fetchDocumentImages",
			saveDocumentImages: "document/saveDocumentImages",
		}),
		saveImages() {
			this.$snackbar.showMessage({
				content: i18n.t("document.msg.imagesSaved"),
				color: 'info'
			})

			this.saveDocumentImages({ payload: { key: this.$route.params.key, images: this.images.results } })

		},
		updateCounters() {
			//need to check for undefined
			if (this.images.results !== null && this.images.results !== undefined) {
				const numImages = this.images.results.length
				const resultsPageNum = this.images.pager.page
				const IMAGES_PER_PAGE = 25

				this.imageCountStart = resultsPageNum == 1 ? 1 : ((resultsPageNum - 1) * IMAGES_PER_PAGE) + 1
				this.imageCountEnd = resultsPageNum == 1 ? numImages : ((resultsPageNum - 1) * IMAGES_PER_PAGE) + numImages
			}
		},
		prevImages() {
			this.fetchDocumentImages({ payload: { key: this.$route.params.key, page: this.images.pager.prev_page } })
				.then((response) => {
					this.isLoading = false
					this.updateCounters()
				})
				.catch((error) => {
					this.isLoading = false
					this.$snackbar.showMessage({
						content: this.$t("document.tabImages.msg.errorGettingImages"),
						color: 'error'
					})
				})
		},
		nextImages() {
			this.fetchDocumentImages({ payload: { key: this.$route.params.key, page: this.images.pager.next_page } })
				.then((response) => {
					this.isLoading = false
					this.updateCounters()
				})
				.catch((error) => {
					this.isLoading = false
					this.$snackbar.showMessage({
						content: this.$t("document.tabImages.msg.errorGettingImages"),
						color: 'error'
					})
				})
		},
		toggleAllZone() {

			for (var i = 0; i < this.images.results.length; i++) {
				this.images.results[i].hideZone = this.checkHideAll;
			}

		},
		toggleAllVisible() {

			for (var i = 0; i < this.images.results.length; i++) {
				this.images.results[i].visible = this.checkAllVisible;
			}

		},
	},
	mounted() {
		this.fetchDocumentImages({ payload: { key: this.$route.params.key } })
			.then((response) => {
				this.isLoading = false
				this.updateCounters()
			})
			.catch((error) => {
				this.isLoading = false
				this.$snackbar.showMessage({
					content: this.$t("document.tabImages.msg.errorGettingImages"),
					color: 'error'
				})
			})
	},
}
</script>
<style scoped>
.tab-content {
	width: 90%;
	margin: auto;
}

.image-row {
	border-top: 1px solid;
}

.img-border {
	border-radius: 4px;
	border: 1px solid
}
</style>
