import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'

// firebase config
/*
const firebaseConfig = {
  apiKey: 'AIzaSyDbZXvamYkFqu0OBnoFmBmSMnWpw6YopSI',
  authDomain: 'equidox-qa.firebaseapp.com',
  databaseURL: 'https://equidox-qa.firebaseio.com',
  projectId: 'equidox-qa',
  storageBucket: 'equidox-qa.appspot.com',
  messagingSenderId: '334668885899',
  appId: '1:334668885899:web:e0c8a5bfc3cc9bfe7f498e'
}
*/

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_API_KEY,
  authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FB_DB_URL,
  projectId: process.env.VUE_APP_FB_PROJ_ID,
  storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FB_SENDER_ID,
  appId: process.env.VUE_APP_FB_APP_ID
}

// Init firebase
firebase.default.initializeApp(firebaseConfig)
const auth = firebase.default.auth()

let google_provider = new firebase.default.auth.GoogleAuthProvider()
google_provider.setCustomParameters({
    prompt: 'select_account'
})

let microsoft_provider = new firebase.default.auth.OAuthProvider('microsoft.com')
microsoft_provider.setCustomParameters({
  prompt: 'select_account'
})

export {
      auth,
      google_provider,
			microsoft_provider,
			firebase,
}
