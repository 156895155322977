export default {
  loginSuccess(state, userData) {
    console.log("Login success", userData)
    state.authenticated = true
  },
  logoutSuccess(state, userData) {
    console.log("Logout success")
    state.authenticated = false
  },
	AUTHENTICATION_CHECK_SUCCESS(state, userData){
		// console.log("Authentication check success", userData)
		state.authenticated = true
	},
  setUserProfile(state, val) {
    state.userProfile = val
  },
  setPerformingRequest(state, val) {
    state.performingRequest = val
  },
  setUserChannel(state, channelInfo) {
    state.userChannel = channelInfo
  },
  CHANNEL_MESSAGE_IN(state, message) {
    console.log('Channel message in =>', message)
  },
  SET_LABELS(state, labels) {
    state.labels = labels
  },
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications
  },
	SET_UID_LOGIN(state, val){
		state.uidLogin = val
	},
	SET_FB_AUTH_STATE(state, val){
		state.fbAuthState = val
	},
  SET_CSRF(state, csrf_token){
    state.csrf_token = csrf_token
  },
	SET_LOGOUT_IN_PROCESS(state, val){
		state.logoutInProcess = val
	},
}
