import HTTP from "@/helpers/http-common"
import EventBus from "@/eventBus"


function initImportingEvents(state, commit) {
	EventBus.$on('import/message', (message) => {
		let tracking = message.data.tracking
		commit("SET_IMPORTING_DOCUMENT_ATT", { tracking: tracking, att: 'last_msg', value: message.data.msg })
		commit("SET_IMPORTING_DOCUMENT_ATT", { tracking: tracking, att: 'progress', value: Math.min(message.data.progress, 100) })

		if (Math.min(message.data.progress, 100) >= 100) {
			commit("SET_IMPORTING_DOCUMENT_ATT", { tracking: tracking, att: 'ready', value: true })
			commit("SET_IMPORTING_DOCUMENT_ATT", { tracking: tracking, att: 'document_key', value: state.importingDocuments[message.data.tracking].document.doc_key })
		}
	})

	EventBus.$on('import/totalpages', (message) => {
		let tracking = message.data.tracking
		commit("SET_IMPORTING_DOCUMENT_ATT", { tracking: tracking, att: 'totalPages', value: message.data.totalPages })
		commit("SET_IMPORTING_DOCUMENT_ATT", { tracking: tracking, att: 'progress', value: Math.min(message.data.progress, 100) })
	})

	EventBus.$on('import/currentpage', (message) => {
		let tracking = message.data.tracking
		commit("SET_IMPORTING_DOCUMENT_ATT", { tracking: tracking, att: 'last_msg', value: message.data.msg })
		commit("SET_IMPORTING_DOCUMENT_ATT", { tracking: tracking, att: 'progress', value: Math.min(message.data.progress, 100) })
	})

	EventBus.$on('import/uploadcount', (message) => {
		let tracking = message.data.tracking
		commit("SET_IMPORTING_DOCUMENT_ATT", { tracking: tracking, att: 'last_msg', value: message.data.msg })
		commit("SET_IMPORTING_DOCUMENT_ATT", { tracking: tracking, att: 'progress', value: Math.min(message.data.progress, 100) })
	})

	EventBus.$on('import/OCRCount', (message) => {
		let tracking = message.data.tracking
		commit("SET_IMPORTING_DOCUMENT_ATT", { tracking: tracking, att: 'last_msg', value: message.data.msg })
		commit("SET_IMPORTING_DOCUMENT_ATT", { tracking: tracking, att: 'progress', value: Math.min(message.data.progress, 100) })
	})

	EventBus.$on('import/OCRCount', (message) => {
		console.log('Import error', message)
	})

	EventBus.$on('channel/message', (message) => {
		console.log('channel message', message)
	})

	commit("SET_IMPORTING_EVENTS", true)
}

export default {

	async deleteDocument({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {

			HTTP.delete("/api/v1/document/" + payload.doc_key)
				.then((response) => {
					resolve('success')
					commit("DELETE_DOCUMENT", payload.doc_key)
				}).catch((error) => {
					console.log('error deleting document', error)
					reject(error)
				})
		})
	},

	async permanentlyDeleteDocument({ dispatch, commit }, { payload }) {
		return new Promise((resolve, reject) => {

			HTTP.delete("/api/v1/delete_doc_permanent/" + payload.doc_key)
				.then((response) => {
					resolve(response)
					commit("DELETE_DOCUMENT", payload.doc_key)
				}).catch((error) => {
					console.log('error permanently deleting document', error)
					reject(error)
				})
		})
	},

	async fetchRecentDocuments({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {

			HTTP.post("api/v1/paged-documents", {
				direction: payload.direction,
				currentResultsPage: payload.currentResultsPage,
				num_docs: payload.num_docs,
				csrf_token: payload.csrf_token
			}).then((response) => {
				// console.log('fetch docs response: ', response.data.data)
				if (response.data) {
					commit("SET_RECENT_DOCUMENTS", response.data.data.items)
					resolve(response)
				}
			}).catch((error) => {
				console.log('error retrieving documents', error)
				reject(error)
			})
		})
	},

	async fetchDocuments({ dispatch, commit }, { payload }) {
		
		return new Promise((resolve, reject) => {
			HTTP.post("api/v1/paged-documents", {
				direction: payload.direction,
				currentResultsPage: payload.currentResultsPage,
				labelKeys: payload.labelKeys,
				num_docs: payload.num_docs,
				query: payload.query,
				queryType: payload.queryType,
				search_offset: payload.search_offset,
				csrf_token: payload.csrf_token
			}).then((response) => {
				// console.log('fetch docs response: ', response.data.data)
				if (response.data) {
					commit("SET_DOCUMENTS", response.data.data.items)
					commit("document/SET_DOCUMENT", undefined, { root: true })
					resolve(response)
				}
			}).catch((error) => {
				console.log('error retrieving documents', error)
				reject(error)
			})
		})

	},
	async StartImportingEvents({ state, commit }) {
		if (!state.importingEventsOn) {
			initImportingEvents(state, commit)
		}
	},

	/*
		Used for both firebase and non firebase
	*/
	async importDocuments({ dispatch, state, commit }, { payload }) {

		const formData = new FormData()
		formData.append('_csrf_token', payload.csrf_token)
		formData.append('group', payload.group)
		formData.append('files', JSON.stringify(payload.files))
		formData.append('fbstr', payload.fbstr)
		formData.append('useMl', payload.useMl)

		return HTTP.put("api/v1/import/" + payload.key, formData).then((response) => {
			commit("SET_IMPORTING", true)

			if (!state.importingEventsOn) {
				initImportingEvents(state, commit)
			}

			for (var i = 0; i < response.data.data.length; i++) {
				commit("SET_IMPORTING_DOCUMENT", {
					document: response.data.data[i],
					tracking: response.data.data[i].tracking_key,
					filename: response.data.data[i].filename,
					group_key: payload.group
				})
				// var progDocument = this.initProgressDocument(response.data[i])
				// progDocument.filename = response.data[i].filename
				// progDocument.group_key = this.groupKey
				// this.$set(this.progressDocuments, response.data[i].tracking_key, progDocument)
				// this.progressDocuments[response.data[i].tracking_key] = progDocument
			}

			// for (var i = 0; i < payload.files.length; i++) {
			//   commit("ADD_IMPORTING_DOCUMENT", payload.files[i])
			// }      

			return response.data
		})
			.catch((error) => {
				console.log(error)

				if (error.response !== undefined) {
					// return Promise.reject(error)
					return error.response.status
				} else {
					return 500
					// return Promise.reject(500)
				}
			})

	},
	async removeImported({ state, commit }, { payload }) {
		commit("REMOVE_IMPORTED_DOCUMENT", payload.doc_id)
	},
}
