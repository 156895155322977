<template>
	<v-tab-item key="tabZoneTransfer" class="pa-10">
		<v-container class="tab-settings">
			<v-row>
				<v-col cols="16" sm="6" md="4">
					<v-label id="zone-tranfer-type">{{$t('settings.tabZoneTransfer.labelZoneTransferType')}}</v-label>
					<v-select v-model="zoneTransferOption" :items="showUploadOnly ? zoneTransferOptionsUploadOnly : zoneTransferOptions" item-text="name" item-value="id"
						@change="clearInputs" :aria-label="zoneTransferOption != null ? zoneTransferOptions[zoneTransferOption - 1].name: ''" aria-labelledby="zone-transfer-type">
					</v-select>
				</v-col>
			</v-row>
		</v-container>
		<!-- section for the different transfer options -->
		<v-container class="tab-settings">
			<v-card flat v-if="zoneTransferOption == 1 && options.can_switch_customer">
				<v-card-title>
					<span id="" class="text-h5">{{ $t("settings.tabZoneTransfer.headerDocumentZoneTransfer") }}</span>
				</v-card-title>

				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="16" sm="6" md="4">
								<v-text-field :label="$t('settings.tabZoneTransfer.labelSourceDocumentId')"
									v-model="documentZoneTransfer.sourceDocumentId"></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="16" sm="6" md="4">
								<v-text-field :label="$t('settings.tabZoneTransfer.labelDestinationDocumentId')"
									v-model="documentZoneTransfer.destinationDocumentId"></v-text-field>
							</v-col>
						</v-row>
					</v-container>

				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="close">
						{{ $t("settings.tabZoneTransfer.buttonClose") }}
					</v-btn>
					<v-btn color="primary" class="mb-2 ml-10" @click="transferDocumentZones"
						:disabled="transferDocumentZonesButtonDisabled == true || documentZoneTransfer.zoneTransferInitiated == true">
						{{ $t("settings.tabZoneTransfer.buttonTransferDocumentZones") }}
					</v-btn>
				</v-card-actions>
			</v-card>

			<v-card flat v-if="zoneTransferOption == 2 && options.can_switch_customer">
				<v-card-title>
					<span class="text-h5">{{ $t("settings.tabZoneTransfer.headerPageZoneTransfer") }}</span>
				</v-card-title>

				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="16" sm="6" md="4">
								<v-text-field label="Source Document ID"
									v-model="pageZoneTransfer.sourceDocumentId"></v-text-field>
							</v-col>
							<v-col cols="16" sm="6" md="4" v-if="showPageZoneTransferPages == true">
								<v-select v-model="pageZoneTransfer.sourcePage" :items="pageZoneTransfer.sourcePageSelect" item-text="name" item-value="id"
									:label="$t('settings.tabZoneTransfer.labelSourcePage')" :hint="$t('settings.tabZoneTransfer.labelSelectSourcePage')" persistent-hint>
								</v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="16" sm="6" md="4">
								<v-text-field :label="$t('settings.tabZoneTransfer.labelDestinationDocumentId')"
									v-model="pageZoneTransfer.destinationDocumentId"></v-text-field>
							</v-col>
							<v-col cols="16" sm="6" md="4" v-if="showPageZoneTransferPages == true">
								<v-select v-model="pageZoneTransfer.destinationPages" :items="pageZoneTransfer.destinationPageSelect" item-text="name" item-value="id"
									:label="$t('settings.tabZoneTransfer.labelDestinationPages')" multiple :hint="$t('settings.tabZoneTransfer.labelSelectDestinationPage')" persistent-hint>
								</v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-btn @click="loadPageOptions"
									:disabled="loadPageOptionsButtonDisabled != false">
									{{ $t("settings.tabZoneTransfer.buttonLoadPageOptions") }}
								</v-btn>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="close">
						{{ $t("settings.tabZoneTransfer.buttonClose") }}
					</v-btn>
					<v-btn color="primary" class="mb-2 ml-10" @click="transferPageZones"
						:disabled="transferPageZonesButtonDisabled != false || pageZoneTransfer.zoneTransferInitiated == true">
						{{ $t("settings.tabZoneTransfer.buttonTransferPageZones") }}
					</v-btn>
				</v-card-actions>
			</v-card>

			<v-card flat v-if="zoneTransferOption == 3">
				<v-card-title>
					<span class="text-h5">{{ $t("settings.tabZoneTransfer.headerUploadDocumentZones") }}</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="16" sm="6" md="4">
								<v-file-input :label="$t('settings.tabZoneTransfer.labelSelectLayoutZipFile')"
									accept=".zip" v-model="uploadDocumentZones.selectedUploadFile">
									
								</v-file-input>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="16" sm="6" md="4">
								<v-text-field :label="$t('settings.tabZoneTransfer.labelDestinationDocumentId')"
									v-model="uploadDocumentZones.destinationDocumentId" @blur="loadDocumentName">
									</v-text-field>
							</v-col>
						</v-row>
							<v-row>
							<v-col cols="16" sm="6" md="4" v-if="document != null">
								<v-text-field :label="$t('settings.tabZoneTransfer.labelDestinationDocumentName')"
									v-model="document.name" readonly>
									</v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="close">
						{{ $t("settings.tabZoneTransfer.buttonClose") }}
					</v-btn>
					<v-btn color="primary" class="mb-2 ml-10" @click="uploadZonesFile"
						:disabled="uploadDocumentZonesButtonDisabled == true || uploadDocumentZones.zoneTransferInitiated == true">
						{{ $t("settings.tabZoneTransfer.buttonUploadZones") }}
					</v-btn>
				</v-card-actions>
			</v-card>

			<v-card flat v-if="zoneTransferOption == 4 && options.can_switch_customer">
				<v-card-title>
					<span class="text-h5">{{ $t("settings.tabZoneTransfer.headerDownloadDocumentZones") }}</span>
				</v-card-title>
					<v-card-text>
					<v-containter>
						<v-row>
							<v-col cols="16" sm="6" md="4">
								<v-text-field :label="$t('settings.tabZoneTransfer.documentId')"
									v-model="downloadDocumentZones.documentId"></v-text-field>
							</v-col>
						</v-row>
					</v-containter>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="close">
						{{ $t("settings.tabZoneTransfer.buttonClose") }}
					</v-btn>
					<!--
					<v-btn color="primary" class="mb-2 ml-10" @click="downloadZonesFile" 
						:disabled="downloadDocumentZonesButtonDisabled == true ||	downloadDocumentZones.zoneTransferInitiated == true">
						{{ $t("settings.tabZoneTransfer.buttonDownloadZones") }}
					</v-btn>
					-->
					<v-btn color="primary" class="mb-2 ml-10" :href=" '/api/v1/documents/' + downloadDocumentZones.documentId + '/layouts'" 
						:disabled="downloadDocumentZonesButtonDisabled == true ||	downloadDocumentZones.zoneTransferInitiated == true">
						{{ $t("settings.tabZoneTransfer.buttonDownloadZones") }}
					</v-btn>
				</v-card-actions>
			</v-card>


		</v-container>
	</v-tab-item>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import i18n from "@/plugins/i18n"

export default {
	props: ["parentTab"],
	data() {
		return {
			zoneTransferOption: null,
			zoneTransferOptions: [
				{ id: 1, name: "Document Zone Transfer" },
				{ id: 2, name: "Page Zone Transfer" },
				{ id: 3, name: "Upload Document Zones" },
				{ id: 4, name: "Download Document Zones" },
			],
			zoneTransferOptionsUploadOnly: [
				{ id: 3, name: "Upload Document Zones" }
			],
			documentZoneTransfer: {
				sourceDocumentId: null,
				destinationDocumentId: null,
				zoneTransferInitiated: false,
			},
			pageZoneTransfer: {
				sourceDocumentId: null,
				destinationDocumentId: null,
				sourcePage: null,
				destinationPages: [],
				sourcePageSelect: [],
				destinationPageSelect: [],
				zoneTransferInitiated: false,

			},
			uploadDocumentZones: {
				destinationDocumentId: null,
				destinationDocumentName: null,
				selectedUploadFile: null,
				zoneTransferInitiated: false,
			},
			downloadDocumentZones: {
				documentId: null,
				zoneTransferInitiated: false,
			},
		}
	},
	computed: {
		...mapGetters({
			language: "language/getLanguage",
			sourcePageCount : "settings/getSourcePageCount",
			destinationPageCount: "settings/getDestinationPageCount",
			document: "settings/getDocument",
			options: "application/getOptions",
		}),
		showUploadOnly(){
			return this.options.zone_transfer_enabled && !this.options.can_switch_customer
		},
		transferDocumentZonesButtonDisabled(){
			if(this.documentZoneTransfer.sourceDocumentId != '' && this.documentZoneTransfer.sourceDocumentId != null &&
					this.documentZoneTransfer.destinationDocumentId != '' && this.documentZoneTransfer.destinationDocumentId != null){
						return false
					}
			return true
		},
		loadPageOptionsButtonDisabled(){
			if(this.pageZoneTransfer.sourceDocumentId != '' && this.pageZoneTransfer.sourceDocumentId != null &&
					this.pageZoneTransfer.destinationDocumentId != '' && this.pageZoneTransfer.destinationDocumentId != null){
						return false
					}
			return true
		},
		transferPageZonesButtonDisabled(){
			if(this.pageZoneTransfer.sourcePage != null && this.pageZoneTransfer.destinationPages.length > 0){
				return false
			}
			return true
		},
		uploadDocumentZonesButtonDisabled(){
			if(this.uploadDocumentZones.destinationDocumentId != null && this.uploadDocumentZones.destinationDocumentId != null &&
				this.uploadDocumentZones.selectedUploadFile != null){
				return false
			}
			return true
		},
		downloadDocumentZonesButtonDisabled(){
			if(this.downloadDocumentZones.documentId != null && this.downloadDocumentZones.documentId.length > 0){
				return false
			}
			return true
		},
		showPageZoneTransferPages(){
			if(this.pageZoneTransfer.sourcePageSelect != null && this.pageZoneTransfer.sourcePageSelect.length > 0 && 
				this.pageZoneTransfer.destinationPageSelect != null && this.pageZoneTransfer.destinationPageSelect.length > 0){
				return true
			}
			return false
		},

	},
	watch: {
		'uploadDocumentZones.destinationDocumentId': function(newVal, oldVal){
			if(this.uploadDocumentZones.destinationDocumentId != null){
				this.loadDocumentName()
			}
		},
	},
	methods: {
		...mapActions({
			fetchPageZoneTransferPages: "settings/fetchPageZoneTransferPages",
			pageZoneTransferAction: "settings/pageZoneTransfer",
			documentZoneTransferAction: "settings/documentZoneTransfer",
			uploadDocumentZonesFile: "settings/uploadDocumentZonesFile",
			loadDocument: "settings/loadDocument",
			downloadDocumentZonesFile: "settings/downloadDocumentZonesFile",
		}),
		...mapMutations({
			setSourcePageCount : "settings/SET_SOURCE_PAGE_COUNT",
			setDestinationPageCount: "settings/SET_DESTINATION_PAGE_COUNT",
			setDocument: "settings/SET_DOCUMENT",
		}),
		clearInputs(){
			//do these for both parent tab types
			this.documentZoneTransfer.destinationDocumentId = null
			this.documentZoneTransfer.zoneTransferInitiated = false
			this.pageZoneTransfer.sourcePage = null
			this.pageZoneTransfer.destinationPages = []
			this.pageZoneTransfer.sourcePageSelect = []
			this.pageZoneTransfer.destinationPageSelect = []
			this.pageZoneTransfer.zoneTransferInitiated = false
			this.uploadDocumentZones.destinationDocumentName = null
			this.uploadDocumentZones.selectedUploadFile = null
			this.downloadDocumentZones.zoneTransferInitiated = false

			//when included in settings tab
			if(this.parentTab == "settings"){
				// console.log('zone transfer option: ' + this.zoneTransferOption)
				this.documentZoneTransfer.sourceDocumentId = null
				
				this.pageZoneTransfer.sourceDocumentId = null
				this.pageZoneTransfer.destinationDocumentId = null
				this.uploadDocumentZones.destinationDocumentId = null	
				this.downloadDocumentZones.documentId = null
				this.setDocument(null)	
			}
				
			//when included in document tab. for convenience, pre-set the logical docid 
			if(this.parentTab == "documentTab"){
				var doc_key = this.$route.params.key
				this.documentZoneTransfer.sourceDocumentId = doc_key
				this.pageZoneTransfer.sourceDocumentId = doc_key
				this.uploadDocumentZones.destinationDocId = doc_key
				this.downloadDocumentZones.documentId = doc_key
			}
			
		},
		close(){
			this.zoneTransferOption = null
			this.clearInputs()
		},
		createPageSelectOptions(){
			// console.log(this.sourcePageCount)
			// console.log(this.destinationPageCount)
			if(this.sourcePageCount != null && this.destinationPageCount != null){
				for(var i=1;i<=this.sourcePageCount;i++){
						this.pageZoneTransfer.sourcePageSelect.push({id: i, name: i});
				}

				for(var j=1;j<=this.destinationPageCount;j++){
						this.pageZoneTransfer.destinationPageSelect.push({id: j, name: j})
				}
			}
			// console.log(this.pageZoneTransfer.sourcePageSelect)
			// console.log(this.pageZoneTransfer.destinationPageSelect)
			return true
		},
		loadPageOptions(){
			//clear previous values
			this.setSourcePageCount(null)
			this.setDestinationPageCount(null)

			this.fetchPageZoneTransferPages({
				payload: {sourceDocId: this.pageZoneTransfer.sourceDocumentId, destinationDocId: this.pageZoneTransfer.destinationDocumentId}
			}).then( (response) => {
				this.createPageSelectOptions()
			}).catch( (error) => {

			})
		},
		transferDocumentZones(){
			this.documentZoneTransfer.zoneTransferInitiated = true
			this.documentZoneTransferAction({
				payload: {
					sourceDocumentId: this.documentZoneTransfer.sourceDocumentId,
					destinationDocumentId: this.documentZoneTransfer.destinationDocumentId
				}
			}).then( (response) => {
				this.$snackbar.showMessage({ content: this.$t("settings.tabZoneTransfer.msg.docInitiated"), color: 'success' })
			}).catch( (error) => {
				this.$snackbar.showMessage({ content: this.$t("settings.tabZoneTransfer.msg.errorDocTransferring", [error]), color: 'error' })
			})
		},
		transferPageZones(){
			this.pageZoneTransfer.zoneTransferInitiated = true
			// console.log(this.pageZoneTransfer.sourcePage + " * " + this.pageZoneTransfer.destinationPages)
			this.pageZoneTransferAction({
				payload:{
					sourceDocumentId: this.pageZoneTransfer.sourceDocumentId, 
					destinationDocumentId: this.pageZoneTransfer.destinationDocumentId,
					source_page_no: this.pageZoneTransfer.sourcePage,
					destination_page_nos: this.pageZoneTransfer.destinationPages,
				}
			}).then( (response) => {
				this.$snackbar.showMessage({ content:this.$t("settings.tabZoneTransfer.msg.pageInitiated"), color: 'success' })
			}).catch( (error) => {
				this.$snackbar.showMessage({ content: this.$t("settings.tabZoneTransfer.msg.errorPageTransferring", [error]), color: 'error' })
			})
		},	
		uploadZonesFile(){
			// console.log('uploaded file: ', this.uploadDocumentZones.selectedUploadFile)
			this.uploadDocumentZones.zoneTransferInitiated = true
			if(this.uploadDocumentZones.selectedUploadFile != null){
				var formData = new FormData()
				formData.append("file", this.uploadDocumentZones.selectedUploadFile)	
				this.uploadDocumentZonesFile({
					payload: {
						fd: formData,
						destinationDocumentId: this.uploadDocumentZones.destinationDocumentId,
					}
				}).then( (results) => {
					this.$snackbar.showMessage({ content: this.$t("settings.tabZoneTransfer.msg.zonesInitiated"), color: 'success' })
				}).catch( (error) => {
					this.$snackbar.showMessage({ content:this.$t("settings.tabZoneTransfer.msg.errorZonesTransferring", [error]), color: 'error' })
				})
			}
		},
		downloadZonesFile(){
			if(this.downloadDocumentZones.documentId != null){
				this.downloadDocumentZones.zoneTransferInitiated = true
				this.downloadDocumentZonesFile({
					payload: {
						sourceDocumentId: this.downloadDocumentZones.documentId
					}
				}).then( (results) => {
					this.$snackbar.showMessage({ content: this.$t("settings.tabZoneTransfer.msg.downloadInitiated"), color: 'success' })
				}).catch( (error) => {
					this.$snackbar.showMessage({ content: this.$t("settings.tabZoneTransfer.msg.errorDownload", [error]), color: 'error' })
				})
			}
		},
		loadDocumentName(){
			// console.log(this.uploadDocumentZones.destinationDocumentId)
			if(this.uploadDocumentZones.destinationDocumentId != null){
				this.loadDocument({
					payload: {
						documentKey: this.uploadDocumentZones.destinationDocumentId
					}
				})
			}
		},
		
	},
	mounted() {
		// console.log('parentTab:' + this.parentTab)
		if(this.$route.params != null && this.$route.params.key != null && this.parentTab == "settings"){
			// console.log('zone transfer params: ' + this.$route.params.key)
			this.zoneTransferOption = 3
			this.uploadDocumentZones.destinationDocumentId = this.$route.params.key
		}
		if(this.parentTab == "documentTab"){
			var doc_key = this.$route.params.key
			// console.log('route params doc key is: ', doc_key)
			this.documentZoneTransfer.sourceDocumentId = doc_key
			this.pageZoneTransfer.sourceDocumentId = doc_key
			this.uploadDocumentZones.destinationDocumentId = doc_key
			this.downloadDocumentZones.documentId = doc_key
		}
	}

}


</script>


<style>
</style>