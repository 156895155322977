import Documents from "../components/Document"

export default [
  {
    path: "/document/:key",
    name: "Document",
    component: Documents,
    meta: {
      requiresAuth: true,
      i18Msg: "barTitle.document",
    },
  },

]
