import Help from '../components/Help'

export default [
    {
        path: '/help',
        name: 'Help',
        component: Help,
        meta: {
            requiresAuth: true,
            i18Msg: 'barTitle.help'            
        }
    },
]