export default {
    SET_LABELS(state, val) {
        state.labels = val
    },
    SET_LABEL(state, val) {
        state.label = val
    },
    ADD_LABEL(state, val) {
        state.labels.push(val)
    },
    SET_CSS_LIST(state, val){
        state.cssList = val
    },
    SET_CSS(state, val){
        state.css = val
    },
    ADD_CSS(state, val){
        state.cssList.push(val)
    },
    SET_GROUPS(state, val) {
        state.groups = val
    },
    SET_GROUP(state, val) {
        state.group = val
    },
    ADD_GROUP(state, val) {
        state.groups.push(val)
    },
    SET_CUSTOMER_USERS(state, val){
        state.customerUsers = val
    },
    SET_ACCOUNTS(state, val){
        state.accounts = val
    },
    SET_ACCOUNT(state, val){
        state.account = val
    },
    ADD_ACCOUNT(state, val){
        state.accounts.push(val)
    },
    SET_SOURCE_PAGE_COUNT(state, val){
        state.sourcePageCount = val
    },
    SET_DESTINATION_PAGE_COUNT(state, val){
        state.destinationPageCount = val
    },
    SET_DOCUMENT(state, val){
        state.document = val
    },

}