
<template>
  <v-sheet class="pa-5">

    <h2>Help</h2>
    <p>
      Looking for the the basics of the Equidox software? Find them here in an overview of the remediation process.
      Learn about importing, page zoning, previewing, and exporting in this 10 minute demo.
    </p>

    <figure data-v-79a2a5f4="" id="ozplayer-1-container" class="ozplayer-container" style="width: 80%;">
      <div data-v-79a2a5f4="" data-responsive="ozplayer-1-container" data-controls="stack" id="ozplayer-1"
        data-transcript="ozplayer-1-transcript" class="ozplayer oz-responsive oz-stack oz-no-images">
        <p class="oz-indicator">
          <strong><em aria-live="assertive" aria-relevant="additions text">

            </em></strong>
          <span></span>
        </p>
        <iframe class="me-plugin" id="me_youtube_0_container" frameborder="0" allowfullscreen=""
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin" title="2024 10 minute demo" width="1176" height="661.5"
          src="https://www.youtube.com/embed/3kpslXrToJA?controls=0&amp;wmode=opaque&amp;showinfo=0&amp;iv_load_policy=3&amp;rel=0&amp;cc_load_policy=1&amp;enablejsapi=1&amp;origin=http%3A%2F%2Flocalhost%3A8081&amp;widgetid=1"
          tabindex="-1">
        </iframe>
        <video data-v-79a2a5f4="" preload="none" style="display: none;" tabindex="-1">
          <source data-v-79a2a5f4="" src="https://youtu.be/3kpslXrToJA" type="video/x-youtube">
        </video>

      </div>
      <details data-v-79a2a5f4="" open="open" class="ozplayer-expander">
        <summary data-v-79a2a5f4="" tabindex="0" role="button" aria-expanded="true" id="ozplayer-1-show-hide-transcript">
          Video Transcript</summary>
        <div data-v-79a2a5f4="" id="ozplayer-1-transcript" class="ozplayer-transcript" aria-live="off" aria-atomic="true"
          aria-relevant="all" tabindex="0" aria-expanded="true" style="position: relative; display: block;">
          <div data-kind="captions" lang="en" data-cue="1">
            <p><q>My name is Dan Tuleta, Senior Sales Engineer&nbsp;</q></p>
            <p><q>with the Equidox accessibility team. Today&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="2">
            <p><q>I'm going to be giving a quick demonstration&nbsp;</q></p>
            <p><q>of our Equidox PDF accessibility software.&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="3">
            <p><q>Equidox is a web-based application that can&nbsp;</q></p>
            <p><q>be operated directly through your browser. It&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="4">
            <p><q>also uses a concurrent user licensing model,&nbsp;</q></p>
            <p><q>which allows for a larger number of users to&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="5">
            <p><q>share access. It is only limited to the number&nbsp;</q></p>
            <p><q>of people who are logged in simultaneously.&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="6">
            <p><q>Another nice aspect of being web-based&nbsp;</q></p>
            <p><q>is that Equidox allows for collaboration.&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="7">
            <p><q>You can share documents with other&nbsp;</q></p>
            <p><q>users in your account, and you can&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="8">
            <p><q>even have multiple users remediating&nbsp;</q></p>
            <p><q>the same document at the same time.</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="9">
            <p><q>The first thing I will do is import&nbsp;</q></p>
            <p><q>a document. I can open my folders and&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="10">
            <p><q>select a document or multiple documents to&nbsp;</q></p>
            <p><q>drag and drop into the import screen. Once&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="11">
            <p><q>the document finishes uploading to the&nbsp;</q></p>
            <p><q>cloud, a blue import button will appear.&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="12">
            <p><q>Pressing this button will trigger an automated&nbsp;</q></p>
            <p><q>process where Equidox will begin to analyze the&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="13">
            <p><q>document for either existing tag structure or, if&nbsp;</q></p>
            <p><q>the document has not yet been previously tagged,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="14">
            <p><q>Equidox will automatically identify things like&nbsp;</q></p>
            <p><q>text, images, hyperlinks, and even fillable form&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="15">
            <p><q>fields. Returning to the document list, I can&nbsp;</q></p>
            <p><q>now see the document that I just imported. When&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="16">
            <p><q>I click on the document, I will arrive at the&nbsp;</q></p>
            <p><q>document detail page. From here, I can see a&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="17">
            <p><q>thumbnail of all pages in the document and adjust&nbsp;</q></p>
            <p><q>some of the basic properties of the document,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="18">
            <p><q>such as the title, the author, or the language&nbsp;</q></p>
            <p><q>attribute. There is also an Images tab that&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="19">
            <p><q>allows me to add alt text or artifact any&nbsp;</q></p>
            <p><q>images in the document from one location.</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="20">
            <p><q>If I click on the thumbnail for the&nbsp;</q></p>
            <p><q>one and only page in this document,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="21">
            <p><q>I will enter the remediation page, where I&nbsp;</q></p>
            <p><q>can make changes to the reading zones on the&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="22">
            <p><q>document. The reading zones are represented by&nbsp;</q></p>
            <p><q>these yellow boxes that cover up the content,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="23">
            <p><q>and they will ultimately create the PDF tag&nbsp;</q></p>
            <p><q>structure when we export the document. Since&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="24">
            <p><q>this document was untagged to begin with, most&nbsp;</q></p>
            <p><q>of the zones on this page say "TX" in the upper&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="25">
            <p><q>left-hand corner to indicate that they are text&nbsp;</q></p>
            <p><q>zones. I can also see zones with the IMG label&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="26">
            <p><q>to indicate where the images are located. In the&nbsp;</q></p>
            <p><q>upper right-hand corner of the zone, I can see&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="27">
            <p><q>a number that reflects the reading order of the&nbsp;</q></p>
            <p><q>page. If I am unsatisfied with the size, location,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="28">
            <p><q>and number of reading zones on the page based on&nbsp;</q></p>
            <p><q>where Equidox has auto-detected the content, I can&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="29">
            <p><q>use the zone detection slider to adjust the way&nbsp;</q></p>
            <p><q>that Equidox analyzes the page. If I slide further&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="30">
            <p><q>to the left, Equidox will be much more focused&nbsp;</q></p>
            <p><q>and create very granular reading zones, whereas&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="31">
            <p><q>if I slide further to the right, Equidox zooms&nbsp;</q></p>
            <p><q>its focus out and gives me much larger zones.</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="32">
            <p><q>The purpose of the slider is to give you different&nbsp;</q>
            </p>
            <p>
              <q>options as starting points, so there's less manual&nbsp;&nbsp;</q>
            </p>
          </div>
          <div data-kind="captions" lang="en" data-cue="33">
            <p><q>work required to establish zones and manipulate&nbsp;</q>
            </p>
            <p><q>their size and location. Another very important&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="34">
            <p><q>feature of Equidox is the button that looks like&nbsp;</q></p>
            <p><q>a computer monitor. When I press this button,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="35">
            <p>
              <q>a separate tab will open in my browser and show&nbsp;</q>
            </p>
            <p><q>me an HTML preview of the page I'm currently&nbsp;&nbsp;</q>
            </p>
          </div>
          <div data-kind="captions" lang="en" data-cue="36">
            <p><q>working on. The reason this HTML preview is&nbsp;</q></p>
            <p><q>important is because this is a simple and&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="37">
            <p>
              <q>linear representation of how a screen reader would&nbsp;</q>
            </p>
            <p><q>read the content on this page. If I were to stop&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="38">
            <p><q>working and export the document, I can use this&nbsp;</q></p>
            <p>
              <q>HTML preview to validate the accuracy of my work,&nbsp;&nbsp;</q>
            </p>
          </div>
          <div data-kind="captions" lang="en" data-cue="39">
            <p>
              <q>because this HTML represents what the PDF&nbsp;</q>
            </p>
            <p><q>tags will become when I export the document.</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="40">
            <p><q>Returning to the PDF page, I'm going to start&nbsp;</q></p>
            <p>
              <q>adjusting my zones to create the proper tags&nbsp;&nbsp;</q>
            </p>
          </div>
          <div data-kind="captions" lang="en" data-cue="41">
            <p>
              <q>on this document. The first zones that I&nbsp;</q>
            </p>
            <p><q>like to set are headings. The main title&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="42">
            <p><q>on the top of this page, in the large bold&nbsp;</q></p>
            <p>
              <q>font, is going to be my heading level one.&nbsp;&nbsp;</q>
            </p>
          </div>
          <div data-kind="captions" lang="en" data-cue="43">
            <p>
              <q>Under the zone properties, I can use the&nbsp;</q>
            </p>
            <p><q>dropdown menu and change it to a heading,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="44">
            <p><q>or I can use keyboard shortcuts. In&nbsp;</q></p>
            <p><q>order to set a heading level one,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="45">
            <p><q>I can simply press one on my keyboard, and I&nbsp;</q></p>
            <p>
              <q>will repeat that step with my heading level&nbsp;&nbsp;</q>
            </p>
          </div>
          <div data-kind="captions" lang="en" data-cue="46">
            <p><q>twos and my heading level threes by hitting&nbsp;</q></p>
            <p><q>two and three, respectively, on my keyboard.</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="47">
            <p><q>Returning to the HTML preview, I can now see&nbsp;</q></p>
            <p><q>the headings identified by this large bold font.&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="48">
            <p><q>The next element I will work on is this nested&nbsp;</q></p>
            <p><q>list, which you can probably tell from the HTML&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="49">
            <p><q>preview is not tagged as a list at all. We use&nbsp;</q></p>
            <p><q>computer vision and machine learning techniques,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="50">
            <p><q>and we have trained Equidox to identify list&nbsp;</q></p>
            <p><q>items and nested list items automatically.&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="51">
            <p><q>All I have to do is hit L on my keyboard to&nbsp;</q></p>
            <p><q>set the element as a list and then use my&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="52">
            <p><q>list detection slider. In just a second&nbsp;</q></p>
            <p><q>or two, Equidox has located a list item&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="53">
            <p><q>and multiple layers of nesting inside of them.&nbsp;</q></p>
            <p><q>Returning to the HTML preview, instead of that&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="54">
            <p><q>list being read as a strange run-on sentence&nbsp;</q></p>
            <p><q>without any punctuation, it will now have a&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="55">
            <p><q>proper list tag structure and be fully usable and&nbsp;</q></p>
            <p><q>understandable for an assistive technology user.</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="56">
            <p><q>The next element to focus on is this table, which&nbsp;</q></p>
            <p><q>currently looks nothing like a table at all in&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="57">
            <p><q>the HTML preview. If this table was ignored, an&nbsp;</q></p>
            <p><q>assistive technology user would hear all that data&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="58">
            <p><q>read in a random order without any ability to&nbsp;</q></p>
            <p><q>navigate or understand what they were listening&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="59">
            <p><q>to. Tables are notoriously difficult and&nbsp;</q></p>
            <p><q>time-consuming to tag properly. However,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="60">
            <p><q>with Equidox, all I need to do is click and drag&nbsp;</q></p>
            <p><q>to create a single zone on top of the table. Next,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="61">
            <p><q>I will hit "T" on my keyboard to change it&nbsp;</q></p>
            <p><q>to a table and double-click on the zone to&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="62">
            <p><q>open the table editor. Inside the table editor,&nbsp;</q></p>
            <p><q>there are now some green grid lines that Equidox&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="63">
            <p><q>has drawn for me. I can click to manipulate the&nbsp;</q></p>
            <p><q>location of these grid lines, but I prefer to use&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="64">
            <p><q>the table detector. Similar to the list detector,&nbsp;</q></p>
            <p><q>the table detector sliders are also using computer&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="65">
            <p><q>vision and machine learning to identify&nbsp;</q></p>
            <p><q>the location of all the cells in the table.</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="66">
            <p><q>If I take a look at the HTML preview of this&nbsp;</q></p>
            <p><q>table, I will see that instead of having a&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="67">
            <p><q>random string of information, I have a real table&nbsp;</q></p>
            <p><q>structure with rows, columns, and table data.&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="68">
            <p><q>However, there are a couple of other things&nbsp;</q></p>
            <p><q>I need to do to make this table perfect. The&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="69">
            <p><q>years 2023 and 2024 need to be spanned or&nbsp;</q></p>
            <p><q>merged across multiple columns. To span,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="70">
            <p><q>I simply select the cell where the&nbsp;</q></p>
            <p><q>span begins, hold shift on my keyboard,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="71">
            <p><q>and select the cell where the span ends. Next,&nbsp;</q></p>
            <p><q>I just hit "S" on my keyboard. I will repeat&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="72">
            <p><q>this process for the other part of the table.&nbsp;</q></p>
            <p><q>Returning to the preview, I can see that all of&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="73">
            <p><q>those extra cells have now been consolidated. The&nbsp;</q></p>
            <p><q>other thing I need to correct on this table is to&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="74">
            <p><q>identify that there are two levels of column&nbsp;</q></p>
            <p><q>headers. Not only is the year a column header,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="75">
            <p><q>but the individual quarters within each year&nbsp;</q></p>
            <p><q>need to be tagged as headers as well. By default,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="76">
            <p><q>Equidox assumes that each table has a single&nbsp;</q></p>
            <p><q>column header and a single row header. In this&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="77">
            <p><q>example, we have two column headers, so I just&nbsp;</q></p>
            <p><q>have to hit the up arrow to change the column&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="78">
            <p><q>header from one to two. Lastly, Equidox is able&nbsp;</q></p>
            <p><q>to provide an accurate table summary by simply&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="79">
            <p><q>hitting this check box. Although not necessarily&nbsp;</q></p>
            <p><q>a requirement, certain accessibility checkers will&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="80">
            <p><q>flag tables for not having table summaries.&nbsp;</q></p>
            <p><q>We have automated this task for our users.</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="81">
            <p><q>One final look at the HTML preview will&nbsp;</q></p>
            <p><q>show that both of the top two rows and the&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="82">
            <p><q>far left column are in bold font, confirming&nbsp;</q></p>
            <p><q>that those cells will be tagged as headers,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="83">
            <p><q>while the standard font will be correctly&nbsp;</q></p>
            <p><q>tagged as table data. Returning to the full PDF,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="84">
            <p><q>I cannot forget about the two images on this&nbsp;</q></p>
            <p><q>page. In the upper left-hand corner of the page,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="85">
            <p><q>we have our Equidox logo. I can add alt text to&nbsp;</q></p>
            <p><q>this image by typing into the description field&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="86">
            <p><q>that appears when I select the image zone. Halfway&nbsp;</q></p>
            <p><q>down the page, I have another image of our logo,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="87">
            <p><q>but this time there is a dog in the picture.&nbsp;</q></p>
            <p><q>This is not a document about dogs, and there's&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="88">
            <p><q>nothing critical being displayed in this image&nbsp;</q></p>
            <p><q>that will allow an assistive technology user to&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="89">
            <p><q>better understand the information on this page.&nbsp;</q></p>
            <p><q>So I might elect to artifact this image to avoid&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="90">
            <p><q>filling up the page with redundant alt text. I&nbsp;</q></p>
            <p><q>can artifact this image or any zone by hitting&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="91">
            <p><q>backspace on my keyboard. The zone will disappear,&nbsp;</q></p>
            <p><q>removing my responsibility to add alt text to it.&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="92">
            <p><q>The visual representation of the image will still&nbsp;</q></p>
            <p><q>be there in the PDF, but an assistive technology&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="93">
            <p><q>user will skip past it because it will not&nbsp;</q></p>
            <p><q>be included in the tagged reading order.</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="94">
            <p><q>If I now take a final look at my HTML preview,&nbsp;</q></p>
            <p><q>I have just one final issue to fix: my reading&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="95">
            <p><q>order is not exactly what I want it to be. We can&nbsp;</q></p>
            <p><q>see that the table has found its way to the very&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="96">
            <p><q>bottom of the reading order because I manually&nbsp;</q></p>
            <p><q>drew that zone. If I return to the page editor,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="97">
            <p><q>I can press the reorder button, and Equidox will&nbsp;</q></p>
            <p><q>reorder the page with the default top-to-bottom&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="98">
            <p><q>reading order. There are also multi-column layout&nbsp;</q></p>
            <p><q>options depending on the structure of the page.&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="99">
            <p><q>You can select individual zones to reorder&nbsp;</q></p>
            <p><q>them, and there's even a method for selecting&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="100">
            <p><q>multiple zones to reorder zones in clusters for&nbsp;</q></p>
            <p><q>pages requiring very complex reading orders.</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="101">
            <p><q>One final look at the HTML preview will show that&nbsp;</q></p>
            <p><q>the reading order is correct, and I can save my&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="102">
            <p><q>work and close out of the document. The final&nbsp;</q></p>
            <p><q>step is to export the PDF. Keep in mind that with&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="103">
            <p><q>Equidox, you can also export the PDF as an HTML or&nbsp;</q></p>
            <p><q>an ePub file; however, the majority of our users&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="104">
            <p><q>prefer to maintain the PDF structure. By pressing&nbsp;</q></p>
            <p><q>"Generate PDF," Equidox will produce a brand new&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="105">
            <p><q>document for me. This document will be exactly&nbsp;</q></p>
            <p><q>the same as the original except it will be tagged,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="106">
            <p><q>fully accessible, and most importantly, fully&nbsp;</q></p>
            <p><q>usable for assistive technology users. Keep&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="107">
            <p><q>in mind the original version of this document&nbsp;</q></p>
            <p><q>was completely untagged and therefore useless&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="108">
            <p><q>to a screen reader user, and of course, fully&nbsp;</q></p>
            <p><q>non-compliant with all Web Content Accessibility&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="109">
            <p><q>Guidelines. The version we just exported from&nbsp;</q></p>
            <p><q>Equidox, however, is fully and accurately tagged,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="110">
            <p><q>100% compliant, and completely usable for&nbsp;</q></p>
            <p><q>people who rely on assistive technology.</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="111">
            <p><q>Equidox also offers an add-on feature that I would&nbsp;</q></p>
            <p><q>like to briefly mention. This feature is called&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="112">
            <p><q>"Zone transfer," which allows users to instantly&nbsp;</q></p>
            <p><q>transfer the reading zones from one version&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="113">
            <p><q>of a document to another version of the same&nbsp;</q></p>
            <p><q>or similar document. There are a couple of&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="114">
            <p><q>different methods of doing this, but the simplest&nbsp;</q></p>
            <p><q>way is to just copy and paste the document ID out&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="115">
            <p><q>of the URL and paste it into this text field&nbsp;</q></p>
            <p><q>on the user interface. This functionality is&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="116">
            <p><q>extremely valuable for our customers who generate&nbsp;</q></p>
            <p><q>documents from consistently formatted templates,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="117">
            <p><q>make last-minute revisions or typo&nbsp;</q></p>
            <p><q>corrections to documents, or if they&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="118">
            <p><q>just find themselves working through a backlog&nbsp;</q></p>
            <p><q>of documents that have a lot of similarities.</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="119">
            <p><q>For more information about how Equidox Software&nbsp;</q></p>
            <p><q>Company can help you with PDF accessibility,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="120">
            <p><q>email us at EquidoxSales@Equidox.co&nbsp;</q></p>
            <p><q>or give us a call at 216-529-3030,&nbsp;&nbsp;</q></p>
          </div>
          <div data-kind="captions" lang="en" data-cue="121">
            <p><q>or visit our website at www.Equidox.co.</q></p>
          </div>
          <p>End of transcript.</p>
        </div>
      </details><a class="oz-skip-anchor" tabindex="-1" name="ozplayer-1-skip-video" id="ozplayer-1-skip-video"></a>

    </figure>


  </v-sheet>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  data() {
    return {
      initOzPlayer: true
    }
  },
  computed: {
    ...mapGetters({
      user: "user/userProfile",
    }),
  },
}
</script>
<style lang="scss" scoped>
.ozplayer-container {
  z-index: 0;
  position: relative;
}</style>
