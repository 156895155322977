<template>
	<v-card class="d-flex align-center align-content-center flex-nowrap pa-2 ml-4" elevation="0">
		<v-text-field class="text-no-wrap" :label="$t('document.gotoPage')" min=0 type="number" dense
			v-model="toPage" style="min-width:90px; max-width: 90px;max-height:25px;"></v-text-field>
		<span class="mr-1">{{ $t("document.gotoOf") }}</span> {{ document != null ? document.total_pages : 0 }}
	</v-card>
</template>

<script>
import router from "../router"

export default {
	name: "LabelsDropdown",
	props: ["document", "pageNo"],
	data() {
		return {
			toPage: null
		}
	},
	computed: {
		
	},

	watch: {
		'toPage': function (newVal, oldVal) {
			var goToPage = this.goToPage
			setTimeout(function () {
				goToPage()
			}, 1750)
		},
		'pageNo': function(newVal, oldVal){
			console.log('page has changed to: ', this.pageNo)
		},
	},
	methods: {
		goToPage() {
			if (this.toPage < 0) {
				this.toPage = Math.abs(this.toPage)
			}
			if (this.toPage == 0) {
				this.toPage = 1
			}
			if (this.toPage > this.document.total_pages) {
				this.toPage = this.document.total_pages
			}
			console.log('after value: ' + this.toPage)

			return router.push({ path: '/document/' + this.document.key + '/page/' + this.toPage }).catch(() => {})
		}
	}
}
</script>

<style>

</style>