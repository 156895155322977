<template>
	<v-tab-item key="tabLabels" class="pa-10">
		<v-container class="tab-settings">
			<template>
				<v-toolbar flat>
					<v-dialog v-model="dialog" max-width="850px">
						<template v-slot:activator="{ on, attr }">
							<v-card class="d-flex mb-10" title flat>
								<h2 class="h2-title">{{ $t("settings.tabLabels.headerCreateNewLabel") }}</h2>
								<v-btn color="primary" class="mb-2 ml-10" v-bind="[attr]" v-on="on">
									<!--<v-icon v-if="buttonStyle.icons.display" class="mr-1">mdi-plus</v-icon>-->
									<span>{{ $t("settings.tabLabels.buttonNewLabel") }}</span>
								</v-btn>
							</v-card>
						</template>

						<v-card>
							<v-card-title>
								<span class="text-h5">{{ formTitle }}</span>
							</v-card-title>
							<v-card-text>
								<v-container>
									<v-row>
										<v-col cols="12" sm="6" md="4">
											<v-color-picker v-model="editedItem.color" @input="getColor(editedItem.color)"
												:label="$t('settings.tabLabels.labelColor')" hide-inputs hide-canvas hide-mode-switch
												:arial-label="$t('settings.tabLabels.labelColorPicker')">
											</v-color-picker>
										</v-col>
										<v-col cols="12" sm="6" md="4">
											<v-text-field v-model="editedItem.name" :label="$t('settings.tabLabels.labelName')"
												:rules="[rules.required, rules.max, rules.min]" maxlength="21">
											</v-text-field>
										</v-col>

										<v-col cols="12" sm="6" md="4">
											<v-text-field v-model="editedItem.description" :label="$t('settings.tabLabels.labelDescription')"
												maxlength="41" :rules="[rules.maxDesc]">
											</v-text-field>
										</v-col>

										<v-col cols="12" sm="6" md="4">
											<v-label id="label-global">{{ $t("settings.tabLabels.headerGlobal") }}</v-label>
											<v-checkbox v-model="editedItem.is_global" aria-labelledby="label-global">
											</v-checkbox>
										</v-col>

									</v-row>
								</v-container>
							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="close">
									{{ $t("settings.tabLabels.buttonCancel") }}
								</v-btn>
								<v-btn color="blue darken-1" text @click="save" :disabled="validate == false">
									{{ $t("settings.tabLabels.buttonSave") }}
								</v-btn>
							</v-card-actions>
						</v-card>

					</v-dialog>

					<v-dialog v-model="dialogDelete" max-width="500px">
						<v-card>
							<v-card-title class="text-h5">
								{{ $t("settings.tabLabels.headerConfirmDelete") }}
							</v-card-title>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="closeDelete">
									{{ $t("settings.tabLabels.buttonCancel") }}
								</v-btn>
								<v-btn color="blue darken-1" text @click="deleteItemConfirm">
									{{ $t("settings.tabLabels.buttonOk") }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>

				</v-toolbar>
			</template>

			<v-data-table :headers="headers" :items="labels" :items-per-page="10" class="">
				<template v-slot:item.color="{ item }">
					<v-chip v-model="item.color" :color="item.color" class="ma-2" key="item.key" label>
					</v-chip>
				</template>
				<template v-slot:item.is_global="{ item }">
					<v-simple-checkbox disabled v-model="item.is_global" key="item.key">
					</v-simple-checkbox>
				</template>
				<template v-slot:item.actions="{ item }">

					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-icon small class="mr-2" @click="editItem(item)" aria-label="$t('settings.tabLabels.edit')" v-bind="attrs"
								v-on="on">
								mdi-pencil
							</v-icon>
						</template>
						<span>{{ $t('settings.tabLabels.edit') }}</span>
					</v-tooltip>

					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-icon small class="mr-2" @click="deleteItem(item)" aria-label="$t('settings.tabLabels.delete')"
								v-bind="attrs" v-on="on">
								mdi-delete
							</v-icon>
						</template>
						<span>{{ $t('settings.tabLabels.delete') }}</span>
					</v-tooltip>

				</template>

				<template v-slot:top>
					<v-toolbar flat>
						<v-toolbar-title>
							<h2 class="h2-title">{{ $t("settings.tabLabels.headerExistingLabels") }}</h2>
						</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</template>

				<!--
				<template v-slot:no-data>
					<v-btn color="primary" @click="fetchLabels">
						{{ $t("settings.tabLabels.buttonReset") }}
					</v-btn>
				</template>
				-->
			</v-data-table>
		</v-container>


	</v-tab-item>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import i18n from "@/plugins/i18n"

export default {
	data() {
		return {
			dialog: false,
			dialogDelete: false,
			editedIndex: -1,
			editedItem: {
				color: '',
				name: '',
				description: '',
				is_global: false
			},
			defaultItem: {
				color: '',
				name: '',
				description: '',
				is_global: false
			},
			headers: [
				{
					text: this.$t("settings.tabLabels.headerColor"),
					align: "start",
					sortable: false,
					value: "color",
					width: "20"
				},
				{
					text: this.$t("settings.tabLabels.headerName"),
					value: "name",

				},
				{
					text: this.$t("settings.tabLabels.headerDescription"),
					value: "description"
				},
				{
					text: this.$t("settings.tabLabels.headerGlobal"),
					value: "is_global",
					align: "end"

				},
				{
					text: this.$t("settings.tabLabels.headerEditDelete"),
					value: "actions",
					align: "end"

				}
			],
			rules: {
                required: (value) => !!value || this.$t("settings.tabLabels.required"),
                max: (v) => v.length <= 20 || this.$t("settings.tabLabels.max20Chars"),
				maxDesc: (v) => v.length <= 40 || this.$t("settings.tabLabels.max40Chars"),
				min: (v) => v.length >= 1 || this.$t("settings.tabLabels.min1Char") ,
            }
		}
	},
	computed: {
		...mapGetters({
			language: "language/getLanguage",
			labels: "settings/getLabels",
			buttonStyle: "application/getButtonStyle",
		}),
		formTitle() {
			return this.editedIndex === -1 ? this.$t("settings.tabLabels.newLabel") : this.$t("settings.tabLabels.editLabel")
		},
		isGlobalDisabled() {
			return this.editedIndex === -1 ? false : true
		},
		validate() {
			if (this.editedItem.name.length < 1 || this.editedItem.name.length > 20) {
				return false
			}
			if (this.editedItem.description.length > 40) {
				return false
			}
			return true
		}

	},
	watch: {
		dialog(val) {
			val || this.close()
		},
		dialogDelete(val) {
			val || this.closeDelete()
		},
	},
	methods: {
		...mapActions({
			fetchLabels: "settings/fetchLabels",
			addLabel: "settings/addLabel",
			deleteLabel: "settings/deleteLabel",
			updateLabel: "settings/updateLabel"
		}),
		//this 'fixes' the color sliders by removing the opacity slider
		getColor(val) {
			if (this.editedItem.color.toString().match(/#[a-zA-Z0-9]{8}/)) {
				this.editedItem.color = this.editedItem.color.substr(0, 7);
			}
			return true
		},
		editItem(item) {
			this.editedIndex = this.labels.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialog = true

			if (this.editedItem.color.toString().match(/#[a-zA-Z0-9]{8}/)) {
				this.editedItem.color = this.editedItem.color.substr(0, 7);
			}

		},
		deleteItem(item) {
			this.editedIndex = this.labels.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialogDelete = true
		},
		deleteItemConfirm() {
			this.labels.splice(this.editedIndex, 1)

			this.deleteLabel({
				payload: { key: this.editedItem.key }
			}).then((response) => {
				this.$snackbar.showMessage({ content: this.$t("settings.tabLabels.msg.labelDeleted"), color: 'success' })
			})

			this.closeDelete()
		},
		close() {
			this.dialog = false

			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		closeDelete() {
			this.dialogDelete = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		save() {
			//edit
			if (this.editedIndex > -1) {
				Object.assign(this.labels[this.editedIndex], this.editedItem)
				console.log('updating existing label')
				this.updateLabel({
					payload: {
						key: this.editedItem.key,
						name: this.editedItem.name,
						description: this.editedItem.description,
						color: this.editedItem.color,
						is_global: this.editedItem.is_global
					}
				}).then((response) => {
					this.$snackbar.showMessage({ content: this.$t("settings.tabLabels.msg.labelUpdated"), color: 'success' })
				}).catch((error) => {
					console.log('update label error', error)
					this.$snackbar.showMessage({ content: this.$t("settings.tabLabels.msg.errorSavingLabel", [error]), color: 'error' })
					//reload
					this.fetchLabels()
				})
			} else { //add
				console.log('saving new label')
				this.addLabel({
					payload: {
						name: this.editedItem.name,
						description: this.editedItem.description,
						color: this.editedItem.color,
						is_global: this.editedItem.is_global
					}
				}).then((response) => {
					this.$snackbar.showMessage({ content: this.$t("settings.tabLabels.msg.labelCreated"), color: 'success' })
				}).catch((error) => {
					console.log('add label error', error)
					this.$snackbar.showMessage({ content: this.$t("settings.tabLabels.msg.errorSavingLabel", [error]), color: 'error' })
					//reload
					this.fetchLabels()
				})
			}
			this.close()
		},

	},
	mounted() {
		this.fetchLabels()
			.then((response) => {
				this.isLoading = false
			})
			.catch((error) => {
				this.isLoading = false
				this.$snackbar.showMessage({ content: this.$t("settings.tabLabels.msg.unableToGetLabels"), color: 'error' })
			})
	},

}

</script>

<style lang="scss" scoped>
.v-chip {
	width: 60px;
	border: 4px solid rgb(168, 168, 168) !important;
}

.title {
	font-family: Lato;
	font-size: 18px;
	line-height: 25px;
	letter-spacing: 0em;
	text-align: center;
}
</style>