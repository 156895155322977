<template>
  
  <v-sheet class="ma-5">

    <v-card v-for="notice in notifications" :key=notice.key class="ma-5">

        <div class="d-flex flex-no-wrap " v-if="notice.notice_data !== null && notice.notice_data.doc_id">
          <v-avatar
            class="ma-3"
            size="165"
            tile
          >
            <v-img :src="'/api/v1/thumbnail/' + notice.notice_data.doc_id + '/1/page-1_thumb.jpg'"></v-img>
          </v-avatar>

          <div>
            <v-card-title
              class="text-h5"
              v-text="notice.notice_data.title"
            ></v-card-title>

            <v-card-subtitle v-if="language == 'en'">
                  <vue-moments-ago
                  prefix="Received"
                  suffix="ago"
                  :date="notice.created"
                  lang="en"
                  ></vue-moments-ago>
              </v-card-subtitle>
              <v-card-subtitle v-if="language == 'fr'">
                  <vue-moments-ago
                  prefix="Recu il y a"
                  :date="notice.created"
                  suffix=""
                  lang="fr"
                  ></vue-moments-ago>
              </v-card-subtitle>

            <v-card-text v-text="notice.notice_data.msg"></v-card-text>

            <v-card-actions>

              <v-btn class="float-right" v-bind="buttonStyle.buttons" color="primary" link :to="'/document/' + notice.notice_data.doc_id">
                <v-icon v-if="buttonStyle.icons.display" v-bind:class="buttonStyle.icons.class">mdi-pencil</v-icon>
                <span v-if="buttonStyle.labels.display">{{ $t("documents.edit") }}</span>
              </v-btn>

            </v-card-actions>
            
          </div>

 
        </div>

        <!-- VCard for deleted document notice-->
        <div class="d-flex flex-no-wrap " v-else>
          <v-avatar v-if="notice.notice_data != null"
            class="ma-3"
            size="165"
            tile
          >
            <v-icon x-large v-if="notice.notice_data != null">
              mdi-file-pdf-box
            </v-icon>
          </v-avatar>

          <div>
            <v-card-title v-if="notice.notice_data != null"
              class="text-h5"
              v-text="notice.notice_data.doc_name"
            ></v-card-title>

						<v-card-text v-if="notice.notice_data == null"
              class="text-h6"
              v-text="notice.description"
            ></v-card-text>

            <v-card-subtitle v-if="language == 'en'">
                  <vue-moments-ago
                  prefix="Received"
                  suffix="ago"
                  :date="notice.created"
                  lang="en"
                  ></vue-moments-ago>
              </v-card-subtitle>
              <v-card-subtitle v-if="language == 'fr'">
                  <vue-moments-ago
                  prefix="Recu il y a"
                  :date="notice.created"
                  suffix=""
                  lang="fr"
                  ></vue-moments-ago>
              </v-card-subtitle>

            <v-card-text v-text="notice.notice_data.msg" v-if="notice.notice_data != null"></v-card-text>
            
          </div>
 
        </div>
       

        <v-btn 
          v-show="notice"
          class="mt-10"
          fab
          absolute
          top
          right
          @click="removeNotification(notice.key)"
          >
            <v-icon color="red" large  >mdi-delete-forever</v-icon>
          </v-btn>
      </v-card>


  </v-sheet>

</template>

<script>
import { mapGetters, mapActions } from "vuex"
import VueMomentsAgo from "@/components/MomentsAgo"

export default {
  data() {
    return {}
  },
  components: {
    VueMomentsAgo,
  },
  computed: {
    ...mapGetters({
      notifications: "user/getNotifications",
      language: "language/getLanguage",
      buttonStyle: "application/getButtonStyle"
    }),
  },
  methods: {
    ...mapActions({
      fetchNotifications: "user/fetchNotifications",
      deleteNotice: "user/deleteNotice"
    }),
    removeNotification(noticeKey) {
      this.deleteNotice(noticeKey)
    }
  },
  mounted() {
    this.fetchNotifications()
      .then((response) => {
        this.isLoading = false
      })
      .catch((error) => {
        this.isLoading = false
      })
  },
}
</script>
<style lang="scss">
  .notice > a {
    margin-right: 6px !important;
  }
</style>
