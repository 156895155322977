import HTTP from "@/helpers/http-common"

export default {
	//Labels
	async fetchLabels({ dispatch, commit }) {

		return HTTP.get("/api/v1/label")
			.then((response) => {
				if (response.data) {
					commit("SET_LABELS", response.data.data.results)
				}
			})
	},

	async addLabel({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {
			HTTP.post("/api/v1/label",
				{
					name: payload.name,
					description: payload.description,
					color: payload.color,
					is_global: payload.is_global
				}).then((response) => {
					//add the returned key to the payload object then set as new label
					payload.key = response.data.data.key
					commit("ADD_LABEL", payload)
					resolve('success')
				}).catch((error) => {
					reject(error.response)
				})
		})

	},

	async deleteLabel({ dispatch, commit }, { payload }) {

		return HTTP.delete("/api/v1/label/" + payload.key)
			.then((response) => {
				console.log(response.data)
			})
	},

	async updateLabel({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {
			HTTP.put("/api/v1/label/" + payload.key,
				{
					name: payload.name,
					description: payload.description,
					color: payload.color,
					is_global: payload.is_global
				}).then((response) => {
					console.log(response.data)
					resolve(response.data)
				}).catch((error) => {
					reject(error.response)
				})
		})
	},

	//CSS
	async fetchCssList({ dispatch, commit }) {
		return HTTP.get("/api/v1/css")
			.then((response) => {
				if (response.data) {
					commit("SET_CSS_LIST", response.data.data.results)
				}
			})
	},

	async addCss({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {
			HTTP.post("/api/v1/css", {
				name: payload.name,
				url: payload.url,
				is_default: payload.is_default
			}).then((response) => {
				//add the returned key to the payload object then set as new css
				payload.key = response.data.data.key
				commit("ADD_CSS", payload)
				resolve('success')
			}).catch((error) => {
				reject(error.response.data)
			})
		})
	},

	async deleteCss({ dispatch, commit }, { payload }) {
		return HTTP.delete("/api/v1/css/" + payload.key)
			.then((response) => {
				console.log(response.data)
			})
	},

	async updateCss({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {
			HTTP.put("/api/v1/css/" + payload.key,
				{
					name: payload.name,
					url: payload.url,
					is_default: payload.is_default
				}).then((response) => {
					console.log(response.data)
					resolve('success')
				}).catch((error) => {
					reject(error.response.data)
				})
		})
	},

	//Groups
	async fetchGroups({ dispatch, commit }) {
		return HTTP.get("/api/v1/group")
			.then((response) => {
				if (response.data) {
					commit("SET_GROUPS", response.data.data.results)
				}
			})

	},
	//this function gets the list of string user emails, not the full user accounts
	async fetchCustomerUsers({ dispatch, commit }) {

		return HTTP.get("/api/v1/account")
			.then((response) => {
				if (response.data) {
					var customerUsers = []
					var email = null
					var users = response.data.data.results
					//we only want to add users who are NOT already in a group
					for (var i = 0; i < users.length; i++) {
						if(users[i].group == null || users[i].group == undefined){
							email = users[i].email
							customerUsers.push(email)
						}				
					}
					commit("SET_CUSTOMER_USERS", customerUsers)
				}
			})
	},

	async addGroup({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {
			HTTP.post("/api/vue/v1/group",
				{
					name: payload.name,
					description: payload.description,
					users: payload.users
				}).then((response) => {
					payload.key = response.data.data.key
					commit("ADD_GROUP", payload)
					resolve('success')
				}).catch((error) => {
					reject(error.response)
				})
		})
	},

	async deleteGroup({ dispatch, commit }, { payload }) {
		return HTTP.delete("/api/vue/v1/group/" + payload.key)
			.then((response) => {
				console.log(response.data)
			})
	},

	async updateGroup({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {
			console.log('calling update group')
			HTTP.put("/api/vue/v1/group/" + payload.key,
				{
					name: payload.name,
					description: payload.description,
					users: payload.users,
					addedUsers: payload.addedUsers,
					deletedUsers: payload.deletedUsers,
				}).then((response) => {
					resolve('success')
				}).catch((error) => {
					reject(error.response.data)
				})
		})
	},

	//accounts

	//this function gets the list of full user accounts
	async fetchAccounts({ dispatch, commit }) {
		
		return HTTP.get("/api/v1/account")
			.then((response) => {
				if (response) {
					commit("SET_ACCOUNTS", response.data.data.results)
				}
			}).catch((error) => {

			})
	},

	async addAccount({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {
			console.log('in post to new account')
			HTTP.post("/api/v1/account", {
				email: payload.email,
				group_key: payload.groupKey,
				group_name: payload.groupName,
				customer_admin: payload.customer_admin,
				force_google: payload.force_google,
			}).then((response) => {
				resolve('success')
			}).catch((error) => {
				console.log('error adding account', error.response.data)
				reject(error.response.data)
			})
		})
	},

	async deleteAccount({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {
			HTTP.delete("/api/v1/account/" + payload.userKey
			).then((response) => {
				resolve('success')
			}).catch((error) => {
				console.log(error)
				reject(error.response.data)
			})
		})
	},

	async updateAccountGroup({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {
			HTTP.put("/api/vue/v1/group/" + payload.groupKey + "/user/" + payload.userKey)
				.then((response) => {
					resolve('success')
					console.log('fetching groups to update cache')
				}).catch((error) => {
					reject(error.message)
				})
		})
	},

	async updateAccountAdministrator({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {
			HTTP.put("/api/v1/admin/" + payload.userKey,
				{
					isAdmin: payload.customer_admin
				}).then((response) => {
					console.log(response.data)
					resolve('success')
				}).catch((error) => {
					console.log(error)
					reject(error.message)
				})
		})
	},

	async updateAccountForceGoogle({dispatch, commit}, { payload }) {

		return new Promise((resolve, reject) => {
			HTTP.put("/api/v1/googlelogin/" + payload.userKey,
			{
				force_google: payload.force_google
			}).then( (response) => {
				resolve('success')
			}).catch((error) => {
				console.log(error)
				reject(error.message)
			})
		})
	},

	async resetAccountPassword({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {
			HTTP.put("/api/v1/account/" + payload.key,
				{
					newPassword: payload.password,
					confirmPassword: payload.confirmPassword
				}).then((response) => {
					console.log(response.data)
					resolve('success')
				}).catch((error) => {
					reject(error.response.data)
				})
		})
	},

	// zone transfer

	async fetchPageZoneTransferPages({ dispatch, commit }, { payload }) {
		//clear previous values
		return new Promise((resolve, reject) => {
			HTTP.get("/api/v1/document/" + payload.sourceDocId + "/" + payload.destinationDocId + "/page_zone_transfer")
				.then((response) => {
					if (response) {
						commit("SET_SOURCE_PAGE_COUNT", response.data.data.source_page_count)
						commit("SET_DESTINATION_PAGE_COUNT", response.data.data.destination_page_count)
						resolve('success')
					}
				}).catch((error) => {
					reject(error.response.data)
				})
		})
	},

	async documentZoneTransfer({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {
			HTTP.post("/api/v1/document/" + payload.sourceDocumentId + "/" + payload.destinationDocumentId + "/document_zone_transfer")
				.then((response) => {
					resolve('success')
				}).catch((error) => {
					reject(error.response.data)
				})
		})

	},

	async pageZoneTransfer({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {
			HTTP.post("/api/v1/document/" + payload.sourceDocumentId + "/" + payload.destinationDocumentId + "/page_zone_transfer",
				{
					source_page_no: payload.source_page_no,
					destination_page_nos: payload.destination_page_nos
				}).then((response) => {
					resolve('success')
				})
		})

	},

	async uploadDocumentZonesFile({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {
			var fd = payload.fd
			HTTP.post("/api/v1/document/" + payload.destinationDocumentId + "/upload_layout", fd)
				.then((response) => {
					resolve('success')
				}).catch((error) => {
					reject(error)
				})
		})
	},

	async downloadDocumentZonesFile({ dispatch, commit }, { payload }) {
		
		return new Promise((resolve, reject) => {
			HTTP.get("/api/v1/documents/" + payload.sourceDocumentId + "/layouts")
				.then((response) => {
					console.log('doc layout downloaded')
					resolve('success')
				}).catch((error) => {
					reject(error)
				})
		})

	},

	async loadDocument({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {
			HTTP.get("/api/v1/document/" + payload.documentKey)
				.then((response) => {
					commit("SET_DOCUMENT", response.data.data)
					resolve('success')
				}).catch((error) => {
					reject(error)
				})
		})
	},

}



