
export default {
    documents: [],
		recentDocuments: [],
    importing: false, // Currently importing something
    importingDocuments: {}, // Currently importing documents
    inProgressDocuments: 0, // Document count of importing in progress
    importingEventsOn: false, // Centrifugo listeners are setup
    importedDocuments: [], // Last documents imported
		labelFilters: [] //label filters used in query
}