
export default {
    document: null,
    isDirty: false,
    documentPages: [],
    documentImages: [],
    metadatas: [],
    documentNotes: [],
    documentHistory: [],
    exporting: {
        html: false,
        pdf: false,
        epub: false
    },
    uncheckedPages: false,
    unicodeMaps: [],
    css: [],   
}