<template>
	<v-tab-item key="tabGroups" class="pa-10">
		<v-container class="tab-settings">
			<template>
				<v-toolbar flat>
					<v-dialog v-model="dialog" max-width="750px">

						<template v-slot:activator="{ on, attr }">
							<v-card class="d-flex mb-10" title flat>
								<h2 class="h2-title">{{ $t("settings.tabGroups.headerCreateNewGroup") }}</h2>
								<v-btn color="primary" dark class="mb-2 ml-10" v-bind="[attr]" v-on="on">
									<!--<v-icon v-if="buttonStyle.icons.display" class="mr-1">mdi-plus</v-icon>-->
									<span>{{ $t("settings.tabGroups.buttonNewGroup") }}</span>
								</v-btn>
							</v-card>
						</template>
						<v-card>
							<v-card-title>
								<span class="text-h5">{{ formTitle }}</span>
							</v-card-title>
							<v-card-text>

								<v-container>
									<v-row>
										<v-col cols="12" sm="6">
											<v-text-field v-model="editedItem.name" :label="$t('settings.tabGroups.labelName')"
												:rules="[rules.required, rules.max, rules.min]" maxlength="21">
											</v-text-field>
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="12" sm="6">
											<v-textarea v-model="editedItem.description" :label="$t('settings.tabGroups.labelDescription')"
												:rules="[descriptionRules.max]" maxlength="201" outlined>
											</v-textarea>
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="12" sm="6">
											<v-autocomplete v-model="user" :items="customerUsers" :label="$t('settings.tabGroups.labelUser')">
											</v-autocomplete>
										</v-col>

										<v-col cols="12" sm="6">
											<v-btn color="primary" @click="addUser(user)">
												{{ $t("settings.tabGroups.buttonAddUser") }}
											</v-btn>
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="12">
											<v-subheader>{{ $t("settings.tabGroups.headerUsers") }}</v-subheader>
											<v-virtual-scroll :items="editedItem.users" height="150" item-height="20">
												<template v-slot:default="{ item }">
													<v-list-item :key="'A' + item.email">
														{{ item.email }}
														<v-list-item-action>
															<v-icon small class="mr-2" @click="deleteUser(item)">
																mdi-delete
															</v-icon>
														</v-list-item-action>
													</v-list-item>
												</template>
											</v-virtual-scroll>
										</v-col>

									</v-row>
								</v-container>

							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="close">
									{{ $t("settings.tabGroups.buttonCancel") }}
								</v-btn>
								<v-btn color="blue darken-1" text @click="save" :disabled="validate == false">
									{{ $t("settings.tabGroups.buttonSave") }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>

					<v-dialog v-model="dialogDelete" max-width="520px">
						<v-card>
							<v-card-title class="text-h5">
								{{ $t("settings.tabGroups.headerConfirmDelete") }}
							</v-card-title>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="closeDelete">
									{{ $t("settings.tabGroups.buttonCancel") }}
								</v-btn>
								<v-btn color="blue darken-1" text @click="deleteItemConfirm">
									{{ $t("settings.tabGroups.buttonOk") }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>

				</v-toolbar>
			</template>

			<v-data-table :headers="headers" :items="groups" :items-per-page="10">
				<template v-slot:item.users="{ item }">
					<v-virtual-scroll :items="item.users" height="60" item-height="20">
						<template v-slot:default="{ item }">
							<v-list-item :key="item.key">
								{{ item.email }}
							</v-list-item>
						</template>
					</v-virtual-scroll>
				</template>
				<template v-slot:item.name="{ item }">
					{{ getFormattedName(item.name) }}
				</template>
				<template v-slot:item.description="{ item }">
					{{ getFormatttedDecription(item.description) }}
				</template>

				<template v-slot:item.actions="{ item }">
					<v-icon small class="mr-2" @click="editItem(item)">
						mdi-pencil
					</v-icon>
					<v-icon small class="mr-2" @click="deleteItem(item)">
						mdi-delete
					</v-icon>

				</template>

				<template v-slot:top>
					<v-toolbar flat>
						<v-toolbar-title> 
							<h2 class="h2-title">{{ $t("settings.tabGroups.headerExistingGroups") }}</h2>
						</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</template>

				<!--
				<template v-slot:no-data>
					<v-btn color="primary" @click="fetchLabels">
						{{ $t("settings.tabGroups.buttonReset") }}
					</v-btn>
				</template>
				-->

			</v-data-table>
		</v-container>


	</v-tab-item>
</template>


<script>
import { mapGetters, mapActions } from "vuex"
import i18n from "@/plugins/i18n"

export default {
	data() {
		return {
			user: null,
			dialog: false,
			dialogDelete: false,
			editedIndex: -1,
			addedUsers: [],
			deletedUsers: [],
			editedItem: {
				name: '',
				description: '',
				users: []
			},
			defaultItem: {
				name: '',
				description: '',
				users: []
			},
			headers: [
				{
					text: this.$t("settings.tabGroups.headerName"),
					value: "name",
					width: "15%"
				},
				{
					text: this.$t("settings.tabGroups.headerDescription"),
					value: "description",
					align: "start",
					width: "30%"
				},
				{
					text: this.$t("settings.tabGroups.headerUserCount"),
					value: "users.length",
					width: "10%"
				},
				{
					text: this.$t("settings.tabGroups.headerUsers"),
					value: "users"
				},
				{
					text: this.$t("settings.tabGroups.headerEditDelete"),
					value: "actions",
					align: "end",
					width: "10%"

				}

			],
			rules: {
				required: (value) => !!value || "Required.",
				max: (v) => v.length <= 20 || "Max 20 characters",
				min: (v) => v.length >= 1 || "Min 1 character",
			},
			descriptionRules: {
				max: (v) => v.length <= 200 || "Max 200 characters",
			}
		}
	},
	computed: {
		...mapGetters({
			language: "language/getLanguage",
			groups: "settings/getGroups",
			customerUsers: "settings/getCustomerUsers",
			options: "application/getOptions",
			buttonStyle: "application/getButtonStyle",
		}),
		formTitle() {
			return this.editedIndex === -1 ? 'New Group' : 'Edit Group'
		},
		validate() {
			if (this.editedItem.name.length < 1 || this.editedItem.name.length > 20) {
				return false
			}
			if (this.editedItem.description.length > 200) {
				return false
			}
			return true
		}
	},
	watch: {
		dialog(val) {
			val || this.close()
		},
		dialogDelete(val) {
			val || this.closeDelete()
		},
	},
	methods: {
		...mapActions({
			fetchGroups: "settings/fetchGroups",
			fetchCustomerUsers: "settings/fetchCustomerUsers",
			addGroup: "settings/addGroup",
			deleteGroup: "settings/deleteGroup",
			updateGroup: "settings/updateGroup"
		}),
		addUser(email) {
			console.log('adding user: ' + email)
			if (email != null) {
				var found = false
				for (var i = 0; i < this.editedItem.users.length; i++) {
					if (this.editedItem.users[i].email == email) {
						found = true
						break
					}
				}

				if (!found) {
					this.editedItem.users.push({ email: email })
					this.addedUsers.push(email)
				}

				this.user = null
			}

		},
		getFormattedName(name) {
			return name != null && name.length > 14 ? name.substring(0, 14) + "..." : name
		},
		getFormatttedDecription(desc) {
			return desc != null && desc.length > 14 ? desc.substring(0, 20) + "..." : desc
		},
		deleteUser(user) {
			console.log('deleting user: ' + user.email)
			var emailIndex = this.editedItem.users.indexOf(user)
			console.log('index of removed user: ' + emailIndex)
			this.editedItem.users.splice(emailIndex, 1)
			this.deletedUsers.push(user.email)
		},
		editItem(item) {
			this.editedIndex = this.groups.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialog = true
		},
		deleteItem(item) {
			this.editedIndex = this.groups.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialogDelete = true
		},
		deleteItemConfirm() {
			this.groups.splice(this.editedIndex, 1)

			this.deleteGroup({
				payload: { key: this.editedItem.key }
			}).then((response) => {
				this.$snackbar.showMessage({ content: this.$t("settings.tabGroups.msg.groupDeleted"), color: 'success' })
			})

			this.closeDelete()
		},
		close() {
			this.dialog = false
			this.editedItem.users = []
			this.defaultItem.users = []
			this.user = null
			this.editedItem.name = ''
			this.defaultItem.name = ''

			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		closeDelete() {
			this.dialogDelete = false

			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		clearAddedDeletedUsers() {
			this.addedUsers = []
			this.deletedUsers = []
		},
		validateName() {
			if (this.editedItem.name == null ||
				this.editedItem.name.length < 1 ||
				this.editedItem.name.length > 20 ||
				this.editedItem.description.length > 200) {
				return false
			}
			return true
		},
		save() {
			//edit
			if (this.editedIndex > -1) {
				Object.assign(this.groups[this.editedIndex], this.editedItem)
				console.log('updating existing group')
				this.updateGroup({
					payload: {
						key: this.editedItem.key,
						name: this.editedItem.name,
						description: this.editedItem.description,
						users: this.editedItem.users,
						addedUsers: this.addedUsers,
						deletedUsers: this.deletedUsers
					}
				}).then((response) => {
					this.clearAddedDeletedUsers()
					//reload
					this.fetchGroups()
					this.$snackbar.showMessage({ content: this.$t("settings.tabGroups.msg.groupUpdated"), color: 'success' })
				}).catch((error) => {
					console.log('update group error', error)
					this.$snackbar.showMessage({ content: this.$t("settings.tabGroups.msg.errorSavingGroup", [error]), color: 'error' })
				})
			} else { //add
				this.addGroup({
					payload: {
						name: this.editedItem.name,
						description: this.editedItem.description,
						users: this.editedItem.users
					}
				}).then((response) => {
					//only add if successful
					this.clearAddedDeletedUsers()
					//this.groups.push(this.editedItem)
					this.fetchGroups()
					this.$snackbar.showMessage({ content: this.$t("settings.tabGroups.msg.groupCreated"), color: 'success' })
				}).catch((error) => {
					console.log('add group error', error)
					this.$snackbar.showMessage({ content: this.$t("settings.tabGroups.msg.errorSavingGroup", [error]), color: 'error' })
				})
			}

			this.close()
		},
	},
	mounted() {
		if (this.options.is_admin == true) {
			this.fetchGroups()
				.then((response) => {
					this.isLoading = false
				})
				.catch((error) => {
					this.isLoading = false
					this.$snackbar.showMessage({ content: this.$t("settings.tabGroups.msg.unabletoGetGroups"), color: 'error' })
				})

			this.fetchCustomerUsers()
				.then((response) => {
					//nothing here
				})
				.catch((error) => {
					//nothing here
				})
		}

	},


}
</script>

<style></style>