import Settings from '../components/Settings'
import TabZoneTransfer from '../components/tabs/TabZoneTransfer'

export default [
	{
		path: '/settings',
		name: 'Settings',
		component: Settings,
		meta: {
			requiresAuth: true,
			i18Msg: 'barTitle.settings'
		},		
	},
  {
		path: '/settings/:key',
		component: Settings
	}




]