import Notifications from '../components/Notifications'

export default [
    {
        path: '/notifications',
        name: 'Notifications',
        component: Notifications,
        meta: {
            requiresAuth: true,
            i18Msg: 'barTitle.notifications'
        }
    },
]