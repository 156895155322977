<template>
	<v-container>
		<v-btn class="ml-2" v-bind="buttonStyle.buttons" color="primary" @click="openDialogShare">
			<v-icon v-if="buttonStyle.icons.display" v-bind:class="buttonStyle.icons.class">mdi-share-variant</v-icon>
			<span v-if="buttonStyle.labels.display">{{ $t("document.share") }}</span>
		</v-btn>

		<v-dialog v-model="dialogShare" max-width="850px">
			<v-card>

				<v-card-title class="text-h5">
					{{ $t("document.shareDocument")}} {{document != null ? document.name : ''}}
				</v-card-title>

				<v-row class="ma-1">
					<v-col cols="4">

						<v-card class="pa-4 ma-4" elevation="2">
							<v-img :src="'/api/v1/thumbnail/' + (document != null ? document.thumbnail_url : '')"></v-img>
						</v-card>

					</v-col>

					<v-col>

						<v-row>
							<v-col>
								<span class="subtitle-1">{{ $t("document.shareWithOthers")}}</span>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="9">
								<v-label>
									{{ $t("document.selectPeople")}}
								</v-label>
								<v-select v-model="document.shared_users" :items="accounts" item-text="email" item-value="key" hide-selected
									multiple small-chips v-if="document != null">

									<!-- this is a selected item-->
									<template v-slot:selection="{ attrs, item, parent}">
										<v-chip v-bind="attrs" :input-value="item.key" label small>
											<span class="pr-2">
												{{item.email != null && item.email.length > 12 ? item.email.substring(0, 12) : item.email}}{{ item.email != null &&
													item.email.length > 12 ? '...' : ''}}
											</span>
											<v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
										</v-chip>
									</template>
								</v-select>
							</v-col>
						</v-row>


						<v-row>
							<v-col>
								<span class="subtitle-1">{{ $t("document.shareWithGroup")}}</span>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="9">
								<v-label>
									{{ $t("document.selectGroup")}}
								</v-label>

								<v-select v-model="document.shared_groups" :items="groups" item-text="name" item-value="key" hide-selected
									multiple small-chips v-if="document != null">
									<!-- this is a selected item-->
									<template v-slot:selection="{ attrs, item, parent}">
										<v-chip v-bind="attrs" :input-value="item.key" label small>
											<span class="pr-2">
												{{item.name != null && item.name.length > 12 ? item.name.substring(0, 12) : item.name}}{{ item.name != null &&
													item.name.length > 12 ? '...' : ''}}
											</span>
											<v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
										</v-chip>
									</template>
								</v-select>
							</v-col>
						</v-row>



					</v-col>

				</v-row>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="close">
						{{ $t("document.cancel")}}
					</v-btn>
					<v-btn color="blue darken-1" text @click="share"
						:disabled="validate == false">
						{{ $t("document.done")}}
					</v-btn>
				</v-card-actions>

			</v-card>
		</v-dialog>

	</v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import EventBus from "@/eventBus"

export default {
	name: "DocumentShareDialog",
	props: ["document"],

	data() {
		return {
			dialogShare: false,
		}
	},
	computed: {
		...mapGetters({
			groups: "settings/getGroups",
			accounts: "settings/getAccounts",
			buttonStyle: "application/getButtonStyle",
		}),
		validate(){
			if(this.document != null && this.document.shared_users != undefined 
				&& this.document.shared_users.length == 0 && this.document.shared_groups != undefined &&
				this.document.shared_groups.length == 0){
				return false
			}
			return true
		}
	},
	watch: {
		dialogShare(val) {
			val || this.close()
		}
	},
	methods: {
		...mapActions({
			fetchGroups: "settings/fetchGroups",
			fetchAccounts: "settings/fetchAccounts",
			shareDocument: "document/shareDocument",
		}),
		close() {
			this.dialogShare = false
		},
		share() {
			this.shareDocument({
				payload: {
					doc_key: this.document.key,
					document: this.document,
					users: this.document.shared_users,
					groups: this.document.shared_groups,
				}
			}).then( (results) => {
				console.log('emitting share update success')
				EventBus.$emit("DOCUMENT_SHARES_UPDATED_SUCCESS", '')
			}).catch( (error) => {
				EventBus.$emit("DOCUMENT_SHARES_UPDATED_ERROR", '')
			})

			this.close()
		},
		openDialogShare() {
			this.dialogShare = true
		},
		getAccounts() {
			this.fetchAccounts().
				then((results) => {

				}).catch((error) => {

				})
		},
		getGroups() {
			this.fetchGroups().
				then((results) => {

				}).catch((error) => {

				})
		},
	},
	mounted() {
		this.getAccounts()

		this.getGroups()

	},

}
</script>

<style>

</style>