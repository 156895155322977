export default {
  methods: {
    getZoneTypes() {
      return [
        { value: "anchor", name: "Anchor" },
        { value: "blockquote", name: "Blockquote" },
        { value: "cite", name: "Cite" },
        { value: "div", name: "Div" },
        { value: "field", name: "Field" },
        { value: "footnotelink", name: "Footnote Link" },
        { value: "footnote", name: "Footnote" },
        { value: "graphic", name: "Graphic" },
        { value: "heading", name: "Heading" },
        { value: "ignore", name: "Ignore" },
        { value: "link", name: "Link" },
        { value: "list", name: "List" },
        { value: "ocr", name: "Ocr" },
        { value: "quote", name: "Quote" },
        { value: "sensitivity", name: "Sensitivity" },
        { value: "text", name: "Text" },
        { value: "table", name: "Table" },
        { value: "toc", name: "Table of content" },
      ]
    },
    getZoneTags() {
      return [
        { value: "p", name: "<p>" },
        { value: "b", name: "b" },
        { value: "em", name: "em" },
        { value: "i", name: "i" },
        { value: "small", name: "small" },
        { value: "strong", name: "strong" },
        { value: "span", name: "span" },
        { value: "sub", name: "sub" },
        { value: "sup", name: "sup" },
        { value: "code", name: "code" },
        { value: "pre", name: "pre" },
        { value: "", name: " " },
      ]
    },
    getZoneIndentations() {
      return [
        { value: "single", name: "Single Indent" },
        { value: "double", name: "Double Indent" },
        { value: "", name: " " },
      ]
    },
    getFieldVisibility() {
      return [
        { value: "visible", name: "Visible" },
        { value: "hidden", name: "Hidden" },
        { value: "visible_no_print", name: "Visible but doesn't print" },
        { value: "hidden_print", name: "Hidden but printable" },
        { value: "", name: " " },
      ]
    },
    getFieldTypes() {
      return [
        { value: "text", name: "Text Input" },
        { value: "button", name: "Button" },
        { value: "check", name: "Checkbox" },
        { value: "radio", name: "Radio" },
        { value: "choice", name: "Dropdown / Choice" },
        { value: "signature", name: "Signature" },
      ]
    },
    getFieldAlignments() {
      return [
        { value: 0, name: "Left" },
        { value: 1, name: "Center" },
        { value: 2, name: "Right" },
      ]
    },
    getCheckboxStyles() {
      return [
        { value: "Check", name: "Check" },
        { value: "Circle", name: "Circle" },
        { value: "Cross", name: "Cross" },
        { value: "Diamond", name: "Diamond" },
        { value: "Square", name: "Square" },
        { value: "Star", name: "Star" },
      ]
    },
    getListStyles() {
      return [
        { value: "a", name: "Lowercase letters" },
        { value: "A", name: "Uppercase letters" },
        { value: "i", name: "Lowercase Roman numerals" },
        { value: "I", name: "Uppercase Roman numerals" },
        { value: "1", name: "Numbers" },
      ]
    },
    getFieldTriggers() {
      return [
        { value: "MouseEnter", name: "Mouse Enter" },
        { value: "MouseLeave", name: "Mouse Exit" },
        { value: "MouseDown", name: "Mouse Down" },
        { value: "MouseUp", name: "Mouse Up" },
        { value: "Focus", name: "On Focus" },
        { value: "Blur", name: "On Blur" },
        { value: "PageOpen", name: "On Page Open" },
        { value: "PageClose", name: "On Page Close" },
        { value: "PageVisible", name: "On Page Visible" },
        { value: "PageNotVisible", name: "On Page Hide" },
        { value: "PageOpenAction", name: "Page Opened" },
        { value: "PageCloseAction", name: "Page Closed" },
        { value: "Keystroke", name: "Keystroke" },
        { value: "BeforeFormat", name: "Before Format" },
        { value: "Changed", name: "Changed" },
        { value: "DocumentWillClose", name: "Document Will Close" },
        { value: "DocumentWillSave", name: "Document Will Save" },
        { value: "DocumentDidSave", name: "Document Did Save" },
        { value: "DocumentWillPrint", name: "Document Will Print" },
        { value: "DocumentDidPrint", name: "Document Did Print" },
      ]
    },
    getFieldActions() {
      return [
        { value: "GoTo", name: "Go to a destination in the current document" },
        { value: "GoToR", name: "Go to a destination in another document" },
        { value: "GoToE", name: "Go to a destination in an embedded file" },
        {
          value: "Named",
          name: "Execute an action predefined by the conforming reader",
        },
        {
          value: "SubmitForm",
          name: "Send data to a uniform resource locator",
        },
        { value: "ResetForm", name: "Set fields to their default values" },
        { value: "JavaScript", name: "Execute a JavaScript script" },
        { value: "URI", name: "Resolve a uniform resource identifier" },
      ]
    },
    getFieldNamedActions() {
      return [
        { value: "Print", name: "Print Document" },
        { value: "Close", name: "Close Document" },
        { value: "FirstPage", name: "Goto First page" },
        { value: "NextPage", name: "Next Page" },
        { value: "PreviousPage", name: "Previous Page" },
        { value: "Spelling:Check Spelling", name: "Check Spelling" },
      ]
    },
    getLanguages() {
      return [
        { value: "EN", name: "English" },
        { value: "FR", name: "French" },
        { value: "ES", name: "Spanish" },
        { value: "", name: "" },
        { value: "AB", name: "Abkhazian" },
        { value: "OM", name: "Afan" },
        { value: "AA", name: "Afar" },
        { value: "AF", name: "Afrikaans" },
        { value: "SQ", name: "Albanian" },
        { value: "AM", name: "Amharic" },
        { value: "AR", name: "Arabic" },
        { value: "HY", name: "Armenian" },
        { value: "AS", name: "Assamese" },
        { value: "AY", name: "Aymara" },
        { value: "AZ", name: "Azerbaijani" },
        { value: "BA", name: "Bashkir" },
        { value: "EU", name: "Basque" },
        { value: "BN", name: "Bengali" },
        { value: "DZ", name: "Bhutani" },
        { value: "BH", name: "Bihari" },
        { value: "BI", name: "Bislama" },
        { value: "BR", name: "Breton" },
        { value: "BE", name: "Byelorussian" },
        { value: "BG", name: "Bulgarian" },
        { value: "MY", name: "Burmese" },
        { value: "KM", name: "Cambodian" },
        { value: "CA", name: "Catalan" },
        { value: "CHA", name: "Chamorro" },
        { value: "CHR", name: "Cherokee" },
        { value: "ZH", name: "Chinese" },
        { value: "CHO", name: "Choctaw" },
        { value: "CO", name: "Corsican" },
        { value: "CPF", name: "Creoles" },
        { value: "HR", name: "Croatian" },
        { value: "CUS", name: "Cushite" },
        { value: "CS", name: "Czech" },
        { value: "DA", name: "Danish" },
        { value: "NL", name: "Dutch" },
        { value: "DE", name: "German" },
        { value: "KA", name: "Georgian" },
        { value: "EL", name: "Greek" },
        { value: "KL", name: "Greenlandic" },
        { value: "EO", name: "Esperanto" },
        { value: "ET", name: "Estonian" },
        { value: "FO", name: "Faroese" },
        { value: "FJ", name: "Fiji" },
        { value: "FI", name: "Finnish" },
        { value: "FY", name: "Frisian" },
        { value: "GL", name: "Galician" },
        { value: "GN", name: "Guarani" },
        { value: "GU", name: "Gujarati" },
        { value: "HA", name: "Hausa" },
        { value: "HAW", name: "Hawaiian" },
        { value: "HE", name: "Hebrew" },
        { value: "HI", name: "Hindi" },
        { value: "HMN", name: "Hmong" },
        { value: "HU", name: "Hungarian" },
        { value: "IBO", name: "Ibo" },
        { value: "IS", name: "Icelandic" },
        { value: "ILO", name: "Ilocano" },
        { value: "ID", name: "Indonesian" },
        { value: "IA", name: "Interlingua" },
        { value: "IE", name: "Interlingue" },
        { value: "IU", name: "Inuktitut" },
        { value: "IK", name: "Inupiak" },
        { value: "GA", name: "Irish" },
        { value: "IT", name: "Italian" },
        { value: "JA", name: "Japanese" },
        { value: "JV", name: "Javanese" },
        { value: "KN", name: "Kannada" },
        { value: "KAR", name: "Karen" },
        { value: "KS", name: "Kashmiri" },
        { value: "KK", name: "Kazakh" },
        { value: "RW", name: "Kinyarwanda" },
        { value: "KY", name: "Kirghiz" },
        { value: "KO", name: "Korean" },
        { value: "KRO", name: "Kru" },
        { value: "KU", name: "Kurdish" },
        { value: "RN", name: "Kurundi" },
        { value: "LO", name: "Laotian" },
        { value: "LA", name: "Latin" },
        { value: "LV", name: "Latvian" },
        { value: "LN", name: "Lingala" },
        { value: "LT", name: "Lithuanian" },
        { value: "MK", name: "Macedonian" },
        { value: "MG", name: "Malagasy" },
        { value: "MS", name: "Malay" },
        { value: "ML", name: "Malayalam" },
        { value: "MT", name: "Maltese" },
        { value: "MI", name: "Maori" },
        { value: "MR", name: "Marathi" },
        { value: "MAH", name: "Marshallese" },
        { value: "PON", name: "Micronesian" },
        { value: "MO", name: "Moldavian" },
        { value: "MN", name: "Mongolian" },
        { value: "MKH", name: "Mon" },
        { value: "NA", name: "Nauru" },
        { value: "NAV", name: "Navajo" },
        { value: "NE", name: "Nepali" },
        { value: "DIN", name: "Nilotic" },
        { value: "NO", name: "Norwegian" },
        { value: "OC", name: "Occitan" },
        { value: "OR", name: "Oriya" },
        { value: "PS", name: "Pashto" },
        { value: "fa", name: "Persian" },
        { value: "PL", name: "Polish" },
        { value: "PT", name: "Portuguese" },
        { value: "PA", name: "Punjabi" },
        { value: "QU", name: "Quechua" },
        { value: "RM", name: "Rhaeto" },
        { value: "RO", name: "Romanian" },
        { value: "RU", name: "Russian" },
        { value: "SM", name: "Samoan" },
        { value: "SA", name: "Sanskrit" },
        { value: "SG", name: "Sangho" },
        { value: "GD", name: "Scots" },
        { value: "SR", name: "Serbian" },
        { value: "SH", name: "Serbo" },
        { value: "ST", name: "Sesotho" },
        { value: "TN", name: "Setswana" },
        { value: "SN", name: "Shona" },
        { value: "SD", name: "Sindhi" },
        { value: "SI", name: "Singhalese" },
        { value: "SS", name: "Siswati" },
        { value: "SK", name: "Slovak" },
        { value: "SL", name: "Slovenian" },
        { value: "SO", name: "Somali" },
        { value: "SU", name: "Sundanese" },
        { value: "SW", name: "Swahili" },
        { value: "SV", name: "Swedish" },
        { value: "SYR", name: "Syriac" },
        { value: "TL", name: "Tagalog" },
        { value: "TG", name: "Tajik" },
        { value: "TA", name: "Tamil" },
        { value: "TT", name: "Tatar" },
        { value: "TE", name: "Telugu" },
        { value: "TH", name: "Thai" },
        { value: "BO", name: "Tibetan" },
        { value: "TI", name: "Tigrinya" },
        { value: "TO", name: "Tonga" },
        { value: "CHK", name: "Trukese" },
        { value: "TS", name: "Tsonga" },
        { value: "TR", name: "Turkish" },
        { value: "TK", name: "Turkmen" },
        { value: "TW", name: "Twi" },
        { value: "UG", name: "Uigur" },
        { value: "UK", name: "Ukrainian" },
        { value: "UR", name: "Urdu" },
        { value: "UZ", name: "Uzbek" },
        { value: "VI", name: "Vietnamese" },
        { value: "VO", name: "Volapuk" },
        { value: "CY", name: "Welsh" },
        { value: "WO", name: "Wolof" },
        { value: "XH", name: "Xhosa" },
        { value: "YI", name: "Yiddish" },
        { value: "YO", name: "Yoruba" },
        { value: "ZA", name: "Zhuang" },
        { value: "ZU", name: "Zulu" },
      ]
    },
    getZoneIndentation() {
      return [
        { value: "single", name: "Single Indent" },
        { value: "double", name: "Double Indent" },
        { value: "", name: " " },
      ]
    },
  },
}

// $scope.sourceTypes = [
//   {value:"PDF", name:"PDF"},
//   {value:"Actual", name:"Actual Text"},
//   {value:"OCR", name:"OCR"},
//   {value:"Custom", name:"Custom"},
// ];

// $scope.autoOrders = [
//   {value:1, name:"Default - Top Down"},
//   {value:2, name:"2 columns"},
//   {value:3, name:"3 columns"},
//   {value:4, name:"4 columns"}
// ];
