<template>
	<svg :id="contrastZone.id" :x="contrastZone.x" :y="contrastZone.y" :width="contrastZone.w" :height="contrastZone.h"
		v-on:keyup.enter="zoneSelected(contrastZone)" @focus="onFocus" @mousedown.stop="onMouseDown">

		<!-- THE BASIC ZONE RECT ALWAYS COME FIRST -->
		<!-- need to set the generic zone fill color and stroke color first, then change with classes -->
		<rect :class="getZoneClass" :id="contrastZone.id" :order="contrastZone.order" fill="#e8ffb657" stroke="#767676"
			stroke-width="1" :width="contrastZone.w" :height="contrastZone.h" x=0 y=0 border="0" />

		<!-- THIS IS THE LONG BOX ON THE LEFT SIDE OF THE ZONE  -->
		<!-- if we put it first, the zone border will be on top, not hidden by the green -->

		<rect class="zone-left-fill left" fill="#6ea9ff" :x="contrastZone.selected ? 1 : 1" :y="contrastZone.selected ? 1 : 1"
			:height="contrastZone.selected ? contrastZone.h - 2 : contrastZone.h - 2" border="0" stroke-width="1" />

		<!-- if it's a list, we move the x over 5% -->
		<text fill="#00444" style="caret-color: transparent;">
			<tspan xml:space="preserve" text-anchor="start" font-family="Noto Sans JP" font-size="8" stroke-width="0"
				:x="contrastZone.w * .05" :textLength="5" y="8" stroke="#000" fill="#000000" filter="url(#solid_green)"
				@mousedown.stop="onMouseDown" style="cursor: default;opacity: 0.65;">
				C</tspan>
		</text>

	</svg>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import EventBus from "@/eventBus"

export default {
	name: "ContrastZoneRect",
	components: {},
	//these are injected from the SVGZones component
	props: {
		contrastZone: { type: Object },  //same as zoneModel from editor_directives
		selected: { type: Boolean, required: false },
		focused: { type: Boolean, required: false }, //prob not needed here.
		initialPosition: { type: Object, required: false },

	},

	data() {
		return {
			zoneClass: ['zone'],
		}
	},
	computed: {
		...mapGetters({
			selectedZone: "page/getSelectedZone",
			zoneToggleSetting: "application/getZoneToggleSetting",
			zoomLevel: "application/getZoomLevel",
			options: "application/getOptions",
		}),
		// show/hide box with zone type abbr.  depends on zoneInfo.
		showZoneType() {
			if (this.zoneToggleSetting == 1 || this.zoneToggleSetting == 3) {
				return true
			}
			return false
		},
		/*
		 Need a way to take array of string class names and join to large space-separated string for the svg zone class
		*/
		getZoneClass() {
			//change to: if it's not selected, remove the class
			var retval = this.zoneClass.join(' ')
			return retval
		},
	},
	watch: {
		/*
			We're watching the selected prop on the zone to know if we need to add or remove the selected class.

			Selected is ultimately set in the parent ie SVGZones, then the selected property is propegated to SVGZones, which
			is the child component.  If this component needs a zone set as selected, it sends an event to the parent to make
			the change see this.zoneSelected.  This watcher looks for changes to the selected property so we know when
			to add the class.  So, selected class is added in this component, but selected is added in the parent component.
			Goal is to have a one-way direction update.
		*/
		selected: {
			handler(newVal, oldVal) {
				if (newVal) {
					this.contrastZone.selected = true
					this.addSelectedClass()
				}
				else {
					this.contrastZone.selected = false
					this.removeSelectedClass()
				}
			}
		},
		focused: {
			handler(newVal, oldVal) {
				if (newVal) {
					//this.addZoneClass('focused')
				}
				else {
					//this.removeZoneClass('focused')
				}
			}
		},
	},
	methods: {
		...mapMutations({
			setFocusedZone: "page/SET_FOCUSED_ZONE",
		}),
		//on keyup enter, select the zone.  ref'd on main svg.
		zoneSelected(zone) {
			this.setSelectedZone(zone)
			//adding this because newly created zones aren't picking up the selected class correctly
			this.addSelectedClass()
			EventBus.$emit("zoneSelected", zone)
		},

		onFocus() {
			this.setFocusedZone(this.zone)
		},
		clickContrastTab() {
			EventBus.$emit("tabContrast", {})
		},

		/*
			Copied from SVGZones.vue since it's required for some of the zone-type-specific mouse
			handlers
		*/
		getCurrentPosition(event) {
			var x_offset = (this.zoomLevel - 1) * 36 + 320
			var x = event.pageX - x_offset
			var y_offset = (this.zoomLevel - 1) * 25 + 160
			var y = event.pageY - y_offset
			return { x: Math.round(x), y: Math.round(y) }
		},

		/*
			Here is where we add/remove the selected class, which includes the ring highlight the is set in user options
		*/
		addSelectedClass() {
			this.removeZoneClass('zone')
			this.addZoneClass('selected')
			this.addZoneClass(this.getRingWidthClass())
		},
		removeSelectedClass() {
			this.addZoneClass('zone')
			this.removeZoneClass('selected')
			this.removeZoneClass(this.getRingWidthClass())
		},
		getRingWidthClass() {
			var ring_width = this.options.ring_width
			if (ring_width == null || ring_width == '') {
				ring_width = 'Large'
			}
		
			if (ring_width == 'Large') {
				return 'large_ring'
			} else if (ring_width == 'Medium') {
				return 'medium_ring'
			} else if (ring_width == 'Small') {
				return 'small_ring'
			} else if (ring_width == 'Off') {
				return 'off_ring'
			} else {
				return ''
			}
		},
		//THIS SECTION IS FOR ADDING/REMOVING CLASSES ON ZONE

		//add string class name to array of classes applied to zone rect
		addZoneClass(zClass) {
			//console.log('adding zClass: ' + zClass)
			//need way to add zone class
			if (!this.hasZoneClass(zClass)) {
				this.zoneClass.push(zClass)
			}
		},
		//remove string class name from array of classes applied to zone rect
		removeZoneClass(zClass) {
			//console.log('removing zClass: ' + zClass)
			for (var i = 0; i < this.zoneClass.length; i++) {
				if (this.zoneClass[i] == zClass) {
					this.zoneClass.splice(i, 1)
					break
				}
			}
		},
		//return true if array of zone rect class names included string class
		hasZoneClass(zClass) {
			var found = false
			for (var i = 0; i < this.zoneClass.length; i++) {
				if (this.zoneClass[i] == zClass) {
					return true
				}
			}
			return false
		},

		/*
			Get all the elements with a class name
			param zClass string class name
		*/
		getAllElementsWithClass(zClass) {
			var allElements = document.querySelectorAll('.' + zClass)
			return allElements
		},

		/*
			Remove a class name from a list of elements
		*/
		removeClassFromElements(zClass, elements) {
			elements.forEach((element) => {
				element.classList.remove(zClass)
			})
		},

		/*
			Get element by id and add a class to it
		*/
		addClassToElementById(zClass, elementId) {
			var element = document.getElementById(elementId)
			element.classList.add(zClass)
		},

		/*
			Get element by id and remove a class from it
		*/
		removeClassFromElementById(zClass, elementId) {
			var element = document.getElementById(elementId)
			element.classList.remove(zClass)
		},

		//mouse handling

		/*
			onMouseDown is callback on mousedown event for rect/zone
			all this method is really doing is emitting an event that gets picked up by the parent
			by way of @mousedown="zoneMouseDown"  on the SVGZone included component
		*/
		onMouseDown(e) {
			//SVGZones zoneMouseDown sets selected zone
			//console.log('emitting mousedown from onMouseDown in zone')
			
			this.$emit("mousedown",
				{ zone: this.contrastZone, evt: e }
			)

			this.clickContrastTab()
			
		},
	},
	mounted() {

	},

}
</script>

<style lang="scss" scoped>
.zone {
	fill: '#e8ffb657';
	stroke: #767676;
	stroke-width: 2;

}

.zone:not(.disable-hover):hover {
	fill: #D4FFC0;
	fill: #d4ffc0;
	fill: rgba(212, 255, 192, 0.49);
	/* Works on all modern browsers */
	border: 1px solid #000000;
	cursor: move;
}

.highlight {
	border: 1px solid #F50808 !important;
	background-color: #f508082b !important;
	z-index: 100;
}

.selected {
	fill: #f508082b !important; //need to override just about everything but not-visible
	stroke: #F50808 !important;
	stroke-width: 2;
}

.selected:hover {
	fill: #ff000045;
	cursor: move;
}

svg:focus {
	outline: 5px solid #4baafa57 !important;
}

/* zone ring sizes. There are more !important than those app.css */
svg:focus:has(.large_ring){
	outline: 5px solid #4baafa57 !important;
}

svg:focus:has(.medium_ring) {
  outline: 3px solid #4baafa57 !important;
}

svg:focus:has(.small_ring) {
  outline: 1px solid #4baafa57 !important;
}

svg:focus:has(.off_ring){
	outline: 0px solid #4baafa57 !important;
}



.zone-left-fill {
	background-color: #6ea9ff;
	width: 5%;
	-moz-opacity: 0.8;
	-khtml-opacity: 0.8;
	opacity: 0.8;
	cursor: move;
}

.left {
	float: left;
}

.right {
	float: right;
}
</style>