
export default {
	config: [],
	options: {
		autosummarize_default: 0,
		document_count: 1,
		import_summary_enabled: true,
		max_doc_import: 10,
		max_users_enabled: true,
		ml_list: true,
		ml_table: true,
		new_notice_count: 0,
		output_default: 0,
		pro_cust: false,
		ring_width: "Large",
		zip_upload: false,
		zone_toggle_setting: 1,
		zone_transfer_enabled: false
	},
	zoomLevel: 1.3,  //pre-set to 1.3
	miniMainMenu: true,
	showSecondToolbar: true,
	snackbar: {
		content: '',
		color: ''
	},
	socket: {
		connectionStatus: 'socket-closed',
		connectionInfo: undefined,
	},
	checkConnection: false,
	buttonStyle:
	{
		buttons: {
			text: true,
			outlined: false,
			elevation: "1",
			small: false,
		},
		icons: {
			display: true,
			class: "mr-3 gray",
			small: false,
		},
		labels:{
			display: true
		},
		ideal: false
	}


}