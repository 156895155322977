<template>
  <v-tab-item key="history" class="pa-10">

      <template v-for="item in documentHistory.results">
        <v-row :key=item.key>
          <v-col cols="1">
              <v-img
                src="@/assets/page_white_acrobat.png"
                max-width="50"
                alt="PDf icon"
              ></v-img>
          </v-col>
          <v-col cols="11">
            <v-row>
              <v-col>
                {{ item.formatted }}
              </v-col>
              <v-col v-if="language == 'en'">
                <vue-moments-ago
                  prefix=""
                  suffix="ago"
                  :date="item.saved_on"
                  lang="en"
                ></vue-moments-ago>
              </v-col>
              <v-col v-if="language == 'fr'">
                <vue-moments-ago
                  prefix=""
                  :date="item.saved_on"
                  suffix=""
                  lang="fr"
                ></vue-moments-ago>
              </v-col>

            </v-row>
            <v-row>
              <v-col v-if="item.document_warnings">
                {{ item.total_warning }} {{ $t("document.tabHistory.warnings") }}
              </v-col>
              <v-col v-else>
                {{ item.formatted }}
              </v-col>
            </v-row>

            <v-row class="mb-5">
              <v-col>
                
                 <v-btn 
                  v-if="!warnings[item.key]"
                  raised
                  @click="loadWarnings(item.key)">
                  {{ $t("document.tabHistory.viewWarnings") }}
                </v-btn>

                <v-expansion-panels accordion focusable>
                  <v-expansion-panel
                    v-for="(warning_entry,i) in warnings[item.key]"
                    :key="i"
                  >
                    <v-expansion-panel-header>
                      {{ warning_entry.message_data.length }} {{ $t("document.tabHistory.warningsOnPage") }} {{ warning_entry.page_no }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      
                      <ul>
                        <li v-for="(warning, w) in warning_entry.message_data" :key="w">
                          {{ warning.message }}
                            <router-link
                              link
                              :to="'/document/' + $route.params.key + '/page/' + warning.page"
                              target= '_blank'
                            >
                              Page {{ warning.page }}
                            </router-link>
                        </li>

                      </ul>
                      
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>


              </v-col>
            </v-row>

          </v-col>

        </v-row>
      </template>

  </v-tab-item>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import VueMomentsAgo from "@/components/MomentsAgo"
import i18n from "@/plugins/i18n"

export default {
  data() {
    return {
      warnings: []
    }
  },
  components: {
    VueMomentsAgo
  },
  computed: {
    ...mapGetters({
      language: "language/getLanguage",      
      documentHistory: "document/getDocumentHistory",
    }),
  },
  methods: {
    ...mapActions({
      fetchDocumentHistory: "document/fetchDocumentHistory",
      fetchDocumentHistoryWarnings: "document/fetchDocumentHistoryWarnings"
    }),
    loadWarnings(historyKey){
      console.log('Loading warnings for key', historyKey)
      this.fetchDocumentHistoryWarnings({ payload: { key: this.$route.params.key, historyKey: historyKey } })
      .then((response) => {
        this.$set(this.warnings, historyKey, response.results)
        this.isLoading = false

      })
      .catch((error) => {
        this.isLoading = false
        this.$snackbar.showMessage({ content: this.$t("document.tabHistory.msg.errorLoadingWarnings"), 
          color: 'error' })
      })      
    }
  },  
  mounted() {
    this.fetchDocumentHistory({ payload: { key: this.$route.params.key } })
      .then((response) => {
        this.isLoading = false

      })
      .catch((error) => {
        this.isLoading = false
        this.$snackbar.showMessage({ content: this.$t("document.tabHistory.msg.errorGettingHistory"), 
          color: 'error' })
      })
  },    
}
</script>
