<template>
	<v-tab-item key="unicodeMapping">
		<v-container class="ma-10">

			<!-- outer panel -->
			<v-expansion-panels focusable v-model="panel" :readonly="isDirty == true" :popout="isDirty">
				<v-expansion-panel v-for="uMap in unicodeMaps" :key="uMap.key" @click="loadUnicodeMap(uMap.page_no, uMap.key)">

					<v-expansion-panel-header>
						<template v-slot:default="{open}">
							<v-row>
								<v-col cols="1">
									<v-icon class="mr-3" color="blue darken-4">mdi-format-font</v-icon>
								</v-col>
								<v-col>
									<span v-if="uMap.fixed_chars != uMap.total_chars">{{ uMap.total_chars - uMap.fixed_chars }}
										{{ $t("document.tabUnicodeMapping.labelUnicodeCharsRequire") }} {{ uMap.page_no}} </span>
									<span v-if="uMap.fixed_chars == uMap.total_chars">
									{{ $t("document.tabUnicodeMapping.labelUnicodeMappingOnPage") }} {{uMap.page_no}} </span>
									<v-btn v-if="open && isDirty == true"  color="primary" class="mb-2 ml-10"
										@click.stop="saveUnicodeMapping(uMap.page_no, uMap.key, map_key[uMap.page_no])">
										<v-icon v-if="buttonStyle.icons.display" class="mr-3">mdi-content-save-outline</v-icon>
										{{$t("document.tabUnicodeMapping.labelSave") }}</v-btn>
									<v-btn v-if="open" color="primary" class="float-right mr-16"
										@click.stop="ocrUnicodeMap(uMap.page_no, uMap.key)" :disabled="loading == true">
										<v-icon v-if="buttonStyle.icons.display" class="mr-3">mdi-eye-arrow-right</v-icon>
										{{ $t("document.tabUnicodeMapping.labelOcrAll") }}</v-btn>
								</v-col>
							</v-row>
						</template>
						
					</v-expansion-panel-header>

					<v-expansion-panel-content>
						<!-- inner panel -->
						<v-expansion-panels v-if="showPanels && map_key[uMap.page_no] != undefined" class="pt-2" v-model="subPanel">
							<v-expansion-panel v-for="thing in map_key[uMap.page_no].chars_map" :key="thing.name" >
								<v-expansion-panel-header>
									{{thing.name}} ({{thing.elements.length}})
								</v-expansion-panel-header>

								<v-expansion-panel-content>
									<v-data-table class="glyph-table" :items="thing.elements" :headers="headers" hide-default-footer
										item-key="code" :sort-by="['source']">

										<template v-slot:item.img="{item}">
											
											<img class="glyph-width" :src="'/api/v1/glyph/' + item.img"
												:style="{'object-position': '-' + item.x + 'px ' + '-' + item.y + 'px' }" />
										</template>

										<template v-slot:item.character="{item}">
											<v-text-field class="pt-3 mb-n4" v-model="item.dest" outlined dense maxlength="2"
												style="max-width:45px;" @change="setDirty"></v-text-field>
										</template>

									</v-data-table>

								</v-expansion-panel-content>

							</v-expansion-panel>

						</v-expansion-panels>
						
					</v-expansion-panel-content>

				</v-expansion-panel>

			</v-expansion-panels>

			<v-toolbar>
				<v-toolbar-items>
					<v-btn class="ma-1" text v-if="hasPrevious" @click="previousPage">
						<v-icon left>
							mdi-arrow-left
						</v-icon>
						Previous
					</v-btn>

					<v-btn class="ma-1" text v-if="hasNext" @click="nextPage">
						Next
						<v-icon right>
							mdi-arrow-right
						</v-icon>
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>

		</v-container>

	</v-tab-item>

</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import EventBus from "@/eventBus"

export default {
	props: ["document"],

	data() {
		return {
			headers: [
				{
					text: "Code",
					align: "start",
					value: "source",
				},
				{
					text: "Glyph",
					value: "img",
					type: "image"
				},
				{
					text: "Character",
					value: "character",
				},

			],
			currentResultsPage: 0,
			hasPrevious: false,
			hasNext: true,
			resultSize: 5,
			previousDirection: 'forward',
			map_key: {},
			showPanels: false,
			count: 0,
			panel: [],
			subPanel: [],
			currentPage: -1,
			isDirty: false,
			loading: false,
		}

	},
	computed: {
		...mapGetters({
			unicodeMaps: "document/getUnicodeMaps",
			buttonStyle: "application/getButtonStyle",
		}),

	},
	watch: {
		//need to watch for data in active panels
		'panel': function (newVal, oldVal) {
			if (newVal == undefined) {
				console.log('all panels closed')
			}
			else {
				console.log('panel ' + newVal + ' opened')
			}
		},

		'subPanel': function (newVal, oldVal) {
			if (newVal == undefined) {
				console.log('subpanels closed')
			}
			else {
				console.log('subPanel ' + newVal + ' opened')
			}
		},
		'currentPage': function (newVal, oldVal) {
			console.log('current page:' + newVal)
		},
		'isDirty': function (newVal, oldVal) {
			console.log('dirty:' + newVal)
			//show save button and lock the current page
		},
	},
	methods: {
		...mapActions({
			fetchUnicodeMaps: "document/fetchUnicodeMaps",
			fetchUnicodeMap: "document/fetchUnicodeMap",
			saveUnicodeMap: "document/saveUnicodeMap",
			fetchOcrUnicodeMap: "document/fetchOcrUnicodeMap",
		}),
		...mapMutations({
			setTotalUnicodeFixed: "document/SET_TOTAL_UNICODE_FIXED",
		}),
		loadUnicodeMaps(direction) {
			console.log('in load unicode maps.  doc_key:' + this.document.key)
			this.fetchUnicodeMaps({
				payload: {
					doc_key: this.document.key,
					currentResultsPage: this.currentResultsPage,
					resultSize: this.resultSize,
					direction: direction
				}
			}).then((response) => {
				if (response) {
					console.log('load unicode maps results:', response)
					this.currentResultsPage = response.nextPage
					this.hasPrevious = this.currentResultsPage > 1
					this.hasNext = response.more

					for (var i = 0; i < this.unicodeMaps.length; i++) {
						this.loadUnicodeMap(this.unicodeMaps[i].page_no, this.unicodeMaps[i].key)
					}
				}
			}).catch((error) => {
				//catch error here
			})
		},
		setDirty() {
			this.isDirty = true
		},
		//convert the chars_map into an object with attributes since we can't iterate over map in v-for
		convertCharsMapToObj(charsMap) {
			var retval = []
			Object.entries(charsMap).forEach(([key, value]) => {
				var obj = { name: key, elements: value }
				retval.push(obj)
			})
			console.log('retval:', retval)
			return retval
		},

		//convert the list of objects back to a map
		convertObjToCharsMap(charsMap) {
			var retval = {}
			for (var i = 0; i < charsMap.length; i++) {
				retval[charsMap[i].name] = charsMap[i].elements
			}
			return retval
		},
		loadUnicodeMap(page_no, map_key) {
			//set the page that we're currently looking at
			this.currentPage = page_no
			//we only want to load these once
			if (this.map_key[page_no] != null && this.map_key[page_no] != undefined) {
				console.log('map_key for page_no has already been loaded')
				return
			}
			this.fetchUnicodeMap({
				payload: {
					doc_key: this.document.key,
					map_key: map_key,
				}
			}).then((response) => {
				this.map_key[page_no] = response
				var charsMapAsObj = this.convertCharsMapToObj(this.map_key[page_no].chars_map)
				this.map_key[page_no].chars_map = charsMapAsObj
				this.count += 1
				if (this.count == this.unicodeMaps.length) {
					this.showPanels = true
				}
			}).catch((error) => {
				//nothing here
			})
		},
		imageSrc(val) {
			return '/api/v1/glyph/' + val
		},
		saveUnicodeMapping(page_no, map_key, entries) {
			//we converted the char_map into an array of obj to iterate over in ui.  have to convert back to map for server handling
			console.log('entries: ', entries)
			var convertedEntries = Object.assign({}, entries)
			console.log('converted entry:', convertedEntries)
			var charsObjAsMap = this.convertObjToCharsMap(entries.chars_map)
			convertedEntries.chars_map = charsObjAsMap
			console.log('converted entries: ', convertedEntries)

			this.saveUnicodeMap({
				payload: {
					doc_key: this.document.key,
					page_no: page_no,
					map_key: map_key,
					data: convertedEntries,
				}
			}).then((response) => {
				console.log('fixed chars: ', response.fixed_chars)
				console.log('total_fixed', response.total_fixed)
				if (response.total_fixed !== undefined && response.total_fixed != 0) {
					this.setTotalUnicodeFixed(response.total_fixed)
				}
				this.map_key[page_no].fixed_chars = response.fixed_chars
			
				this.isDirty = false
				this.$snackbar.showMessage({ content: this.$t("document.tabUnicodeMapping.msg.mappingSavedForPage", [this.currentPage]), 
					color: 'success' })

			}).catch((error) => {
				console.log('unicode map not found')
			})
		},
		fixCharMapImageUrl(charMap) {
			var data = JSON.parse(charMap)
			for (var i = 0; i < data.length; i++) {
				if (data[i].img.includes("storage.googleapis.com")) {
					var tokens = data[i].img.split('/');
					if (tokens != null && tokens != undefined) {
						var imgUrl = tokens.slice(-4).join('/');
						data[i].img = imgUrl;
					}
				}
			}
			return JSON.stringify(data)
		},
		ocrUnicodeMap(page_no, map_key) {
			this.loading = true

			EventBus.$on('callback-unicode', (msg) => {
				console.log('charData in callback:', msg.data.charData)
				msg.data.charData = this.fixCharMapImageUrl(msg.data.charData);
			
				for(var i=0;i<this.map_key[page_no].chars_map.length; i++){
					if(this.map_key[page_no].chars_map[i].name == msg.data.fontName){
						
						this.map_key[page_no].chars_map[i].elements = JSON.parse(msg.data.charData)
					}
				}

				this.isDirty = true
				this.loading = false

				console.log('now chars_map looks like this:', this.map_key[page_no].chars_map)
				this.$forceUpdate()
			})

			console.log('calling fetch ocr unicode map')
			this.fetchOcrUnicodeMap({
				payload: {
					doc_key: this.document.key,
					map_key: map_key,
				}
			})

		},
		previousPage() {
			this.loadUnicodeMaps('reverse')
		},

		nextPage() {
			this.loadUnicodeMaps('forward')
			if (this.hasPrevious == false) {
				this.hasPrevious = true
			}
		},

	},
	mounted() {
		this.loadUnicodeMaps('forward')
	}

}
</script>

<style lang="scss" scoped>
.glyph-width {
	object-fit: none;
	width: 35px;
	height: 35px;
}

.glyph-table {
	font-size: 12px !important;
}
</style>