import axios from 'axios';
import router from "../router"

const HTTP = axios.create({
  baseURL: '/'
})

HTTP.interceptors.response.use((response) => { // intercept the global error

    return response
  }, function (error) {
    console.log('Http error', error)
    let originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) { 
      // if the error is 401 and hasn't already been retried
      console.log('401 Error: ', error)
			
			if(router.currentRoute.path !== "/login"){
				console.log('pushing path /logout on 401 error')
				router.push({path: '/logout'}).catch(() => {})
			}
    }
    if (error.response.status === 403 && !originalRequest._retry) {
      window.location.href = '/logout'
    }
    if (error.response.status === 404 && !originalRequest._retry) {
      console.log('404 Error')
      if (!originalRequest.url.includes('/api/')) {
        window.location.href = '/notFound'
      }
      
      return Promise.reject(error)
    }
    // Do something with response error
    return Promise.reject(error)
  })

export default HTTP