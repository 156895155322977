<template>
	<v-tab-item key="tabCss" class="pa-10">
		<v-container class="tab-settings">
			<template>
					<v-toolbar flat>
						<v-dialog v-model="dialog" max-width="850px">
							<template v-slot:activator="{ on, attr }">
									<v-card
										class="d-flex mb-10"
										title
										flat
									>
									<h2 class="h2-title">{{ $t("settings.tabCss.headerCreateCss") }}</h2>
									<v-btn color="primary" class="mb-2 ml-10" v-bind="[attr]" v-on="on">
										<!--<v-icon v-if="buttonStyle.icons.display" class="mr-1">mdi-plus</v-icon>-->
										<span>{{ $t("settings.tabCss.buttonNewCss") }}</span>
									</v-btn>	
									</v-card>
							</template>

							<v-card>
								<v-card-title>
									<span class="text-h5">{{ formTitle }}</span>
								</v-card-title>
								<v-card-text>
									<v-container>
										<v-row>
											<v-col cols="12" sm="6" md="4">
												<v-text-field v-model="editedItem.name" :label="$t('settings.tabCss.labelName')"
												:rules="[rules.required, rules.max, rules.min]"
												maxlength="21"
												>
												</v-text-field>
											</v-col>

											<v-col cols="12" sm="6" md="4">
												<v-text-field v-model="editedItem.url" :label="$t('settings.tabCss.labelUrl')"
												:rules="[rules.required, rules.minUrl]">
												</v-text-field>
											</v-col>

											<v-col cols="12" sm="6" md="4">
												<v-label>{{ $t("settings.tabCss.headerDefault") }}</v-label>
												<v-checkbox v-model="editedItem.is_default">
												</v-checkbox>
											</v-col>

										</v-row>
									</v-container>
								</v-card-text>

								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn color="blue darken-1" text @click="close">
										{{ $t("settings.tabCss.buttonCancel") }}
									</v-btn>
									<v-btn color="blue darken-1" text @click="save"
										:disabled="validate == false">
										{{ $t("settings.tabCss.buttonSave") }}
									</v-btn>
								</v-card-actions>
							</v-card>

						</v-dialog>

						<v-dialog v-model="dialogDelete" max-width="500px">
							<v-card>
								<v-card-title class="text-h5">
									{{ $t("settings.tabCss.headerConfirmDelete") }}
								</v-card-title>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn color="blue darken-1" text @click="closeDelete">
										{{ $t("settings.tabCss.buttonCancel") }}
									</v-btn>
									<v-btn color="blue darken-1" text @click="deleteItemConfirm">
										{{ $t("settings.tabCss.buttonOk") }}
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>

					</v-toolbar>
				</template>

			<v-data-table :headers="headers" :items="cssList" :items-per-page="10" class="">
				<template v-slot:item.is_default="{ item }">
					<v-simple-checkbox disabled v-model="item.is_default" key="item.key">
					</v-simple-checkbox>
				</template>
				<template v-slot:item.actions="{ item }">
					<v-icon small class="mr-2" @click="editItem(item)">
						mdi-pencil
					</v-icon>
					<v-icon small class="mr-2" @click="deleteItem(item)">
						mdi-delete
					</v-icon>

				</template>

				<template  v-slot:top>
					<v-toolbar flat>
							<v-toolbar-title>
								<h2 class="h2-title">{{ $t("settings.tabCss.headerExistingCss") }}</h2>
							</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</template>

				<!--
				<template v-slot:no-data>
					<v-btn color="primary" @click="fetchCssList">
						{{ $t("settings.tabCss.buttonReset") }}
					</v-btn>
				</template>
				-->
			</v-data-table>
		</v-container>


	</v-tab-item>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import i18n from "@/plugins/i18n"

export default {
	data() {
		return {
			dialog: false,
			dialogDelete: false,
			editedIndex: -1,
			editedItem: {
				name: '',
				url: '',
				is_default: false
			},
			defaultItem: {
				name: '',
				url: '',
				is_default: false
			},
			headers: [
				{
					text: this.$t("settings.tabCss.headerName"),
					value: "name",

				},
				{
					text: this.$t("settings.tabCss.headerUrl"),
					value: "url"
				},
				{
					text: this.$t("settings.tabCss.headerDefault"),
					value: "is_default",
					align: "end"

				},
				{
					text: this.$t("settings.tabCss.headerEditDelete"),
					value: "actions",
					align: "end"

				}
			],
			rules: {
        required: (value) => !!value || "Required.",
        max: (v) => v.length <= 20 || "Max 20 characters",
				min: (v) => v.length >= 1 || "Min 1 character",
				minUrl: (v) => v.length >= 8 || "Min 8 characters",
      },
		}
	},
	computed: {
		...mapGetters({
			language: "language/getLanguage",
			cssList: "settings/getCssList",
			options: "application/getOptions",
			buttonStyle: "application/getButtonStyle",
		}),
		formTitle() {
			return this.editedIndex === -1 ? 'New CSS' : 'Edit CSS'
		},
		validate(){
			if(this.editedItem.name.length < 1 || this.editedItem.name.length > 20){
				return false
			}
			if(this.editedItem.url.length < 8 ){
				return false
			}
			return true
		}
	},
	watch: {
		dialog(val) {
			val || this.close()
		},
		dialogDelete(val) {
			val || this.closeDelete()
		},
	},
	methods: {
		...mapActions({
			fetchCssList: "settings/fetchCssList",
			addCss: "settings/addCss",
			deleteCss: "settings/deleteCss",
			updateCss: "settings/updateCss"
		}),
		editItem(item) {
			this.editedIndex = this.cssList.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialog = true
		},
		deleteItem(item) {
			this.editedIndex = this.cssList.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialogDelete = true
		},
		deleteItemConfirm() {
			this.cssList.splice(this.editedIndex, 1)

			this.deleteCss({
				payload: { key: this.editedItem.key }
			}).then((response) => {
				this.$snackbar.showMessage({ content: this.$t("settings.tabCss.msg.deleted"), color: 'success' })
			})

			this.closeDelete()
		},
		close() {
			this.dialog = false

			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		closeDelete() {
			this.dialogDelete = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		save() {
			//edit
			if (this.editedIndex > -1) {
				Object.assign(this.cssList[this.editedIndex], this.editedItem)
				console.log('updating existing css')
				this.updateCss({
					payload: {
						key: this.editedItem.key,
						name: this.editedItem.name,
						url: this.editedItem.url,
						is_default: this.editedItem.is_default
					}
				}).then( (response) => {
					this.$snackbar.showMessage({ content: this.$t("settings.tabCss.msg.updated"), color: 'success' })
					// reloading here since global can only apply to one.  backend sets false on all others, so pick up new list
					this.fetchCssList()
				}).catch( (error) => {
					console.log('update css error', error)
					this.$snackbar.showMessage({ content: this.$t("settings.tabCss.msg.errorSaving", [error]), color: 'error'})
					//reload
					this.fetchCssList()	
				})
			} else { //add
				console.log('saving new css')
				this.addCss({
					payload: {
						name: this.editedItem.name,
						url: this.editedItem.url,
						is_default: this.editedItem.is_default
					}
				}).then( (response) => {
					//only add if successful 
					this.cssList.push(this.editedItem)
					this.$snackbar.showMessage({ content: this.$t("settings.tabCss.msg.created"), color: 'success' })
					// reloading here since global can only apply to one.  backend sets false on all others, so pick up new list
					this.fetchCssList()
				}).catch( (error) => {
					console.log('add css error', error)
					this.$snackbar.showMessage({ content: this.$t("settings.tabCss.msg.errorCreating", [error]), color: 'error'})
					this.fetchCssList()
				})
			}
			this.close()
		},

	},
	mounted() {
		if(this.options.is_admin){
				this.fetchCssList()
			.then((response) => {
				this.isLoading = false
			})
			.catch((error) => {
				this.isLoading = false
				this.$snackbar.showMessage({ content: this.$t("settings.tabCss.msg.unableToGetCSS") , color: 'error' })
			})
		}
	
	},

}

</script>

<style lang="scss" scoped>

</style>