<template>
	<v-tab-item key="Zones" class="scroll-margin-bottom">

		<v-select v-if="zones && Array.isArray(zones)" class="base pl-1 pr-1" :items="zones" item-text="name" item-value="id"
			v-model="dropDownSelection" return-object @change="onZoneSelected" style=""></v-select>


		<v-card ref="zoneProperties" fluid fill-height class="side-bar-tab side-bar-bg scrolling-tab">
			<div v-if="selectedZone && !isContrastZone(selectedZone)" class="room-for-scrollbar pl-2" style="">

				<SectionTitle :title="$t('page.sidebarZone.properties')" />

				<v-row class="mt-0">
					<v-col cols="3" class="section-label"> {{ $t('page.sidebarZone.name') }} </v-col>
					<v-col>
						<v-text-field dense solo v-model="selectedZone.name" @input="updateLayout"></v-text-field>
					</v-col>
				</v-row>

				<v-row class="mt-0" v-if="selectedZone.type !== 'image'">
					<v-col cols="3" class="section-label"> {{ $t('page.sidebarZone.type') }} </v-col>
					<v-col>
						<v-select :items=getZoneTypes() item-text="name" item-value="value" v-model="selectedZone.type"
							@change="zoneTypeChanged" dense solo :disabled="selectedZone.type == 'field'"></v-select>
					</v-col>
				</v-row>

				<v-row class="mt-0">
					<v-col cols="3" class="section-label"> {{ $t('page.sidebarZone.language') }} </v-col>
					<v-col>
						<v-select :items=getLanguages() item-text="name" item-value="value" v-model="selectedZone.language" dense solo
							@change="updateSelectedZone"></v-select>
					</v-col>
				</v-row>

				<v-row class="mt-0">
					<v-col cols="3" class="section-label"> {{ $t('page.sidebarZone.order') }} </v-col>
					<v-col>
						<v-text-field class="short-text" v-model.number="selectedZone.order" type="number" min="1" max="999" step="1"
							dense solo @input="updateSelectedZone"></v-text-field>
					</v-col>
					<v-col v-if="selectedZone.type !== 'image' && selectedZone.type !== 'field'">
						<v-btn @click="removeSelectedZone" color="primary" small elevation="2">
							{{ $t('page.sidebarZone.removeZone') }}
						</v-btn>
					</v-col>
				</v-row>

				<template v-if="selectedZone.type === 'anchor'">
					<SectionTitle :title="$t('page.sidebarZone.anchorProperties')" />
					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.anchorID') }} </v-col>
						<v-col>
							<v-text-field dense solo v-model="selectedZone.anchorID" @input="updateSelectedZone"></v-text-field>
						</v-col>
					</v-row>

				</template>

				<template v-else-if="selectedZone.type === 'blockquote'">
					<SectionTitle :title="$t('page.sidebarZone.blockquoteProperties')" />

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.merge') }} </v-col>
						<v-col>
							<v-checkbox class="mt-1" v-model="selectedZone.merge"></v-checkbox>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.breakLines') }} </v-col>
						<v-col>
							<v-checkbox class="mt-1" v-model="selectedZone.breakLine"></v-checkbox>
						</v-col>
					</v-row>

				</template>

				<template v-else-if="selectedZone.type === 'cite'">
					<SectionTitle :title="$t('page.sidebarZone.citeProperties')" />
					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.merge') }} </v-col>
						<v-col>
							<v-checkbox class="mt-1" v-model="selectedZone.merge"></v-checkbox>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.breakLines') }} </v-col>
						<v-col>
							<v-checkbox class="mt-1" v-model="selectedZone.breakLine"></v-checkbox>
						</v-col>
					</v-row>

				</template>

				<template v-else-if="selectedZone.type === 'div'">
					<SectionTitle :title="$t('page.sidebarZone.divProperties')" />

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.divReorderAuto') }} </v-col>
						<v-col>
							<v-select v-model="selectedZone.subAutoOrder" :items="$t('page.toolbar.readingOrders')" solo dense />
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col class="text-center">
							<v-btn color="primary" small elevation="2" @click="clickDivReorder">
								{{ $t('page.sidebarZone.divReOrderButton') }}
							</v-btn>
						</v-col>
					</v-row>

				</template>

				<template v-else-if="selectedZone.type === 'field'">

					<template v-if="selectedZone.v === 2">

						<SectionTitle :title="$t('page.sidebarZone.fieldProperties')" />

						<v-row class="mt-0" v-if="options.pro_cust">
							<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.fieldType') }} </v-col>
							<v-col cols="8">
								<v-select :items=getFieldTypes() item-text="name" item-value="value" dense solo
									v-model="selectedZone.field_type" @change=fieldTypeChanged></v-select>
							</v-col>
						</v-row>

						<v-row class="mt-0">
							<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.fieldTooltip') }} </v-col>
							<v-col>
								<v-text-field dense solo v-model="selectedZone.tooltip" @input="updateSelectedZone"></v-text-field>
							</v-col>
						</v-row>

						<div v-if="options.pro_cust">
							<v-row class="mt-0">
								<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.fieldLabelZone') }} </v-col>
								<v-col cols="8">
									<v-select :items="zones" item-text="name" item-value="id" dense solo
										v-model="selectedZone.toFootnoteID" @change="updateSelectedZone"></v-select>
								</v-col>
							</v-row>

							<v-row class="mt-0">
								<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.fieldGroupName') }} </v-col>
								<v-col cols="8">
									<v-text-field dense solo v-model="selectedZone.group_name" @input="updateSelectedZone"></v-text-field>
								</v-col>
							</v-row>

							<v-row class="mt-0">
								<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.fieldVisibility') }} </v-col>
								<v-col cols="8">
									<v-select :items=getFieldVisibility() item-text="name" item-value="value"
										v-model="selectedZone.fieldVisibility" dense solo></v-select>

								</v-col>
							</v-row>

							<v-row class="mt-0">
								<v-col cols="5" class="section-label"> {{ $t('page.sidebarZone.fieldReadOnly') }} </v-col>
								<v-col cols="7">
									<v-checkbox class="mt-1" v-model="selectedZone.f_f.read_only"></v-checkbox>
								</v-col>
							</v-row>

							<v-row class="mt-0" v-if="selectedZone.field_type !== 'button'">
								<v-col cols="5" class="section-label"> {{ $t('page.sidebarZone.fieldRequired') }} </v-col>
								<v-col cols="7">
									<v-checkbox class="mt-1" v-model="selectedZone.f_f.required"></v-checkbox>
								</v-col>
							</v-row>

							<v-row class="mt-0" v-if="selectedZone.field_type === 'button'">
								<v-col cols="5" class="section-label"> {{ $t('page.sidebarZone.buttonLabel') }} </v-col>
								<v-col cols="7">
									<v-text-field dense solo v-model="selectedZone.button_label" @input="updateSelectedZone"></v-text-field>
								</v-col>
							</v-row>

							<template v-if="selectedZone.field_type === 'text'">
								<v-row class="mt-0">
									<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.fieldAlignment') }} </v-col>
									<v-col cols="8">
										<v-select :items=getFieldAlignments() item-text="name" item-value="value"
											v-model="selectedZone.text_align" dense solo></v-select>
									</v-col>
								</v-row>

								<v-row class="mt-0">
									<v-col cols="7" class="section-label"> {{ $t('page.sidebarZone.checkSpelling') }} </v-col>
									<v-col cols="5">
										<v-checkbox class="mt-1" v-model="selectedZone.f_f.check_spelling"></v-checkbox>
									</v-col>
								</v-row>

								<v-row class="mt-0">
									<v-col cols="7" class="section-label"> {{ $t('page.sidebarZone.multiline') }} </v-col>
									<v-col cols="5">
										<v-checkbox class="mt-1" v-model="selectedZone.f_f.multiline"></v-checkbox>
									</v-col>
								</v-row>

								<v-row class="mt-0">
									<v-col cols="7" class="section-label"> {{ $t('page.sidebarZone.scrollLongText') }} </v-col>
									<v-col cols="5">
										<v-checkbox class="mt-1" v-model="selectedZone.f_f.scroll_long_text"></v-checkbox>
									</v-col>
								</v-row>

								<v-row class="mt-0">
									<v-col cols="7" class="section-label"> {{ $t('page.sidebarZone.richText') }} </v-col>
									<v-col cols="5">
										<v-checkbox class="mt-1" v-model="selectedZone.f_f.rich_text"></v-checkbox>
									</v-col>
								</v-row>

								<v-row class="mt-0">
									<v-col cols="7" class="section-label"> {{ $t('page.sidebarZone.maxCharacters') }} </v-col>
									<v-col cols="5">
										<v-text-field dense solo type="number" step="1" min="1" max="99999" v-model="selectedZone.max_len"
											@input="updateSelectedZone"></v-text-field>
									</v-col>
								</v-row>

							</template>

							<v-row class="mt-0" v-if="selectedZone.field_type === 'check'">
								<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.checkboxStyle') }} </v-col>
								<v-col cols="8">
									<v-select :items=getCheckboxStyles() item-text="name" item-value="value"
										v-model="selectedZone.checkbox_style" dense solo></v-select>
								</v-col>
							</v-row>

							<v-row class="mt-0" v-if="selectedZone.field_type === 'check' || selectedZone.field_type === 'radio'">
								<v-col cols="6" class="section-label"> {{ $t('page.sidebarZone.exportValue') }} </v-col>
								<v-col cols="6">
									<v-text-field dense solo v-model="selectedZone.export_value" @input="updateSelectedZone"></v-text-field>
								</v-col>
							</v-row>

							<v-row class="mt-0" v-if="selectedZone.field_type === 'check' || selectedZone.field_type === 'radio'">
								<v-col cols="6" class="section-label"> {{ $t('page.sidebarZone.checkedByDefault') }} </v-col>
								<v-col cols="6">
									<v-checkbox class="mt-1" v-model="selectedZone.value"></v-checkbox>
								</v-col>
							</v-row>

							<template v-if="selectedZone.field_type === 'choice'">
								<v-row class="mt-0">
									<v-col cols="3" class="section-label"> {{ $t('page.sidebarZone.options') }} </v-col>
									<v-col cols="9" class="pa-1">
										<select multiple style="width: 100%; min-height:100px;border: 1px solid;" class="pa-2">
											<option v-for="opt in selectedZone.options" :key="opt"> {{ opt }}</option>
										</select>
									</v-col>
								</v-row>

								<v-row class="mt-0">
									<v-col class="text-center pt-4" offset="3" cols="9">

										<v-dialog v-model="editOptionValuesDialog" persistent max-width="600px">
											<template v-slot:activator="{ on, attrs }">
												<v-btn color="primary" small elevation="2" @click="clickEditOptionValues" v-bind="attrs"
													v-on="on">
													{{ $t('page.sidebarZone.editValues') }}
												</v-btn>
											</template>

											<v-card>
												<v-card-title>
													<span class="text-h5">Edit drop down value of {{ selectedZone.name }}</span>
												</v-card-title>

												<v-card-text>
													<v-container>

														<v-row class="mt-0">
															<v-col>
																<v-textarea solo outlined label="" v-model="optionValues"></v-textarea>
															</v-col>

														</v-row>
													</v-container>

												</v-card-text>

												<v-card-actions>
													<v-spacer></v-spacer>
													<v-btn text @click="editOptionValuesDialog = false">
														Cancel
													</v-btn>
													<v-btn color="primary" text @click="clickSaveOptionValues">
														Done
													</v-btn>
												</v-card-actions>
											</v-card>
										</v-dialog>
									</v-col>
								</v-row>

								<v-row class="mt-0">
									<v-col cols="5" class="section-label"> {{ $t('page.sidebarZone.optionSort') }} </v-col>
									<v-col cols="7">
										<v-checkbox class="mt-1" v-model="selectedZone.f_f.sort"></v-checkbox>
									</v-col>
								</v-row>

								<v-row class="mt-0">
									<v-col cols="5" class="section-label"> {{ $t('page.sidebarZone.multiSelect') }} </v-col>
									<v-col cols="7">
										<v-checkbox class="mt-1" v-model="selectedZone.f_f.multiselect"></v-checkbox>
									</v-col>
								</v-row>
							</template>
						</div>
					</template>

				</template>

				<template v-else-if="selectedZone.type === 'footnote'">
					<SectionTitle :title="$t('page.sidebarZone.footnoteProperties')" />

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.merge') }} </v-col>
						<v-col>
							<v-checkbox class="mt-1" v-model="selectedZone.merge"></v-checkbox>
						</v-col>
					</v-row>

				</template>

				<template v-else-if="selectedZone.type === 'footnotelink'">
					<SectionTitle :title="$t('page.sidebarZone.footnoteLinkProperties')" />

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.toFootnote') }} </v-col>
						<v-col cols="8">
							<v-select :items="getFootnoteZones()" item-text="name" item-value="id" dense solo
								v-model="selectedZone.toFootnoteID" @change="updateSelectedZone"></v-select>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.description') }} </v-col>
						<v-col cols="8">
							<v-text-field dense solo v-model="selectedZone.description" @input="updateSelectedZone"></v-text-field>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.merge') }} </v-col>
						<v-col>
							<v-checkbox class="mt-1" v-model="selectedZone.merge"></v-checkbox>
						</v-col>
					</v-row>

				</template>

				<template v-else-if="selectedZone.type === 'form'">
					<SectionTitle :title="$t('page.sidebarZone.formProperties')" />

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.method') }} </v-col>
						<v-col cols="8">
							<v-text-field dense solo v-model="selectedZone.method" @input="updateSelectedZone"></v-text-field>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.action') }} </v-col>
						<v-col cols="8">
							<v-text-field dense solo v-model="selectedZone.action" @input="updateSelectedZone"></v-text-field>
						</v-col>
					</v-row>

				</template>

				<template v-else-if="selectedZone.type === 'graphic'">
					<SectionTitle :title="$t('page.sidebarZone.graphicProperties')" />
					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.descriptionAlt') }} </v-col>
					</v-row>
					<v-row class="mt-0">
						<v-col>
							<v-textarea solo label="" v-model="selectedZone.description" @input="updateSelectedZone"></v-textarea>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.longDescription') }} </v-col>
					</v-row>
					<v-row class="mt-0">
						<v-col>
							<v-textarea solo label="" v-model="selectedZone.longDescription" @input="updateSelectedZone"></v-textarea>
						</v-col>
					</v-row>

				</template>

				<template v-else-if="selectedZone.type === 'heading'">
					<SectionTitle :title="$t('page.sidebarZone.headingProperties')" />

					<v-row class="mt-0">
						<v-col cols="5" class="section-label"> {{ $t('page.sidebarZone.level') }} </v-col>
						<v-col cols="7">
							<v-text-field class="short-text" dense solo type="number" min="1" max="10" step="1"
								v-model="selectedZone.headerlevel" @input="updateHeaderlevel"></v-text-field>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="5" class="section-label"> {{ $t('page.sidebarZone.merge') }} </v-col>
						<v-col cols="7">
							<v-checkbox class="mt-1" v-model="selectedZone.merge" @change="updateSelectedZone"></v-checkbox>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="5" class="section-label"> {{ $t('page.sidebarZone.breakLines') }} </v-col>
						<v-col cols="7">
							<v-checkbox class="mt-1" v-model="selectedZone.breakLine" @change="updateSelectedZone"></v-checkbox>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="5" class="section-label"> {{ $t('page.sidebarZone.useAsTemplate') }} </v-col>
						<v-col cols="7">
							<v-checkbox class="mt-1" v-model="selectedZone.useAsTemplate" @change="updateSelectedZone"></v-checkbox>
						</v-col>
					</v-row>

				</template>

				<template v-else-if="selectedZone.type === 'ignore'">
					<SectionTitle :title="$t('page.sidebarZone.ignoreProperties')" />

					<v-row class="mt-0">
						<v-col cols="5" class="section-label"> {{ $t('page.sidebarZone.enabled') }} </v-col>
						<v-col cols="7">
							<v-checkbox class="mt-1" v-model="selectedZone.visible" @change="updateSelectedZone"></v-checkbox>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col class="text-center">
							<v-btn color="primary" small elevation="2" @click="clickRemoveUnderlyingZones">
								{{ $t('page.sidebarZone.removeUnderlyingZones') }}
							</v-btn>
						</v-col>
					</v-row>

				</template>

				<template v-else-if="selectedZone.type === 'image'">
					<SectionTitle :title="$t('page.sidebarZone.imageProperties')" />

					<v-row class="mt-0">
						<v-col cols="5" class="section-label"> {{ $t('page.sidebarZone.hideZone') }} </v-col>
						<v-col cols="7">
							<v-checkbox class="mt-1" v-model="selectedZone.hideZone" @change="updateSelectedZone"></v-checkbox>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="5" class="section-label"> {{ $t('page.sidebarZone.visible') }} </v-col>
						<v-col cols="7">
							<v-checkbox class="mt-1" v-model="selectedZone.visible" @change="updateSelectedZone"></v-checkbox>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col>
							{{ $t('page.sidebarZone.description') }}
							<v-textarea ref="imageDescription" solo label="" v-model="selectedZone.description"
								@input="updateSelectedZone"></v-textarea>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col>
							{{ $t('page.sidebarZone.longDescription') }}
							<v-textarea solo label="" v-model="selectedZone.longDescription" @input="updateSelectedZone"></v-textarea>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col>
							{{ $t('page.sidebarZone.title') }}
							<v-textarea solo label="" v-model="selectedZone.title" @input="updateSelectedZone"></v-textarea>
						</v-col>
					</v-row>

				</template>

				<template v-else-if="selectedZone.type === 'link'">
					<SectionTitle :title="$t('page.sidebarZone.linkProperties')" />

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.toAnchor') }} </v-col>
						<v-col cols="8">
							<v-text-field dense solo v-model="selectedZone.toAnchor" @input="updateSelectedZone"></v-text-field>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.description') }} </v-col>
						<v-col cols="8">
							<v-text-field dense solo v-model="selectedZone.description" @input="updateSelectedZone"></v-text-field>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="5" class="section-label"> {{ $t('page.sidebarZone.merge') }} </v-col>
						<v-col cols="7">
							<v-checkbox class="mt-1" v-model="selectedZone.merge" @change="updateSelectedZone"></v-checkbox>
						</v-col>
					</v-row>

				</template>

				<!-- LIST SECTION HERE.  turn off if selected list item -->
				<template v-else-if="selectedZone.type === 'list'">
					<SectionTitle :title="$t('page.sidebarZone.listProperties')" />

					<!-- need a hide when selectedListItem-->
					<div v-if="selectedListItem == null">

						<v-row class="mt-0">
							<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.caption') }} </v-col>
							<v-col cols="8">
								<v-text-field dense solo v-model="selectedZone.caption" @input="changeSelectedZoneCaption"></v-text-field>
							</v-col>
						</v-row>

						<v-row class="mt-0">
							<v-col>
								{{ $t('page.sidebarZone.summary') }}
								<v-textarea solo label="" v-model="selectedZone.summary" @input="changeSelectedZoneSummary"></v-textarea>
							</v-col>
						</v-row>

						<!-- LIST MERGE IS HERE! -->

						<v-row class="mt-0">
							<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.merge') }} </v-col>
							<v-col cols="8">
								<v-checkbox id="lstMerge" class="mt-1" v-model="selectedZone.merge"
									@change="changeSelectedZoneMerge"></v-checkbox>
							</v-col>
						</v-row>

						<!--merge options here-->
						<div v-if="selectedZone.merge">

							<v-row class="mt-0" v-if="selectedZone.merge">
								<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.with') }} </v-col>
								<v-col>
									<v-select :items="getZoneMergeOptions" item-text="name" item-value="value"
										v-model="selectedZone.mergeOptions" dense solo class="small-text"
										@change="mergeWithPageChanged(selectedZone)"></v-select>
								</v-col>
							</v-row>

							<!-- MERGE WITH STUFF GOES HERE -->

							<v-row class="mt-0" v-if="selectedZone.mergeOptions == 'current'">
								<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.zone') }} </v-col>
								<v-col>
									<v-select v-model="selectedZone.mergeWithZone" dense solo :items="mergeWithZones" item-text="name"
										item-value="id" @change="updateSelectedZone"></v-select>
								</v-col>
							</v-row>


							<v-row class="mt-0" v-if="selectedZone.mergeOptions == 'page'">
								<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.page') }} </v-col>
								<v-col>
									<v-select v-model="selectedZone.mergeWithPageNo" dense solo :items="getMergingPages()"
										@change="mergeWithPageChanged(selectedZone)"></v-select>
								</v-col>
							</v-row>


							<v-row class="mt-0" v-if="selectedZone.mergeOptions == 'page' && selectedZone.mergeWithPageNo > 0">
								<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.zone') }} </v-col>
								<v-col>
									<v-select v-model="selectedZone.mergeWithZone" dense solo :items="mergeWithZones" item-text="name"
										item-value="id" @change="updateSelectedZone"></v-select>
								</v-col>
							</v-row>
						</div>

						<!-- END MERGE WITH STUFF-->

						<!-- List type is here -->
						<v-row class="mt-0">
							<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.listType') }} </v-col>
							<v-col cols="8">
								<v-radio-group v-model="selectedZone.listType" row @change="updateSelectedZone" class="mt-1">
									<v-radio class="pb-3" :label="$t('page.sidebarZone.listUnordered')" value="u"></v-radio>
									<v-radio :label="$t('page.sidebarZone.listOrdered')" value="o" class=""></v-radio>
								</v-radio-group>
							</v-col>
						</v-row>

						<template v-if="selectedZone.listType == 'o'">

							<v-row class="mt-0">
								<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.styleType') }} </v-col>
								<v-col cols="8">
									<v-select :items=getListStyles() item-text="name" item-value="value"
										v-model="selectedZone.listStyleType" dense solo @change="updateSelectedZone"></v-select>
								</v-col>
							</v-row>

							<v-row class="mt-0">
								<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.startAt') }} </v-col>
								<v-col cols="8">
									<v-text-field dense solo type="number" v-model="selectedZone.listStart"
										@input="updateSelectedZone"></v-text-field>
								</v-col>
							</v-row>

						</template>
						<!-- End list type-->

						<template v-if="!options.ml_list">
							<v-row class="mt-0">
								<v-col class="text-center">
									<v-btn color="primary" small elevation="2" @click="clickDetectList">
										{{ $t('page.sidebarZone.listDetector') }}
									</v-btn>
								</v-col>
							</v-row>
						</template>

						<!-- *** Change ml list detect first *** -->
						<template v-if="options.ml_list">
							<SectionTitle id="listDetector" ref="listDetector" :title="$t('page.sidebarZone.listDetector')" />

							<v-row class="mt-0">
								<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.listSensitivity') }} {{
									selectedZone.listSensitivity ? selectedZone.listSensitivity : 0
								}}</v-col>
								<v-col>
									<v-slider min="0" max="5" step="1" v-model="selectedZone.listSensitivity" @change="onRadiusListChange">
										<template v-slot:append>
											<v-progress-circular indeterminate color="primary" v-show="listLoading"
												:size="20"></v-progress-circular>
										</template>
									</v-slider>
								</v-col>
							</v-row>
						</template>

						<v-row class="mt-0">
							<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.listItems') }} </v-col>
							<v-col cols="3">
								<v-text-field dense solo type="number" step="1" min="1" max="100" v-model="selectedZone.listItemsCount"
									@input="changedListItemCount" placeholder="#" @keydown="$event.preventDefault()">
								</v-text-field>
							</v-col>
						</v-row>

					</div>

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.showListLabel') }} </v-col>
						<v-col cols="8">
							<v-checkbox class="mt-1" v-model="selectedZone.showListLabel" @change="updateSelectedZone"></v-checkbox>
						</v-col>
					</v-row>

					<!-- LIST SOURCE SECTION-->
					<SectionTitle id="zoneSourceList" :title="$t('page.sidebarZone.zoneSource')" class="mt-0" />

					<v-row class="ml-16 pb-2 pl-2 mt-0 mb-n6">
						<v-btn-toggle v-model="selectedZone.itemsSource" @change="itemsSourceChanged">
							<v-btn class="btn-group-item" value="PDF">
								<v-icon x-large>
									$vuetify.icons.SourcePDF
								</v-icon>
							</v-btn>
							<v-btn class="btn-group-item" value="OCR">
								<v-icon x-large>
									$vuetify.icons.SourceOCR
								</v-icon>
							</v-btn>
							<v-btn class="btn-group-item" value="Custom">
								<v-icon x-large>
									$vuetify.icons.SourceCustom
								</v-icon>
							</v-btn>
						</v-btn-toggle>
					</v-row>

					<v-row class="ma-3 pa-3 ml-0 mt-0">
						<v-row class="mt-0" v-if="selectedZone.itemsSource == 'PDF'">
							<v-col class="text-center">
								<v-btn color="primary" small elevation="2" @click="pdfAllListItems">
									{{ $t('page.sidebarZone.pdfAllItems') }}
								</v-btn>
							</v-col>
						</v-row>

						<v-row class="mt-0" v-if="selectedZone.itemsSource == 'OCR'">
							<v-col class="text-center">
								<v-btn color="primary" small elevation="2" @click="ocrAllListItems">
									{{ $t('page.sidebarZone.ocrAllItems') }}
								</v-btn>
							</v-col>
						</v-row>

						<v-row class="mt-0" v-if="selectedZone.itemsSource == 'Custom'">
							<v-col class="text-center">
								<v-btn color="primary" small elevation="2" @click="customAllListItems">
									{{ $t('page.sidebarZone.customAllItems') }}
								</v-btn>
							</v-col>
						</v-row>
					</v-row>
					<!-- END LIST SOURCE SECTION-->
					<!-- END LIST SECTION -->
				</template>

				<template v-else-if="selectedZone.type === 'quote'">
					<SectionTitle :title="$t('page.sidebarZone.quoteProperties')" />

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.merge') }} </v-col>
						<v-col>
							<v-checkbox class="mt-1" v-model="selectedZone.merge"></v-checkbox>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.breakLines') }} </v-col>
						<v-col>
							<v-checkbox class="mt-1" v-model="selectedZone.breakLine"></v-checkbox>
						</v-col>
					</v-row>

				</template>

				<template v-else-if="selectedZone.type === 'sensitivity'">
					<SectionTitle :title="$t('page.sidebarZone.sensitivityProperties')" />

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.listSensitivity') }} {{ zoneSensitivity
						}}</v-col>
						<v-col>
							<v-slider min="0" max="20" step="1" v-model="zoneSensitivity" @change="changedSensitivityZone"></v-slider>
						</v-col>
					</v-row>

				</template>

				<template v-else-if="selectedZone.type === 'table'">
					<SectionTitle :title="$t('page.sidebarZone.tableProperties')" />
					<TableZone :zone=selectedZone />


				</template>

				<template v-else-if="selectedZone.type === 'text'">
					<SectionTitle :title="$t('page.sidebarZone.textProperties')" />

					<v-row class="mt-0">
						<v-col cols="3" class="section-label"> {{ $t('page.sidebarZone.tag') }} </v-col>
						<v-col>
							<v-select :items=getZoneTags() item-text="name" item-value="value" v-model="selectedZone.tag" dense solo
								@change="updateSelectedZone"></v-select>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="3" class="section-label"> {{ $t('page.sidebarZone.indentation') }} </v-col>
						<v-col>
							<v-select :items=getZoneIndentations() item-text="name" item-value="value"
								v-model="selectedZone.indentation" dense solo @change="updateSelectedZone"></v-select>
						</v-col>
					</v-row>

					<!-- Text Merge -->
					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.merge') }} </v-col>
						<v-col>
							<v-checkbox class="mt-1" v-model="selectedZone.merge" @change="changeSelectedZoneMerge"></v-checkbox>
						</v-col>
					</v-row>

					<v-row class="mt-0" v-if="selectedZone.merge">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.with') }} </v-col>
						<v-col>
							<v-select :items="getZoneMergeOptions" item-text="name" item-value="value"
								v-model="selectedZone.mergeOptions" dense solo class="small-text"
								@change="mergeWithPageChanged(selectedZone)"></v-select>
						</v-col>
					</v-row>

					<!-- text merge options here -->

					<v-row class="mt-0" v-if="selectedZone.mergeOptions == 'current'">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.zone') }} </v-col>
						<v-col>
							<v-select v-model="selectedZone.mergeWithZone" dense solo :items="mergeWithZones" item-text="name"
								item-value="id" @change="updateSelectedZone"></v-select>
						</v-col>
					</v-row>


					<v-row class="mt-0" v-if="selectedZone.mergeOptions == 'page'">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.page') }} </v-col>
						<v-col>
							<v-select v-model="selectedZone.mergeWithPageNo" dense solo :items="getMergingPages()"
								@change="mergeWithPageChanged"></v-select>
						</v-col>
					</v-row>


					<v-row class="mt-0" v-if="selectedZone.mergeOptions == 'page' && selectedZone.mergeWithPageNo > 0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.zone') }} </v-col>
						<v-col>
							<v-select v-model="selectedZone.mergeWithZone" dense solo :items="mergeWithZones" item-text="name"
								item-value="id" @change="updateSelectedZone"></v-select>
						</v-col>
					</v-row>

					<!-- End text merge -->

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.breakLines') }} </v-col>
						<v-col>
							<v-checkbox class="mt-1" v-model="selectedZone.breakLine" @change="updateSelectedZone"></v-checkbox>
						</v-col>
					</v-row>
				</template>

				<!-- TOC SECTION HERE !-->
				<template v-else-if="selectedZone.type === 'toc'">
					<SectionTitle :title="$t('page.sidebarZone.tocProperties')" />
					<!-- This looks exactly the same as any other zonen type Caption & Summary.  Repeated in angular code as well -->
					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.caption') }} </v-col>
						<v-col cols="8">
							<v-text-field dense solo v-model="selectedZone.caption" @input="changeSelectedZoneCaption"></v-text-field>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col>
							{{ $t('page.sidebarZone.summary') }}
							<v-textarea solo label="" v-model="selectedZone.summary" @input="changeSelectedZoneSummary"></v-textarea>
						</v-col>
					</v-row>

					<!-- TOC merge toggle is here-->
					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.merge') }} </v-col>
						<v-col cols="8">
							<v-checkbox id="lstMerge" class="mt-1" v-model="selectedZone.merge"
								@change="changeSelectedZoneMerge"></v-checkbox>
						</v-col>
					</v-row>

					<!-- TOC Merge options start here-->
					<div v-if="selectedZone.merge">

						<v-row class="mt-0" v-if="selectedZone.merge">
							<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.with') }} </v-col>
							<v-col>
								<v-select :items="getZoneMergeOptions" item-text="name" item-value="value"
									v-model="selectedZone.mergeOptions" dense solo class="small-text"
									@change="mergeWithPageChanged(selectedZone)"></v-select>
							</v-col>
						</v-row>

						<v-row class="mt-0" v-if="selectedZone.mergeOptions == 'current'">
							<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.zone') }} </v-col>
							<v-col>
								<v-select v-model="selectedZone.mergeWithZone" dense solo :items="mergeWithZones" item-text="name"
									item-value="id" @change="updateSelectedZone"></v-select>
							</v-col>
						</v-row>


						<v-row class="mt-0" v-if="selectedZone.mergeOptions == 'page'">
							<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.page') }} </v-col>
							<v-col>
								<v-select v-model="selectedZone.mergeWithPageNo" dense solo :items="getMergingPages()"
									@change="mergeWithPageChanged"></v-select>
							</v-col>
						</v-row>


						<v-row class="mt-0" v-if="selectedZone.mergeOptions == 'page' && selectedZone.mergeWithPageNo > 0">
							<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.zone') }} </v-col>
							<v-col>
								<v-select v-model="selectedZone.mergeWithZone" dense solo :items="mergeWithZones" item-text="name"
									item-value="id" @change="updateSelectedZone"></v-select>
							</v-col>
						</v-row>
					</div>
					<!-- End TOC Merge options-->

					<template v-if="options.ml_list">
						<SectionTitle :title="$t('page.sidebarZone.tocDetector')" />

						<v-row class="mt-0">
							<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.listSensitivity') }} {{ listRadius ?
								listRadius : 0 }}</v-col>
							<v-col>
								<v-slider min="0" max="5" step="1" v-model="listRadius" @change="onRadiusTOCChange"
									:disabled="disableMlTocSlider"></v-slider>
							</v-col>
						</v-row>
					</template>

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.tocItems') }} </v-col>
						<v-col cols="3">
							<v-text-field dense solo type="number" step="1" min="1" max="100" v-model="selectedZone.listItemsCount"
								@input="changedListItemCount" placeholder="#" @keydown="allowOnlyTab">>
							</v-text-field>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.showArtifacts') }} </v-col>
						<v-col cols="8">
							<v-checkbox class="mt-1" v-model="selectedZone.showArtifact" @change="updateSelectedZone"></v-checkbox>
						</v-col>
					</v-row>
				</template>

				<!-- Selected TOC item goes here -->

				<template v-if="selectedZone.type == 'toc' && selectedListItem != null && selectedListItem != undefined">
					<SectionTitle :title="$t('page.sidebarZone.toc') + ' ' + parseInt(selectedListItem.listItemIndex + 1)">
					</SectionTitle>

					<v-row class="mt-0">
						<v-col cols="1" class="section-label"> {{ $t('page.sidebarZone.source') }} </v-col>
						<v-col>
							<v-row class="mt-0">
								<v-btn-toggle v-model="selectedListItem.source"
									@change="listItemSourceChanged(selectedZone, selectedListItem)">
									<v-btn class="btn-group-item" value="PDF">
										<v-icon x-large>
											$vuetify.icons.SourcePDF
										</v-icon>
									</v-btn>
									<v-btn class="btn-group-item" value="OCR">
										<v-icon x-large>
											$vuetify.icons.SourceOCR
										</v-icon>
									</v-btn>
									<v-btn class="btn-group-item" value="Custom">
										<v-icon x-large>
											$vuetify.icons.SourceCustom
										</v-icon>
									</v-btn>
									<v-btn class="btn-group-item" value="Actual">
										<v-icon x-large>
											$vuetify.icons.SourceActual
										</v-icon>
									</v-btn>
								</v-btn-toggle>
							</v-row>
						</v-col>
					</v-row>

					<!-- TOC LIST ITEM SOURCE GOES HERE-->

					<template>
						
						<v-row class="mt-0">
							<v-textarea v-if="selectedListItem.source == 'OCR' && selectedListItem.ocrText" color="success" solo
								label="" :loading="selectedListItem.ocrInProgress" :disabled="selectedListItem.ocrInProgress"
								v-model="selectedListItem.ocrText" @input="updateSelectedListItem"></v-textarea>

							<v-textarea v-if="selectedListItem.source == 'Custom'" color="success" solo label=""
								placeholder="Enter text here" :loading="selectedListItem.customInProgress"
								:disabled="selectedListItem.customInProgress" v-model="selectedListItem.customText"
								@input="updateSelectedListItem"></v-textarea>

							<v-textarea v-if="selectedListItem.source == 'Actual'" color="white" solo label=""
								placeholder="Enter actual text here" @input="updateSelectedListItem"
								v-model="selectedListItem.actual_text"></v-textarea>
						</v-row>
						<v-row class="mt-0" v-if="selectedListItem.source == 'OCR' && !selectedListItem.ocrText">
							<v-col class="text-center">
								<v-btn color="primary" :loading="selectedListItem.ocrInProgress"
									:disabled="selectedListItem.ocrInProgress" small elevation="2" @click="getListItemOcr">
									{{ $t('page.sidebarZone.ocrConvert') }}
								</v-btn>
							</v-col>
						</v-row>
					</template>

					<!--TOC selected item merge toggle -->
					<v-row class="mt-0" v-if="selectedListItem.listItemIndex == 0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.mergeItem') }} </v-col>
						<v-col cols="8">
							<v-checkbox class="mt-1" v-model="selectedListItem.merge"
								@change="mergeWithPageChanged(selectedListItem)"></v-checkbox>
						</v-col>
					</v-row>

					<!-- TOC selected item link to page goes here -->
					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.linkToPage') }} </v-col>
						<v-col cols="8">
							<v-checkbox class="mt-1" v-model="selectedListItem.linkto" @change="updateSelectedZone"></v-checkbox>
						</v-col>
					</v-row>

					<!--TOC 'link to page' page number  -->
					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.pageNumber') }} </v-col>
						<v-col cols="4">
							<v-text-field dense solo type="number" step="1" min="1" :max="document.total_pages"
								v-model="selectedListItem.linktopage" @input="updateTocLinkToPage" :disabled="!selectedListItem.linkto">
							</v-text-field>
						</v-col>
					</v-row>

					<!-- Description-->
					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.description') }} </v-col>
						<v-col cols="8">
							<v-text-field dense solo v-model="selectedListItem.description" @input="updateSelectedZone"></v-text-field>
						</v-col>
					</v-row>

					<!-- Artifact Position -->
					<SectionTitle :title="$t('page.sidebarZone.artifactPosition')" />

					<v-row class="mt-0">
						<v-col cols="2" class="section-label">
							{{ $t('page.sidebarZone.left') }}
						</v-col>
						<v-col cols="6">
							<v-text-field dense solo type="number" min="0" :max="selectedListItem.w - 2" step="1"
								v-model="selectedListItem.artifact.x" @input="zonePositionArtifactChange"></v-text-field>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="2" class="section-label">
							{{ $t('page.sidebarZone.top') }}
						</v-col>
						<v-col cols="6">
							<v-text-field dense solo type="number" min="0" :max="selectedListItem.h" step="1"
								v-model="selectedListItem.artifact.y" @input="zonePositionArtifactChange"></v-text-field>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="2" class="section-label">
							{{ $t('page.sidebarZone.width') }}
						</v-col>
						<v-col cols="6">
							<v-text-field dense solo type="number" min="0" :max="selectedListItem.w" step="1"
								v-model="selectedListItem.artifact.w" @input="zonePositionArtifactChange"></v-text-field>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="2" class="section-label">
							{{ $t('page.sidebarZone.height') }}
						</v-col>
						<v-col cols="6">
							<v-text-field dense solo type="number" min="0" :max="selectedListItem.h" step="1"
								v-model="selectedListItem.artifact.h" @input="zonePositionArtifactChange"></v-text-field>
						</v-col>
					</v-row>

				</template>
				<!-- END TOC selected items-->


				<!-- ZONE SOURCE HERE.  This is outside of the list section so it applies to a few types other than table/div/field/list&listitem -->
				<div v-if="selectedZone.type !== 'table' && selectedZone.type !== 'div' && selectedZone.type !== 'field' && selectedZone.type !== 'list' &&
					(selectedListItem === null || selectedListItem === undefined)">
					<SectionTitle id="zoneSourceGeneric" :title="$t('page.sidebarZone.zoneSource')" />

					<v-row class="ml-10 mb-2 pb-2 mt-0">
						<v-btn-toggle v-model="selectedZone.source" @change="zoneSourceChanged">
							<v-btn class="btn-group-item" value="PDF">
								<v-icon x-large>
									$vuetify.icons.SourcePDF
								</v-icon>
							</v-btn>

							<v-btn class="btn-group-item" value="OCR">
								<v-icon x-large>
									$vuetify.icons.SourceOCR
								</v-icon>
							</v-btn>

							<v-btn class="btn-group-item" value="Custom">
								<v-icon x-large>
									$vuetify.icons.SourceCustom
								</v-icon>
							</v-btn>

							<v-btn class="btn-group-item" value="Actual">
								<v-icon x-large>
									$vuetify.icons.SourceActual
								</v-icon>
							</v-btn>
						</v-btn-toggle>
					</v-row>

					<template v-if="selectedZone.type !== 'list'">
						<v-row class="ma-0">
							<v-textarea v-if="selectedZone.source == 'OCR'" color="success" solo label=""
								:loading="selectedZone.ocrInProgress" :disabled="selectedZone.ocrInProgress"
								v-model="selectedZone.ocrText" @input="updateSelectedZone"></v-textarea>

							<v-textarea v-if="selectedZone.source == 'Custom'" color="success" solo label=""
								:loading="selectedZone.customInProgress" :disabled="selectedZone.customInProgress"
								v-model="selectedZone.customText" @input="updateSelectedZone"></v-textarea>

							<v-textarea v-if="selectedZone.source == 'Actual'" color="success" solo label=""
								:loading="selectedZone.customInProgress" :disabled="selectedZone.customInProgress"
								v-model="selectedZone.actual_text" @input="updateSelectedZone"></v-textarea>

						</v-row>

						<v-row class="mt-0" v-if="selectedZone.source == 'OCR'">
							<v-col class="text-center">

								<v-btn color="primary" :loading="selectedZone.ocrInProgress" :disabled="selectedZone.ocrInProgress" small
									elevation="2" @click="clickSourceOcr">
									{{ $t('page.sidebarZone.ocrConvert') }}
								</v-btn>
							</v-col>
						</v-row>
					</template>

					<template v-if="selectedZone.type === 'list'">
						<v-row class="ma-3 pa-3">

							<v-row class="mt-0" v-if="selectedZone.source == 'PDF'">
								<v-col class="text-center">
									<v-btn color="primary" small elevation="2" @click="clickDetectList">
										{{ $t('page.sidebarZone.pdfAllItems') }}
									</v-btn>
								</v-col>
							</v-row>

							<v-row class="mt-0" v-if="selectedZone.source == 'OCR'">
								<v-col class="text-center">
									<v-btn color="primary" small elevation="2" @click="clickDetectList">
										{{ $t('page.sidebarZone.ocrAllItems') }}
									</v-btn>
								</v-col>
							</v-row>

							<v-row class="mt-0" v-if="selectedZone.source == 'Custom'">
								<v-col class="text-center">
									<v-btn color="primary" small elevation="2" @click="clickDetectList">
										{{ $t('page.sidebarZone.customAllItems') }}
									</v-btn>
								</v-col>
							</v-row>

						</v-row>
					</template>
				</div>
				<!-- END ZONE SOURCE -->

				<!-- selectedListItem section -->
				<template v-if="selectedZone.type == 'list' && selectedListItem != null && selectedListItem != undefined">
					<SectionTitle :title="$t('page.sidebarZone.list') + ' ' + parseInt(selectedListItem.listItemIndex + 1)">
					</SectionTitle>

					<v-row class="mt-0">
						<v-col cols="1" class="section-label"> {{ $t('page.sidebarZone.source') }} </v-col>
						<v-col>
							<v-row class="ml-10">
								<v-btn-toggle v-model="selectedListItem.source"
									@change="listItemSourceChanged(selectedZone, selectedListItem)">
									<v-btn class="btn-group-item" value="PDF">
										<v-icon x-large>
											$vuetify.icons.SourcePDF
										</v-icon>
									</v-btn>
									<v-btn class="btn-group-item" value="OCR">
										<v-icon x-large>
											$vuetify.icons.SourceOCR
										</v-icon>
									</v-btn>
									<v-btn class="btn-group-item" value="Custom">
										<v-icon x-large>
											$vuetify.icons.SourceCustom
										</v-icon>
									</v-btn>
									<v-btn class="btn-group-item" value="Actual">
										<v-icon x-large>
											$vuetify.icons.SourceActual
										</v-icon>
									</v-btn>
								</v-btn-toggle>
							</v-row>
						</v-col>
					</v-row>

					<!-- LIST ITEM SOURCE GOES HERE-->
					<template>
						<v-row class="ma-0">
							<v-textarea v-if="selectedListItem.source == 'OCR'  && selectedListItem.ocrText" color="success" solo
								label="" :loading="selectedListItem.ocrInProgress" :disabled="selectedListItem.ocrInProgress"
								v-model="selectedListItem.ocrText" @input="updateSelectedListItem"></v-textarea>

							<v-textarea v-if="selectedListItem.source == 'Custom'" color="success" solo label=""
								placeholder="Enter text here" :loading="selectedListItem.customInProgress"
								:disabled="selectedListItem.customInProgress" v-model="selectedListItem.customText"
								@input="updateSelectedListItem"></v-textarea>

							<v-textarea v-if="selectedListItem.source == 'Actual'" color="white" solo label=""
								placeholder="Enter actual text here" @input="updateSelectedListItem"
								v-model="selectedListItem.actual_text"></v-textarea>
						</v-row>
						<v-row class="mt-0" v-if="selectedListItem.source == 'OCR' && !selectedListItem.ocrText">
							<v-col class="text-center">
								<v-btn color="primary" :loading="selectedListItem.ocrInProgress"
									:disabled="selectedListItem.ocrInProgress" small elevation="2" @click="getListItemOcr">
									{{ $t('page.sidebarZone.ocrConvert') }}
								</v-btn>
							</v-col>
						</v-row>
					</template>

					<v-row class="mt-0" v-if="selectedListItem.listItemIndex == 0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.mergeItem') }} </v-col>
						<v-col cols="8">
							<v-checkbox class="mt-1" v-model="selectedListItem.merge"
								@change="mergeWithPageChanged(selectedListItem)"></v-checkbox>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="4" class="section-label"> {{ $t('page.sidebarZone.showListLabel') }} </v-col>
						<v-col cols="8">
							<v-checkbox class="mt-1" v-model="selectedListItem.uselbl" @change="useListLabelChange"></v-checkbox>
						</v-col>
					</v-row>

					<!--need to hide if not showing labels because labels may not exist yet which causes and error when evaluating lbl.x -->
					<v-row class="mt-0" v-if="selectedListItem.uselbl">
						<v-col cols="2" class="section-label">
							{{ $t('page.sidebarZone.width') }}
						</v-col>
						<v-col cols="6">
							<!--  lbl is undefined here on import of existing list, so lbl.x causes error-->
							<v-text-field dense solo type="number" min="1" :max="selectedListItem.w - selectedListItem.lbl.x" step="1"
								v-model="selectedListItem.lbl.w" @input="updateListLabelWidth"
								:readonly="selectedZone.type == 'image' || selectedZone.type == 'field'"></v-text-field>

						</v-col>
					</v-row>

					<!--need to hide if not showing labels because labels may not exist yet which causes and error when evaluating lbl.y -->
					<v-row class="mt-0" v-if="selectedListItem.uselbl">
						<v-col cols="2" class="section-label">
							{{ $t('page.sidebarZone.height') }}
						</v-col>
						<v-col cols="6">
							<!--  lbl is undefined here on import of existing list, so lbl.y causes error -->
							<v-text-field dense solo type="number" min="1" :max="selectedListItem.h - selectedListItem.lbl.y" step="1"
								v-model="selectedListItem.lbl.h" @input="updateListLabelHeight"
								:readonly="selectedZone.type == 'image' || selectedZone.type == 'field'"></v-text-field>
						</v-col>
					</v-row>

				</template>

				<template v-if="selectedZone.type == 'list' && selectedListItem == null || selectedListItem == undefined">
					<SectionTitle :title="$t('page.sidebarZone.zonePosition')" />

					<v-row class="mt-0">
						<v-col cols="2" class="section-label">
							{{ $t('page.sidebarZone.left') }}
						</v-col>
						<v-col cols="6">
							<v-text-field dense compact solo type="number" min="1" max="9999" step="1" v-model="selectedZone.x"
								@input="zonePositionChange"
								:readonly="selectedZone.type == 'image' || selectedZone.type == 'field'"></v-text-field>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="2" class="section-label">
							{{ $t('page.sidebarZone.top') }}
						</v-col>
						<v-col cols="6">
							<v-text-field dense solo type="number" min="1" max="9999" step="1" v-model="selectedZone.y"
								@input="zonePositionChange"
								:readonly="selectedZone.type == 'image' || selectedZone.type == 'field'"></v-text-field>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="2" class="section-label">
							{{ $t('page.sidebarZone.width') }}
						</v-col>
						<v-col cols="6">
							<v-text-field dense solo type="number" min="1" max="9999" step="1" v-model="selectedZone.w"
								@input="zonePositionChange"
								:readonly="selectedZone.type == 'image' || selectedZone.type == 'field'"></v-text-field>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="2" class="section-label">
							{{ $t('page.sidebarZone.height') }}
						</v-col>
						<v-col cols="6">
							<v-text-field dense solo type="number" min="1" max="9999" step="1" v-model="selectedZone.h"
								@input="zonePositionChange"
								:readonly="selectedZone.type == 'image' || selectedZone.type == 'field'"></v-text-field>
						</v-col>
					</v-row>
				</template>
				<!-- without these, can't scroll down far enough to see all of the Zone Position vals -->
				<v-row class="sidebar-bottom-padding">
					<br />
				</v-row>

			</div>

			<div v-else style="min-height: 1500px" class="room-for-scrollbar">
				<!-- Placeholder -->
			</div>

		</v-card>
	</v-tab-item>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import i18n from "@/plugins/i18n"
import StaticDataMixin from "@/mixins/staticData"
import SectionTitle from "./SectionTitle"
import EventBus from "@/eventBus"
import helpers from "@/helpers/common"

import TableZone from "./Properties/TableZone"
import { resolve } from "path"

export default {
	props: ["zones", "page", "document"],
	mixins: [StaticDataMixin],
	data() {
		return {
			dropDownSelection: null,
			ocrInProgress: false,
			ocrZoneProcessed: 0,
			ocrPageProgress: 0,
			ocrTotalZones: 0,
			optionValues: '',
			editOptionValuesDialog: false,
			zoneSensitivity: 0,
			listRadius: 0,
			disableMlListSlider: false,
			disableMlTocSlider: false,
			savedListDetectedZones: [],
			savedTOCDetectedZones: [],
			loadingMergeZones: false,
			mergeWithZones: [],
			listLoading: false,
			tocLoading: false,
		}
	},
	components: {
		SectionTitle,
		TableZone
	},
	computed: {
		...mapGetters({
			selectedZone: "page/getSelectedZone",
			language: "language/getLanguage",
			options: "application/getOptions",
			selectedZoneList: "page/getSelectedZoneList",
			selectedListItem: "page/getSelectedListItem",
		}),
		getZoneMergeOptions() {
			var retval = []
			var type = this.selectedZone.type
			retval.push({ name: "Previous " + type + " Zone", value: "" })
			retval.push({ name: type + " Zone on this page", value: "current" })
			retval.push({ name: type + " Zone on Page ...", value: "page" })
			return retval
		},
	},
	watch: {

		selectedZone: {
			handler(newVal, oldVal) {
				//skip the rest if contrast zone
				if (this.isContrastZone(this.selectedZone)) {
					return
				}
				this.dropDownSelection = this.selectedZone
				if (this.selectedZone != undefined && this.selectedZone != null && this.selectedZone.type == 'list') {
					this.adjustListSensitivity()
				}

				//test: Moved these lines from document_controllers $scope.zoneSelection
				this.getMergingPages()
				this.mergeWithPageChanged(this.selectedZone)

				//TODO: This is breaking the double click list item 

				if (this.selectedZone != null && this.selectedZone != undefined && this.selectedZone.type == 'list') {
					this.setSelectedZoneList(this.selectedZone)
				}
				//end test: moved lines
			}
		},
	},
	methods: {
		...mapActions({
			removeZone: "page/removeZone",
			ocrZone: "page/ocrZone",
			customZone: "page/customZone",
			loadOriginalListDetectAction: "page/loadOriginalListDetect",
			loadMlListDetectedZonesAction: "page/loadMlListDetectedZones",
			loadMlTOCDetectedZonesAction: "page/loadMlTOCDetectedZones",
			getPage: "page/getPage",
			ocrListZone: "page/ocrListZone",
		}),
		...mapMutations({
			changeListItemCount: "page/UPDATE_LIST_ITEM_COUNT",
			setSelectedZoneList: "page/SET_SELECTED_ZONE_LIST",
			setSelectedListItem: "page/SET_SELECTED_LIST_ITEM",
			updateLayoutZone: "page/UPDATE_LAYOUT_ZONE",
			changeArtifactZonePosition: "page/UPDATE_ARTIFACT_ZONE_POSITION",
			changeLblZonePosition: "page/UPDATE_LBL_ZONE_POSITION",
			setSelectedZoneCaption: "page/SET_SELECTED_ZONE_CAPTION",
			setSelectedZoneSummary: "page/SET_SELECTED_ZONE_SUMMARY",
			setSelectedZoneMerge: "page/SET_SELECTED_ZONE_MERGE",
			setSelectedZone: "page/SET_SELECTED_ZONE",
			setLayout: "page/SET_LAYOUT",
			callbackOcr: "page/CALL_BACK_OCR",
		}),
		isContrastZone(zone) {
			return helpers.isContrastZone(zone)
		},
		updateTocLinkToPage() {
			this.selectedListItem.linktopage = parseInt(this.selectedListItem.linktopage)
			this.updateSelectedZone()
		},
		updateListLabelWidth() {
			this.selectedListItem.lbl.w = parseInt(this.selectedListItem.lbl.w)
			this.zonePositionLblChange()
		},
		updateListLabelHeight() {
			this.selectedListItem.lbl.h = parseInt(this.selectedListItem.lbl.h)
			this.zonePositionLblChange()
		},
		updateHeaderlevel() {
			this.selectedZone.headerlevel = parseInt(this.selectedZone.headerlevel)
			this.updateSelectedZone()
		},
		updateLayout() {
			this.setSelectedZone(this.selectedZone)
		},
		changeSelectedZoneMerge() {
			this.updateLayout()
			//set default mergeOption to name 'Previous' which has value of ""
			//this.selectedZone.mergeOptions = ""
			this.setSelectedZoneMerge(this.selectedZone.merge)
		},
		changeSelectedZoneCaption() {
			this.setSelectedZoneCaption(this.selectedZone.caption)
			this.setSelectedZone(this.selectedZone)
		},
		changeSelectedZoneSummary() {
			this.setSelectedZoneSummary(this.selectedZone.summary)
			this.setSelectedZone(this.selectedZone)
		},
		allowOnlyTab(e) {
			//allow only the tab key to avoid keyboard trap
			if (e.keyCode != 9 && e.key != "Tab") {
				e.preventDefault()
			}
		},
		/*
			Lets try just setting the selected zone ie updating it when selected zone list merge values change
		*/
		updateSelectedZone() {
			// console.log('****** updating selected zone: ', this.selectedZone)
			this.setSelectedZone(this.selectedZone)
		},
		//creates a list label if there isn't one.  showing list label properties when no label causes errors.
		useListLabelChange() {
			if (this.selectedListItem !== undefined && this.selectedListItem.uselbl == true && this.selectedListItem.lbl === undefined) {
				this.selectedListItem.lbl = { id: helpers.generateUUID(), type: "lbl", x: 1, y: 1, w: 15, h: 15 }
			}
		},
		zonePositionLblChange() {
			this.changeLblZonePosition(this.selectedListItem)
		},

		zonePositionArtifactChange() {
			this.selectedListItem.artifact.x = parseFloat(this.selectedListItem.artifact.x)
			this.selectedListItem.artifact.y = parseFloat(this.selectedListItem.artifact.y)
			this.selectedListItem.artifact.w = parseFloat(this.selectedListItem.artifact.w)
			this.selectedListItem.artifact.h = parseFloat(this.selectedListItem.artifact.h)
			this.changeArtifactZonePosition(this.selectedListItem)
		},

		zonePositionChange() {
			var parent = document.getElementById('svg')

			//was setting as str val
			this.selectedZone.x = parseFloat(this.selectedZone.x)
			this.selectedZone.y = parseFloat(this.selectedZone.y)
			this.selectedZone.h = parseFloat(this.selectedZone.h)
			this.selectedZone.w = parseFloat(this.selectedZone.w)

			if (this.selectedZone.x < 1) {
				this.selectedZone.x = 0
			}

			//x can't be set beyond clientWidth, if it is, move x back so 
			//that x + width is equal to clientWidth
			if (this.selectedZone.x > parent.clientWidth) {
				this.selectedZone.x = parseFloat(parent.clientWidth) - parseFloat(this.selectedZone.w)
			}

			//if x + y is greater than or equal to clientWidth, set width
			//equal to clientWidth - x
			if (parseFloat(this.selectedZone.x) + parseFloat(this.selectedZone.w) >= parent.clientWidth) {
				this.selectedZone.w = parseFloat(parent.clientWidth) - parseFloat(this.selectedZone.x)
			}

			if (this.selectedZone.y < 1) {
				this.selectedZone.y = 0
			}

			//similar to x: if y is beyond client height, set y = clientHeight - h
			if (this.selectedZone.y > parent.clientHeight) {
				this.selectedZone.y = parseFloat(parent.clientHeight) - parseFloat(this.selectedZone.h)
			}
			//if y + h is greater than or equal to client height, set h = client height - y
			if (parseFloat(this.selectedZone.y) + parseFloat(this.selectedZone.h) >= parent.clientHeight) {
				this.selectedZone.h = parseFloat(parent.clientHeight) - parseFloat(this.selectedZone.y)
			}

			if (this.selectedZone.h < 1) {
				this.selectedZone.h = 0
			}

			if (this.selectedZone.w < 1) {
				this.selectedZone.w = 0
			}

			//finally: update
			this.updateLayoutZone(this.selectedZone)
		},
		onZoneSelected(zone) {
			this.$store.commit("page/SET_SELECTED_ZONE", zone)

		},
		removeSelectedZone() {
			//new.  emit REMOVE_ZONE to be picked up on Page component
			EventBus.$emit("REMOVE_ZONE", this.selectedZone)
		},
		subReOrderByColumns(divZone, zones, baseOrder, colCount) {
			var colWidth = divZone.width || divZone.w / colCount

			zones.sort(function (a, b) {
				//sort by x, secondary by y
				return a.x == b.x ? a.y - b.y : a.x - b.x;
			})

			var counter = 1
			var startCol = 0
			var endCol = colWidth

			for (var col = 0; col < colCount; col++) {
				startCol = (colWidth * col) - (60 / colCount)
				endCol = (colWidth * (col + 1)) - (60 / colCount)
				for (var i = 0; i < zones.length; i++) {
					if (zones[i] !== divZone) {
						if (zones[i].x >= startCol && zones[i].x <= endCol) {
							zones[i].order = baseOrder + (counter++ * 0.001)
						}
					}
				}
			}
		},

		//this is basically subReOrder from angular document_controllers.js
		clickDivReorder() {
			if (this.selectedZone !== undefined && this.selectedZone.type === 'div') {
				var baseOrder = this.selectedZone.order
				// console.log('selected div zone order: ', this.selectedZone.order)
				var divChildrens = helpers.findIntersectorsExcludingZone(this.selectedZone, this.zones)
				if (divChildrens.length > 0) {
					switch (this.selectedZone.subAutoOrder) {
						case 2:
						case "2 columns":
							this.subReOrderByColumns(this.selectedZone, divChildrens, baseOrder, 2)
							this.$snackbar.showMessage({ content: this.$t("page.sidebarZone.msg.zonesReordered2Col"), color: 'success' })
							break
						case 3:
						case "3 columns":
							this.subReOrderByColumns(this.selectedZone, divChildrens, baseOrder, 3)
							this.$snackbar.showMessage({ content: this.$t("page.sidebarZone.msg.zonesReordered3Col"), color: 'success' })
							break
						case 4:
						case "4 columns":
							this.subReOrderByColumns(this.selectedZone, divChildrens, baseOrder, 4)
							this.$snackbar.showMessage({ content: this.$t("page.sidebarZone.msg.zonesReordered4Col"), color: 'success' })
							break
						case 1:
						case "Default - Top Down":
						default:
							divChildrens.sort(function (a, b) {
								if (a.x < b.x) {
									if (a.y - 10 < b.y)
										return -1
									if (a.y - 10 > b.y)
										return 1
									return 0
								}
								else {
									if (b.x < a.x) {
										if (a.y < b.y - 10)
											return -1
										if (a.y > b.y - 10)
											return 1
										return 0
									}
									else {
										if (a.y < b.y)
											return -1
										if (a.y > b.y)
											return 1
										return 0
									}
								}
							})

							var counter = 1;
							for (var i = 0; i < divChildrens.length; i++) {
								if (this.selectedZone !== divChildrens[i]) {
									divChildrens[i].order = baseOrder + (counter++ * 0.001)
								}
							}
							this.$snackbar.showMessage({ content: this.$t("page.sidebarZone.msg.zonesReorderedDefault"), color: 'success' })
							break
					}
				}
			}
		},
		scrollToListDetector() {
			this.$nextTick(function () {
				this.$vuetify.goTo('#listDetector', { container: this.$refs.zoneProperties })
			}.bind(this))
		},

		scrollToZoneSourceGeneric() {
			this.$nextTick(function () {
				this.$vuetify.goTo('#zoneSourceGeneric', { container: this.$refs.zoneProperties })
			}.bind(this))
		},

		scrollToZoneSourceList() {
			this.$nextTick(function () {
				this.$vuetify.goTo('#zoneSourceList', { container: this.$refs.zoneProperties })
			}.bind(this))
		},

		zoneTypeChanged() {
			// console.log('in zone type changed')
			if (this.selectedZone !== undefined) {

				//we need to remove any merge options when the zone type changes
				this.removeMergeOptions()

				if (this.selectedZone.type == 'blockquote') {
					for (var i = 0; i < this.zones.length; i++) {
						var childZone = this.zones[i]
						if ((childZone.x >= this.selectedZone.x && childZone.x <= this.selectedZone.x + this.selectedZone.w) &&
							(childZone.y >= this.selectedZone.y && childZone.y <= this.selectedZone.y + this.selectedZone.h)) {
							// console.log("in zoneTypeChanged. childZone: ", childZone)
						}
					}
				}

				if (this.selectedZone.type == 'list') {
					this.adjustListSensitivity();
					if (this.selectedZone.listItemsCount === undefined) {
						this.selectedZone.listitems = [{
							id: helpers.generateUUID(),
							type: "listitem",
							name: this.selectedZone.name + ' LI 1',
							listItemIndex: 0,
							parent: this.selectedZone.id,
							order: this.selectedZone.order + 1,
							uselbl: false,
							merge: false,
							x: 0,
							y: 0,
							h: parseInt(this.selectedZone.h),
							w: parseInt(this.selectedZone.w),
							source: "PDF",
							lbl: { id: helpers.generateUUID(), type: "lbl", x: 1, y: 1, w: 15, h: 15 },
						}];
						this.selectedZone.listItemsCount = 1
						this.selectedZone.splitters = []
						// console.log('Type changed to list', this.selectedZone)

						//Test: when we change the zone type to list, the zone should be set as selected list
						this.setSelectedZoneList(this.selectedZone)
					}

					var scrollToListDetector = this.scrollToListDetector

					scrollToListDetector()


				}
				else if (this.selectedZone.type != 'list' && this.selectedZone.listitems !== undefined) {
					delete this.selectedZone.listitems
					delete this.selectedZone.listItemsCount
				}

				if (this.selectedZone.type == 'field') {
					this.selectedZone.v = 2;
					//adding object property 'f_f' to prevent console error of undefined for v-model pointing to field.f_f.readOnly
					this.selectedZone.f_f = { readOnly: false }
				}

				if (this.selectedZone.type == 'toc') {
					// console.log('in change type to toc')
					if (this.selectedZone.listItemsCount === undefined) {
						this.selectedZone.listitems = [{
							id: helpers.generateUUID(),
							type: "listitem",
							name: this.selectedZone.name + ' TOCI 1',
							listItemIndex: 0,
							parent: this.selectedZone.id,
							order: this.selectedZone.order + 1,
							uselbl: false,
							merge: false,
							x: 0,
							y: 0,
							h: parseInt(this.selectedZone.h),
							w: parseInt(this.selectedZone.w),
							source: "PDF",
							artifact: { id: helpers.generateUUID(), type: "artifact", x: 1, y: 1, w: 15, h: 15 }  //need to add an artifact for ui to display artifact positionn
						}];
						this.selectedZone.listItemsCount = 1;
						this.selectedZone.splitters = [];
					}

					//TODO: FIGURE THIS OUT
					this.loadMlTOCDetectedZones()
				}

				if (this.selectedZone.listSensitivity != undefined && this.selectedZone.listSensitivity != null) {
					this.undoListDetection()
				}

				//finally update the selected Zone
				this.updateSelectedZone()
			}

		},
		undoListDetection() {
			// console.log('undoing list detection')
			this.selectedZone.listSensitivity = null
			this.listRadius = 0
			this.removeListDetectedZones()
		},
		removeMergeOptions() {
			this.selectedZone.merge = false;
			this.selectedZone.mergeOptions = null;
			this.selectedZone.mergeWithZone = null;
			this.selectedZone.mergeWithPageNo = null;
		},

		/*
			This acts on the selected zone
		*/
		adjustListSensitivity() {
			if (this.selectedZone.listSensitivity != undefined && this.selectedZone.listSensitivity != null) {
				this.listRadius = this.selectedZone.listSensitivity;
			}
			else {
				this.listRadius = 0;
			}
		},
		fieldTypeChanged() {
			this.$store.commit("page/UPDATE_FIELD_TYPE", this.selectedZone.field_type)
		},

		/*
			Section for list source change
		*/
		itemsSourceChanged() {
			//make sure selected Item itemsSource has changed in state
			console.log('in items source changed')
		},

		pdfAllListItems() {
			if (this.selectedZone.listitems) {
				for (var list_index = 0; list_index < this.selectedZone.listitems.length; list_index++) {
					this.selectedZone.listitems[list_index].source = 'PDF'
				}
				this.$snackbar.showMessage({ content: this.$t("page.sidebarZone.msg.listItemSourcePDF"), color: 'success' })
			}
		},
		//used when the selected list item source text changes
		updateSelectedListItem() {
			this.setSelectedListItem(this.selectedListItem)
		},
		/*
			This is for the list item source change
		*/
		getListItemOcr() {
			//make the call
			this.ocrZone({
				payload: {
					doc_key: this.document.key,
					page_no: this.page.page_no,
					name: this.selectedListItem.name,
					id: this.selectedListItem.id,
					left: this.selectedZone.x + this.selectedListItem.x,
					top: this.selectedZone.y + this.selectedListItem.y,
					width: this.selectedListItem.w,
					height: this.selectedListItem.h,
				}
			}).then((response) => {
				console.log('response from ocrListZone: ', response)
				// var data = response.data
				// if (data != null && data.transactId != null) {
				// 	this.selectedListItem.ocrStatus = data.transactId
				// }

			}).catch((error) => {
				// console.log('error in ocrListZone: ', error)
				this.$snackbar.showMessage({ content: this.$t("page.sidebarZone.msg.unableDetectOCR"), color: 'error' })
			})
		},

		/*
			This is for the main list source change
		*/
		ocrAllListItems() {
			var nestedLists = helpers.findIntersectors(this.selectedZone, this.zones)
			// console.log('nested lists found: ', nestedLists)
			var lists = []

			for (var d = 0; d < nestedLists.length; d++) {
				for (var i = 0; i < this.zones.length; i++) {
					if (this.zones[i].id.toString() === nestedLists[d].id.toString()) {

						if (this.zones[i].type === 'list') {
							lists.push(this.zones[i])

							// Flag all list and nested list to ocr in progress
							this.zones[i].ocrInProgress = true;
							if (this.zones[i].listitems !== undefined) {
								for (var list_index = 0; list_index < this.zones[i].listitems.length; list_index++) {
									// Change the source of list items to OCR and set the transact id on them
									// Transact ID will be matched with the response from the backend server
									this.zones[i].listitems[list_index].source = 'OCR';
									this.zones[i].listitems[list_index].ocrStatus = this.zones[i].id + '/' +
										this.zones[i].listitems[list_index].id;
								}
							}
						}
						break
					}
				}
			}

			//make the call
			this.ocrListZone({
				payload: {
					doc_key: this.document.key,
					page_no: this.page.page_no,
					lists: lists,
					zone: this.selectedZone,
				}
			}).then((response) => {
				var data = response.data

			}).catch((error) => {
				this.$snackbar.showMessage({ content: this.$t("page.sidebarZone.msg.unableDetectOCR"), color: 'error' })
			})
		},

		customAllListItems() {
			if (this.selectedZone.listitems) {
				for (var list_index = 0; list_index < this.selectedZone.listitems.length; list_index++) {
					this.selectedZone.listitems[list_index].source = 'Custom'
					this.listItemSourceChanged(this.selectedZone, this.selectedZone.listitems[list_index]);
				}
				this.$snackbar.showMessage({ content: this.$t("page.sidebarZone.msg.listItemSourceCustom"), color: 'success' })
			}
		},

		listItemSourceChanged(list, listItem) {

			// console.log("List item source changed!!!")

			if (listItem && 
				(listItem.source == 'Custom' && (listItem.customText === undefined || listItem.customText === '')) || 
				(listItem.source == 'Actual' && (listItem.actual_text === undefined || listItem.actual_text === '')) 
				) {

				var updated_zone = JSON.parse(JSON.stringify(listItem))
				updated_zone.x += list.x
				updated_zone.y += list.y

				this.customZone({payload:
					{
						doc_key: this.document.key,
						page_no: this.page.page_no,
						listId: list.id,
						zone: updated_zone
					}
				}).then((response) => {
						// this.setSelectedListItem(listItem)
						this.$forceUpdate()
					}

				)

			}
			
		},

		zoneSourceChanged(msg) {
			this.updateSelectedZone(this.selectedZone)
			if (this.selectedZone.source === 'Custom' &&
				(this.selectedZone.customText === undefined || this.selectedZone.customText === '')) {

				this.customZone({
					payload:
					{
						doc_key: this.document.key,
						page_no: this.page.page_no,
						zone: this.selectedZone
					}
				}).then((response) => {
					this.selectedZone.customText = response.data.customText
					this.selectedZone.customInProgress = false
				})
					.catch((error) => {
						this.isLoading = false
					})
			}

			// console.log("Source changed!?")
			if (this.selectedZone.source === 'Actual' &&
				(this.selectedZone.actual_text === undefined || this.selectedZone.actual_text === '')) {

				this.customZone({
					payload:
					{
						doc_key: this.document.key,
						page_no: this.page.page_no,
						zone: this.selectedZone
					}
				}).then((response) => {
					this.selectedZone.actual_text = response.data.customText
					this.selectedZone.customInProgress = false
				})
					.catch((error) => {
						this.isLoading = false
					})
			}

			//for scrolling on zone source change. triggered by event emitted from the toolbar
			if (msg != null) {
				var selectedZone = msg
				// console.log('in zone source changed.  selected zone is: ', msg)
				if (selectedZone.type == 'list') {
					this.scrollToZoneSourceList()
				}
				else {
					this.scrollToZoneSourceGeneric()
				}
			}

		},

		//From document_controllers 'getOcr'
		clickSourceOcr() {
			this.ocrZone({
				payload: {
					doc_key: this.document.key,
					page_no: this.page.page_no,
					name: this.selectedZone.name,
					id: this.selectedZone.id,
					left: this.selectedZone.x,
					top: this.selectedZone.y,
					width: this.selectedZone.w,
					height: this.selectedZone.h
				}
			}).then((response) => {
				// console.log("response from ocrZone: ", response)
				this.selectedZone.ocrStatus = response.data.transactId
				// this.updateSelectedZone(this.selectedZone)
			})
				.catch((error) => {
					this.isLoading = false
					this.$snackbar.showMessage({ content: this.$t("page.sidebarZone.msg.unableDetectOCR"), color: 'error' })
				})
		},

		clickEditOptionValues() {
			// console.log('Edit values')
			if (this.selectedZone.options !== undefined) {
				this.optionValues = this.selectedZone.options.join('\n')
			}
			else {
				this.optionValues = ''
			}
			this.editValues = true
		},
		clickSaveOptionValues() {
			if (this.optionValues === undefined || this.optionValues === '') {
				this.selectedZone.options = []
			}
			else {
				this.selectedZone.options = this.optionValues.split('\n').reduce(function (a, b) { if (a.indexOf(b) < 0) a.push(b); return a; }, [])
			}
			this.editOptionValuesDialog = false
		},
		getFootnoteZones() {
			return this.zones.filter(z => z.type === 'footnote')
		},
		clickRemoveUnderlyingZones() {
			this.$store.commit("page/REMOVE_UNDERLYING_ZONE", { zone: this.selectedZone })
		},

		clickDetectList() {
			console.log("Detect list")
		},

		addSplitterObjsToTaggedLists() {

			var addSplitterObjs = function (zone) {
				if (zone.splitters != null && zone.splitters != undefined && zone.splitters.length > 0) {
					var splitterObjs = []
					for (var i = 0; i < zone.splitters.length; i++) {
						var splitterObj = { y: zone.splitters[i], id: i }
						splitterObjs.push(splitterObj)
					}
					zone.splitterObjs = splitterObjs
					// console.log('zone after adding splitterObj: ', zone)
				}
			}

			// console.log('adding splitter objs to list zones on tagged layout')

			if (this.zones != null && this.zones.length > 0) {
				for (var i = 0; i < this.zones.length; i++) {
					var zone = this.zones[i]
					if (zone.type == 'list') {
						if (zone.splitters != undefined && zone.splitters != null && zone.splitters.length > 0
							&& zone.splitterObjs == undefined) {
							addSplitterObjs(zone)
							this.updateLayoutZone(zone)
						}
					}
				}
			}

		},

		detectTOCML() {
			var selectedZone = this.selectedZone
			var zones = this.zones
			var selectListByID = this.selectListByID
			var setLayout = this.setLayout

			var addSplitterObjs = function (zone) {
				if (zone.splitters != null && zone.splitters != undefined && zone.splitters.length > 0) {
					var splitterObjs = []
					for (var i = 0; i < zone.splitters.length; i++) {
						var splitterObj = { y: zone.splitters[i], id: i }
						splitterObjs.push(splitterObj)
					}
					zone.splitterObjs = splitterObjs
					// console.log('zone after adding splitterObj: ', zone)
				}
			}

			var processResults = function (results) {
				var zoneId = selectedZone.id;
				if (results != null) {
					// Remove previous list zone
					for (var i = 0; i < zones.length; i++) {
						if (zones[i].type == 'toc' && zones[i].id.toString() === selectedZone.id.toString()) {
							// console.log('found previous list zone to remove')
							zones.splice(i, 1)
							break
						}
					}
					// console.log(' *** in toc ml process results')

					// Remove previous zone
					var newZones = []
					for (i = 0; i < zones.length; i++) {
						if (zones[i].parentListId != undefined && zones[i].parentListId.toString() === zoneId.toString()) {
							console.log('.')
						}
						else {
							// console.log('pushing new zone: ', zones[i])
							newZones.push(zones[i])
						}
					}

					zones = newZones

					for (i = 0; i < results.length; i++) {
						//for (i = results.length - 1; i >= 0; i--) {
						// console.log('adding copy of results to zones:', results[i])

						//test add splitter objs
						addSplitterObjs(results[i])

						zones.push(Object.assign({}, results[i]))
					}
					selectListByID(zoneId)
				}


				//test: Try setting the new layout in store
				setLayout(zones)

			}

			if (this.savedTOCDetectedZones[this.selectedZone.id] == undefined ||
				this.savedTOCDetectedZones[this.selectedZone.id][this.listRadius] == undefined ||
				this.savedTOCDetectedZones[this.selectedZone.id][this.listRadius] == null) {

				//load then process results
				// console.log('Calling ML');

				this.loadMlTOCDetectedZones().then(function (results) {
					// console.log('after calling ml toc, getting results: ', results)
					if (results) {
						// console.log('Processing results');
						processResults(this.savedTOCDetectedZones[this.selectedZone.id][this.listRadius])
					}
				})
			}
			else { //already loaded, process saved zones
				// console.log('in else')
				processResults(this.savedTOCDetectedZones[this.selectedZone.id][this.listRadius])
			}
		},

		onRadiusTOCChange() {
			// console.log('in toc radius change')
			this.selectedZone.listSensitivity = this.listRadius
			this.removeTextZonesInsideListZone(this.selectedZone)
			this.detectTOCML()
		},

		/* for ml list detection */
		onRadiusListChange() {
			// this.selectedZone.listSensitivity = this.listRadius
			this.removeListDetectedZones()
			this.removeTextZonesInsideListZone()
			// console.log('in onRadiusListChange.  listRadius is: ', this.selectedZone.listSensitivity)
			switch (this.selectedZone.listSensitivity) {
				//no detection
				case 0:
					//nothing here
					break

				//old way of detecting list
				case 1:
					this.detectList()
					break

				//computer vision list detection
				case 2:
					this.detectListML(1)
					break

				//computer vision list detection #2
				case 3:
					this.detectListML(2)
					break

				//ml list detection #3
				case 4:
					this.detectListML(3)
					break

				//ml list detection #4
				case 5:
					this.detectListML(4)
					break

				default:
				//nothing here
			}
		},

		removeListDetectedZones() {
			// console.log('in remove list detected zones')
			var zoneCount = this.zones.length
			while (zoneCount--) {
				if ((this.zones[zoneCount].parentListId == this.selectedZone.id) &&  //if the parent list of the contained zone is the id of the container zone
					//but done remove the parentList
					(this.zones[zoneCount].parentListId != undefined && this.zones[zoneCount].parentListId != this.zones[zoneCount].id)) {
					this.zones.splice(zoneCount, 1)
				}
			}

			//clean up the list zone
			this.selectedZone.splitterObjs = []
			this.selectedZone.splitters = []
			this.selectedZone.listItems = []
			this.selectedZone.listItemCount = 0

			this.setSelectedZone(this.selectedZone)
		},

		removeTextZonesInsideListZone() {
			var zoneCount = this.zones.length;
			while (zoneCount--) {
				//if a zone is a text zone inside the list zone, remove it
				if (this.zones[zoneCount].x >= this.selectedZone.x && (this.zones[zoneCount].x + this.zones[zoneCount].w <= this.selectedZone.x + this.selectedZone.w) &&
					this.zones[zoneCount].y >= this.selectedZone.y && this.zones[zoneCount].y + this.zones[zoneCount].h <= this.selectedZone.y + this.selectedZone.h) {
					if (this.zones[zoneCount].type == 'text') {
						this.zones.splice(zoneCount, 1)
					}
				}
			}
		},

		detectList() {
			var selectedZone = this.selectedZone
			var zones = this.zones
			var selectListByID = this.selectListByID
			var turnOffListLoading = this.turnOffListLoading
			var savedListDetectedZones = this.savedListDetectedZones

			//test add splitterObj so we have an id for iteration
			// var addSplitterObjs = function (zone) {
			// 	if (zone.splitters != null && zone.splitters != undefined && zone.splitters.length > 0) {
			// 		var splitterObjs = []
			// 		for (var i = 0; i < zone.splitters.length; i++) {
			// 			var splitterObj = { y: zone.splitters[i], id: i }
			// 			splitterObjs.push(splitterObj)
			// 		}
			// 		zone.splitterObjs = splitterObjs
			// 		console.log('zone after adding splitterObj: ', zone)
			// 	}
			// }

			// var processResults = function (results) {
			// 	console.log('in process results for detectList.  results:', results)
			// 	// Remove previous list zone
			// 	var zoneId = selectedZone.id
			// 	for (var i = 0; i < zones.length; i++) {
			// 		if (zones[i].type == 'list' && zones[i].id.toString() === selectedZone.id.toString()) {
			// 			zones.splice(i, 1)
			// 			break
			// 		}
			// 	}

			// 	//object coming back in result doesn't have the list sensitivity with it, so we need to set it
			// 	//so it's saved
			// 	if (results[0].id.toString() === selectedZone.id.toString()) {
			// 		results[0].listSensitivity = selectedZone.listSensitivity
			// 	}

			// 	//add the splitter objs
			// 	addSplitterObjs(results[0])

			// 	//we want to add a copy of the original result.  if we make changes on the original result, we can't get
			// 	//them back since the call results are cached. so make changes to a copy instead
			// 	zones.push(Object.assign({}, results[0]))
			// 	selectListByID(zoneId)

			// }

			var hasChild = false;

			// Check if this zone cover other zones
			var childs = [];
			for (var i = 0; i < this.zones.length; i++) {
				var layZone = this.zones[i];
				if (layZone.name != this.selectedZone.name) {
					if (layZone.x >= this.selectedZone.x && (layZone.x + layZone.w <= this.selectedZone.x + this.selectedZone.w) &&
						layZone.y >= this.selectedZone.y && layZone.y + layZone.h <= this.selectedZone.y + this.selectedZone.h) {
						hasChild = true;
						childs.push(layZone);
					}
				}
			}

			if (this.savedListDetectedZones[this.selectedZone.id] == undefined ||
				this.savedListDetectedZones[this.selectedZone.id][0] == undefined ||
				this.savedListDetectedZones[this.selectedZone.id][0] == null) {
				
				// console.log("this.selectedZone.id =>", this.selectedZone.id)
				this.loadOriginalListDetectAction({
					payload: {
						doc_key: this.document.key,
						page_no: this.page.page_no,
						name: this.selectedZone.name,
						id: this.selectedZone.id,
						order: this.selectedZone.order,
						left: this.selectedZone.x,
						top: this.selectedZone.y,
						width: this.selectedZone.w,
						height: this.selectedZone.h,
					}
				}).then((response) => {
					// console.log('in load original list detect.  results are: ', response)
					// this.selectedZone
					
					// if (response && response.zones) {
					// 	var zones = response.zones
					// 	for (var i = 0; i < zones.length; i++) {
					// 		zones[i].parentListId = selectedZone.id
					// 	}

					// 	if (this.savedListDetectedZones[selectedZone.id] == undefined) {
					// 		this.savedListDetectedZones[selectedZone.id] = []
					// 	}

					// 	this.savedListDetectedZones[selectedZone.id][0] = zones
					// 	console.log('in load orig list detect. saved list detected zones is:', this.savedListDetectedZones)

					// }
					// else {
					// 	this.$snackbar.showMessage({ content: "Unable to detect any list items, move the list left border closer to the bullets", color: 'error' })
					// }
					//resolve success in either above case, even if we don't get good results
					// resolve('success')
					turnOffListLoading()

				}).catch((error) => {
					console.log(error)
				})




				//load then process results
				// this.loadOriginalListDetect().then(function (results) {
				// 	if (results) {
				// 		if (savedListDetectedZones[selectedZone.id] && savedListDetectedZones[selectedZone.id].lenght > 0)
				// 		processResults(savedListDetectedZones[selectedZone.id][0])
				// 	}
				// })



			}
			else { //already loaded, process saved zones
				// processResults(savedListDetectedZones[selectedZone.id][0])
			}
		},

		turnOffListLoading() {
			this.listLoading = false
		},
		turnOffTocLoading() {
			this.tocLoading = false
		},

		detectListML(option) {
			var selectedZone = this.selectedZone
			var zones = this.zones
			var selectListByID = this.selectListByID
			var turnOffListLoading = this.turnOffListLoading

			//test add splitterObj so we have an id for iteration
			var addSplitterObjs = function (zone) {
				if (zone.splitters != null && zone.splitters != undefined && zone.splitters.length > 0) {
					var splitterObjs = []
					for (var i = 0; i < zone.splitters.length; i++) {
						var splitterObj = { y: zone.splitters[i], id: i }
						splitterObjs.push(splitterObj)
					}
					zone.splitterObjs = splitterObjs
					// console.log('zone after adding splitterObj: ', zone)
				}
			}

			var processResults = function (results) {
				var zoneId = selectedZone.id
				if (results != null) {
					// Remove previous list zone
					for (var i = 0; i < zones.length; i++) {
						if (zones[i].type == 'list' && zones[i].id.toString() === selectedZone.id.toString()) {
							// console.log('found previous list zone to remove')
							zones.splice(i, 1)
							break
						}
					}
					// console.log(' *** in list ml process results')

					//for (i = 0; i < results.length; i++) {  //try reverse loop so last svg is on top
					for (i = results.length - 1; i >= 0; i--) {
						//object coming back in result doesn't have the list sensitivity with it, so we need to set it
						//so it's saved
						if (results[i].id.toString() === selectedZone.id.toString()) {
							results[i].listSensitivity = selectedZone.listSensitivity
						}

						addSplitterObjs(results[i])

						//we want to add a copy of the original result.  if we make changes on the original result, we can't
						//get them back since the call results are cached. so make changes to a copy instead.
						zones.push(Object.assign({}, results[i]))
					}
					// console.log('in process results.  zones:', zones)

					selectListByID(zoneId);
				}
			}

			// console.log('Calling ml list')
			var option_num = option + 1
			var opt = 'option' + option_num.toString()
			// console.log('user selected option: ' + option_num)

			if (this.savedListDetectedZones[this.selectedZone.id] == undefined ||
				this.savedListDetectedZones[this.selectedZone.id][option] == undefined ||
				this.savedListDetectedZones[this.selectedZone.id][option] == null) {

				//load then process results
				// console.log('Calling ML');
				var savedListDetectedZones = this.savedListDetectedZones
				//var selectedZone = this.selectedZone
				//show loading
				this.listLoading = true
				this.loadMlListDetectedZones().then(function (results) {
					turnOffListLoading()
					if (results) {
						// console.log('Processing ml list detection results', results);
						processResults(savedListDetectedZones[selectedZone.id][option]);
					}
				})
			}
			else { //already loaded, process saved zones
				turnOffListLoading()
				processResults(this.savedListDetectedZones[this.selectedZone.id][option]);
			}

		},

		clickZonesTab() {
			EventBus.$emit("tabZones", {})
		},

		selectListByID(id) {
			// console.log('in select list by id.  id is: ', id)
			for (var i = 0; i < this.zones.length; i++) {
				if (this.zones[i].id.toString() === id.toString()) {
					this.clickZonesTab()

					var removeClasses = (this.selectedZone !== this.zones[i])
					this.zones[i].selected = true
					this.highlightSelected(id)

					if (this.selectedZoneList !== this.zones[i]) {
						//this.selectedZone = this.zones[i];
						this.$store.commit("page/SET_SELECTED_ZONE", this.zones[i])
						this.setSelectedZoneList(this.zones[i])

						if (this.selectedListItem !== undefined && this.selectedListItem != null &&
							this.selectedListItem.parent.toString() !== this.selectedZoneList.id.toString()) {
							this.setSelectedListItem(undefined)
						}

						this.getMergingPages()
						this.mergeWithPageChanged(this.zones[i])

						if (removeClasses) {
							// console.log('in remove classes')
							this.removeZoneClass('zone-list-selected')

							//preset the zone
							if (this.zones[i].type == 'list') {
								//TODO: not right, no param needed
								this.adjustListSensitivity(this.zones[i])
								this.addZoneClass('zone-list-selected')
							}
						}
					}
				}
			}
		},
		async loadMlTOCDetectedZones() {
			// console.log('in loadMlTOCDetectedZones!!')
			return new Promise((resolve, reject) => {
				this.disableMlTocSlider = true

				this.loadMlTOCDetectedZonesAction({
					payload: {
						doc_key: this.document.key,
						page_no: this.page.page_no,
						name: this.selectedZone.name,
						id: this.selectedZone.id,
						order: this.selectedZone.order,
						left: this.selectedZone.x,
						top: this.selectedZone.y,
						width: this.selectedZone.w,
						height: this.selectedZone.h,
					}
				}).then((response) => {
					if (response && response.zones) {
						var zones_list = response.zones

						//double loop
						for (var i = 0; i < zones_list.length; i++) {
							var zones = zones_list[i];

							for (var j = 0; j < zones.length; j++) {
								zones[j].parentListId = this.selectedZone.id
							}

							//save the results
							if (this.savedTOCDetectedZones[this.selectedZone.id] == undefined) {
								this.savedTOCDetectedZones[this.selectedZone.id] = []
								// set item at 0 as the original
								this.savedTOCDetectedZones[this.selectedZone.id][0] = [this.selectedZone]
							}

							this.savedTOCDetectedZones[this.selectedZone.id][i + 1] = zones

						}

						this.disableMlTocSlider = false
						resolve('success')
					}
					else {
						//nothing here
					}
				}).catch((error) => {
					reject(error)
				})
			})
		},

		async loadOriginalListDetect() {
			var selectedZone = this.selectedZone
			var turnOffListLoading = this.turnOffListLoading
			
			this.listLoading = true
			return new Promise((resolve, reject) => {



			})
		},

		async loadMlListDetectedZones() {

			return new Promise((resolve, reject) => {
				this.disableMlListSlider = true

				this.loadMlListDetectedZonesAction({
					payload: {
						doc_key: this.document.key,
						page_no: this.page.page_no,
						name: this.selectedZone.name,
						id: this.selectedZone.id,
						order: this.selectedZone.order,
						left: this.selectedZone.x,
						top: this.selectedZone.y,
						width: this.selectedZone.w,
						height: this.selectedZone.h,
					}
				}).then((response) => {
					if (response && response.zones) {
						var zones_list = response.zones

						//double loop
						for (var i = 0; i < zones_list.length; i++) {
							var zones = zones_list[i];

							for (var j = 0; j < zones.length; j++) {
								zones[j].parentListId = this.selectedZone.id
							}

							//save the results
							if (this.savedListDetectedZones[this.selectedZone.id] == undefined) {
								this.savedListDetectedZones[this.selectedZone.id] = []
							}
							this.savedListDetectedZones[this.selectedZone.id][i + 1] = zones //element at index 0 is the orig list detect
						}

						this.disableMlListSlider = false
						resolve('success')

					}
					else {
						//nothing here
					}
				}).catch((error) => {
					reject(error)
				})
			})
		},

		highlightSelected() {

		},

		getMergingPages() {
			var retval = []
			var currentPage = this.page.page_no
			if (currentPage == 1) {
				return [1]
			}
			else {
				for (var i = 1; i < currentPage; i++) {
					retval.push(i)
				}
			}
			return retval.reverse()
		},

		mergeWithPageChanged(zone) {
			// console.log('in merge with page changed. zone is: ', zone)
			if (zone !== undefined && zone.merge) {
				this.loadingMergeZones = true

				if (zone.mergeOptions === 'current') {
					this.mergeWithZones = []
					for (var i = 0; i < this.zones.length; ++i) {
						if (this.zones[i].type === zone.type && this.zones[i].id !== zone.id) {
							this.mergeWithZones.push(this.zones[i])
						}
					}
					this.loadingMergeZones = false
				}
				else if (zone.mergeOptions === 'page' && zone.mergeWithPageNo > 0) {
					this.getPage({
						payload: {
							key: this.document.key,
							pageno: zone.mergeWithPageNo
						}
					}).then((response) => {
						if (response.page.layout != null) {
							var layout = undefined;
							try {
								layout = JSON.parse(response.page.layout)
							}
							catch (e) {
								// Already in json...
								layout = response.page.layout
							}

							// console.log('layout: ', layout)

							this.mergeWithZones = []
							for (var i = 0; i < layout.length; ++i) {
								if (layout[i].type === zone.type && layout[i].id !== zone.id) {
									// console.log('found a zone of same type in page!')
									this.mergeWithZones.push(layout[i])
								}
							}
							this.loadingMergeZones = false

							// console.log('after mergeWithPageChanged, mergeWithZones is now: ', this.mergeWithZones)
						}
					}).catch((error) => {
						console.log('error getting page: ', error)
					})
				}
			}
			this.updateSelectedZone()

		},

		/*
			Test for get page
		*/
		testGetPage() {
			console.log('in test get page')
			this.getPage({
				payload: {
					key: this.document.key,
					pageno: 3
				}
			}).then((response) => {
				console.log('response is: ', response.page)
				var layout = undefined;
				try {
					layout = JSON.parse(response.page.layout)
				}
				catch (e) {
					// Already in json...
					layout = response.page.layout
				}
				console.log('got page layout: ', layout)
			}).catch((error) => {

			})
		},

		changedSensitivityZone(newSensitivity) {
			let payload = { zone: this.selectedZone, sensitivity: newSensitivity }
			this.$store.commit("page/SET_SENSITIVITY_ZONE", payload)

		},
		changedListItemCount() {
			this.changeListItemCount(this.selectedZone)
		},

		addZoneClass(zClass) {
			EventBus.$emit("add-zone-class", zClass)
		},

		removeZoneClass(zClass) {
			EventBus.$emit("remove-zone-class", zClass)
		},

		zoneSelected(zone) {
			// Autoscroll to image description
			if (zone.type === 'image') {
				setTimeout(function () {
					this.$vuetify.goTo(this.$refs.imageDescription, { container: this.$refs.zoneProperties })
					//this.$refs.imageDescription.focus() //remove per ryan
				}.bind(this), 300)

			}

		},

		/*
		 Adds an extra couple items to the double click event handled in SVGZone
		 */
		zoneDoubleClicked() {
			switch (this.selectedZone.type) {
				case 'field':
					if (this.selectedZone.fieldType_str == 'e_combo') {
						this.editValues()
					}
					break;
				case 'table':
					EventBus.$emit("EDIT_TABLE", {})
					break
			}
		},

		//TODO: placeholder for edit values above
		editValues() {

		},

	},
	mounted() {
		//sent from SVGZone
		EventBus.$on('ADJUST_LIST_SENSITIVITY', (msg) => {
			this.adjustListSensitivity(msg)
		})

		EventBus.$on('type-changed', (msg) => {
			this.zoneTypeChanged()
		})

		EventBus.$on('source-changed', (msg) => {
			this.zoneSourceChanged(msg)
		})

		EventBus.$on('zoneDblClicked', (msg) => {
			this.zoneDoubleClicked()
		})

		EventBus.$on('zoneSelected', (zone) => {
			this.zoneSelected(zone)
		})

		EventBus.$on("callback-ocr", (msg) => {
			// console.log('got a callback on ocr!!!', msg)
			this.callbackOcr(msg)
			this.$forceUpdate()
		})


		//if a page is tagged, we need to add the splitterObjs (shows splitters in editor) where lists have splitters
		if (this.page.is_tagged) {
			this.addSplitterObjsToTaggedLists()
		}

		//test
		//this.testGetPage()
	},

	beforeDestroy() {
		EventBus.$off('ADJUST_LIST_SENSITIVITY')
		EventBus.$off('type-changed')
		EventBus.$off('source-changed')
		EventBus.$off('zoneDblClicked')
		EventBus.$off('callback-ocr')
		EventBus.$off('zoneSelected')
	}

}
</script>

<style lang="scss" scoped>
// .mb1 {
//   margin-bottom: 1px !important;
// }
// .full-height-sheet {
//   height: calc(90vh - 230x);
// }
// .full-height {
//   height: 90vh;
// }

.v-row {
	margin-top: 0px;
	margin-bottom: 0px;
}

.short-text {
	width: 65px;
	max-width: 65px;
}

.side-bar {
	position: absolute;
	top: 5px;
	left: 5px;
	width: 300px;
	padding: 1px;
}

.side-bar-tab {
	padding: 6px;

	&>* {
		font-size: 14px;

		.col {
			padding: 2px 10px;
			min-height: 55px;
		}

		.v-btn {
			margin: 10px;
		}
	}
}

.small-text {
	font-size: .9em;
}

.room-for-scrollbar {
	width: 97%;
}

.section-label {
	white-space: nowrap !important;
	margin-top: 10px;
}

.v-btn.v-size--small {
	margin-top: 1px;
	margin-right: 0px;
	height: 38px;
}

.theme--light.v-card.side-bar-bg {
	background-color: #f3f3f3;
}

.theme--dark.v-card.side-bar-bg {
	background-color: #3c3c3c;
}

.scrolling-tab {
	overflow-y: scroll;
	height: 100vh;
}

.scroll-margin-bottom {
	margin-bottom: 100px;
}

.btn-group-item {
	margin: 0 !important;
	padding: 0 !important;
	color: #003366 !important;
}

.section-divider {
	margin-bottom: 5px;
	margin-top: -31px;
	padding-bottom: 20px;
}
</style>
