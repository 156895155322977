export default {
	appName: "Equidox",
	mainMenu: {
		home: "Inicio",
		documents: "Documentos",
		importDocuments: "Importar documento(s)",
		notifications: "Notificaciones",
		settings: "Configuración",
		help: "Ayuda",
		signOut: "Cerrar sesión"
	},
	userMenu: {
		profile: "Perfil",
		language: "Idioma",
		darkTheme: "Tema oscuro",
		darkThemeHint: "Cambiar a tema oscuro"
	},
	barTitle: {
		dashboard: "Tablero",
		documents: "Documentos",
		document: "Detalle del documento",
		editor: "Página de remediación",
		import: "Importar documento(s)",
		notifications: "Notificaciones",
		settings: "Configuración",
		help: "Ayuda",
		signOut: "Cerrar sesión",
		profile: "Perfil"
	},
	login: {
		signIn: "Iniciar sesión",
		email: "Correo electrónico",
		password: "Contraseña",
		logIn: "Iniciar sesión",
		forgotPassword: "¿Olvidó su contraseña?",
		signInWith: "O inicie sesión con",
		equidoxMainPage: "Página principal de Equidox",
		equidoxTutorials: "Videos tutoriales de Equidox",
		equidoxNewsBlog: "Blog de noticias de Equidox",
		dontHaveAnAccount: "¿No tiene una cuenta? ",
		requestADemo: "Solicitar una demostración",
		msg: {
			invalidUserPwd: "Nombre de usuario o contraseña no válidos",
			errorLoginGoogle: "Error al iniciar sesión con la cuenta de Google: {0}"
		}
	},
	profile: {
		firstName: "Nombre",
		lastName: "Apellido",
		email: "Correo electrónico",
		phone: "Teléfono",
		address: "Dirección (incluida la ciudad y el país)",
		description: "Descripción",
		zoneHighlight: "Ancho de resaltado de zona",
		autosummarizeMode: "Modo de resumen automático",
		outputDefault: "Pestaña predeterminada de salida",
		customerInstance: "Instancia del cliente",
		save: "Actualizar perfil",
		msg: {
			profileSaved: "Perfil guardado"
		}
	},
	documents: {
		recent: "Documentos recientes",
		list: "Lista de documentos",
		resultPerPage: "Resultados por página",
		sortBy: "Ordenar por",
		sortItems: ["Fecha de importación", "Título del documento", "Estatus"],
		filterSearch: "Filtrar búsqueda",
		documentTitle: "Título del documento",
		importedBy: "Importado por",
		addLabel: "Agregar etiqueta",
		edit: "Editar",
		gridView: "Vista de cuadrícula",
		listView: "Vista de lista",
		delete: "Eliminar",
		zoneTransfer: "Transferencia de zona",
		headerConfirmDelete: "¿Estás seguro de que quieres eliminar",
		buttonCancel: "Cancelar",
		buttonOk: "OK",
		headerArchiveOrPermanentDelete: "¿Quieres archivar o eliminar permanentemente",
		buttonArchive: "Archivar",
		buttonPermanentDelete: "Eliminar permanentemente",
		buttonReset: "Restablecer",
	},
	document: {
		gotoPage: "Ir a la página",
		gotoOf: "de",
		showUncheckedPages: "Mostrar páginas sin marcar",
		exportToPDF: "Exportar a pdf",
		share: "Compartir",
		delete: "Eliminar",
		shareDocument: "Compartir documento ",
		cancel: "Cancelar",
		done: "Hecho",
		shareWithOthers: "Compartir con otros",
		selectPeople: "Seleccione las personas con las que desea compartir este documento",
		shareWithGroup: "Compartir con un grupo",
		selectGroup: "Seleccione el grupo con el que desea compartir este documento",
		tabProperties: {
			tabTitle: "Propiedades",
			original: "Documento original",
			title: "Título",
			author: "Autor",
			createdDate: "Fecha de creación",
			lastModificationDate: "Fecha de última modificación",
			producer: "Productor",
			language: "Idioma",
			addLabel: "Agregar etiqueta",
			pages: "Páginas",
			images: "Imágenes",
			metadata: "Metadatos",
			metadataName: "Nombre",
			metadataValue: "Valor",
			addDelete: "Agregar o eliminar metadatos",
			saveProperties: "Guardar propiedades",
			msg: {
				metadataSaved: "Metadatos {0} guardados",
				metadataRemoved: "Metadatos {0} eliminados",
			}
		},
		tabImages: {
			tabTitle: "Imágenes",
			hideAllZones: "Ocultar todas las zonas",
			allVisible: "Todo visible",
			saveImageProperties: "Guardar propiedades de imagen",
			hideThisZone: "Ocultar esta zona en el editor",
			visible: "Visible",
			imageDescription: "Introducir descripción de la imagen",
			previousPage: "Anterior",
			nextPage: "Siguiente",
			imagesTo: "a",
			imagesMany: " de muchos",
			msg: {
				errorGettingImages: "Error al obtener imágenes",
			}
		},
		tabUnicodeMapping: {
			tabTitle: "Asignar caracteres Unicode",
			labelUnicodeCharsRequire: "caracteres requieren asignación Unicode en la página",
			labelUnicodeMappingOnPage: "Asignación Unicode en la página ",
			labelOcrAll: "OCR todo",
			labelSave: "Guardar",
			msg: {
				mappingSavedForPage: "Asignación de caracteres guardado para la página {0}"
			}
		},
		tabZoneTransfer: {
			tabTitle: "Transferencia de zona",
			sourceDocumentID: "ID de documento de origen",
			destinationDocumentID: "ID de documento de destino",
			sourcePage: "Página de origen",
			destinationPage: "Página de destino",
			transferDocumentZones: "Transferir zonas de documentos",
			cancelTransfer: "Cancelar transferencia",
		},
		tabPageNotes: {
			tabTitle: "Notas de página",
			headerPageNo: "Número de página",
			headerNote: "Nota",
			headerResolved: "Resuelto",
			rowsCount: "Filas por página",
			pageOf: "de",
		},
		tabHistory: {
			tabTitle: "Historia",
			viewWarnings: "Ver",
			warnings: "Advertencias",
			warningsOnPage: "Advertencias en la página ",
			msg: {
				errorGettingHistory: "No se puede obtener historial de página",
				errorLoadingWarnings: "No se pueden cargar advertencias",
			}
		},
		tabExport: {
			tabTitle: "Exportar",
			htmlDocument: "Documento HTML",
			selectPageToDownload: "Seleccionar páginas a descargar",
			allPages: "Todo",
			generatePageIDS: "Generar ID de página",
			fixNoSpace: "Arreglo para no espacios",
			flattenFormX: "Aplanar objetos formX",
			pdfOutputEngine: "PDF Output Engine",
			filename: "Nombre de archivo",
			previewHtml: "Vista preliminar del documento HTML",
			downloadHtml: "Descargar HTML",
			downloadZones: "Descargar Zones",
			pdfDocument: "Documento PDF",
			generatePdf: "Generar PDF",
			epubDocument: "Documento EPUB",
			downloadEpub: "Descargar EPub",
			css: "CSS",
			documentZones: "Document Zones",
			msg: {
				unableToExport: "No se puede exportar en este momento, vuelva a intentarlo en un par de minutos",
				errorHtml: "Error al generar html",
				errorEpub: "Error al generar epub",
				errorPDF: "Error al generar pdf",
			}
		},
		msg: {
			updated: "Documento actualizado",
			errorUpdating: "Error al actualizar el documento",
			documentSaved: "Documento guardado",
			permanentlyDeleted: "Documento eliminado permanentemente",
			errorPermanentlyDeleted: "Error al eliminar permanentemente el documento",
			archived: "Documento archivado",
			deleted: "Documento eliminado",
			errorDelete: "Error al eliminar el documento",
			imagesSaved: "Imágenes guardadas",
			queuingPermanentDelete: "Creando cola para eliminar permanentemente el documento",
			sharedUpdated: "Lista de compartidos actualizada",
			errorSharesUpdates: "Error actualizando la lista de compartidos",
		}
	},
	import: {
		dragFilesOrClickHere: "Arrastre archivos o haga clic aquí...",
		enablePageMatch: "Enable ML Page Match",
		msg: {
			maxFileNameLengthMsg: "Error al cargar el archivo. La longitud del nombre de archivo excede {0}. Porfavor cambie el nombre del archivo antes de cargarlo. Nombre de archivo: ",
			maxFileSizeMsg: "El tamaño total del archivo excede el tamaño de importación permitido de {0} MB",
			maxFileCountMsg: "Total file count exceeds allowed import count of {0}",
			onlyPDFZip: "No puede cargar archivos de este tipo. Solo se aceptan archivos PDF o ZIP",
			invalidFileType: "Tipo de archivo no válido",
			importing: "Importando {0} documento(s)"
		}
	},
	page: {
		confirmSaveTitle: "¿Guardar esta página?",
		confirmChangedSaveMsg: "Esta página no se ha guardado. ¿Desea guardar esta página antes de salir de la página?",

		confirmIgnoreZoneTitle: "Ignore (repeated) zone",
		confirmIgnoreZoneChangeMsg: "The ignore zone(s) changed. It will update the zone(s) on all pages. Do you want to commit changes?",

		tableEditor: {
			title: "Editor de tablas",
			msg: {
				errorNoTable: "Sin mesa",
				tableSaved: "Tabla guardada",
				columnAlreadySpanned: "La columna {0} ya ha abarcado elemento(s)",
				rowAlreadySpanned: "La fila {0} ya ha abarcado elemento(s)",
				tableCellsOCRUpdated: "Celdas de tabla actualizadas con texto ocr",
				unableOCRTable: "No se puede detectar ocr para la tabla",
				cellsCustomText: "Celdas de tabla actualizadas con texto personalizado",
				unableTextContent: "No se puede obtener el contenido del texto",
				unableTextContentTable: "No se puede obtener el contenido del texto para la tabla",
				unableToOCR: "No se puede detectar ocr",
			}
		},
		sideBarTable: {
			name: "Nombre",
			caption: "Subtítulo",
			summary: "Resumen",
			columnHeader: "Encabezado de columna",
			rowHeader: "Encabezado de fila",
			column: "Columna",
			row: "Fila",
			tableInformation: "Información de la tabla",
			tableDetector: "Detector de tabla",
			opticalCharacterRecognition: "Reconocimiento óptico de caracteres",
			htmlGeneration: "Generación de HTML",
			rowscolsAttributes: "Atributos de filas / columnas",
			ocrAllCells: "OCR todas las celdas",
			customAllCells: "Personalizar todas las celdas",
			sensitivity: "Sensibilidad",
			autoSummarize: "Autoresumen",
		},
		sideBarCells: {
			noCellSelected: "Ninguna celda seleccionada",
			nudgeLeft: "Empujar todo a la izquierda",
			nudgeRight: "Empujar todo a la derecha",
			nudgeUp: "Empujar todo hacia arriba",
			nudgeDown: "Empujar todo hacia abajo",
			source: "Origen",
			cellSource: "Origen de la celda",
			columnAdjustments: "Ajustes de columna",
			rowAdjustments: "Ajustes de fila",
			columnOperations: "Operaciones de columna",
			spanOperations: "Operaciones de span",
			rowOperations: "Operaciones de fila",
			splitColumn: "Dividir columna",
			mergeRight: "Combinar a la derecha",
			splitRow: "Dividir fila",
			mergeDown: "Combinar hacia abajo",
			spanColumns: "Columnas de span",
			spanRows: "Filas de span",
			removeSpans: "Eliminar span",
			convertToText: "Convertir a texto",
			ocrContent: "Texto OCR",
			customContent: "Texto personalizado",
			actualContent: "Texto real",
		},
		tableToolBar: {
			row: "Fila",
			column: "Columna",
			splitRow: "Dividir fila (R)",
			deleteRow: "Eliminar fila (D)",
			splitColumn: "Dividir columna (C)",
			removeColumn: "Eliminar columna (M)",
			removeSpan: "Eliminar span",
			spanColumns: "Columnas de span",
			spanColumnsS: "Columnas de span (S)",
			spanRows: "Filas de span",
			previewTable: "Vista preliminar de esta tabla",
			saveTable: "¿Guardar tabla?",
			tableNotSaved: "Esta tabla no se ha guardado. ¿Desea guardar esta tabla antes de salir del editor de tablas?",
			yes: "Sí",
			no: "No",
		},
		toolbar: {
			gotoPage: "Ir a la página",
			pageOf: "de",
			sensitivity: "Sensibilidad",
			readingOrder: "Orden de lectura",
			readingOrders: ["1 columna", "2 columnas", "3 columnas", "4 columnas"],
			reOrder: "Reordenar",
			undo: "Deshacer",
			redo: "Rehacer",
			toggleZoneInfo: "Alternar información de zona",
			save: "Guardar",
			preview: "Vista preliminar de esta página",
			fixedPreview: "(Fijo) Vista preliminar de esta página",
			zoomIn: "Acercar",
			zoomOut: "Alejar",
			close: "Cerrar",
			validate: "Validar",
			shortcuts: "Atajos",
			extension: {
				name: "Nombre",
				order: "Orden",
				removeZone: "Eliminar zona",
				newZone: "Nueva zona",
				zoneTag: "Etiqueta de zona",
				zoneSource: "Origen de zona",
				left: "Izquierda",
				width: "Anchura",
				top: "Arriba",
				height: "Altura",
				l: "L",
				w: "W",
				t: "T",
				h: "H",
			},
			messageZoneInfo: "Información de zona actualizada",
			msg: {
				default: "Orden predeterminado de la página (s) establecido en {0}",
				reordered: "La página fue reordenada - {0}",
				markedValidated: "La página fue marcada como validada",
				unmarkedValidated: "La página fue desmarcada como validada",
			}
		},
		sidebarPage: {
			pageInformation: "Información de la página",
			zoneDetector: "Detector de zonas",
			readingOrder: "Orden de lectura",
			page: "Página",
			lastSaved: "Última vez guardado",
			validatedOn: "Validado en",
			validatedBy: "Validado por",
			sensitivity: "Sensibilidad",
			fields: "Campos",
			images: "Images",
			keepZoneTypes: "Keep Zone Types",
			mlPageMatch: "ML Page Match",
			enable: "Enable",
			applyToAll: "Aplicar a todas las páginas",
			automatic: "Automático",
			reOrder: "Reordenar",
			ocr: "Reconocimiento óptico de caracteres de zonas",
			ocr_all: "OCR todas las zonas",
			msg: {
				default: "Orden predeterminado de la página (s) establecido en {0}",
				reordered: "La página fue reordenada - {0}",
				ocrAllProgress: {
					preparing: "Preparando zonas...",
					processing: "Procesando zonas",
					alreadyProcessed: "Todas las zonas ya procesadas",

				}
			}
		},
		sidebarZone: {
			properties: "Propiedades",
			name: "Nombre",
			type: "Tipo",
			language: "Idioma",
			order: "Orden",
			removeZone: "Eliminar zona",
			tag: "Etiqueta",
			indentation: "Sangría",
			merge: "Fusionar",
			breakLines: "Romper líneas",
			anchorProperties: "Propiedades de anclaje",
			anchorID: "ID del ancla",
			blockquoteProperties: "Propiedades de cita en bloque",
			citeProperties: "Propiedades de cita",
			divProperties: "Suborden de lectura",
			divReorderAuto: "Automático",
			divReOrderButton: "Reordenar este div",
			fieldProperties: "Propiedades de campo",
			fieldType: "Tipo de campo",
			fieldTooltip: "Consejo de herramienta",
			fieldLabelZone: "Zona de etiqueta",
			fieldGroupName: "Nombre del grupo",
			fieldVisibility: "Visibilidad",
			fieldReadOnly: "Solo lectura",
			fieldRequired: "Requerido",
			buttonLabel: "Etiqueta del botón",
			fieldAlignment: "Alineación",
			checkSpelling: "Revisar ortografía",
			multiline: "Multi línea",
			scrollLongText: "Desplazar texto largo",
			richText: "Formato de texto enriquecido",
			maxCharacters: "Caracteres máximos",
			checkboxStyle: "Estilo",
			exportValue: "Valor de exportación",
			checkedByDefault: "Marcado por defecto",
			options: "Opciones",
			editValues: "Editar valores",
			optionSort: "Ordenar",
			multiSelect: "Selección múltiple",
			footnoteProperties: "Propiedades de nota al pie",
			footnoteLinkProperties: "Propiedades de enlace de nota al pie",
			toFootnote: "A nota al pie",
			description: "Descripción",
			method: "Método",
			action: "Acción",
			visible: "Visible",
			hideZone: "Ocultar zona",
			descriptionAlt: "Descripción (Alt)",
			longDescription: "Descripción larga",
			level: "Nivel",
			useAsTemplate: "Usar como plantilla",
			enabled: "Habilitado",
			removeUnderlyingZones: "Eliminar zona (s) subyacente (s)",
			title: "Título",
			toAnchor: "URL/Ancla",
			caption: "Subtítulo",
			summary: "Resumen",
			listType: "Tipo de lista",
			listUnordered: "Desordenado",
			listOrdered: "Ordenado",
			styleType: "Tipo de estilo",
			startAt: "Comenzar en",
			listDetector: "Detector de lista",
			tocDetector: "Detector de TOC",
			listSensitivity: "Sensibilidad",
			listItems: "Elementos de la lista",
			tocItems: "Elementos de TOC",
			showListLabel: "Mostrar etiquetas",
			showArtifacts: "Mostrar artefactos",
			listLabel: "Etiqueta de lista",
			pdfAllItems: "PDF todos los elementos de la lista",
			ocrAllItems: "OCR todos los elementos de la lista",
			customAllItems: "Personalizar todos los elementos de la lista",
			tableEditor: "Editor de tablas",
			formProperties: "Propiedades del formulario",
			graphicProperties: "Propiedades gráficas",
			headingProperties: "Propiedades de encabezado",
			ignoreProperties: "Ignorar propiedades",
			imageProperties: "Propiedades de imagen",
			linkProperties: "Propiedades de enlace",
			listProperties: "Propiedades de lista",
			quoteProperties: "Propiedades de cita",
			sensitivityProperties: "Propiedades de sensibilidad",
			tableProperties: "Propiedades de tabla",
			textProperties: "Propiedades de texto",
			tocProperties: "Propiedades de tabla de contenido",
			zoneSource: "Origen de la zona",
			source: "Origen",
			zonePosition: "Posición de la zona",
			left: "Izquierda",
			width: "Anchura",
			top: "Arriba",
			height: "Altura",
			ocrConvert: "Convertir a texto",
			list: "Lista",
			toc: "TOC",
			with: "Con",
			zone: "Zona",
			page: "Página",
			mergeItem: "Combinar elemento",
			linkToPage: "Enlace a la página",
			pageNumber: "Número de página",
			artifactPosition: "Posición del artefacto",
			msg: {
				listItemSourcePDF: "Los elementos de la lista de origen ahora están configurados en PDF",
				listItemSourceCustom: "Los elementos de la lista de origen ahora están configurados en Personalizado",
				unableDetectOCR: "No se puede detectar OCR",
				ocrResultsReturned: "Resultados de OCR devueltos",
				//zone reorder under div
				zonesReordered2Col: "Zones under this div were reordered - 2 columns",
				zonesReordered3Col: "Zones under this div were reordered - 3 columns",
				zonesReordered4Col: "Zones under this div were reordered - 4 columns",
				zonesReorderedDefault: "Zones under this div were reordered - default",
			}
		},
		msg: {
			saved: "Página fue guardada"
		}
	},
	settings: {
		tabLabels: {
			tabTitle: "Etiquetas",
			headerColor: "Color",
			headerName: "Nombre",
			headerDescription: "Descripción",
			headerGlobal: "Global",
			headerCreateNewLabel: "Crear una nueva etiqueta",
			headerEditDelete: "Editar o eliminar etiqueta",
			headerExistingLabels: "Etiquetas existentes",
			buttonNewLabel: "Nueva etiqueta",
			buttonCancel: "Cancelar",
			buttonSave: "Guardar",
			buttonReset: "Reiniciar",
			buttonOk: "OK",
			headerConfirmDelete: "¿Estás seguro de que quieres eliminar esta etiqueta?",
			labelName: "Nombre",
			labelDescription: "Descripción",
			labelColor: "Color",
			msg: {
				labelDeleted: "Etiqueta eliminada",
				labelUpdated: "Etiqueta actualizada",
				labelCreated: "Etiqueta creada",
				errorSavingLabel: "Error al guardar la etiqueta: {0}",
				unableToGetLabels: "No se pueden obtener etiquetas",
			}
		},
		tabGroups: {
			tabTitle: "Grupos",
			headerName: "Nombre",
			headerDescription: "Descripción",
			headerUserCount: "Recuento de usuarios",
			headerUsers: "Usuarios",
			headerCreateNewGroup: "Crear un nuevo grupo",
			headerEditDelete: "Editar o eliminar grupo",
			headerExistingGroups: "Grupos existentes",
			buttonNewGroup: "Nuevo grupo",
			buttonAddUser: "Añadir usuario",
			buttonCancel: "Cancelar",
			buttonSave: "Guardar",
			buttonReset: "Reiniciar",
			buttonOk: "OK",
			headerConfirmDelete: "¿Estás seguro de que quieres eliminar este grupo?",
			labelName: "Nombre",
			labelDescription: "Descripción",
			labelUser: "Usuario",
			msg: {
				groupDeleted: "Grupo eliminado",
				groupUpdated: "Grupo actualizado",
				groupCreated: "Grupo creado",
				errorSavingGroup: "Error al guardar el grupo: {0}",
				unabletoGetGroups: "No se pueden obtener grupos",
			}
		},
		tabAccounts: {
			tabTitle: "Cuentas",
			headerName: "Nombre",
			headerCreateNewAccount: "Crear una nueva cuenta",
			headerAccounts: "Cuentas",
			headerEditDelete: "Editar o eliminar cuenta",
			headerExistingAccounts: "Cuentas existentes",
			buttonNewAccount: "Nueva cuenta",
			buttonAddAccount: "Añadir cuenta",
			buttonCancel: "Cancelar",
			buttonSave: "Guardar",
			buttonReset: "Reiniciar",
			buttonOk: "OK",
			headerConfirmDelete: "¿Estás seguro de que quieres eliminar esta cuenta?",
			headerEmail: "Correo electrónico",
			headerGroup: "Grupo",
			headerActivated: "Activado",
			headerGoogleAccount: "Cuenta de Google",
			headerAdministrator: "Administrador",
			headerResetPasswordFor: "Restablecer contraseña para",
			headerResetPassword: "Restablecer contraseña",
			labelPassword: "Contraseña",
			labelConfirmPassword: "Confirmar contraseña",
			labelEmail: "Correo electrónico",
			labelGroup: "Grupo",
			msg: {
				accountDeleted: "Cuenta eliminada",
				accountUpdated: "Cuenta actualizada",
				accountAdded: "Cuenta agregada",
				passwordChanged: "Contraseña cambiada",
				errorAddingAccount: "Error al agregar cuenta: {0}",
				errorSavingAccount: "Error al guardar la cuenta: {0}",
				errorSavingPassword: "Error al guardar la contraseña: {0}",
				unableToGetAccount: "No se pueden obtener cuentas",
				unableToGetGroups: "No se pueden obtener grupos",
			}
		},
		tabCss: {
			tabTitle: "CSS",
			headerName: "Nombre",
			headerUrl: "Url",
			headerDefault: "Defecto",
			headerCreateCss: "Crear un nuevo css",
			headerEditDelete: "Editar o eliminar CSS",
			headerExistingCss: "CSS existente",
			buttonNewCss: "Nuevo CSS",
			buttonCancel: "Cancelar",
			buttonSave: "Guardar",
			buttonReset: "Reiniciar",
			buttonOk: "OK",
			headerConfirmDelete: "¿Estás seguro de que quieres eliminar este css?",
			labelName: "Nombre",
			labelUrl: "Url",
			msg: {
				deleted: "CSS eliminado",
				updated: "CSS actualizado",
				created: "CSS creado",
				errorSaving: "Error al guardar css: {0}",
				errorCreating: "Error al crear css: {0}",
				unableToGetCSS: "No se puede obtener la lista de css",
			}
		},
		tabDocumentExport: {
			tabTitle: "Exportación de documentos"
		},
		tabZoneTransfer: {
			tabTitle: "Transferencia de zona",
			headerDownloadDocumentZones: "Descargar zonas de documentos",
			headerUploadDocumentZones: "Cargar zonas de documentos",
			headerDocumentZoneTransfer: "Transferencia de zona de documentos",
			headerPageZoneTransfer: "Transferencia de zona de página",
			buttonTransferDocumentZones: "Transferir zonas de documentos",
			buttonCancel: "Cancelar",
			buttonClose: "Cerrar",
			buttonBack: "Atrás",
			buttonLoadPageOptions: "Cargar opciones de página",
			buttonTransferPageZones: "Transferir zonas de página",
			buttonUploadZones: "Cargar zonas",
			buttonDownloadZones: "Descargar zonas",
			documentId: "ID de documento",
			labelSourceDocumentId: "ID de documento de origen",
			labelDestinationDocumentId: "ID de documento de destino",
			labelZoneTransferType: "Tipo de transferencia de zona",
			labelSourcePage: "Página de origen",
			labelDestinationPages: "Páginas de destino",
			labelChooseFile: "Elegir archivo",
			labelSelectSourcePage: "Seleccionar página de origen",
			labelSelectDestinationPage: "Seleccionar página(s) de destino",
			labelSelectLayoutZipFile: "Seleccionar archivo zip de diseño",
			labelDestinationDocumentName: "Nombre del documento de destino",
			msg: {
				docInitiated: "Transferencia de zona de documento iniciada",
				errorDocTransferring: "Error al transferir zonas de documentos: {0}",
				pageInitiated: "Transferencia de zona de página iniciada",
				errorPageTransferring: "Error al transferir zonas de página: {0}",
				zonesInitiated: "Transferencia de zonas cargadas iniciada",
				errorZonesTransferring: "Error al transferir zonas cargadas: {0}",
				downloadInitiated: "Descargar zonas iniciadas",
				errorDownload: "Error al descargar zonas: {0}"
			}
		},
	},
	dashboard: {
		groups: "Grupos",
		allGroups: "Todos los grupos",
		activities: "Actividades",
		users: "Usuarios",
		usersActive: "Activo",
		documents: "Documentos",
		documentsImported: "Importado",
		documentsActive: "Activo",
		documentsDeleted: "Eliminado",
		pages: "Páginas",
		pagesActive: "Activo",
		pagesModified: "Modificado",
		pagesValidated: "Validado",
		progress: "Progreso",
		exportedPDF: "PDFs exportados",
		validatedPercentage: "Porcentaje validado",
		reportTypes: {
			thisWeek: "Esta semana",
			lastWeek: "La semana pasada",
			thisMonth: "Este mes",
			lastMonth: "El mes pasado",
			thisYear: "Este año",
			lastYear: "El año pasado",
			last30Days: "Últimos 30 días",
			last60Days: "Últimos 60 días",
			last90Days: "Últimos 90 días",
			last180Days: "Últimos 180 días",
			last365Days: "Últimos 365 días",
			sinceOnline: "Desde en línea",
		},
		months: {
			jan: "Ene",
			feb: "Feb",
			mar: "Mar",
			apr: "Abr",
			may: "Mayo",
			jun: "Jun",
			jul: "Jul",
			aug: "Ago",
			sep: "Sep",
			oct: "Oct",
			nov: "Nov",
			dec: "Dic"
		}
	}
}