<template>
	<div :class="sidebarPageList">
		<v-btn class="sidebar-button" elevation="1" @click="toggleSideBar">
			<v-icon v-if="sidebarVisible" class="toggle-icon" large>mdi-menu-right</v-icon>
			<v-icon v-if="!sidebarVisible" class="toggle-icon" large>mdi-menu-left</v-icon>
		</v-btn>

		<div :class="showSecondToolbar ? 'side-bar-pages vertical-container': 'side-bar-pages-up vertical-container-up'" v-if="documentPages && Array.isArray(documentPages)">
			<template v-for="(page, index) in documentPages">
				<v-hover v-slot="{ hover }" :key="index">
					<v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" class="pa-5 mr-5 text-center" min-width="220"
						max-width="240" min-height="300" height="320" tile>
						<router-link v-if="document" link :to="'/document/' + document.key + '/page/' + page.page_no">
							<v-img :src="'/api/v1/thumbnail/' + page.thumbnail_url" :class="[
								{ 'page-saved-img-border': page.last_saved_by },
								`elevation-${hover ? 24 : 6}`,
							]"></v-img>

							<!-- <v-badge
                  avatar
                  left
                  color="primary"
                  icon="mdi-check-bold"
                  overlap
                  :value="page.validated"
                > -->

							<!-- </v-badge> -->
							<span class="validated-corner" v-if="page.validated">
								<v-icon class="validated-check mr-3">mdi-check-bold</v-icon>
							</span>
						</router-link>
						<h4 class="pt-1">{{ page.page_no }}</h4>
					</v-card>
				</v-hover>
			</template>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import EventBus from "@/eventBus"

export default {
	props: ["page", "document"],
	data() {
		return {
			isLoading: true,
			errors: null,
			sidebarVisible: true,
		}
	},
	components: {},
	computed: {
		...mapGetters({
			documentPages: "document/getDocumentPages",
			language: "language/getLanguage",
			showSecondToolbar: "application/getShowSecondToolbar",
		}),
		sidebarPageList() {
			if (this.showSecondToolbar) {
				if (this.sidebarVisible) {
					return {
						"sidebar-page-list": true,
					}
				} else {
					return {
						"sidebar-page-list-hidden": true,
					}
				}
			}
			else { //not showing
				if (this.sidebarVisible) {
					return {
						"sidebar-page-list-up": true,
					}
				} else {
					return {
						"sidebar-page-list-hidden-up": true,
					}
				}
			}


		},
	},
	methods: {
		...mapActions({
			fetchDocumentPages: "document/fetchDocumentPages",
		}),
		toggleSideBar() {
			console.log("Side bar toggle")
			this.sidebarVisible = !this.sidebarVisible
			this.$emit('sideBarToggled', this.sidebarVisible)
		},
		showSideBar() {
			this.sidebarVisible = true
		},
	},
	mounted() {
		this.fetchDocumentPages({ payload: { key: this.$route.params.key } })
			.then((response) => {
				this.isLoading = false
			})
			.catch((error) => {
				this.isLoading = false
			})

		EventBus.$on("show-pages-sidebar", (msg) => {
			this.showSideBar()
		})
	},
	beforeDestroy() {
		EventBus.$off('show-pages-sidebar')
	},

}
</script>
<style lang="scss" scoped>
.vertical-container {
	margin: 10px 0;
	padding: 15px 10px;
	display: flex;
	overflow-x: auto;
	border: 1px #77777761 solid;
}

.vertical-container-up {
	margin: 10px 0;
	padding: 15px 10px;
	display: flex;
	overflow-x: auto;
	border: 1px #77777761 solid;
}

.theme--light.v-application .side-bar-pages {
	background-color: #f3f3f3;
	padding-bottom: 229px;
}

.theme--light.v-application .side-bar-pages-up {
	background-color: #f3f3f3;
	padding-bottom: 295px;
}

.theme--dark.v-application .side-bar-pages {
	background-color: #3c3c3c;
	padding-bottom: 229px;
}

.theme--dark.v-application .side-bar-pages-up {
	background-color: #3c3c3c;
	padding-bottom: 295px;
}

.sidebar-page-list {
	margin-top: 0;
	position: fixed;
	padding: 1px 0px 0px 20px;
	top: 233px; //was 233 
	right: -3px;
	width: 254px;
	height: 100% !important;
	border-left: 1px solid lightgray;
	display: flex;
	flex-direction: column;
}

.sidebar-page-list-up {
	margin-top: 0;
	position: fixed;
	padding: 1px 0px 0px 20px;
	top: 167px;
	right: -3px;
	width: 254px;
	height: 100% !important;
	border-left: 1px solid lightgray;
	display: flex;
	flex-direction: column;
}

.sidebar-page-list-hidden {
	margin-top: 0;
	position: fixed;
	padding: 1px 0px 0px 20px;
	top: 233px;
	right: 0px;
	width: 30px;
	height: 100% !important;
	border-left: 1px solid lightgray;
	display: flex;
	flex-direction: column;
}

.sidebar-page-list-hidden-up {
	margin-top: 0;
	position: fixed;
	padding: 1px 0px 0px 20px;
	top: 167px;
	right: 0px;
	width: 30px;
	height: 100% !important;
	border-left: 1px solid lightgray;
	display: flex;
	flex-direction: column;
}

.sidebar-page-list .side-bar-pages {
	margin-top: 0;
	position: absolute;
	padding: 0px;
	top: 0px;
	right: 0px;
	width: 224px;
	height: 100% !important;
	border-left: 1px solid lightgray;
	display: flex;
	flex-direction: column;
}
.sidebar-page-list-up .side-bar-pages-up {
	margin-top: 0;
	position: absolute;
	padding: 0px;
	top: 0px;
	right: 0px;
	width: 224px;
	height: 100% !important;
	border-left: 1px solid lightgray;
	display: flex;
	flex-direction: column;
}

.sidebar-page-list-hidden .side-bar-pages {
	right: -224px;
	transition: all 0.5s ease-in-out;
}

.side-bar-pages>div.v-window {
	overflow: auto !important;
}

.side-bar-pages-up>div.v-window {
	overflow: auto !important;
}

.side-bar-pages>>>.v-window__container,

.side-bar-pages-up>>>.v-window__container,

.side-bar-pages>>>.v-window-item {
	height: 100%;
}

.side-bar-pages-up>>>.v-window-item {
	height: 100%;
}

/* customise the dimensions of the card content here */
.side-bar-pages>>>.v-card {
	height: 100%;
}

.side-bar-pages-up>>>.v-card {
	height: 100%;
}

.sidebar-button {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 30px;
	height: 87vh !important;
}

.toggle-icon {
	margin-left: -34px;
}

.page-saved-img-border {
	border: 2px solid var(--v-primary-base) !important;
}

.validated-corner {
	background-color: transparent !important;
	color: blue;
	right: auto;
	left: 0;
	top: 0;
	border-color: green;
	display: block;
	position: absolute !important;
	height: 48px;
	width: 48px;
	text-align: center;
	z-index: 1;
	float: left;
}

.validated-check {
	background-color: var(--v-primary-base);
	color: var(--v-primary-lighten5);
}

.validated-corner:after {
	border-top: 4em solid transparent;
	border-right: 4em solid transparent;
	border-bottom: 0 solid transparent;
	border-left: 0 solid transparent;
	border-top-color: inherit;
}
</style>
