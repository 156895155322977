import Profile from '../components/Profile'

export default [
    {
        path: '/profile',
        component: Profile,
        name: 'Profile',
        meta: {
            requiresAuth: true,
            i18Msg: 'barTitle.profile'
        }
    }
]