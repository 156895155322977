<template>
  <v-tab-item key="pageNotes">
  
    <v-container class="ma-10 tab-document">
  
      <v-data-table 
        :headers="headers"
        :footer-props="{itemsPerPageText: footerText } "
        :items="documentNotes" class="elevation-1"
        :item-class="rowClass"
        @click:row="handleClick">

        <template v-slot:item.resolved="{ item }">
          <v-simple-checkbox :ripple="false" v-model="item.resolved" key=item.id></v-simple-checkbox>
        </template>
        <template v-slot:footer.page-text="items">
           {{ pageStart }} - {{ pageStop }} {{ $t("document.tabPageNotes.pageOf") }} {{ items.length }} 
        </template>

      </v-data-table>

    </v-container>
  </v-tab-item>

</template>
<script>
import { mapGetters, mapActions } from "vuex"
import router from "../../../../router"
import i18n from "@/plugins/i18n"

export default {
  props: ["document"],
  data() {
    return {
      pageStart: 1,
      pageStop: 1,
      headers: [
        {
          text: this.$t("document.tabPageNotes.headerPageNo"),
          align: "start",
          value: "page_no",
        },
        { 
          text: this.$t("document.tabPageNotes.headerNote"),
          sortable: false,
          value: "notes" },
        { 
          text: this.$t("document.tabPageNotes.headerResolved"),
          value: "resolved" 
        },
      ],
      footerText: this.$t("document.tabPageNotes.rowsCount"),
    }
  },
  components: {},
  computed: {
    ...mapGetters({
      documentNotes: "document/getDocumentNotes",
    }),
  },
  methods: {
    ...mapActions({
      fetchDocumentNotes: "document/fetchDocumentNotes",
    }),
    handleClick(value) {
      return router.push({ path: '/document/' + this.document.key + '/page/' + value.page_no})
    },
    rowClass(item) {
        const rowClass = 'row-pointer'
        return rowClass
    }
  },
  mounted() {
    this.fetchDocumentNotes({ payload: { key: this.$route.params.key } })
      .then((response) => {
        this.isLoading = false

      })
      .catch((error) => {
        this.isLoading = false
        // this.$snackbar.showMessage({ content: 'Unable to get page notes', color: 'error' })
      })
  },  
}
</script>
<style>

.row-pointer {
  cursor: pointer;
}
</style>

