<template>
	<v-sheet class="pa-3 mt-5 fill-height">
		<v-toolbar elevation="2">
			<v-toolbar-title class="h2-title">
				<h2>{{ $t("documents.recent") }}</h2>
			</v-toolbar-title>
		</v-toolbar>

		<!-- Recent documents -->
		<div v-if="isLoading" class="horizontal-container">
			<v-row class="pt-5 pa-3">
				<v-skeleton-loader v-for="index in 5" :key="index" elevation="2" class="pa-5 mr-5 mb-5" min-width="520"
					max-width="520" min-height="380" type="card"></v-skeleton-loader>
			</v-row>
		</div>
		<div v-else class="horizontal-container">
			<DocumentGridCard v-for="document in recentDocuments" :key="document.doc_key" :document="document" />
		</div>

		<v-toolbar elevation="2">
			<v-toolbar-title class="h2-title">
				<h2>{{ $t("documents.list") }}</h2>
			</v-toolbar-title>
		</v-toolbar>

		<v-toolbar extended :extension-height="labelFilters.length > 2 ? 40 : 0">
			<v-toolbar-items>
				<v-card class="d-flex align-center align-content-center" elevation="0">
					<v-select v-model="resultSize" :label="$t('documents.resultPerPage')" :items="resultSizes" class="mt-5 ml-2 mr-3"
						style="max-width:100px;" dense item-text="name" item-value="id"></v-select>
				</v-card>

				<v-divider class="mx-2" vertical></v-divider>

				<!-- Here is the query box for searching -->
				<v-card class="d-flex align-center align-content-center flex-no-wrap pa-3 mt-4" elevation="0">
					<v-text-field class="pt-1" dense :label="$t('documents.filterSearch')" solo append-icon="mdi-magnify"
						style="width:325px;" v-model="query" @click:append="resetAndGetDocsOnTimer"
						@input="resetAndGetDocsOnTimer"></v-text-field>
				</v-card>

				<!-- testing search criteria-->
				<v-card class="mr-0 ml-1 flex-nowrap" elevation="0">
					<span class="text-no-wrap body-2 font-weight-light">search by</span>
					<v-radio-group v-model="queryType" row style="width:170px;">
						<v-radio value="name">
							<template v-slot:label>
								<div>title</div>
							</template>
						</v-radio>
						<v-radio value="owner">
							<template v-slot:label>
								<div>owner</div>
							</template>
						</v-radio>
					</v-radio-group>
				</v-card>

				<v-divider class="mx-2" vertical></v-divider>
				<!-- end testing search criteria -->

				<v-card class="d-flex align-center align-content-center flex-no-wrap pa-3" elevation="0" style="width:150px;">
					<v-select v-model="sortBy" :items="$t('documents.sortItems')" :label="$t('documents.sortBy')" class="mt-5"
						style="max-width:150px;" dense item-text="name" item-value="id" @change="updateSort"></v-select>
				</v-card>

				<v-card class="d-flex align-content-center mt-1 ml-2" elevation="0">
					<LabelsFilterDropdown />
				</v-card>

				<v-card elevation="0" class="pa-2 mt-2 ml-2">
					<v-btn simple small @click="reset">{{ $t("documents.buttonReset") }}</v-btn>
				</v-card>
			</v-toolbar-items>

			<v-spacer />

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" @click="toggleView('grid')" :aria-label="$t('documents.gridView')">
						<v-icon>mdi-view-grid-outline</v-icon>
					</v-btn>
				</template>
				<span>{{ $t("documents.gridView") }}</span>
			</v-tooltip>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" @click="toggleView('list')" :aria-label="$t('documents.listView')"
						class="mr-2">
						<v-icon>mdi-format-list-text</v-icon>
					</v-btn>
				</template>
				<span>{{ $t("documents.listView") }}</span>
			</v-tooltip>

		</v-toolbar>

		<v-sheet elevation="1" v-if="currentView === 'grid'">
			<v-row v-if="isLoading" class="pt-5 pa-3">
				<v-skeleton-loader v-for="index in 5" :key="index" elevation="2" class="pa-5 mr-5 mb-5" min-width="520"
					max-width="520" min-height="380" type="card"></v-skeleton-loader>
			</v-row>

			<v-row v-else class="pt-5 pa-3">
				<DocumentGridCard v-for="document in documents" :key="document.doc_key" :document="document" />
			</v-row>
		</v-sheet>

		<v-sheet elevation="1" v-if="currentView === 'list'">
			<DocumentListCard v-for="document in documents" :key="document.doc_key" :document="document" />
		</v-sheet>


		<v-toolbar>
			<v-toolbar-items>
				<v-btn class="ma-1" text v-if="hasPrevious" @click="previousPage">
					<v-icon left>
						mdi-arrow-left
					</v-icon>
					Previous
				</v-btn>

				<v-btn class="ma-1" text v-if="hasNext" @click="nextPage">
					Next
					<v-icon right>
						mdi-arrow-right
					</v-icon>
				</v-btn>
			</v-toolbar-items>
		</v-toolbar>
	</v-sheet>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import DocumentGridCard from "./DocumentGridCard"
import DocumentListCard from "./DocumentListCard"
import LabelsFilterDropdown from "@/components/LabelsFilterDropdown"

import i18n from "@/plugins/i18n"

export default {
	data() {
		return {
			isLoading: true,
			errors: null,
			currentView: "grid",
			search: null,
			orderDirection: "asc",
			resultsPerPage: "",
			//new copied from controller
			resetAndGetTimer: null,
			documentsLoaded: false,
			MAX_LABELS: 1,
			NUM_RECENT_DOCS: 5,
			hasNext: false,
			hasPrevious: false,
			previousDirection: "forward",
			labelOptions: [],
			resultSize: 25,
			query: "",
			queryType: "name",
			currentResultsPage: 0, //current page of documment results
			searchPagesOffsets: {}, //the page no of results sets and the offset we needed to get each page
			sortBy: null,
			sortByList: [{ id: "last_saved_on", name: "" },
			{ id: "imported_on", name: "Imported Date" },
			{ id: "title", name: "Title" },
			{ id: "assignee", name: "Owner" },
			{ id: "name", name: "Filename" },
			{ id: "label_key", name: "Label" }],
			resultSizes: [{ id: 25, name: "25" },
			{ id: 50, name: "50" },
			{ id: 100, name: "100" }]
		}
	},
	components: {
		DocumentGridCard,
		DocumentListCard,
		LabelsFilterDropdown,
	},
	computed: {
		...mapGetters({
			recentDocuments: "documents/getRecentDocuments",
			documents: "documents/getDocuments",
			language: "language/getLanguage",
			userChannel: "user/userChannel",
			labelFilters: "documents/getLabelFilters",
			csrf_token: "user/getCSRFToken",
		}),
	},
	/*
	this listens for the labelFilters value to change by listening to the getter.
	when the LabelsFilterDropdown changes, the values will be picked up here
	*/
	watch: {
		'labelFilters': function (newVal, oldVal) {
			this.resetAndGetDocs()
		},
		userChannel: function (channelInfo) {
			// token needs to be loaded before getting documents
			this.getRecentDocuments()
			this.getDocuments('forward')
			this.fetchLabels()
		},

	},
	methods: {
		...mapActions({
			fetchDocuments: "documents/fetchDocuments",
			fetchRecentDocuments: "documents/fetchRecentDocuments",
			fetchLabels: "user/fetchLabels",
			fetchChannel: 'user/fetchUserChannel',
		}),
		...mapMutations({
			setLabelFilters: "documents/SET_LABEL_FILTERS",
		}),
		updateSort() {
			// console.log('sortBy: ' + this.sortBy)
			if (this.sortBy == "Imported Date") {
				this.documents.sort((a, b) => {
					let fa = a.imported_on
					let fb = b.imported_on
					if (fa < fb) {
						return -1
					}
					if (fa > fb) {
						return 1
					}
					return 0
				})
			}
			else if (this.sortBy == "Document title") {
				this.documents.sort((a, b) => {
					let fa = a.title != null ? a.title.toLowerCase() : ''
					let fb = b.title != null ? b.title.toLowerCase() : ''
					if (fa < fb) {
						return -1
					}
					if (fa > fb) {
						return 1
					}
					return 0
				})
			}
			else if (this.sortBy == "Status") {
				this.documents.sort((a, b) => {
					let fa = a.status
					let fb = b.status
					if (fa < fb) {
						return -1
					}
					if (fa > fb) {
						return 1
					}
					return 0
				})
			}

		},

		toggleView(view) {
			this.currentView = view
		},
		zoneTransfer() {

		},
		resetAndGetDocsOnTimer() {
			clearTimeout(this.resetAndGetTimer)
			this.resetAndGetTimer = setTimeout(this.resetAndGetDocs, 1500)
		},
		resetAndGetDocs() {
			this.currentResultsPage = 0
			this.searchPagesOffsets = {}
			this.hasNext = false
			this.hasPrevious = false
			this.previousDirection = 'forward'
			this.sortBy = null
			this.getDocuments('forward')
		},
		reset() {
			this.setLabelFilters([])
			this.query = ""
			this.currentResultsPage = 0
			this.searchPagesOffsets = {}
			this.hasNext = false
			this.hasPrevious = false
			this.previousDirection = 'forward'
			this.getDocuments('forward')
		},
		previousPage() {
			this.getDocuments('reverse')
		},
		nextPage() {
			this.getDocuments('forward')
			if (this.hasPrevious == false) {
				this.hasPrevious = true
			}
		},
		applyLabelSelectors() {

		},
		refresh() {
			this.getDocuments('forward')
		},
		getRecentDocuments() {
			this.fetchRecentDocuments({
				payload: {
					direction: 'forward',
					currentResultsPage: 0,
					num_docs: this.NUM_RECENT_DOCS,
					csrf_token: this.csrf_token
				}
			}).then((response) => {
				// console.log('get recent documents response: ', response)
			}).catch((error) => {
				this.isLoading = false
			})

		},
		//main method to get the paged documents
		getDocuments(direction) {

			var payload = {
				direction: direction,
				currentResultsPage: this.currentResultsPage,
				labelKeys: this.labelFilters,
				num_docs: this.resultSize,
				csrf_token: this.csrf_token
			}

			if (this.query.length > 0) {
				payload['query'] = this.query
				payload['queryType'] = this.queryType
				payload['search_offset'] = this.searchPagesOffsets
			}

			this.fetchDocuments({
				payload: payload
			}).then((response) => {
				// console.log('get documents response: ', response)
				this.isLoading = false
				this.currentResultsPage = response.data.data.next_page
				// console.log('currentResultsPage: ' + this.currentResultsPage)
				this.hasPrevious = this.currentResultsPage > 1
				this.hasNext = response.data.data.more
				this.applyLabelSelectors()
				this.documentsLoaded = true
				if (response.data.data.search_offset != null) {
					this.searchPagesOffsets[this.currentResultsPage] = response.data.data.search_offset
				}
			}).catch((error) => {
				this.isLoading = false
			})
		},
	},
	inject: {
		theme: {
			default: { isDark: false },
		},
	},
	mounted() {
		this.fetchChannel()
	},
}
</script>

<style lang="scss" scoped>
.horizontal-container {
	margin: 10px 0;
	padding: 15px 2px;
	display: flex;
	min-height: 410px;
	overflow-x: auto;
}
</style>
