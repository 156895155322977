import HTTP from "@/helpers/http-common"

export default {
	async fetchDocument({ dispatch, commit }, { payload }) {

		return HTTP.get("api/v1/document/" + payload.key)
			.then((response) => {
				if (response.data) {
					// console.log('got document: ', response.data.data)
					commit("SET_DOCUMENT", response.data.data)
				}
			})
	},
	async fetchDocumentPages({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {
			var endpoint
			var params = { unchecked: payload.unchecked }
			//need to make sure we're calling the correct endpoint based on the state
			if (payload.pageStart == null || payload.pageStart == undefined) {
				endpoint = "api/v1/document/" + payload.key + "/pages"
			}
			else {
				endpoint = "api/v1/document/" + payload.key + "/pages/" + payload.pageStart
			}

			HTTP.get(endpoint, { params: params })
				.then((response) => {
					if (response.data) {
						var itemsReturned = response.data.data.length
						// console.log('have data: ' + itemsReturned)
						//do we set the pages or add them?  if page start is greater than 1, then we already have pages
						if (payload.pageStart > 1) {
							// console.log('adding the pages')
							commit("ADD_DOCUMENT_PAGES", response.data.data)
						}
						else { //otherwise, we're getting them
							// console.log('setting the pages')
							commit("SET_DOCUMENT_PAGES", response.data.data)
						}
						var lastPage = response.data.data[response.data.data.length - 1].page_no
						// console.log('last page retrieved: ' + lastPage)
						resolve({ itemsReturned: itemsReturned, lastPage: lastPage })
					}
				}).catch((error) => {
					console.log('error fetching document pages', error)
					reject(error)
				})
		})

	},
	async fetchDocumentImages({ dispatch, commit }, { payload }) {

		var params = { page: payload.page !== undefined ? payload.page : 1 }

		return HTTP.get("api/v1/document/" + payload.key + "/images", { params: params })
			.then((response) => {
				if (response.data) {
					commit("SET_IMAGES", response.data.data)
				}
			})
		// .catch((error) => {
		//   console.log(error);

		//   if (error.response !== undefined) {
		//     // return Promise.reject(error)
		//     return error.response.status;
		//   } else {
		//     return 500;
		//     // return Promise.reject(500)
		//   }
		// });
	},
	async fetchMetadatas({ dispatch, commit }, { payload }) {

		return HTTP.get("api/v1/document/" + payload.key + "/metadatas")
			.then((response) => {
				if (response.data) {
					commit("SET_METADATAS", response.data.data)
				}
			})
		// .catch((error) => {
		//   console.log(error);

		//   if (error.response !== undefined) {
		//     // return Promise.reject(error)
		//     return error.response.status;
		//   } else {
		//     return 500;
		//     // return Promise.reject(500)
		//   }
		// });
	},
	async addMetadata({ dispatch, commit }, { payload }) {

		return HTTP.put("api/v1/document/" + payload.key + "/metadatas/new", payload.newMetadata)
			.then((response) => {
				commit("ADD_METADATA", response.data.data)
			})
		// .catch((error) => {
		//   console.log(error);

		//   if (error.response !== undefined) {
		//     // return Promise.reject(error)
		//     return error.response.status;
		//   } else {
		//     return 500;
		//     // return Promise.reject(500)
		//   }
		// });
	},
	async deleteMetadata({ dispatch, commit }, { payload }) {

		return HTTP.delete("api/v1/document/" + payload.key + "/metadatas/" + payload.metadataKey)
			.then((response) => {
				commit("DELETE_METADATA", payload.metadataKey)
			})
		// .catch((error) => {
		//   console.log(error);

		//   if (error.response !== undefined) {
		//     // return Promise.reject(error)
		//     return error.response.status;
		//   } else {
		//     return 500;
		//     // return Promise.reject(500)
		//   }
		// });
	},
	// I don't think this works because you can't pass the document object itself
	async saveDocumentProperties({ dispatch, commit }, { payload }) {
		// console.log('Saving properties', payload)

		return HTTP.put("api/v1/document/" + payload.document.key, payload.document)
			.then((response) => {
				if (response.data) {
					commit("SET_DOCUMENT", payload.document)
				}
			})
	},

	async shareDocument({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {

			HTTP.put("api/v1/share/" + payload.doc_key,
				{
					users: payload.users,
					groups: payload.groups,
				}).then((response) => {
					commit("SET_DOCUMENT", payload.document)
					resolve('success')
				}).catch((error) => {
					reject('error')
				})
		})
	},

	//creating alternate to saveDocumentProperties with a promise

	async updateDocument({ dispatch, commit }, { payload }) {
		// console.log('in updateDocument', payload.document)

		return new Promise((resolve, reject) => {

			HTTP.put("api/v1/document/" + payload.document.key, {
				label_keys: payload.document.label_keys,
				title: payload.document.title,
				language: payload.document.language,
				assigned_to: payload.document.assigned_to,
			}
			).then((response) => {
				// console.log('update doc response:', response)
				if (response.data) {
					commit("SET_DOCUMENT", payload.document)
				}
				resolve(response)
			}).catch((error) => {
				console.log('update doc error:', error)
				reject(error)
			})
		})
	},
	async saveDocumentImages({ dispatch, commit }, { payload }) {
		// console.log('Saving images', payload)

		return HTTP.post("api/v1/document/" + payload.key + "/images", payload.images)
			.then((response) => {
				if (response.data) {
					commit("SET_DOCUMENT", payload.document)
				}
			})
	},
	async fetchDocumentNotes({ dispatch, commit }, { payload }) {

		return HTTP.get("api/v1/document/" + payload.key + "/pagenotes")
			.then((response) => {
				if (response.data) {
					commit("SET_PAGENOTES", response.data.data)
				}
			})
		// .catch((error) => {
		//   console.log(error);

		//   if (error.response !== undefined) {
		//     // return Promise.reject(error)
		//     return error.response.status;
		//   } else {
		//     return 500;
		//     // return Promise.reject(500)
		//   }
		// });
	},
	async fetchDocumentHistory({ dispatch, commit }, { payload }) {

		return HTTP.get("api/v1/document/" + payload.key + "/history")
			.then((response) => {
				if (response.data) {
					// console.log(response.data)
					commit("SET_DOCUMENT_HISTORY", response.data.data)
				}
			})
		// .catch((error) => {
		//   console.log(error);

		//   if (error.response !== undefined) {
		//     // return Promise.reject(error)
		//     return error.response.status;
		//   } else {
		//     return 500;
		//     // return Promise.reject(500)
		//   }
		// });
	},
	async fetchDocumentHistoryWarnings({ dispatch, commit }, { payload }) {

		return HTTP.get("api/v1/document/" + payload.key + "/warnings/" + payload.historyKey)
			.then((response) => {
				if (response.data) {
					// console.log(response.data)
					return response.data.data
					// commit("SET_DOCUMENT_HISTORY", response.data.data)
				}
			})
		// .catch((error) => {
		//   console.log(error);

		//   if (error.response !== undefined) {
		//     // return Promise.reject(error)
		//     return error.response.status;
		//   } else {
		//     return 500;
		//     // return Promise.reject(500)
		//   }
		// });
	},

	/*
		old version w/o promise

	async generatePDFOutputOld({ dispatch, commit }, { payload }) {

		return HTTP.post("api/v1/pdfoutput/" + payload.key, payload.options)
			.then((response) => {

				commit("SET_EXPORTING_PDF", true)

				if (response.data) {
					console.log(response.data)
					return response.data.data
					// commit("SET_DOCUMENT_HISTORY", response.data.data)
				}
			})
			.catch((error) => {
				commit("SET_EXPORTING_PDF", false)
				if (error.response !== undefined) {
					return Promise.reject(error.response)
				} else {
					return Promise.reject(500)
					// return Promise.reject(500)
				}
			})
	},
	*/

	/*
		Rewrite of generatePDFOutput(old) using promise
	*/
	async generatePDFOutput({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {

			HTTP.post("api/vue/v1/pdfoutput/" + payload.key, payload.options)
				.then((response) => {
					commit("SET_EXPORTING_PDF", true)
					// console.log('in generatePDFOutput.  response is:', response.data)
					resolve(response.data.data)
				})
				.catch((error) => {
					commit("SET_EXPORTING_PDF", false)
					if (error.response !== undefined) {
						reject(error.response)
					} else {
						reject(500)
					}
				})
		})

	},

	async fetchPDFOutputStatus({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {

			HTTP.get("api/v1/pdfoutput/" + payload.key)
				.then((response) => {
					// console.log('in get generatePDFOutput.  response is:', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(error.response)
				})
		})
	},

	//list
	async fetchUnicodeMaps({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {
			// console.log('in fetch unicode maps')

			HTTP.post('/api/v1/unicodemaps/' + payload.doc_key,
				{
					direction: payload.direction,
					currentResultsPage: payload.currentResultsPage,
					num_items: payload.resultSize,
				})
				.then((response) => {
					// console.log('got unicode maps:', response.data.data.results)
					commit('SET_UNICODE_MAPS', response.data.data.results)
					var retval = {
						nextPage: response.data.data.next_page,
						more: response.data.data.more
					}
					resolve(retval)
				}).catch((error) => {
					reject(error)
				})
		})
	},

	//single item
	async fetchUnicodeMap({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {

			HTTP.get('/api/v1/unicodemaps/' + payload.doc_key + '/' + payload.map_key)
				.then((response) => {
					resolve(response.data.data.results)
				}).catch((error) => {
					reject(error)
				})
		})
	},

	async saveUnicodeMap({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {

			HTTP.put('/api/v1/unicodemaps/' + payload.doc_key + '/' + payload.map_key,
				{
					data: payload.data
				}).then((response) => {
					// console.log('in action: response: ', response)
					var total_fixed = response.data.data.total_fixed
					var fixed_chars = response.data.data.fixed_chars
					resolve({ total_fixed: total_fixed, fixed_chars: fixed_chars })
				}).catch((error) => {
					reject(error)
				})
		})
	},

	async fetchOcrUnicodeMap({ dispatch, commit }, { payload }) {

		return new Promise((resolve, reject) => {

			HTTP.get('/api/v1/ocrunicodemaps/' + payload.doc_key + '/' + payload.map_key
			).then((response) => {
				resolve('success')
			}).catch((error) => {
				reject(error)
			})
		})
	},

	async fetchCSS({ commit }) {

		return new Promise((resolve, reject) => {

			HTTP.get('/api/v1/css'
			).then((response) => {
				// console.log("CSS=>", response)
				commit('SET_CSS', response.data.data.results)
				resolve('success')
			}).catch((error) => {
				reject(error)
			})
		})
	},

	async htmlPreview({ dispatch, commit }, { payload }) {

		//convert to form url-encoded
		var transformRequest = function (obj) {
			var str = []
			for (var p in obj) {
				str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
			}
			var retval = str.join("&")
			// console.log('transformed send options: ' + retval)
			return retval
		}

		return new Promise((resolve, reject) => {
			// console.log('posting in html preview action')
			HTTP.post('/preview/' + payload.doc_key + '?' +
				//payload items
				transformRequest(payload.sendOptions)
				,
				{
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
				}).then((response) => {
					resolve('success')
				}).catch((error) => {
					reject(error)
				})

		})

	},

	async htmlDownload({ dispatch, commit }, { payload }) {

		//convert to form url-encoded
		var transformRequest = function (obj) {
			// console.log('transforming send options: ', obj)
			var str = []
			for (var p in obj) {
				str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
			}
			var retval = str.join("&")
			// console.log('transformed send options: ', retval)
			return retval
		}

		return new Promise((resolve, reject) => {

			HTTP.post('/download/' + payload.doc_key + '?' +
				//payload items
				transformRequest(payload.sendOptions),
				{
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
				}).then((response) => {
					resolve('success')
				}).catch((error) => {
					reject(error)
				})
		})
	},

	async epubDownload({ dispatch, commit }, { payload }) {

		//convert to form url-encoded
		var transformRequest = function (obj) {
			// console.log('transforming send options: ', obj)
			var str = []
			for (var p in obj) {
				str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
			}
			var retval = str.join("&")
			// console.log('transformed send options: ', retval)
			return retval
		}

		return new Promise((resolve, reject) => {

			HTTP.post('/download/output/epub/' + payload.doc_key + '?' +
				transformRequest(payload.sendOptions),
				{
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
				}).then((response) => {
					resolve('success')
				}).catch((error) => {
					reject(error)
				})
		})
	},

}
