export default {
	getLabelFilters(state){
		return state.labelFilters
	},
	getRecentDocuments(state){
		return state.recentDocuments
	},
  getDocuments(state) {
    return state.documents
  },
  getImporting(state) {
    return state.importing
  },
  getImportingDocuments(state) {
    return state.importingDocuments
  },
  getImportedDocuments(state) {   
    return state.importedDocuments
  },
  getImportingDocumentsInProgress(state) {
    return state.inProgressDocuments
  }

};
