export default {
  userProfile: {
    displayName: "",
    email: "",
    photoURL: "",
  },
  authenticated: false,
  userChannel: undefined,
  connected: false,
  centrifuge: undefined,
  notifications: [],
  labels: [],
	uidLogin: true,
	fbAuthState: null,
  csrf_token: undefined,
	logoutInProcess: false,
}
