<template>
	<v-toolbar elevation="0" class="doc-toolbar">
		<v-toolbar-title class="doc-title" v-if="document">
			{{document.title}}
		</v-toolbar-title>

		<v-toolbar-items>
			<GoToPage :document="document"/>

			<v-card class="d-flex align-center align-content-center flex-nowrap pa-2 ml-4" elevation="0">
				<span class="text-no-wrap">{{ $t("document.showUncheckedPages") }}</span>
				<v-switch class="ma-3 pt-5" v-model="unchecked"></v-switch>
			</v-card>

			<v-card class="d-flex align-center flex-nowrap pa-2" elevation="0">
				<v-btn class="ma-2" v-bind="buttonStyle.buttons" color="primary" :loading="exportingPDF" :disabled="exportingPDF" @click="onGeneratePDF">
					<v-icon v-if="buttonStyle.icons.display" v-bind:class="buttonStyle.icons.class">mdi-download</v-icon>
					<span v-if="buttonStyle.labels.display">{{ $t("document.exportToPDF") }}</span>
				</v-btn>

				<DocumentShareDialog :document="document" />

				<DocumentDeleteDialog :document="document" />

			</v-card>

		</v-toolbar-items>
	</v-toolbar>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"
import EventBus from "@/eventBus"
import DocumentDeleteDialog from '@/components/DocumentDeleteDialog'
import DocumentShareDialog from "../../../components/DocumentShareDialog.vue"
import GoToPage from "../../../components/GoToPage.vue"

export default {
	props: ["document"],
	data() {
		return {
			default: null,
			toPage: null,
			unchecked: false,
		}
	},
	computed: {
		...mapGetters({
			exportingPDF: "document/getExportingPDF",
			buttonStyle: "application/getButtonStyle",

			setToPage(val) {
				return this.toPage = val
			}
		}),
	},
	components: {
		DocumentDeleteDialog,
		DocumentShareDialog,
		GoToPage,
	},
	watch: {
		'unchecked': function (newVal, oldVal) {
			this.setUncheckedPages(newVal)
		},
	},
	methods: {
		...mapMutations({
			setUncheckedPages: "document/SET_UNCHECKED_PAGES",
		}),
		onGeneratePDF() {
			EventBus.$emit("GENERATE_PDF", '')
		},
	}
}
</script>

<style>
/* this doesn't seem to be working correctly */
#pageinput {
	max-width: 150px;
	max-height: 20px;
}
.doc-toolbar {
	background: none;
}
.theme--dark.v-card {
	background: #272727;
}

</style>
