<template>
	<v-sheet class="ma-5">

		<template v-show="fbUser != null">
			<fb-vue-dropzone ref="fbDropzoneComponent" id="fbupload_dropzone" :options="fbDropzoneOptions"
				class="ma-5 dropzone-height" @vdropzone-success="fbDzSuccess" @vdropzone-error="fbDzError" tabindex="0"
				@vdropzone-removed-file="fbDzRemoved" @vdropzone-sending="fbDzSending"
				@vdropzone-queue-complete="fbDzQueueComplete" :useCustomSlot=true @vdropzone-total-upload-progress="fbDzProgress"
				v-show="fbUser != null">

				<div class="dropzone-label" v-show="fbUser != null">
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="#ffffff"
						aria-labelledby="drag-or-click-fb">
						<path
							d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
					</svg>
					<span id="drag-or-click-fb" style="margin-left:5px;">{{ $t("import.dragFilesOrClickHere") }}</span>
				</div>
			</fb-vue-dropzone>

			<v-container v-show="fbUser != null && options.enable_ml_page_match" class="ml-4 ">
				<v-row>
					<v-col cols="2">
						<v-label id="use-page-match" style="display: inline;" class="mr-1">{{ $t("import.enablePageMatch")
						}}:</v-label>
					</v-col>
					<v-col cols="1">
						<v-checkbox v-model="useMlFb" aria-labeledby="use-page-match" style="display: inline;"></v-checkbox>
					</v-col>
				</v-row>
			</v-container>

			<v-card fluid v-if="fileQueue.length > 0 && fileQueueReady" class="ma-3 pa-3" elevation="0" v-show="fbUser != null">
				<v-row>
					<v-col>
						<v-btn color="primary" small elevation="2" @click=importFileQueue>
							{{ $t("import.import") }}
						</v-btn>
					</v-col>
				</v-row>
			</v-card>

		</template>

		<template v-show="fbUser == null">
			<vue-dropzone ref="dropzoneComponent" id="dropzone" :options="dropzoneOptions" @vdropzone-file-added="dzFileAdded"
				class="ma-5 dropzone-height" @vdropzone-success="dzSuccess" @vdropzone-error="dzError" tabindex="0"
				@vdropzone-removed-file="dzRemoved" @vdropzone-sending="dzSending" @vdropzone-queue-complete="dzQueueComplete"
				@vdropzone-total-upload-progress="dzProgress" v-show="fbUser == null" :useCustomSlot=true>

				<div class="dropzone-label" v-show="fbUser == null">
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="#ffffff"
						aria-labelledby="drag-or-click-no-fb">
						<path
							d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
					</svg>
					<span id="drag-or-click-no-fb" style="margin-left:5px;">{{ $t("import.dragFilesOrClickHere") }}</span>
				</div>

			</vue-dropzone>

			<v-container v-show="fbUser == null && options.enable_ml_page_match" class="ml-4 ">
				<v-row>
					<v-col cols="2">
						<v-label id="use-page-match" style="display: inline;" class="mr-1">{{ $t("import.enablePageMatch")
						}}:</v-label>
					</v-col>
					<v-col cols="1">
						<v-checkbox v-model="useMl" aria-labeledby="use-page-match" style="display: inline;"></v-checkbox>
					</v-col>
				</v-row>
			</v-container>

			<v-card fluid v-if=importEnable class="ma-3 pa-3" elevation="0" v-show="fbUser == null">
				<v-row>
					<v-col>
						<v-btn color="primary" small elevation="2" @click=clickImport>
							{{ $t("import.import") }}
						</v-btn>
					</v-col>
				</v-row>
			</v-card>
		</template>

		<!-- importing  -->
		<template v-if="inProgressDocuments">

			<v-row class="ma-5">
				<h2>{{ $t("import.inProgress") }} ({{ inProgressDocuments }}) </h2>
			</v-row>

			<v-card elevation="2" class="pa-5 ma-5 text-center" min-height="200" v-for="importDocument in progressDocuments"
				:key="importDocument.tracking_key">

				<div class="d-flex flex-no-wrap ">
					<div>
						<v-card-title class="text-h5 left" v-text="importDocument.filename"></v-card-title>

						<template v-if="importDocument.ready">
							<v-card-text class="left">{{ $t("import.documentReady") }}</v-card-text>
							<v-card-actions>
								<v-btn class="float-right" v-bind="buttonStyle.buttons" color="primary" link
									:to="getDocumentReadyLink(importDocument)">
									<v-icon v-if="buttonStyle.icons.display" v-bind:class="buttonStyle.icons.class">mdi-pencil</v-icon>
									<span v-if="buttonStyle.labels.display">{{ $t("documents.edit") }}</span>
								</v-btn>
							</v-card-actions>
						</template>

					</div>

				</div>

				<template v-if="!importDocument.ready && !importDocument.aborted">
					<v-row class="pa-5">
						<v-progress-linear v-model="importDocument.progress" color="secondary" height="25"
							style="pointer-events: none">
							<template v-slot:default="{ value }">
								<strong>{{ Math.ceil(value) }}%</strong>
							</template>
						</v-progress-linear>
					</v-row>

					<v-row class="ma-3 pa-3">
						<v-col class="text-center">
							{{ importDocument.last_msg }}
						</v-col>
					</v-row>

				</template>

				<template v-if="importDocument.aborted">
					<v-row class="mt-5 pa-5">
						<v-col>
							{{ $t("import.couldNotImport") }}
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							{{ importDocument.last_msg }}
						</v-col>
					</v-row>
				</template>

				<template class="ma-5 pa-5" v-if="importDocument.errors">
					<v-row>
						<v-expansion-panels>
							<v-expansion-panel>
								<v-expansion-panel-header>
									{{ importDocument.errors.length }} {{ $t("import.importMessages") }}
								</v-expansion-panel-header>
								<v-expansion-panel-content>

									<v-row class="pa-5" v-for="(error_msg, i) in importDocument.errors" :key="i">
										{{ error_msg }}
									</v-row>

								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-row>

				</template>

				<!-- <v-row> class="ui one column stackable grid">
              <div class="column">

                <div class="ui feed" data-ng-repeat="msg in backMessages">
                  <div class="event">
                    <div class="label">
                      <i aria-hidden="true" class="file pdf outline icon"></i>
                    </div>

                    <div class="content">
                      <div class="summary">
                        <a class="user">
                          Username
                        </a>
                        {{ msg.data.msg }}
                      </div>
                    </div>
                  </div>

                </div>
              </v-row> -->

			</v-card>

		</template>

		<template v-if="importedDocuments.length > 0">
			<v-row class="ma-5">
				<h3> {{ $t("import.recently") }} {{ importedDocuments.length }}</h3>
			</v-row>

			<v-card elevation="2" class="pa-5 ma-5 text-center" min-height="200" v-for="importedDocument in importedDocuments"
				:key="importedDocument.document.doc_key">


				<div class="d-flex flex-no-wrap ">
					<v-avatar class="ma-3" size="165" tile>
						<v-img :src="'/api/v1/thumbnail/' + importedDocument.document.doc_key + '/1/page-1_thumb.jpg'"></v-img>
					</v-avatar>

					<div>
						<v-card-title class="text-h5 left" v-text="importedDocument.filename"></v-card-title>

						<template v-if="importedDocument.ready">
							<v-card-actions>
								<v-btn class="float-right" v-bind="buttonStyle.buttons" color="primary" link
									:to="'/document/' + importedDocument.document.doc_key">
									<v-icon v-if="buttonStyle.icons.display" v-bind:class="buttonStyle.icons.class">mdi-pencil</v-icon>
									<span v-if="buttonStyle.labels.display">{{ $t("documents.edit") }}</span>
								</v-btn>
							</v-card-actions>
						</template>

					</div>

				</div>
				<v-btn class="mt-10" fab absolute top right @click="removeImportedDoc(importedDocument.document.doc_key)">
					<v-icon color="red" large>mdi-close</v-icon>
				</v-btn>

			</v-card>
		</template>

	</v-sheet>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import EventBus from "@/eventBus"
import i18n from "@/plugins/i18n"
import { auth } from './../../../firebase'
import { firebase } from './../../../firebase'

const MAX_FILENAME_LENGTH = 128

export default {
	data() {
		return {
			//regular dropzone
			dropzoneOptions: {
				url: '/upload',
				clickable: true,
				maxFilesize: 32,
				maxFiles: 100,
				parallelUploads: 5,
				addRemoveLinks: true,
				autoProcessQueue: true,
				thumbnailWidth: 120,
				acceptedFiles: "application/pdf,application/x-pdf,application/vnd.pdf,text/pdf",
				headers: { "X-CSRF-TOKEN": '' },
				//dictDefaultMessage: "Drag and drop files here or click to upload",
			},
			//firebase dropzone
			fbDropzoneOptions: {
				url: '/upload',
				clickable: true,
				maxFilesize: 1000,
				maxFiles: 100,
				parallelUploads: 5,
				addRemoveLinks: true,
				autoProcessQueue: false,
				thumbnailWidth: 120,
				uploadMultiple: true,
				acceptedFiles: "application/pdf,application/x-pdf,application/vnd.pdf,text/pdf, application/zip, application/x-zip-compressed",
				headers: { "X-CSRF-TOKEN": '' },
				//dictDefaultMessage: "Drag files or click here",
			},

			storageRef: null,
			storageRoot: null,
			fbUser: null,
			fileQueue: [], //firebase
			fileQueueReady: false,

			groupKey: undefined,
			importFiles: [],  //non-firebase
			importEnable: false,
			importing: false,
			maxFileSize: 1000,
			maxFileNameLength: 128,
			acceptedFiles: "application/pdf,application/x-pdf,application/vnd.pdf,text/pdf", //for validation of non-firebase files
			newFileSize: null,
			lastTotalFileSize: null,
			useMlFb: false,
			useMl: false,
		}
	},
	components: {
		//for whatever reason, can't use the same component name for two different areas of page
		vueDropzone: vue2Dropzone,
		fbVueDropzone: vue2Dropzone,
	},
	computed: {
		...mapGetters({
			csrf_token: "user/getCSRFToken",
			userChannel: "user/userChannel",
			progressDocuments: "documents/getImportingDocuments",
			inProgressDocuments: "documents/getImportingDocumentsInProgress",
			importedDocuments: "documents/getImportedDocuments",
			fbAuthState: "user/getFbAuthState",
			language: "language/getLanguage",
			options: "application/getOptions",
			buttonStyle: "application/getButtonStyle"

		}),
		importIsEnabled() {
			if (this.importFiles.length > 0) {
				return true
			}
			else {
				return false
			}
		},
		ready() {
			return this.fileQueueReady
		}
	},

	watch: {

		fileQueue: {
			handler(newVal, oldVal) {
				console.log('fileQueue has changed: ', this.fileQueue)
				this.newFileSize = this.getTotalFileSize()
				if (this.newFileSize !== this.lastTotalFileSize) {
					this.lastTotalFileSize = this.newFileSize;
					if (this.lastTotalFileSize > this.maxFileSize) {
						this.$snackbar.showMessage({ content: this.$t("import.msg.maxFileSizeMsg", [this.maxFileSize]), color: 'success' })
					}
				}
			},
			deep: true
		},

	},
	methods: {
		...mapActions({
			importDocuments: "documents/importDocuments",
			removeImported: "documents/removeImported"
		}),

		...mapMutations({
			setFbAuthState: "user/SET_FB_AUTH_STATE",
		}),
		keydown(event){
			console.log('keyboard event picked up: ', event)
			this.handleEnterKeyOnDropzone(event)
		},
		handleEnterKeyOnDropzone(event){
			//was enter key pressed?
			if(event.keyCode == 13 && event.code == "Enter"){
				//is the target a dropbox?
				console.log('event target id is: ', event.target)
				console.log('target id is: ', event.target.id)
				if(event.target.id == "dropzone" || event.target.id == "fbupload_dropzone"){
					console.log('clicking on dropzone')
					event.target.click()
				}
			}
		},
		//need a setter for function that doesn't see correct scope
		setFileQueueReady(ready) {
			this.fileQueueReady = ready
		},
		//newly added for fb upload
		getIndexForID(arr, id) {
			for (var i = 0; i < arr.length; i++) {
				if (arr[i].id == id) {
					return i
				}
			}
			return -1
		},

		getTotalFileSize() {
			var totalSize = 0
			var fileSize = 0
			for (var i = 0; i < this.fileQueue.length; i++) {
				fileSize = this.fileQueue[i].fileSizeBytes
				totalSize += fileSize
			}
			totalSize = totalSize / 1000000;
			console.log('total size of files is now: ' + totalSize + ' MB')
			return totalSize
		},

		validateFileType(mimeType) {
			var acceptedFiles = this.acceptedFiles.split(',');
			if (acceptedFiles.includes(mimeType)) {
				return true
			}
			return false
		},

		validateFileNameLength(filename) {
			var retval = true
			var urlEncodedName = encodeURI(filename);
			if (urlEncodedName.length > this.maxFileNameLength) {
				retval = false
			}
			return retval
		},

		//File added to firebase dropzone
		fbDzFileAdded(file) {
			console.log('in upload files!', file)
			console.log('in upload files. fileQueue is: ', this.fileQueue)

			if (!this.validateFileNameLength(file.name)) {
				this.$snackbar.showMessage({ content: this.$t("import.msg.maxFileNameLengthMsg", [file.name]), color: 'error' })
				//TODO: remove file?

				//test 
				return
			}

			//validate fileQueue length.  if at limit, can't add more.
			if (this.fileQueue.length >= this.options.max_doc_import) {
				this.$snackbar.showMessage({ content: this.$t("import.msg.maxFileCountMsg", [this.options.max_doc_import]), color: 'error' })
				
				//test
				//console.log('fileQueue: ', this.fileQueue)
				console.log('bad file is:',file)
				file._removeLink.click()
				console.log('after removing bad file, fileQueue is:', this.fileQueue)
				return
			}


			var fd = new FormData()
			fd.append('file', file)

			this.fireBaseUpload(file, function (data) {
				console.log(data);
				if (!data.error) {
					if (data.progress) {
						// progress update to view here
					}
					if (data.downloadURL) {
						// update done
						// download URL here "data.downloadURL"
					}
				} else {
					console.log(data.error + ' Firebase image upload error')
				}
			});

		},
		fbDzRemoved(file, xhr, error) {
			
			console.log('in fbDzRemoved. file is:', file)
			console.log('in fbDzRemoved.  fileQueue is: ', this.fileQueue)
			
			for (var i = 0; i < this.fileQueue.length; i++) {
				if (this.fileQueue[i].fileName === file.name) {
					console.log('found file by name.  removing from fileQueue')
					this.fileQueue.splice(i, 1)
				}
			}

			if (this.fileQueue.length > 0) {
				this.importEnable = true
			}
			else {
				this.importEnable = false
			}
			
		},

		/*
			Not implemented
		*/
		fbDzSending(file, xhr, formData) {

		},
		fbDzQueueComplete(file, xhr, formData) {

		},
		fbDzProgress(totalProgress, totalBytes, totalBytesSent) {

		},
		fbDzSuccess(file, response) {

		},
		fbDzError(e) {

		},

		/*
			Handles the upload of the file to firebase when a file is dropped in the firebase dropzone
		*/
		fireBaseUpload(file, callBackData) {
			console.log('in firebase upload!! file is: ', file)
			this.storageRef = firebase.storage().ref()

			var uploadRef = this.storageRef.child(this.storageRoot).child('/upload')
			console.log('uploadRef is: ', uploadRef)

			var generateRandomString = function (length) {
				var chars = "abcdefghijklmnopqrstuvwxyz"
				var pass = ""
				for (var x = 0; x < length; x++) {
					var i = Math.floor(Math.random() * chars.length)
					pass += chars.charAt(i)
				}
				return pass
			};

			var formatBytes = function (bytes, decimals) {
				if (bytes == 0) return '0 Byte'
				var k = 1000
				var dm = decimals + 1 || 3
				var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
				var i = Math.floor(Math.log(bytes) / Math.log(k))
				return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
			}

			if (this.options.zip_upload && !this.validateFileType(file.type)) {
				console.log('Invalid file type:' + file.type);
				this.$snackbar.showMessage({ content: this.$t("import.msg.onlyPDFZip"), color: 'error' })
				return
			}

			//if zip upload is not enabled and file is not pdf

			if (!this.options.zip_upload && !this.validateFileType(file.type)) {
				console.log('Invalid file type:' + file.type);
				this.$snackbar.showMessage({ content: this.$t("import.msg.invalidFileType"), color: 'error' })
				return
			}

			var metaData = { 'contentType': file.type }
			var arr = file.name.split('.')
			var fileSize = formatBytes(file.size) // get clean file size (function below)
			var fileSizeBytes = file.size //need the actual size
			var fileType = file.type
			var realFilename = file.name
			var tmpFilename = generateRandomString(20) + (file.type === 'application/zip' ? '.zip' : '.pdf')
			var getIndexForID = this.getIndexForID
			var fileQueue = this.fileQueue
			var setFileQueueReady = this.setFileQueueReady

			uploadRef = uploadRef.child(tmpFilename)

			//here is where the file send request is made
			var uploadTask = uploadRef.put(file)

			// first instance identifier
			callBackData({ id: tmpFilename, fileSize: fileSize, fileType: fileType, fileName: realFilename })

			//push the file so we can change the state for the dropzone component
			this.fileQueue.push({
				id: tmpFilename, fileSize: fileSize, fileSizeBytes: fileSizeBytes, fileType: fileType, fileName: realFilename,
				progress: 0, file: file,
			})

			uploadTask.on('state_changed', function (snapshot) {
				// Observe state change events such as progress, pause, and resume
				// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
				var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
				progress = Math.floor(progress);
				console.log('Upload is ' + progress + '% done')

				var idx = getIndexForID(fileQueue, tmpFilename)

				fileQueue[idx].progress = progress
				//update the ui style for progress
				fileQueue[idx].file.previewElement.children[2].firstChild.style.width = progress + "%"
				fileQueue[idx].file.upload.bytesSent = snapshot.bytesTransferred

				callBackData({
					progress: progress,
					element: tmpFilename,
					fileSize: fileSize,
					fileType: fileType,
					fileName: realFilename
				});

				switch (snapshot.state) {
					case firebase.storage.TaskState.PAUSED: // or 'paused'
						console.log('Upload is paused');
						break;
					case firebase.storage.TaskState.RUNNING: // or 'running'
						console.log('Upload is running');
						break;
				}

			}, function (error) {
				// Handle unsuccessful uploads
				callBackData({ error: error })
			}, function () {
				var idx = getIndexForID(fileQueue, tmpFilename)

				fileQueue[idx].success = true
				fileQueue[idx].file.status = "success"
				//update the ui with success when done
				fileQueue[idx].file.previewElement.classList.add("dz-success")

				//pause for a bit then update ui with complete class
				setTimeout(function () {
					fileQueue[idx].file.previewElement.classList.add("dz-complete")
				}, 200)

				var allReady = true
				for (var i = 0; i < fileQueue.length; i++) {
					if (fileQueue[i].success === undefined || !fileQueue[i].success) {
						allReady = false
						break
					}
				}
				if (allReady) {
					console.log('file queue is ready. fileQueue: ', fileQueue)

					setFileQueueReady(allReady)
				}

				// Handle successful uploads on complete
				// For instance, get the download URL: https://firebasestorage.googleapis.com/...
				uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
					console.log('File available at', downloadURL);
					callBackData({
						downloadURL: downloadURL,
						element: tmpFilename,
						fileSize: fileSize,
						fileType: fileType,
						fileName: realFilename
					})
				})
			})

		},
		//for firebase import
		importFileQueue() {
			var selectedFiles = []

			for (var i = 0; i < this.fileQueue.length; i++) {
				selectedFiles.push(this.fileQueue[i])
			}

			this.fileQueueReady = false //set to false which will reset when uploads are complete
			this.importDocuments({
				payload: {
					csrf_token: this.csrf_token,
					group: this.groupKey,
					files: selectedFiles,
					key: this.userChannel.user,
					fbstr: this.storageRoot + "/upload",  //not used for regular import
					useMl: this.useMlFb,
				}
			})
				.then((response) => {
					console.log('Importing', response, this.progressDocuments)
					//clear queue
					this.fileQueue = []
					this.$refs.fbDropzoneComponent.dropzone.removeAllFiles()
					this.$snackbar.showMessage({ content: this.$t("import.msg.importing", [response.data.length]), color: 'success' })
				})
				.catch((error) => {

				})

		},

		/*
			These are the handlers for the regular dropzone events which are bound 
			in the component with @ sign
			so @vdropzone-removed-file="dzRemoved" means dzRemoved() is called on 
			vdropzone-removed-file event
		*/
		dzFileAdded(file) {
			// dz.options.url = '/api/v1/import/' + $scope.upload.data.key;
			console.log('dzFileAdded', file)

			var acceptedFiles = this.$refs.dropzoneComponent.dropzone.options.acceptedFiles.split(',')
			if (!acceptedFiles.includes(file.type)) {
				console.log('Invalid type!')
				return
			}

			var urlEncodedName = encodeURI(file.name);
			if (urlEncodedName.length > MAX_FILENAME_LENGTH) {
				console.log('Filename too long')
				return
			}

			//validate importFiles length.  if at limit, can't add more
			if (this.importFiles.length >= this.options.max_doc_import) {
				this.$snackbar.showMessage({ content: this.$t("import.msg.maxFileCountMsg", [this.options.max_doc_import]), color: 'error' })

				//test
				console.log('bad file is:',file)
				file._removeLink.click()
				console.log('after removing bad file, importFiles is:', this.importFiles)
				return
			}

			this.importFiles.push(file.upload.filename)

		},
		dzError(e) {
			console.log('dzErrorfile', e)
		},
		dzSuccess(file, response) {
			console.log('dzSuccess', file, response)
		},
		dzRemoved(file, xhr, error) {
			console.log('dzRemoved', file, xhr, error)

			for (var i = 0; i < this.importFiles.length; i++) {
				if (this.importFiles[i] === file.name) {
					this.importFiles.splice(i, 1)
				}
			}

			if (this.importFiles.length > 0) {
				this.importEnable = true
			}
			else {
				this.importEnable = false
			}
		},
		dzSending(file, xhr, formData) {
			formData.append("filesize", file.size)
			formData.append("_csrf_token", this.userChannel.csrf_token)
			formData.append("group", this.groupKey)
		},
		dzQueueComplete(file, xhr, formData) {
			console.log('dzQueueComplete', file, xhr, formData)
			if (this.importFiles.length > 0) {
				this.importEnable = true
			}
			else {
				this.importEnable = false
			}
		},
		dzProgress(totalProgress, totalBytes, totalBytesSent) {
			console.log('dzProgress', totalProgress, totalBytes, totalBytesSent)
		},
		getDocumentReadyLink(document) {
			return '/document/' + document.document_key
		},
		//for non-firebase import
		clickImport() {
			this.importEnable = false
			this.importDocuments({
				payload: {
					csrf_token: this.csrf_token,
					group: this.groupKey,
					files: this.importFiles,
					key: this.userChannel.user,
					fbstr: null,  //not used for regular import,
					useMl: this.useMl,
				}
			})
				.then((response) => {
					console.log('Importing', response, this.progressDocuments)
					this.importFiles = []
					this.$refs.dropzoneComponent.dropzone.removeAllFiles()

					this.$snackbar.showMessage({ content: this.$t("import.msg.importing", [response.data.length]), color: 'success' })
				})
				.catch((error) => {

				})
		},
		removeImportedDoc(doc_id) {
			this.removeImported({ payload: { doc_id: doc_id } })
		}
	},
	destroyed(){
		window.removeEventListener("keydown", this.keydown)
	},
	mounted() {
		window.addEventListener("keydown", this.keydown)

		/*
			This is the current solution necessary for making class/style changes to the 
			file elements in the vue2-dropzone component. This makes the files reactive such 
			that changing the class list or style of the file objects triggers a change in 
			the ui. Tried using many variations, including the regular dropzone library (like in angular).
			The regular dropzone library sort of works, but the styling is completey wrong and difficult to 
			figure out how to change correctly based on events.  The problem with vue2-dropzone
			is that simply changing the values of progress/complete on the file objects directly 
			doesn't trigger the dropzone to apply the necessary classes or styles.  
		*/
		var initializeFbDropzone = function (context) {
			var dropzoneComponent = context.$refs.fbDropzoneComponent
			var vm = context
			var dropzone = dropzoneComponent.$refs.dropzoneElement.dropzone
			dropzone.on('addedfile', function (file) {
				vm.fbDzFileAdded(file)
			})
		}

		//this one is watch for the changed auth state
		auth.onAuthStateChanged((authState) => {
			console.log('in import mounted.  auth state changed: ', authState)
			if (authState != null && authState != undefined) {
				this.setFbAuthState(authState)
				this.storageRoot = this.fbAuthState.uid
				this.fbUser = this.fbAuthState.email
				console.log('current user: ', this.fbUser)
				initializeFbDropzone(this)
			}
		})

		setTimeout(() => {
			var d = new Date().getTime()
			var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
				var r = (d + Math.random() * 16) % 16 | 0
				d = Math.floor(d / 16)
				return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
			})

			this.groupKey = this.userChannel.user + uuid

			if (this.$refs.dropzoneComponent != null && this.$refs.dropzoneComponent != undefined) {
				this.$refs.dropzoneComponent.dropzone.options.url = '/api/v1/import/' + this.userChannel.user
				this.$refs.dropzoneComponent.dropzone.options.headers = { "X-CSRF-TOKEN": this.userChannel.csrf_token }
				//test
				//this.$refs.dropzoneComponent.dropzone.options.maxFiles = this.options.max_doc_import
			}
			else { //must be fb dropzone
				this.$refs.fbDropzoneComponent.dropzone.options.headers = { "X-CSRF-TOKEN": this.userChannel.csrf_token }
				//test
				//this.$refs.fbDropzoneComponent.dropzone.options.maxFiles = this.options.max_doc_import
			}

			// this.$set(this.progressDocuments['123'], 'totalPages', 6)
			// this.$set(this.progressDocuments['123'], 'filename', 'Span title 7.pdf')

			// this.$set(this.progressDocuments['456'], 'totalPages', 26)
			// this.$set(this.progressDocuments['456'], 'filename', 'test.pdf')

			console.log("inProgressDocuments =>", this.inProgressDocuments)
		}, 0)
		//clear the store
	},
}
</script >

<style lang="scss" scoped>
.dropzone-label {
	font-size: 1.15rem;
	font-weight: 700;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	display: inline-block;
	overflow: hidden;
	padding: 0.625rem 1.25rem;
	color: #f1e5e6;
	background-color: #003366;
}

.left {
	text-align: left;
}

.dropzone-height {
	min-height: 300px;
}
</style>
<style lang="scss">
.vue-dropzone>.dz-preview .dz-details {
	background-color: #003366 !important;
}
</style>
