
export default {
	SET_DOCUMENT(state, val) {
		state.document = val
	},
	SET_DOCUMENT_PAGES(state, val) {
		state.documentPages = val
	},
	ADD_DOCUMENT_PAGES(state, val) {
		for (var i = 0; i < val.length; i++) {
			state.documentPages.push(val[i])
		}
	},
	SET_IMAGES(state, val) {
		state.documentImages = val
	},
	SET_METADATAS(state, val) {
		state.metadatas = val
	},
	ADD_METADATA(state, newMetadata) {
		state.metadatas.push(newMetadata)
	},
	DELETE_METADATA(state, metadataKey) {
		const index = state.metadatas.findIndex(item => item.key == metadataKey)
		state.metadatas.splice(index, 1)
	},
	SET_PAGENOTES(state, val) {
		state.documentNotes = val
	},
	SET_DOCUMENT_HISTORY(state, val) {
		state.documentHistory = val
	},
	SET_EXPORTING_HTML(state, val) {
		state.exporting.html = val
	},
	SET_EXPORTING_PDF(state, val) {
		state.exporting.pdf = val
	},
	SET_EXPORTING_EPUB(state, val) {
		state.exporting.epub = val
	},
	SET_UNCHECKED_PAGES(state, val) {
		state.uncheckedPages = val
	},
	SET_UNICODE_MAPS(state, val){
		state.unicodeMaps = val
	},
	SET_TOTAL_UNICODE_FIXED(state, val){
		state.document.total_unicode_fixed = val
	},
	SET_CSS(state, val) {
		state.css = val
	}
}  