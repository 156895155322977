export default {
  authenticated(state) {
    return state.authenticated
  },
  userProfile(state) {
    return state.userProfile
  },
  userChannel(state) {
    return state.userChannel
  },
  getLabels(state) {
    return state.labels
  },  
  getNotifications(state) {
    if (state.notifications !== undefined) {
      return state.notifications
    }
    return {}
  },
	getUidLogin(state){
		return state.uidLogin
	},
	getFbAuthState(state){
		return state.fbAuthState
	},
  getCSRFToken(state) {
    return state.csrf_token
  },
	getLogoutInProcess(state){
		return state.logoutInProcess
	}
}
