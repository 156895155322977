import Import from '../components/Import'

export default [
    {
        path: '/import',
        name: 'ImportDocument',
        component: Import,
        meta: {
            requiresAuth: true,
            i18Msg: 'barTitle.import'
        }
    },
]