<template>
	<div v-if="authenticated">
		<v-navigation-drawer app permanent :mini-variant.sync="miniMenu" mini-variant-width=80 fixed class="main-drawer">
			<v-list nav dense class="main-menu pa-0">

				<v-list-item link to="/">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-list-item-icon>
								<v-icon large v-bind="attrs" v-on="on">mdi-home-outline</v-icon>
							</v-list-item-icon>
						</template>
						<span>{{ $t('mainMenu.home') }}</span>
					</v-tooltip>
					<v-list-item-title>{{ $t("mainMenu.home") }}</v-list-item-title>
				</v-list-item>

				<v-divider></v-divider>

				<v-list-item link to="/documents">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-list-item-icon>
								<v-icon large v-bind="attrs" v-on="on">mdi-format-list-bulleted</v-icon>
							</v-list-item-icon>
						</template>
						<span>{{ $t('mainMenu.documents') }}</span>
					</v-tooltip>
					<v-list-item-title>{{ $t("mainMenu.documents") }}</v-list-item-title>
				</v-list-item>

				<v-divider></v-divider>

				<v-list-item link to="/import">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-list-item-icon>
								<v-badge class="mt-4" :content="inProgressDocuments" :value="inProgressDocuments" color="secondary" overlap>
									<v-icon large v-bind="attrs" v-on="on">mdi-cloud-upload-outline</v-icon>
								</v-badge>
							</v-list-item-icon>
						</template>
						<span>{{ $t('mainMenu.importDocuments') }}</span>
					</v-tooltip>
					<v-list-item-title>
						{{ $t("mainMenu.importDocuments") }}
					</v-list-item-title>
				</v-list-item>

				<v-divider></v-divider>

				<v-list-item link to="/notifications">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-list-item-icon>
								<v-badge class="mt-4" :content="notifications.length" :value="notifications.length" color="green" overlap>
									<v-icon large v-bind="attrs" v-on="on">mdi-bell-outline</v-icon>
								</v-badge>
							</v-list-item-icon>
						</template>
						<span>{{ $t('mainMenu.notifications') }}</span>
					</v-tooltip>
					<v-list-item-title>{{
						$t("mainMenu.notifications")
					}}</v-list-item-title>
				</v-list-item>

				<v-divider></v-divider>

				<v-list-item link to="/settings">

					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-list-item-icon>
								<v-icon large v-bind="attrs" v-on="on">mdi-cog-outline</v-icon>
							</v-list-item-icon>
						</template>
						<span>{{ $t('mainMenu.settings') }}</span>
					</v-tooltip>
					<v-list-item-title>{{ $t("mainMenu.settings") }}</v-list-item-title>
				</v-list-item>

				<v-divider></v-divider>

				<v-list-item link to="/help">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-list-item-icon>
								<v-icon large v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
							</v-list-item-icon>
						</template>
						<span>{{ $t('mainMenu.help') }}</span>
					</v-tooltip>
					<v-list-item-title>{{ $t("mainMenu.help") }}</v-list-item-title>
				</v-list-item>

				<v-divider></v-divider>

				<v-list-item link @click.stop="logout">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-list-item-icon>
								<v-icon large v-bind="attrs" v-on="on">mdi-power</v-icon>
							</v-list-item-icon>
						</template>
						<span>{{ $t('mainMenu.signOut') }}</span>
					</v-tooltip>
					<v-list-item-title>{{ $t("mainMenu.signOut") }}</v-list-item-title>
				</v-list-item>

				<!--
				<v-divider v-if="showTest"></v-divider>
				
				<v-list-item link @click.stop="triggerError" v-if="showTest">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-list-item-icon>
								<v-icon large v-bind="attrs" v-on="on">mdi-dev-to</v-icon>
							</v-list-item-icon>
						</template>
						<span>401 Error</span>
					</v-tooltip>
					<v-list-item-title>401 Error</v-list-item-title>
				</v-list-item>
				-->
			</v-list>
			
			<v-divider></v-divider>

			<div v-if="!miniMenu">
				<v-btn class="float-right" icon @click.stop="toggleMini">
					<v-icon large>mdi-chevron-left</v-icon>
				</v-btn>
			</div>
		</v-navigation-drawer>


	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
	name: "MainMenu",
	data() {
		return {
			miniMenu: true
		}
	},
	methods: {
		...mapActions({
			logout: "user/logout",
			triggerError: "user/triggerError",
			fetchNotifications: "user/fetchNotifications",
		}),
		toggleMini() {
			this.miniMenu = !this.miniMenu
			this.$store.commit("application/SET_MINI_MAIN_MENU", this.miniMenu)
		},
		showTest(){
			if(process.env.VUE_APP_ENV === 'local' || process.env.VUE_APP_ENV === 'qa'){
				return true
			}
			return false
		}
	},
	mounted() {
		this.fetchNotifications()
	},
	computed: {
		...mapGetters({
			authenticated: "user/authenticated",
			inProgressDocuments: "documents/getImportingDocumentsInProgress",
			miniMainMenu: "application/getMiniMainMenu",
			notifications: "user/getNotifications",
		}),
	},
	watch: {
		'miniMainMenu': function (newVal, oldVal) {
			this.miniMenu = newVal
		},
		'miniMenu': function (newVal, oldVal) {
			this.$store.commit("application/SET_MINI_MAIN_MENU_VAL", this.miniMenu)
		},
	},
}
</script>
<style lang="scss" scoped>
.main-drawer {
	border-right: 1px solid lightgray;
}

.nav-pa {
	padding: 0px !important;
}

.v-list--nav .v-list-item {
	height: 80px;
	padding: 0px;
	margin-bottom: 0px !important;
}

.v-navigation-drawer--open .main-menu .v-icon.v-icon {
	padding-left: 10px;
}

.v-navigation-drawer--mini-variant .main-menu .v-icon.v-icon {
	padding-left: 0px;
}

.main-menu .v-icon.v-icon {
	display: initial;
	padding-top: 10px;
	padding-left: 0px;
}
</style>
