<template>
    <v-row>
        <div class="section-title">
            <span>{{title}}</span>
            <hr/>
        </div>

    </v-row>
</template>

<script>

export default {
  props: ['title'],
   data() {
    return {
        default: true
    }
  },
}
</script>
<style lang="scss" scoped>

.section-title {
    width: 100%;
    height: 60px;
    margin: auto;
    padding: 10px;
    text-align: center;
}
.section-title span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    padding: 6px 12px;
}

.theme--light.v-card .section-title span {
  background-color: #f3f3f3;
}
.theme--dark.v-card .section-title span {
  background-color: #3c3c3c;
}

.section-title hr {
    margin-top: -10px;
}
</style>