<template>
	<v-sheet class="pa-3 mt-5 fill-height">
		<Toolbar v-bind:document="document" />

		<!-- Pages -->
		<div class="horizontal-container rounded" v-scroll.self="onScroll">
			<template v-for="page in documentPages">
				<v-hover v-slot="{ hover }" :key="page.page_no">
					<v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" class="pa-5 mr-5 text-center" 
						min-width="220" max-width="250" min-height="300">
						<!-- added undefined/null check as link throwing error in console.  maybe timing issue -->
						<router-link v-if="document != undefined && document != null" link :alt="'Open page ' + page.page_no"
							:to="'/document/' + document.key + '/page/' + page.page_no">
							<v-img :src="'/api/v1/thumbnail/' + page.thumbnail_url"
								:class="[ {'page-saved-img-border' : page.last_saved_by}, `elevation-${hover ? 24 : 6}`]"></v-img>

							<span class="validated-corner" v-if="page.validated">
								<v-icon class="validated-check mr-3">mdi-check-bold</v-icon>
							</span>

						</router-link>
						<span class="pt-10">{{ page.page_no }}</span>
					</v-card>
				</v-hover>
			</template>
		</div>

		<DocumentTabs v-if="document" v-bind:document="document" />
	</v-sheet>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import Toolbar from "./Toolbar"
import DocumentTabs from "./DocumentTabs"
import EventBus from "@/eventBus"

export default {
	data() {
		return {
			isLoading: true,
			errors: null,
			selectedList: [],
			search: null,
			orderDirection: "asc",
			resultsPerPage: "",
			resultsPageCounts: ["25", "50", "100"],
			sortBy: "Imported Date",
			sortBySelection: ["Imported Date", "Document title", "Status"],
			pageStart: 1,
			offset: 0,
			unchecked: false,
			lastPage: null,
		}
	},
	components: {
		Toolbar,
		DocumentTabs,
	},
	computed: {
		...mapGetters({
			document: "document/getDocument",
			uncheckedPages: "document/getUncheckedPages",
			documentPages: "document/getDocumentPages",
			images: "document/getDocumentImages",
			language: "language/getLanguage",
		}),
	},
	watch: {
		'uncheckedPages': function(newVal, oldVal){
			// console.log('in document details.  unchecked is now: ' + newVal)
			this.unchecked = newVal
			//need to reset the page start.  could have clicked on some page of results after.
			this.pageStart = 1
			this.refreshDocumentPages()
		}
	},
	methods: {
		...mapActions({
			fetchDocument: "document/fetchDocument",
			fetchDocumentPages: "document/fetchDocumentPages",
			fetchDocumentImages: "document/fetchDocumentImages",
		}),
		...mapMutations({
			setDocument: "document/SET_DOCUMENT",
			setPages: "document/SET_DOCUMENT_PAGES",
			setUncheckedPages: "document/SET_UNCHECKED_PAGES",

		}),
		onScroll(e){
			var target = e.target
			if(target.scrollLeft + target.offsetWidth >= target.scrollWidth - 500 && !this.isLoading){
				//this.loadPages
				// console.log('should load pages now.')
				this.loadPages()
			}
		},
		loadPages(){
			// console.log('loading pages. pageStart is: ' + this.pageStart)
			if(this.pageStart < this.document.total_pages){
				this.isLoading = true

				this.fetchDocumentPages({
					payload: {
						key: this.$route.params.key,
						pageStart: this.pageStart,
						unchecked: this.unchecked,
					}
				}).then( (response) => {
					//increment page start.  
					// console.log('load pages items returned: ' + response.itemsReturned)
					this.lastPage = response.lastPage
					//if unchecked is set pageStart needs to be one more than the last page retrieved, which isn't necessarily page 25
					// console.log('unchecked? ' + this.unchecked)
					if(this.unchecked){
						this.pageStart = this.lastPage + 1
						// console.log('setting page start to: ' + this.pageStart)
					}
					else{ //otherwise pages are continuous
						this.pageStart += response.itemsReturned
					}
					
					this.isLoading = false
				}).catch( (error) => {
					this.isLoading = false
				})
			}
		},

		//refresh is the initial loading of the pages.  after that, load pages is doing the work
		refreshDocumentPages(){
			if(this.document == null){
				//bad state.  can't do anything here.
				return
			}
			if(this.document.total_pages < 50){
				//not passing a start, so we get 50
				this.isLoading = true
				this.fetchDocumentPages({
					payload: {
						key: this.$route.params.key,
						unchecked: this.unchecked,
					}
				}).then( (response) => {
					//LastPageSaved call goes here. 
					// console.log('refresh doc pages items returned: ' + response.itemsReturned) 
					this.isLoading = false
					//don't need pageStart or lastPage adjusted from this call
				}).catch( (error) => {
					this.isLoading = false
				})
			}
			else{
				//doc has more than 50 pgs, so we get 25 at a time
				this.isLoading = true
				this.fetchDocumentPages({
					payload: {
						key: this.$route.params.key,
						pageStart: 1,
						unchecked: this.unchecked,
					}
				}).then( (response) => {
					//server returns 25 at a time.  
					this.lastPage = response.lastPage
					//if unchecked is set pageStart needs to be one more than the last page retrieved, which isn't necessarily page 25
					// console.log('unchecked? ' + this.unchecked)
					if(this.unchecked){
						this.pageStart = this.lastPage + 1
						// console.log('setting page start to: ' + this.pageStart)
					}
					else{ //otherwise pages are continuous
						this.pageStart = response.itemsReturned + 1
					}

					this.isLoading = false
					// console.log('refresh doc pages items returned: ' + response.itemsReturned)
				}).catch( (error) => {
					this.isLoading = false
				})
			}

		}
	},
	
	mounted() {
		// console.log('in doc detail mounted')
		//lets remove any doc & pages for good measure
		this.setDocument(null)
		this.setPages([])
		this.setUncheckedPages(false)
		this.unchecked = false

		// Check socket connection, we might need it for exporting
		this.$store.dispatch('application/socketCheckConnection')

		this.fetchDocument({
			payload: {
				key: this.$route.params.key
			}
		}).then((response) => {
			this.isLoading = false
			//now load the pages
			this.refreshDocumentPages()
		}).catch((error) => {
			this.isLoading = false
		})

		this.fetchDocumentImages({
			payload: { key: this.$route.params.key }
		}).then((response) => {
			this.isLoading = false
		}).catch((error) => {
			this.isLoading = false
		})

		EventBus.$on('DOCUMENT_SHARES_UPDATED_SUCCESS', (msg) => {
			this.$snackbar.showMessage({ content: this.$t("document.msg.sharedUpdated"), 
				color: 'success' })
		})

		EventBus.$on('DOCUMENT_SHARES_UPDATED_ERROR', (msg) => {
			this.$snackbar.showMessage({ content: this.$t("document.msg.errorSharesUpdates"), 
				color: 'error' })
		})

	},
}
</script>

<style lang="scss" scoped>
.horizontal-container {
	margin: 10px 0;
	padding: 15px 10px;
	display: flex;
	height: 410px;
	overflow-x: auto;
	border: 1px #77777761 solid;
}

.doc-title {
	width: 30%;
}

.v-card {
	transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
	opacity: 0.8;
}

.page-saved-img-border {
	border: 2px solid var(--v-primary-base) !important;
}

.validated-corner {
	background-color: transparent !important;
	color: blue;
	right: auto;
	left: 0;
	top: 0;
	border-color: green;
	display: block;
	position: absolute !important;
	height: 48px;
	width: 48px;
	text-align: center;
	z-index: 1;
	float: left;


}

.validated-check {
	background-color: var(--v-primary-base);
	color: var(--v-primary-lighten5);
}

.validated-corner:after {
	border-top: 4em solid transparent;
	border-right: 4em solid transparent;
	border-bottom: 0 solid transparent;
	border-left: 0 solid transparent;
	border-top-color: inherit;
}
</style>
<style>
.select-pages {
	max-width: 200px !important;
	margin-top: 5px;
}
</style>