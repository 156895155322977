<template>
	<div>
		<v-row>
			<v-col cols="4" class="section-label">
				{{ $t("page.sidebarZone.caption") }}
			</v-col>
			<v-col cols="8">
				<v-text-field dense solo v-model="selectedZone.caption" @input="updateSelectedZone"></v-text-field>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				{{ $t("page.sidebarZone.summary") }}
				<v-textarea solo label="" v-model="selectedZone.summary" @input="updateSelectedZone"></v-textarea>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="4" class="section-label">
				{{ $t("page.sidebarZone.merge") }}
			</v-col>
			<v-col cols="8">
				<v-checkbox class="mt-1" v-model="selectedZone.merge" @change="updateSelectedZone"></v-checkbox>
			</v-col>
		</v-row>

		<v-row>
			<v-col class="text-center">
				<v-btn color="primary" small elevation="2" @click="clickEditTable">
					{{ $t("page.sidebarZone.tableEditor") }}
				</v-btn>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex"
import EventBus from "@/eventBus"

export default {
	data() {
		return {}
	},
	components: {},
	computed: {
		...mapGetters({
			selectedZone: "page/getSelectedZone",
			language: "language/getLanguage",
			options: "application/getOptions"
		}),
	},

	methods: {
		...mapMutations({
			setSelectedZone: "page/SET_SELECTED_ZONE",
		}),
		clickEditTable() {
			EventBus.$emit("EDIT_TABLE", {})
		},
		updateSelectedZone(){
			this.setSelectedZone(this.selectedZone)
		},
	}
}
</script>
