import Vue from "vue"
import Vuex from "vuex"
import language from "./language"
import stats from "./modules/stats"
import application from "./modules/application"
import user from "./modules/user"
import documents from "./modules/documents"
import document from "./modules/document"
import page from "./modules/page"
import settings from "./modules/settings"

Vue.use(Vuex)

export default new Vuex.Store({
  name: "store",
  namespaced: true,
  modules: {
    language,
    stats,
    application,
    user,
    documents,
    document,
    page,
    settings,
  },
})
