<!-- This is the left hand sidebar Cells Tab for the table editor.  This goes in TableEditorSideBar -->
<template>
	<v-tab-item key="Cells" class="tab-item-wrapper">
		<v-card fluid fill-height class="side-bar-tab side-bar-bg tab-item-wrapper scrolling-tab">

			<div class="pa-2">
				<span v-if="selectedTableCell.id">Row: {{selected_row_index}} Column:{{ selected_col_index }}</span>
				<span v-else>{{ $t("page.sideBarCells.noCellSelected") }}</span>
			</div>
		
			<div class="room-for-scrollbar pl-2" v-if="selectedTableCell.id">
				<SectionTitle :title="$t('page.sideBarCells.cellSource')" v-if="selectedCell"/>

				<v-row v-if="selectedTableCell">
					<v-col v-if="selectedTableCell" cols="3" class="section-label">{{ $t("page.sideBarCells.source") }}</v-col>
					<v-col>
						<v-select v-if="selectedTableCell" item-text="name" item-value="value" :items="sourceTypes" dense solo
							v-model="selectedTableCell.source" @change="sourceChanged"></v-select>
					</v-col>
				</v-row>

				<!-- for source == OCR -->
				<v-row v-show="selectedTableCell != null && selectedTableCell.source == 'OCR' && selectedTableCell.ocrText">
					<v-col>
						<v-textarea solo color="white" auto-grow v-model="selectedTableCell.ocrText" 
							v-if="selectedTableCell != null && selectedTableCell.ocrText"
							@input="onTextAreaChange"></v-textarea>
					</v-col>
				</v-row>
			
				<!-- for source == OCR -->
				<v-row v-if="selectedTableCell && selectedTableCell != null && selectedTableCell.source == 'OCR' && !selectedTableCell.ocrText">
					<v-col align="center">
						<v-btn color="primary" class="pt-4 pb-4" small dense elevation="2" 
							@click="getOcr" :loading="selectedTableCell.callingOcr">
							{{ $t("page.sideBarCells.convertToText") }}
						</v-btn>
					</v-col>
				</v-row>

				<!-- for source == Custom -->

				<v-row v-show="selectedTableCell != null && selectedTableCell.source == 'Custom'">
					<v-col>
						<v-textarea solo color="white" auto-grow v-model="selectedTableCell.customText" placeholder="Enter custom text here"
							@input="onTextAreaChange" v-if="selectedTableCell"></v-textarea>
					</v-col>
				</v-row>


				<!-- for source == Actual -->

				<v-row v-show="selectedTableCell != null && selectedTableCell.source == 'Actual'">
					<v-col>
						<v-textarea solo color="white" auto-grow v-model="selectedTableCell.actual_text" placeholder="Enter actual text here"
							@input="onTextAreaChange" v-if="selectedTableCell"></v-textarea>
					</v-col>
				</v-row>

				<SectionTitle :title="$t('page.sideBarCells.columnAdjustments')" />

				<v-row>
					<v-col align="center">
						<v-btn color="primary" class="pt-4 pb-4" x-small dense elevation="2" @click="nudgeLeft">
							{{ $t("page.sideBarCells.nudgeLeft") }}
						</v-btn>

						<v-btn color="primary" class="pt-4 pb-4" x-small elevation="2" @click="nudgeRight">
							{{ $t("page.sideBarCells.nudgeRight") }}
						</v-btn>
					</v-col>
				</v-row>

				<SectionTitle :title="$t('page.sideBarCells.rowAdjustments')" />

				<v-row>
					<v-col align="center">
						<v-btn color="primary" class="pt-4 pb-4" x-small dense elevation="2" @click="nudgeUp">
							{{ $t("page.sideBarCells.nudgeUp") }}
						</v-btn>

						<v-btn color="primary" class="pt-4 pb-4" x-small elevation="2" @click="nudgeDown">
							{{ $t("page.sideBarCells.nudgeDown") }}
						</v-btn>
					</v-col>
				</v-row>

				<SectionTitle :title="$t('page.sideBarCells.columnOperations')" />


				<v-row>
					<v-col align="center">
						<v-btn color="primary"  class="pt-4 pb-4" x-small dense elevation="2" @click="splitColumn(selected_col_index)">
							{{ $t("page.sideBarCells.splitColumn") }}
						</v-btn>

						<v-btn color="primary"  class="pt-4 pb-4" x-small elevation="2" @click="removeColumn(selected_col_index)">
							{{ $t("page.sideBarCells.mergeRight") }}
						</v-btn>
					</v-col>
				</v-row>

				<SectionTitle :title="$t('page.sideBarCells.rowOperations')" />

				<v-row>
					<v-col align="center">
						<v-btn color="primary" class="pt-4 pb-4" x-small dense elevation="2" @click="splitRow(selected_row_index)">
							{{ $t("page.sideBarCells.splitRow") }}
						</v-btn>

						<v-btn color="primary" class="pt-4 pb-4" x-small elevation="2" @click="removeRow(selected_row_index)">
							{{ $t("page.sideBarCells.mergeDown") }}
						</v-btn>
					</v-col>
				</v-row>

				<SectionTitle :title="$t('page.sideBarCells.spanOperations')" v-show="selectedCells || isSpan || isRowSpan"/>

				<v-row>
					<v-col align="center">
						<v-btn v-if="showSpanColumns" color="primary" class="pt-4 pb-4" x-small dense elevation="2" @click="spanColumns(selectedCells)">
							{{ $t("page.sideBarCells.spanColumns") }} {{ selectedCells.columns != null ? selectedCells.columns.start + 1 : '' }} - {{ selectedCells.columns != null ?
							selectedCells.columns.end + 1 : ''}}
						</v-btn>

						<v-btn v-if="showSpanRows" color="primary" class="pt-4 pb-4" x-small elevation="2" @click="spanRows(selectedCells)">
							{{ $t("page.sideBarCells.spanRows") }} {{ selectedCells.rows != null ? selectedCells.rows.start + 1 : '' }} - {{ selectedCells.rows != null ?
							selectedCells.rows.end + 1 : ''}}
						</v-btn>

						<v-btn v-if="isSpan || isRowSpan" color="primary" class="pt-4 pb-4" x-small elevation="2" @click="removeSpans(selectedCells)">
							{{ $t("page.sideBarCells.removeSpans") }}
						</v-btn>

					</v-col>
				</v-row>

			</div>
		</v-card>

	</v-tab-item>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import i18n from "@/plugins/i18n"
import StaticDataMixin from "@/mixins/staticData"
import SectionTitle from "./../SectionTitle.vue"
import EventBus from "@/eventBus"

export default {
	name: "SideBarCells",
	props: ["page", "document", "tableZone", "selected_row_index",
		"selected_col_index", "selectedCells", "selectedCell",
		"isSpan", "isRowSpan", "selectedHead"],
	data() {
		return {
			sourceTypes: [{ id: "PDF", name: "PDF" },
										{ id: 'Actual', name: "Actual"},
										{ id: "OCR", name: "OCR"},
										{ id: "Custom", name: "Custom"}],
		}
	},
	computed: {
		...mapGetters({
			//zoomLevel: "application/getZoomLevel", //passed in as prop
			selectedZone: "page/getSelectedZone",
			selectedTableCell: "page/getSelectedCell"
		}),		
		showSpanColumns() {
			var retval = false
			if (this.selectedCells != undefined  && this.selectedCells.columns != undefined) {
				retval = !this.isSpan && !this.isRowSpan && this.selectedCells.columns.end > this.selectedCells.columns.start
			}
			return retval
		},

		showSpanRows() {
			var retval = false
			if (this.selectedCells != null && this.selectedCells.rows != undefined) {
				retval = !this.isSpan && !this.isRowSpan && this.selectedCells.rows != undefined && this.selectedCells.rows.end > this.selectedCells.rows.start
			}
			return retval
		},

	},
	components: {
		SectionTitle,
	},

	watch: {
		//for testing
		
		selectedHead: {
			handler(newVal, oldVal) {
				console.log('selectedHead changed in sideBarCells:', newVal)
				this.$forceUpdate()
			},
			deep: true
		},
		
	},

	methods: {
		...mapActions({
			getSelectedCellCustomText: "page/getSelectedCellCustomText",
			getSelectedCellOCRText: "page/getSelectedCellOCRText",
			updateSelectedCellText: "page/updateSelectedCellText", 
		}),	
		/*
			Need to use force update on selectedHead attributes since they are not picked up correctly by vue
		*/
		onTextAreaChange(){
			if (this.selectedTableCell ) {
				this.updateSelectedCellText({ payload: { 
						customText: this.selectedTableCell.customText,
						actual_text: this.selectedTableCell.actual_text,
						ocrText: this.selectedTableCell.ocrText,
						cell: this.selectedTableCell
					}})
			}
			this.$forceUpdate()
		},
		nudgeUp() {
			EventBus.$emit("nudge-up", {})
		},
		nudgeDown() {
			EventBus.$emit("nudge-down", {})
		},
		nudgeRight() {
			EventBus.$emit("nudge-right", {})
		},
		nudgeLeft() {
			EventBus.$emit("nudge-left", {})
		},
		splitColumn(index) {
			EventBus.$emit("split-column", index)
		},
		removeColumn(index) {
			EventBus.$emit("remove-column", index)
		},
		splitRow(index) {
			EventBus.$emit("split-row", index)
		},
		removeRow(index) {
			EventBus.$emit("remove-row", index)
		},
		spanColumns(selectedCells) {
			EventBus.$emit("span-columns", selectedCells)
		},
		spanRows(selectedCells) {
			EventBus.$emit("span-rows", selectedCells)
		},
		removeSpans(){
			EventBus.$emit("remove-spans", {})
		},
		sourceChanged(source){
			this.$store.commit("page/UPDATE_SELECTED_CELL_SOURCE", source)
			if (this.selectedTableCell && (source === 'Custom' || source === 'Actual')) {
				this.getSelectedCellCustomText({ payload: { 
						page_no: this.page.page_no,
						doc_key: this.document.key,
						tableZoneX: this.tableZone['x'], 
						tableZoneY: this.tableZone['y'],
						cell: this.selectedTableCell
					}})
			}
			// selectedCellSourceChanged
			// EventBus.$emit("source-changed", {})
		},
		getOcr(){
			console.log("Getting ocr for cell!?")

			//set calling ocr flag to true so we know if we're waiting on something to come back
			if (this.selectedTableCell) {
				this.$store.commit("page/UPDATE_SELECTED_CELL_CALLING_OCR", true)
				
				this.getSelectedCellOCRText({
					payload: {
						doc_key: this.document.key,
						page_no: this.page.page_no,
						tableZoneX: this.tableZone['x'], 
						tableZoneY: this.tableZone['y'],
						cell: this.selectedTableCell
					}
				}).then((response) => {
					// Update the state of the cell ocr status and transactionid needed for the callback 
					var data = undefined;
					if (response.data !== undefined && response.data.data !== undefined) {
						data = response.data.data;
						if (data !== undefined && data.transactId != undefined) {
							this.$store.commit("page/UPDATE_SELECTED_CELL_OCR_STATUS", data.transactId)
						}						
					}

				}).catch((error) => {
					this.$snackbar.showMessage({ content: this.$t("page.tableEditor.msg.unableToOCR"), color: 'error' })
					EventBus.$emit("callback-ocr", undefined)
					this.$store.commit("page/UPDATE_SELECTED_CELL_CALLING_OCR", false)
				})

			}
		}
	},
	mounted() {
		// EventBus.$on("selected-head-changed", (selectedHead) => {
		// 	console.log('selectedHead change event picked up in sideBarCells')
		// 	this.$forceUpdate()
		// })

	},
	beforeDestroy(){
		EventBus.$off('selected-head-changed')
	}
}

</script>

<style lang="scss" scoped>
div > .section-title {
	height: 30px;
	margin-bottom: 8px;
}
.tab-item-wrapper {
	/* vuetify sets the v-tabs__container height to 48px */
	height: calc(100vh - 48px)
}

.short-text {
	width: 65px;
	max-width: 65px;
}

.side-bar {
	position: absolute;
	top: 5px;
	left: 5px;
	width: 300px;
	padding: 1px;
}

.side-bar-tab {
	padding: 6px;

	&>* {
		font-size: 14px;

		.col {
			padding: 2px 10px;
			min-height: 55px;
		}

		.v-btn {
			margin: 10px;
		}
	}
}

.room-for-scrollbar {
	width: 97%;
}

.section-label {
	white-space: nowrap !important;
	margin-top: 10px;
}

.v-btn.v-size--small {
	margin-top: 1px;
	margin-right: 0px;
	height: 38px;
}

.theme--light.v-card.side-bar-bg {
	background-color: #f3f3f3;
}

.theme--dark.v-card.side-bar-bg {
	background-color: #3c3c3c;
}

.scrolling-tab {
	overflow-y: scroll;
	height: 100vh;
}

.scroll-margin-bottom {
	margin-bottom: 100px;
}

.btn-group-item {
	margin: 0 !important;
	padding: 0 !important;
}

.section-divider {
	margin-bottom: 5px;
	margin-top: -31px;
	padding-bottom: 20px;
}
</style>