<template>
	<v-tab-item key="Contrast" class="scroll-margin-bottom" v-if="isContrastZone(selectedZone)">

		<v-select v-if="contrastZones && Array.isArray(contrastZones)" class="base pl-1 pr-1" :items="contrastZones"
			item-text="name" item-value="id" v-model="dropDownSelection" return-object @change="onZoneSelected"
			style=""></v-select>

		<v-card ref="zoneProperties" fluid fill-height class="side-bar-tab side-bar-bg scrolling-tab">
			<div v-if="selectedZone" class="room-for-scrollbar pl-2" style="">
				<SectionTitle :title="selectedZone.name" style="height:15px;" />
			</div>

			<div id="color-contrast">
				<div id="boxes">
					<div id="aa-large" :class="{ 'fail': contrast_ratio > 0.33333 }">
						<span>Large <br> Text</span>
						<span>WCAG AA</span>
					</div>
					<div id="aa-normal" :class="{ 'fail': contrast_ratio > 0.22222 }">
						<span>Normal <br> Text</span>
						<span>WCAG AA</span>
					</div>
					<div id="aaa-large" :class="{ 'fail': contrast_ratio > 0.22222 }">
						<span>Large <br> Text</span>
						<span>WCAG AAA</span>
					</div>
					<div id="aaa-normal" :class="{ 'fail': contrast_ratio > 0.14285 }">
						<span>Normal<br> Text</span>
						<span>WCAG AAA</span>
					</div>
				</div>
			</div>

			<div class="mt-4">
				<!-- room for label -->
				<h3 class="font-weight-light">Text Preview</h3>
				<div id="sample-text" class="font-weight-medium"
					style="color:rgb(0,0,0);background:rgb(255,255,255);margin-top:4px;">
					{{ selectedZone.text }}
				</div>
			</div>

			<div class="bars">
				<h3 class="font-weight-light">Text Color</h3>

				<div style="margin-top:10px;">
					<div class="mb-n1 ml-n1">
						<!--<label for="color-1-r" class="red">R</label>-->
						<v-chip color="red" text-color="white">R</v-chip>
						<!--
						<input id="color-1-r" type="range" min="0" max="255" v-model="foreColors.fR"
							@input="updateColors([foreColors.fR, foreColors.fG, foreColors.fB], [selectedZone.b.R, selectedZone.b.G, selectedZone.b.B])" />
						-->
						<v-slider id="color-1-r" min="0" max="255" v-model="foreColors.fR"
							@input="updateColors([foreColors.fR, foreColors.fG, foreColors.fB], [selectedZone.b.R, selectedZone.b.G, selectedZone.b.B])"
							class="mr-1"></v-slider>

						<v-text-field dense solo class="mt-n1" style="max-width:70px;" id="number-1-r" type="number" min="0" max="255"
							v-model="foreColors.fR"
							@input="updateColors([foreColors.fR, foreColors.fG, foreColors.fB], [selectedZone.b.R, selectedZone.b.G, selectedZone.b.B])" />
					</div>

					<div class="mb-n1  ml-n1">
						<!--<label for="color-1-g" class="green">G</label>-->
						<v-chip color="green" text-color="white">G</v-chip>
						<v-slider id="color-1-g" min="0" max="255" v-model="foreColors.fG"
							@input="updateColors([foreColors.fR, foreColors.fG, foreColors.fB], [selectedZone.b.R, selectedZone.b.G, selectedZone.b.B])"
							class="mr-1"></v-slider>
						<v-text-field dense solo class="mt-n1" style="max-width:70px;" id="number-1-g" type="number" min="0" max="255"
							v-model="foreColors.fG"
							@input="updateColors([foreColors.fR, foreColors.fG, foreColors.fB], [selectedZone.b.R, selectedZone.b.G, selectedZone.b.B])" />
					</div>

					<div class="mb-n1  ml-n1">
						<!--<label for="color-1-b" class="blue">B</label>-->
						<v-chip color="indigo" text-color="white">B</v-chip>
						<v-slider id="color-1-b" type="range" min="0" max="255" v-model="foreColors.fB"
							@input="updateColors([foreColors.fR, foreColors.fG, foreColors.fB], [selectedZone.b.R, selectedZone.b.G, selectedZone.b.B])"
							class="mr-1"></v-slider>
						<v-text-field dense solo class="mt-n1" style="max-width:70px;" id="number-1-b" type="number" min="0" max="255"
							v-model="foreColors.fB"
							@input="updateColors([foreColors.fR, foreColors.fG, foreColors.fB], [selectedZone.b.R, selectedZone.b.G, selectedZone.b.B])" />
					</div>

					<v-text-field class="ml-n1 font-weight-regular" outlined label="Color Hex Value" id="color-1-hex"
						data-target="1" type="text" v-model="foreColors.hex" maxlength="7" @input="hexToRgb" />
				</div>
			</div>
			<div class="mt-n4">
				<h3 class="font-weight-light" style="font-size: 1.1em;">Zone Display</h3>
				<v-switch dense v-model="showContrastZones" class="mt-1" label="Show Contrast Zones"
					@change="toggleShowContrastZones"></v-switch>
				<v-switch dense v-model="showOtherZones" class="mt-n2" label="Show Other Zones"
					@change="toggleShowOtherZones"></v-switch>
			</div>

			<!-- without these, can't scroll down far enough to see all of the Zone Position vals -->
			<div class="sidebar-bottom-padding">
				<br />
			</div>


		</v-card>

	</v-tab-item>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import SectionTitle from "./SectionTitle"
import EventBus from "@/eventBus"
import helpers from "@/helpers/common"

export default {
	props: ["contrastZones", "page", "document"],

	data() {
		return {
			showOtherZones: true,
			showContrastZones: true,
			dropDownSelection: null,
			foreColors: { fR: 0, fG: 0, fB: 0, hex: '000000' },
			contrast_ratio: 0,
			foregroundColor: null,
			backgroundColor: null,
		}
	},
	components: {
		SectionTitle,
	},
	computed: {
		...mapGetters({
			selectedZone: "page/getSelectedZone",
		}),
	},
	watch: {
		selectedZone: {
			handler(newVal, oldVal) {
				var updateSelectedZoneColorSettings = this.updateSelectedZoneColorSettings
				setTimeout(function () {
					updateSelectedZoneColorSettings()
				}, 200)

			}
		},
	},
	methods: {
		...mapActions({

		}),

		...mapMutations({
			setSelectedZone: "page/SET_SELECTED_ZONE",
		}),

		isContrastZone(zone) {
			return helpers.isContrastZone(zone)
		},
		onZoneSelected(zone) {
			this.$store.commit("page/SET_SELECTED_ZONE", zone)
		},
		/* the following functions are adapted from https://stackoverflow.com/a/9733420/3695983 */
		updateDemoColors(colorFront, colorBack) {
			var sampleTextElem = document.getElementById('sample-text')
			sampleTextElem.style.color = `rgb(${colorFront})`
			sampleTextElem.style.backgroundColor = `rgb(${colorBack})`
		},
		luminance(r, g, b) {
			var a = [r, g, b].map(function (v) {
				v /= 255;
				return v <= 0.03928
					? v / 12.92
					: Math.pow((v + 0.055) / 1.055, 2.4)
			});
			return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
		},
		contrast(rgb1, rgb2) {
			const luminanceFront = this.luminance(rgb1[0], rgb1[1], rgb1[2])
			const luminanceBack = this.luminance(rgb2[0], rgb2[1], rgb2[2])
			return luminanceBack > luminanceFront
				? ((luminanceFront + 0.05) / (luminanceBack + 0.05))
				: ((luminanceBack + 0.05) / (luminanceFront + 0.05))
		},
		updateBoxesColors(colorFront, colorBack) {
			this.contrast_ratio = this.contrast(colorFront, colorBack)
		},
		updateHex(colorFront, colorBack) {
			const colorFrontHex = colorFront.map(function (el) { return Number(el).toString(16).padStart(2, "0").toUpperCase() })
			const colorBackHex = colorBack.map(function (el) { return Number(el).toString(16).padStart(2, "0").toUpperCase() })
			this.foregroundColor = `#${colorFrontHex.join('')}`
			this.backgroundColor = `#${colorBackHex.join('')}`
		},
		updateContrastZone(colorFront) {
			if (this.selectedZone !== undefined) {
				this.selectedZone.f.R = colorFront[0]
				this.selectedZone.f.G = colorFront[1]
				this.selectedZone.f.B = colorFront[2]
				const colorFrontHex = colorFront.map(function (el) { return Number(el).toString(16).padStart(2, "0").toUpperCase() })
				this.selectedZone.f.hex = `${colorFrontHex.join('')}`
				this.foreColors.hex = this.selectedZone.f.hex
			}
		},
		updateColors(colorFront, colorBack) {
			this.updateDemoColors(colorFront, colorBack)
			this.updateBoxesColors(colorFront, colorBack)
			this.updateHex(colorFront, colorBack)
			this.updateContrastZone(colorFront)
		},
		updateSelectedZoneColorSettings() {
			if (this.selectedZone !== undefined) {
				//$('#' + zone.id).addClass('highlight').addClass(getRingWidthClass()).siblings().removeClass('highlight');
				this.foreColors = { fR: 0, fG: 0, fB: 0 }
				this.foreColors.fR = parseInt(this.selectedZone.f.R)
				this.foreColors.fG = parseInt(this.selectedZone.f.G)
				this.foreColors.fB = parseInt(this.selectedZone.f.B)
				this.foreColors.hex = this.selectedZone.f.hex;

				document.querySelector('#color-1-r').value = this.foreColors.fR
				document.querySelector('#color-1-g').value = this.foreColors.fG
				document.querySelector('#color-1-b').value = this.foreColors.fB
				document.querySelector('#number-1-r').value = this.foreColors.fR
				document.querySelector('#number-1-g').value = this.foreColors.fG
				document.querySelector('#number-1-b').value = this.foreColors.fB

				var colorFront = [this.selectedZone.f.R, this.selectedZone.f.G, this.selectedZone.f.B]
				var colorBack = [this.selectedZone.b.R, this.selectedZone.b.G, this.selectedZone.b.B]

				this.contrast_ratio = this.contrast(colorFront, colorBack);

				//second section
				colorFront = [this.selectedZone.f.R, this.selectedZone.f.G, this.selectedZone.f.B]
				colorBack = [this.selectedZone.b.R, this.selectedZone.b.G, this.selectedZone.b.B]
				this.updateDemoColors(colorFront, colorBack)
				this.updateBoxesColors(colorFront, colorBack)
				this.updateHex(colorFront, colorBack)
			}
		},
		hexToRgb() {
			var bigint = parseInt(this.foreColors.hex, 16)
			var r = (bigint >> 16) & 255;
			var g = (bigint >> 8) & 255;
			var b = bigint & 255;
			this.foreColors.fR = r
			this.foreColors.fG = g
			this.foreColors.fB = b
			var colorFront = [r, g, b]
			var colorBack = [this.selectedZone.b.R, this.selectedZone.b.G, this.selectedZone.b.B]
			this.updateDemoColors(colorFront, colorBack)
			this.selectedZone.f.R = r
			this.selectedZone.f.G = g
			this.selectedZone.f.B = b
			this.selectedZone.f.hex = this.foreColors.hex
			this.setSelectedZone(this.selectedZone)
		},
		toggleShowContrastZones() {
			EventBus.$emit("SHOW_CONTRAST_ZONES")
		},
		toggleShowOtherZones() {
			EventBus.$emit("SHOW_OTHER_ZONES")
		},
	},
	mounted() {

	},
}
</script>

<style scoped>
.v-row {
	margin-top: 0px;
	margin-bottom: 0px;
}

.short-text {
	width: 65px;
	max-width: 65px;
}

.side-bar {
	position: absolute;
	top: 5px;
	left: 5px;
	width: 300px;
	padding: 1px;
}

.side-bar-tab {
	padding: 6px;

	&>* {
		font-size: 14px;

		.col {
			padding: 2px 10px;
			min-height: 55px;
		}

		.v-btn {
			margin: 10px;
		}
	}
}

.small-text {
	font-size: .9em;
}

.room-for-scrollbar {
	width: 97%;
}

.section-label {
	white-space: nowrap !important;
	margin-top: 10px;
}

.v-btn.v-size--small {
	margin-top: 1px;
	margin-right: 0px;
	height: 38px;
}

.theme--light.v-card.side-bar-bg {
	background-color: #f3f3f3;
}

.theme--dark.v-card.side-bar-bg {
	background-color: #3c3c3c;
}

.scrolling-tab {
	overflow-y: scroll;
	height: 100vh;
}

.scroll-margin-bottom {
	margin-bottom: 100px;
}

.btn-group-item {
	margin: 0 !important;
	padding: 0 !important;
	color: #003366 !important;
}

.section-divider {
	margin-bottom: 5px;
	margin-top: -31px;
	padding-bottom: 20px;
}

/* Tool general styles */
#color-contrast {
	width: 340px;
	margin-left: -18px;
	box-sizing: border-box;
	padding: 2rem;
	/*position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);*/
}

/* boxes */
#boxes {
	display: flex;
	margin-top: 1.5rem;
}

#boxes>div {
	flex: 1;
	text-align: center;
	background: #081;
	color: white;
	padding: 0.5rem 0;
	margin-left: 14px;
	line-height: 95%;
	border-radius: 3px;
	transition: background 0.5s;
	position: relative;
}

#boxes>div::after {
	content: "✓ Pass";
	display: block;
	position: absolute;
	color: #000;
	text-align: center;
	width: 100%;
	margin-top: 12px;
}

#boxes>div.fail {
	background: #d32;
}

#boxes>div.fail::after {
	content: "✕ Fail";
}

#boxes>div:first-child {
	margin-left: 0;
}

#boxes span:first-child {
	font-size: 0.75em;
	display: inline-block;
	margin-bottom: 0.5em;
}

/* demo */
#sample-text {
	margin: 2.5rem 0 1.6rem 0;
	padding: 1rem;
	color: #000;
	text-align: center;
	font-size: 20px;
	border-radius: 2px;
}

/* sliders */
.bars {
	/*display: flex;*/
	flex-direction: row;
}

.bars>div {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.bars>div:first-child {
	margin-right: 0.5rem;
}

.bars>div:last-child {
	margin-left: 0.5rem;
}

.bars>div>div {
	display: flex;
	margin-bottom: 0.5rem;
}

.bars label {
	display: inline-block;
	color: white;
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	font-size: 1rem;
	text-align: center;
	border-radius: 2px;
	box-sizing: border-box;
}

label.red {
	background: #d32;
}

label.green {
	background: #081;
}

label.blue {
	background: #26e;
}

.bars input[type="range"] {
	flex: 1;
}

.bars input[type="number"] {
	text-align: center;
	width: 3rem;
	border-radius: 2px;
	border: 1px solid #ccc;
	font-size: 0.75rem;
	height: 2rem;
	box-sizing: border-box;
	line-height: 2rem;
}

.bars input[type="text"] {
	border-radius: 2px;
	border: 1px solid #ccc;
	text-align: center;
	font-size: 1rem;
	height: 2rem;
	box-sizing: border-box;
	line-height: 2rem;
}
</style>