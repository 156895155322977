<template>
  <v-card>
    <v-tabs
      v-model="selectedTab"
      center-active
      show-arrows
      class="tab-headers"
    >
      <v-tab key="properties">{{ $t("document.tabProperties.tabTitle") }}</v-tab>
      <v-tab key="unicode" v-if="document.total_unicode_errors > 0  && options.pro_cust">{{$t("document.tabUnicodeMapping.tabTitle")}}
				<span style="margin-left:5px;color: #b71c1c;">{{ document.total_unicode_fixed }} / {{ document.total_unicode_errors }}</span></v-tab>
      <v-tab key="tabImages">{{ $t("document.tabImages.tabTitle") }}</v-tab>
      <v-tab key="zoneTransfer" v-show="options.zone_transfer_enabled" >{{ $t("document.tabZoneTransfer.tabTitle") }}</v-tab>
      <v-tab key="pageNotes">{{ $t("document.tabPageNotes.tabTitle") }}</v-tab>
      <v-tab key="history">{{ $t("document.tabHistory.tabTitle") }}</v-tab>
      <v-tab key="export">{{ $t("document.tabExport.tabTitle") }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab">
      <TabProperties v-bind:document="document" />
			<span v-if="document.total_unicode_errors > 0  && options.pro_cust"><TabUnicodeMapping v-bind:document="document" /></span>
      <TabImages />
      <TabZoneTransfer v-bind:document="document" v-bind:parentTab="parentTab"/>
      <TabPageNotes v-bind:document="document" />
      <TabHistory />
      <TabExport v-bind:document="document"/>
    </v-tabs-items>
    
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import i18n from "@/plugins/i18n"
import TabProperties from "./tabs/TabProperties"
import TabUnicodeMapping from "./tabs/TabUnicodeMapping.vue"
import TabImages from "./tabs/TabImages"
import TabZoneTransfer from "../../settings/components/tabs/TabZoneTransfer.vue"
import TabPageNotes from "./tabs/TabPageNotes"
import TabHistory from "./tabs/TabHistory"
import TabExport from "./tabs/TabExport"

export default {
  props: ["document"],
  data() {
    return {
      selectedTab: "properties",
			parentTab: "documentTab",
    }
  },
  components: {
    TabProperties,
		TabUnicodeMapping,
    TabImages,
    TabZoneTransfer,
    TabPageNotes,
    TabHistory,
    TabExport,
  },
  computed: {
    ...mapGetters({
      language: "language/getLanguage",
			options: "application/getOptions",
    }),	
  },
	watch: {
		'document.total_unicode_fixed': function(newVal, oldVal){
			console.log('total unicode fixed changed:' + this.document.total_unicode_fixed)
			this.$forceUpdate()
		}
	},
  methods: {

	},
}
</script>
<style lang="scss" scoped>

.v-tab {
  border: 1px solid lightgray;
  font-weight: bold;
  color: var(--v-primary-base);
  min-width: 200px;
}
.v-tabs-slider-wrapper {
  min-width: 210px;
}

.v-tabs-items {
  border: 1px solid lightgray;
}
.v-tab--active {
  background-color: lightgray;
  color: #003366;
}

</style>
<style lang="scss">
.tab-document {
	max-width: none !important;
}
</style>