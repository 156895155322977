export default {
    getLabels(state) {
        return state.labels
    },
		//getLabel is prob not used
    getLabel(state) {
        return state.label
    },
    //using suffix List for plural css
    getCssList(state) {
        return state.cssList
    },
    getGroups(state) {
        return state.groups
    },
    getGroup(state) {
        return state.group
    },
    getCustomerUsers(state){
        return state.customerUsers
    },
    getAccount(state){
        return state.account
    },
    getAccounts(state){
        return state.accounts
    },
    getSourcePageCount(state){
        return state.sourcePageCount
    },
    getDestinationPageCount(state){
        return state.destinationPageCount
    },
    getDocument(state){
        return state.document
    },

}
