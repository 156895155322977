import Documents from '../components/Documents'

export default [
    {
        path: '/documents',
        name: 'Documents',
        component: Documents,
        meta: {
            requiresAuth: true,
            i18Msg: 'barTitle.documents'
        }
    },
]