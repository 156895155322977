<template>
    <v-row class="progressBar mx-auto lighten-2 ma-5">
        <v-col class="text-body-2 pa-2 " cols="5">
        {{ document.total_pages_validated }} /
        {{ document.total_pages }} ({{
            Math.ceil(
            (document.total_pages_validated * 100) / document.total_pages
            )
        }}%)
        </v-col>
        <v-col class="mt-1 pa-2">
            <v-progress-linear
                color="primary"
                background-color="grey"
                height="15"
                :value="
                (document.total_pages_validated * 100) / document.total_pages
                "
                striped
            ></v-progress-linear>
        </v-col>
    </v-row>
    
</template>
<script>

export default {
  props: ['document'],
   data() {
    return {
    }
  },
}
</script>
<style lang="scss" scoped>

.theme--light .progressBar {
  background-color: #f3f3f3;
}
.theme--dark .progressBar {
  background-color: #3c3c3c;
}
</style>