<template>
    <!-- This is what the button in the parent component might look like -->
		<v-container>

		<v-btn class="ml-2" v-bind="buttonStyle.buttons" color="error" @click="deleteItem">
			<v-icon v-if="buttonStyle.icons.display" v-bind:class="buttonStyle.icons.class">mdi-trash-can</v-icon>
			<span v-if="buttonStyle.labels.display">{{ $t("document.delete") }}</span>
		</v-btn>
    

		<!-- Delete dialog -->
		<v-dialog v-model="dialogDelete" max-width="550px">
			<v-card v-if="!options.enable_permanent_delete && document != null">
				<v-card-title class="text-h5">
					{{ $t("documents.headerConfirmDelete") + " " + document.name + "?" }}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeDelete">
						{{ $t("documents.buttonCancel") }}
					</v-btn>
					<v-btn color="blue darken-1" text @click="deleteItemConfirm">
						{{ $t("documents.buttonOk") }}
					</v-btn>
				</v-card-actions>
			</v-card>
			<!-- Permanent or archive version -->
			<v-card v-if="options.enable_permanent_delete && document != null">
				<v-card-title class="text-h5">
					{{ $t("documents.headerArchiveOrPermanentDelete") + " " + document.name + "?" }}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeDelete">
						{{ $t("documents.buttonCancel") }}
					</v-btn>
					<v-btn color="blue darken-1" text @click="deleteItemConfirm">
						{{ $t("documents.buttonArchive") }}
					</v-btn>
					<v-btn color="blue darken-1" text @click="permanentDeleteItemConfirm">
						{{ $t("documents.buttonPermanentDelete") }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		</v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import router from "../router"

export default{
	name: "DeleteDocumentDialog",
	//document is the property we pass in from the parent component ie doc list or grid card
	props: ["document"],

	data() {
		return {
			dialogDelete: false,
		}
	},
	computed: {
    ...mapGetters({
      language: "language/getLanguage",
			options: "application/getOptions",
			buttonStyle: "application/getButtonStyle",
    }),
  },
	watch: {
		dialogDelete(val){
			val || this.closeDelete
		}
	},
	methods: {
		...mapActions({
			deleteDocument: "documents/deleteDocument",
			permanentlyDeleteDocument: "documents/permanentlyDeleteDocument",
		}),
		deleteItem(){
			this.dialogDelete = true
		},
		deleteItemConfirm(){
			this.deleteDoc()
		},
		permanentDeleteItemConfirm() {
			this.permanentlyDeleteDoc()
		},
		closeDelete(){
			this.dialogDelete = false
		},
		permanentlyDeleteDoc() {
			
			this.$snackbar.showMessage({ content: this.$t("document.msg.queuingPermanentDelete"),
				color: 'success' })

			this.permanentlyDeleteDocument({
				payload: {
					doc_key: this.document.key
				}
			}).then((response) => {
				this.$snackbar.showMessage({ content: this.$t("document.msg.permanentlyDeleted"),
					color: 'success' })
			}).catch((error) => {
				this.$snackbar.showMessage({ content: this.$t("document.msg.errorPermanentlyDeleted"),
					color: 'error' })
			})
			this.closeDelete()

			setTimeout(function(){
				return router.push({path: '/documents'})

			}, 300)
		},
		deleteDoc() {
			this.deleteDocument({
				payload:{
					doc_key: this.document.key
				}
			}).then( (response) => {
				if (this.options.enable_permanent_delete) {
					this.$snackbar.showMessage({ content: this.$t("document.msg.archived"),
						color: 'success' })
				}
				else {
					this.$snackbar.showMessage({ content: this.$t("document.msg.deleted"),
						color: 'success' })
				}
			}).catch( (error) => {
				this.$snackbar.showMessage({ content: this.$t("document.msg.errorDelete"),
					color: 'error' })
			})
			this.closeDelete()

			setTimeout(function(){
				return router.push({path: '/documents'})

			}, 300)
		},
	},
}

</script>

<style>

</style>

