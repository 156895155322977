export default {
    getDocument(state) {
      return state.document
    },
    getDocumentPages(state) {
      return state.documentPages
    },
    getDocumentImages(state) {
      return state.documentImages
    },
    getMetadatas(state) {
      return state.metadatas
    },
    getDocumentNotes(state) {
      return state.documentNotes
    },
    getDocumentHistory(state) {
      return state.documentHistory
    },
    getExportingHtml(state) {
      return state.exporting.html
    },
    getExportingPDF(state) {
      return state.exporting.pdf
    },
    getExportingEpub(state) {
      return state.exporting.epub
    },
		getUncheckedPages(state){
			return state.uncheckedPages
		},
		getUnicodeMaps(state){
			return state.unicodeMaps
		},
    getCSS(state){
      return state.css
    }
  }
  