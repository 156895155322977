<template>
  <v-select
    class="pa-3 mt-1"
		style="width:245px;"  
    v-model="label_keys"
    :items="labels"
    item-text="name"
    item-value="key"
    hide-selected
    label="Label Filters"
    multiple
    small-chips
		dense
		@change="(event) => handleSelectionChange(event)"
  >
    <!-- this is a selected item-->
		<template v-slot:selection="{ attrs, item, parent }">
      <v-chip
        v-bind="attrs"
        :input-value="item.key"
        label
				small
      >
				<v-avatar left>
          <v-icon :color="item.color" >mdi-tag</v-icon>
        </v-avatar>
        <span class="pr-2">
          {{item.name != null && item.name.length > 12 ? item.name.substring(0, 12) : item.name}}{{ item.name != null && item.name.length > 12 ? '...' : ''}}
        </span>
        <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
      </v-chip>
    </template>
    <!-- this is the item in the dropdown list -->
    <template v-slot:item="{ item }">
      <v-chip label small>
        <v-avatar left>
          <v-icon :color="item.color">mdi-tag</v-icon>
        </v-avatar>
        {{ item.name }}
      </v-chip>
    </template>
    <!-- only on empty list -->
    <template v-slot:no-data>
      <v-list-item>
        <span class="subheading">Create</span>
      </v-list-item>
    </template>
  </v-select>

</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex"

export default {
  name: "LabelsFilterDropdown",
  
  data() {
    return {
			MAX_SELECTED_LABELS: 5, //max num labels that can be selected
			label_keys: [],
    }
  },
  computed: {
    ...mapGetters({
      labels: "user/getLabels",
			labelFilters: "documents/getLabelFilters",
    }),   
  },
	watch: {
		'labelFilters': function(newVal, oldVal){
			console.log('label filter changed:' + newVal)
			this.label_keys = newVal
		},
	},	
  methods: {
    ...mapActions({
      fetchLabels: "user/fetchLabels",
    }),
		...mapMutations({
			setLabelFilters: "documents/SET_LABEL_FILTERS",
		}),	
		handleSelectionChange(event){
			if(this.label_keys.length > this.MAX_SELECTED_LABELS){
				this.label_keys.pop()
			}
			this.setLabelFilters(event)
		},
  },
  mounted() {
    if (this.labels === undefined) {
        console.log('Fetching labels')
        this.fetchLabels()
    }
  },  
}
</script>