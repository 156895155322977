<template>
	<v-select class="pa-3" v-model="document.label_keys" :items="labels" item-text="name" item-value="key" hide-selected
		label="Add label" multiple small-chips @change="(event) => handleSelectionChange(event)">
		<!-- this is a selected item-->
		<template v-slot:selection="{ attrs, item, parent }">
			<v-chip v-bind="attrs" :input-value="item.key" label small>
				<v-avatar left>
					<v-icon :color="item.color">mdi-tag</v-icon>
				</v-avatar>
				<span class="pr-2">
					{{item.name != null && item.name.length > 12 ? item.name.substring(0, 12) : item.name}}{{ item.name != null &&
					item.name.length > 12 ? '...' : ''}}
				</span>
				<v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
			</v-chip>
		</template>
		<!-- this is the item in the dropdown list -->
		<template v-slot:item="{ item }">
			<v-chip label small>
				<v-avatar left>
					<v-icon :color="item.color">mdi-tag</v-icon>
				</v-avatar>
				{{ item.name }}
			</v-chip>
		</template>
		<!-- only on empty list -->
		<template v-slot:no-data>
			<v-list-item>
				<span class="subheading">Create</span>
			</v-list-item>
		</template>
	</v-select>

</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex"

export default {
	name: "LabelsDropdown",
	props: ["document"],
	data() {
		return {
			MAX_SELECTED_LABELS: 5, //max num labels that can be selected
		}
	},
	computed: {
		...mapGetters({
			labels: "user/getLabels",

		}),
	},
	/*

	NOTE: do not use this to update values.  on reload, every label value in list of docs will trigger this!!

	watch: {
		'document.label_keys': function(newVal, oldVal){
			console.log('watched:' + newVal)
		},
	},
	*/
	methods: {
		...mapActions({
			fetchLabels: "user/fetchLabels",
			updateDocument: "document/updateDocument"
		}),
		...mapMutations({
		updateRecentDocsList: "documents/UPDATE_RECENT_DOCS_LIST",
		updateDocsList: "documents/UPDATE_DOCS_LIST",
	}),
		//use this for handling the event
		handleSelectionChange(event) {
			if (this.document.label_keys.length > this.MAX_SELECTED_LABELS) {
				this.document.label_keys.pop()
			}
			console.log('event:', event)
			this.saveDocument()
		},
		saveDocument() {
			console.log('label keys: ' + this.document.label_keys)
			var document = this.document
			this.updateDocument({
				payload: {
					document: this.document
				}
			})
				.then((response) => {
					//we don't know if this widget is in the recent docs list or the docs list, so we have to update the other list
					this.updateRecentDocsList(document)
					this.updateDocsList(document)
					this.$snackbar.showMessage({ content: this.$t("document.msg.updated"),
						color: 'success' })
				}).catch((error) => {
					this.$snackbar.showMessage({ content: this.$t("document.msg.errorUpdating"),
						color: 'error' })
					//if it failed, we want to remove the last added item
					this.document.label_keys.pop()
				})
		}
	},
	
	mounted() {
		if (this.labels === undefined) {
			console.log('Fetching labels')
			this.fetchLabels()
		}
	},
}
</script>