<template>
	<v-card class="d-flex align-center align-content-center flex-nowrap pa-2" outlined elevation="0" style="width:210px; border-left:none;">
		<v-btn large icon 
			@click="previousPage">
			<v-icon >mdi-chevron-left</v-icon>
		</v-btn>
		<v-text-field class="text-no-wrap" :label="$t('document.gotoPage')" min=0 type="number" dense 
			v-model="toPage" style="max-height:25px;"></v-text-field>
		<span class="mr-1">{{ $t("document.gotoOf") }}</span> {{ document != null ? document.total_pages : 0 }}
		<v-btn large icon 
			@click="nextPage">
			<v-icon>mdi-chevron-right</v-icon>
		</v-btn>
	</v-card>
</template>

<script>
import router from "../router"

export default {
	name: "LabelsDropdown",
	props: ["document", "pageNo"],
	data() {
		return {
			toPage: this.pageNo
		}
	},
	computed: {
		
	},

	watch: {
		'toPage': function (newVal, oldVal) {		
			var goToPage = this.goToPage
			setTimeout(function () {
				goToPage()
			}, 1750)
		},
		'pageNo': function(newVal, oldVal){
			console.log('page has changed to: ', this.pageNo)
			this.toPage = this.pageNo
		},
	},
	methods: {
		previousPage(){
			console.log('current page is: ', this.pageNo)
			if(this.pageNo > 1){
				this.toPage = this.pageNo - 1
				this.goToPage()
			}
		},
		nextPage(){
			if(this.pageNo < this.document.total_pages){
				this.toPage = this.pageNo + 1
				this.goToPage()
			}
		},
		goToPage() {
			if (this.toPage < 0) {
				this.toPage = Math.abs(this.toPage)
			}
			if (this.toPage == 0) {
				this.toPage = 1
			}
			if (this.toPage > this.document.total_pages) {
				this.toPage = this.document.total_pages
			}
			
			var gotoPath = '/document/' + this.document.key + '/page/' + this.toPage 
			if(router.currentRoute.path !== gotoPath){
				return router.push({ path: gotoPath}).catch(() => {})
			}
		}
	}
}
</script>

<style>

</style>