export default {
    getConfig(state) {
      return state.config
    },
    getOptions(state) {
      return state.options
    },
		getZoneToggleSetting(state){
			return state.options.zone_toggle_setting
		},
    getZoomLevel(state) {
      return state.zoomLevel
    },
    getMiniMainMenu(state) {
      return state.miniMainMenu
    },
    getSnackbar(state) {
      return state.snackbar
    },
    getConnectionStatus(state) {
      return state.socket.connectionStatus
    },
    checkConnection(state) {
      return state.checkConnection
    },
		getButtonStyle(state){
			return state.buttonStyle
		},
		getShowSecondToolbar(state){
			return state.showSecondToolbar
		},
		
  };
  