export default {
	appName: "Equidox",
	mainMenu: {
		home: "Home page",
		documents: "Documents",
		importDocuments: "Import Document(s)",
		notifications: "Notifications",
		settings: "Settings",
		help: "Help",
		signOut: "Sign Out"
	},
	userMenu: {
		profile: "Profile",
		language: "Language",
		darkTheme: "Dark Theme",
		darkThemeHint: "Switch to dark theme"
	},
	barTitle: {
		dashboard: "Dashboard",
		documents: "Documents",
		document: "Document detail",
		editor: "Remediation Page",
		import: "Import document(s)",
		notifications: "Notifications",
		settings: "Settings",
		help: "Help",
		signOut: "Sign out",
		profile: "Profile"
	},
	login: {
		signIn: "Sign In",
		email: "Email",
		password: "Password",
		logIn: "Log In",
		forgotPassword: "Forgot Password ?",
		signInWith: "Or sign in with",
		equidoxMainPage: "Equidox main page",
		equidoxTutorials: "Equidox tutorial videos",
		equidoxNewsBlog: "Equidox news blog",
		dontHaveAnAccount: "Don't have an account? ",
		requestADemo: "Request a Demo",
		msg: {
			invalidUserPwd: "Invalid username or password",
			errorLoginGoogle: "Error logging in with Google Account: {0}"
		}
	},
	profile: {
		firstName: "First Name",
		lastName: "Last Name",
		email: "Email",
		phone: "Phone",
		address: "Address (including city and country)",
		description: "Description",
		zoneHighlight: "Zone highlight width",
		autosummarizeMode: "Autosummarize mode",
		outputDefault: "Output default tab",
		customerInstance: "Customer instance",
		save: "Update Profile",
		msg: {
			profileSaved: "Profile Saved"
		}
	},
	documents: {
		recent: "Recent documents",
		list: "Document List",
		resultPerPage: "Results per page",
		sortBy: "Sort by",
		sortItems: ["Imported Date", "Document title", "Status"],
		filterSearch: "Filter Search",
		documentTitle: "Document title",
		importedBy: "Imported by",
		addLabel: "Add label",
		edit: "Edit",
		gridView: "Grid view",
		listView: "List view",
		delete: "Delete",
		zoneTransfer: "Zone Transfer",
		headerConfirmDelete: "Are you sure you want to delete",
		buttonCancel: "Cancel",
		buttonOk: "OK",
		headerArchiveOrPermanentDelete: "Do you want to archive or permanently delete",
		buttonArchive: "Archive",
		buttonPermanentDelete: "Permanently Delete",
		buttonReset: "Reset",
	},
	document: {
		gotoPage: "Go to page",
		gotoOf: "of",
		showUncheckedPages: "Show unchecked pages",
		exportToPDF: "Export to pdf",
		share: "Share",
		delete: "Delete",
		shareDocument: "Share Document ",
		cancel: "Cancel",
		done: "Done",
		shareWithOthers: "Share with others",
		selectPeople: "Select the people you want to share this document with",
		shareWithGroup: "Share with a group",
		selectGroup: "Select the group you want to share this document with",
		tabProperties: {
			tabTitle: "Properties",
			original: "Original document",
			downloadOriginalDocument: "Download original document",
			title: "Title",
			author: "Author",
			createdDate: "Created Date",
			lastModificationDate: "Last modification date",
			producer: "Producer",
			language: "Language",
			addLabel: "Add Label",
			pages: "Pages",
			images: "Images",
			metadata: "Metadata",
			metadataName: "Name",
			metadataValue: "Value",
			addDelete: "Add or Delete Metadata",
			saveProperties: "Save properties",
			deleteMetadata: "Delete metadata",
			addNewMetadata: "Add new metadata",
			msg: {
				metadataSaved: "Metadata {0} saved",
				metadataRemoved: "Metadata {0} removed",
			}
		},
		tabImages: {
			tabTitle: "Images",
			hideAllZones: "Hide all Zones",
			allVisible: "All visible",
			saveImageProperties: "Save image properties",
			hideThisZone: "Hide this zone in the editor",
			visible: "Visible",
			imageDescription: "Enter image description",
			previousPage: "Prev",
			nextPage: "Next",
			imagesTo: "to",
			imagesMany: " of many",
			msg: {
				errorGettingImages: "Error while getting images",
			}
		},
		tabUnicodeMapping: {
			tabTitle: "Map Unicode Characters",
			labelUnicodeCharsRequire: "characters requires unicode mapping on page",
			labelUnicodeMappingOnPage: "Unicode mapping on page ",
			labelOcrAll: "OCR All",
			labelSave: "Save",
			msg: {
				mappingSavedForPage: "Character mapping saved for page {0}"
			}
		},
		tabZoneTransfer: {
			tabTitle: "Zone Transfer",
			sourceDocumentID: "Source Document ID",
			destinationDocumentID: "Destination Document ID",
			sourcePage: "Source Page",
			destinationPage: "Destination Page",
			transferDocumentZones: "Transfer Document Zones",
			cancelTransfer: "Cancel Transfer",
		},
		tabPageNotes: {
			tabTitle: "Page Notes",
			headerPageNo: "Page Number",
			headerNote: "Note",
			headerResolved: "Resolved",
			rowsCount: "Rows per page",
			pageOf: "of",
		},
		tabHistory: {
			tabTitle: "History",
			viewWarnings: "View",
			warnings: "Warnings",
			warningsOnPage: "Warnings on Page ",
			msg: {
				errorGettingHistory: "Unable to get page history", 
				errorLoadingWarnings: "Unable to load warnings",
			}
		},
		tabExport: {
			tabTitle: "Export",
			htmlDocument: "HTML Document",
			selectPageToDownload: "Select document pages to download",
			allPages: "All",
			generatePageIDS: "Generate Page IDs",
			fixNoSpace: "Fix for no spaces",
			flattenFormX: "Flatten formX Objects",
			pdfOutputEngine: "PDF Output Engine",
			filename: "Filename",
			previewHtml: "Preview HTML Document",
			downloadHtml: "Download HTML",
			downloadZones: "Download Zones",
			pdfDocument: "PDF Document",
			generatePdf: "Generate PDF",
			epubDocument: "EPUB Document",
			downloadEpub: "Download EPub",
			css: "CSS",
			documentZones: "Document Zones",
			msg: {
				unableToExport: "Unable to export at this time, please retry in a couple of minutes", 
				errorHtml: "Error generating html",
				errorEpub: "Error generating epub",
				errorPDF: 'Error generating pdf', 
			}
		},
		msg: {
			updated: "Document Updated", 
			errorUpdating:"Error updating Document", 
			documentSaved: "Document Saved",
			permanentlyDeleted: "Document Permanently Deleted", 
			errorPermanentlyDeleted:  "Error Permanently Deleting Document",
			archived: "Document Archived",
			deleted: "Document deleted",
			errorDelete: "Error Deleting Document",
			imagesSaved: "Images Saved",
			queuingPermanentDelete: "Queueing Permanent Document Delete",
			sharedUpdated: "Document shares updated", 
			errorSharesUpdates: "Error updating document shares", 
		}
	},
	import: {
		import: "Import",
		inProgress: "In progress:",
		documentReady: "Document is ready",
		couldNotImport: "Could not import this document:",
		importMessages: "Import message(s)",
		recently: "Recently imported documents:",
		dragFilesOrClickHere: "Drag files or click here...",
		enablePageMatch: "Enable ML Page Match",
		msg: {
			maxFileNameLengthMsg: "Error uploading file. Filename length exceeds {0}. Please rename the file before uploading. Filename: ",
			maxFileSizeMsg: "Total file size exceeds allowed import size of {0} MB",
			maxFileCountMsg: "Total file count exceeds allowed import count of {0}",
			onlyPDFZip: "You can't upload files of this type. Only PDF or ZIP files are accepted",
			invalidFileType: "Invalid file type",
			importing: "Importing {0} document(s)"
		}
	},
	page: {
		confirmSaveTitle: "Save this page?",
		confirmChangedSaveMsg: "This page has not been saved. Do you want to save this page before exiting?",

		confirmIgnoreZoneTitle: "Ignore (repeated) zone",
		confirmIgnoreZoneChangeMsg: "The ignore zone(s) changed. It will update the zone(s) on all pages. Do you want to commit changes?",

		tableEditor: {
			title: "Table Editor",
			msg: {
				errorNoTable: "No Table",
				tableSaved: "Table saved",
				columnAlreadySpanned: "Column {0} has already spanned item(s)",
				rowAlreadySpanned: "Row {0} has already spanned item(s)",
				tableCellsOCRUpdated: "Table cells updated with ocr text",
				unableOCRTable: "Unable to detect ocr for table",
				cellsCustomText: 'Table cells updated with custom text',
				unableTextContent: "Unable to get text content",
				unableTextContentTable: "Unable to get text content for table",
				unableToOCR: "Unable to detect ocr",
			}
		},
		sideBarTable: {
			name: "Name",
			caption: "Caption",
			summary: "Summary",
			columnHeader: "Column Header",
			rowHeader: "Row Header",
			column: "Column",
			row: "Row",
			tableInformation: "Table Information",
			tableDetector: "Table Detector",
			opticalCharacterRecognition: "Optical Character Recognition",
			htmlGeneration: "Html Generation",
			rowscolsAttributes: "Rows/Cols Attributes",
			ocrAllCells: "OCR All Cells",
			customAllCells: "Custom All Cells",
			sensitivity: "Sensitivity",
			autoSummarize: "Autosummarize",
		},
		sideBarCells: {
			noCellSelected: "No cell selected",
			nudgeLeft: "Nudge All Left",
			nudgeRight: "Nudge All Right",
			nudgeUp: "Nudge All Up",
			nudgeDown: "Nudge All Down",
			source: "Source",
			cellSource: "Cell Source",
			columnAdjustments: "Column Adjustments",
			rowAdjustments: "Row Adjustments",
			columnOperations: "Column Operations",
			spanOperations: "Span Operations",
			rowOperations: "Row Operations",
			splitColumn: "Split Column",
			mergeRight: "Merge Right",
			splitRow: "Split Row",
			mergeDown: "Merge Down",
			spanColumns: "Span Columns",
			spanRows: "Span Rows",
			removeSpans: "Remove Span",
			convertToText: "Convert To Text",
			ocrContent: "OCR Text",
			customContent: "Custom Text",
			actualContent: "Actual Text",
		},
		tableToolBar: {
			row: "Row",
			column: "Column",
			splitRow: "Split Row (R)",
			deleteRow: "Delete Row (D)",
			splitColumn: "Split Column (C)",
			removeColumn: "Remove Column (M)",
			removeSpan: "Remove Span",
			spanColumns: "Span Columns",
			spanColumnsS: "Span Columns (S)",
			spanRows: "Span Rows",
			previewTable: "Preview This Table",
			saveTable: "Save Table?",
			tableNotSaved: "This table has not been saved.  Do you want to save this table before exiting the table editor?",
			yes: "Yes",
			no: "No",
		},
		toolbar: {
			gotoPage: "Go to page",
			pageOf: "of",
			sensitivity: "Sensitivity",
			readingOrder: "Reading Order",
			readingOrders: ["1 column", "2 columns", "3 columns", "4 columns"],
			reOrder: "Re-Order",
			undo: "Undo",
			redo: "Redo",
			toggleZoneInfo: "Toggle Zone Info",
			save: "Save",
			preview: "Preview this page",
			fixedPreview: "(Fixed) Preview this page",
			zoomIn: "Zoom in",
			zoomOut: "Zoom out",
			close: "Close",
			validate: "Validate",
			shortcuts: "Shortcuts",
			extension: {
				name: "Name",
				order: "Order",
				removeZone: "Remove zone",
				newZone: "New zone",
				zoneTag: "Zone Tag",
				zoneSource: "Zone Source",
				left: "Left",
				width: "Width",
				top: "Top",
				height: "Height",
				l: "L",
				w: "W",
				t: "T",
				h: "H",

			},
			messageZoneInfo: "Zone info updated",
			msg: {
				default: "Page(s) default order set to {0}",
				reordered: "Page was reordered - {0}",
				markedValidated: "Page was marked as validated",
				unmarkedValidated: "Page was unmarked as validated",
			}
		},
		sidebarPage: {
			pageInformation: "Page information",
			zoneDetector: "Zone Detector",
			readingOrder: "Reading Order",
			page: "Page",
			lastSaved: "Last saved",
			validatedOn: "Validated on",
			validatedBy: "Validated by",
			sensitivity: "Sensitivity",
			fields: "Fields",
			images: "Images",
			keepZoneTypes: "Keep Zone Types",
			mlPageMatch: "ML Page Match",
			enable: "Enable",
			applyToAll: "Apply to all pages",
			automatic: "Automatic",
			reOrder: "Re-Order",
			ocr: "Zones Optical Character Recognition",
			ocr_all: "OCR all zones",
			msg: {
				default: "Page(s) default order set to {0}",
				reordered: "Page was reordered - {0}",
				ocrAllProgress: {
					preparing: "Preparing zones...",
					processing: "Processing zones",
					alreadyProcessed: "All zones already processed",

				}
				
			}
		},
		sidebarZone: {
			properties: "Properties",
			name: "Name",
			type: "Type",
			language: "Language",
			order: "Order",
			removeZone: "Remove zone",
			tag: "Tag",
			indentation: "Indentation",
			merge: "Merge",
			breakLines: "Break lines",
			anchorProperties: "Anchor Properties",
			anchorID: "Anchor ID",
			blockquoteProperties: "Blockquote Properties",
			citeProperties: "Cite Properties",
			divProperties: "Sub Reading Order",
			divReorderAuto: "Automatic",
			divReOrderButton: "Re-order this div",
			fieldProperties: "Field Properties",
			fieldType: "Field Type",
			fieldTooltip: "Tool Tip",
			fieldLabelZone: "Label Zone",
			fieldGroupName: "Group Name",
			fieldVisibility: "Visibility",
			fieldReadOnly: "Read Only",
			fieldRequired: "Required",
			buttonLabel: "Button Label",
			fieldAlignment: "Alignment",
			checkSpelling: "Check Spelling",
			multiline: "Multi Line",
			scrollLongText: "Scroll Long Text",
			richText: "Rich Text Formatting",
			maxCharacters: "Max Characters",
			checkboxStyle: "Style",
			exportValue: "Export Value",
			checkedByDefault: "Checked by default",
			options: "Options",
			editValues: "Edit Values",
			optionSort: "Sort",
			multiSelect: "Multi Select",
			footnoteProperties: "Footnote Properties",
			footnoteLinkProperties: "Footnote Link Properties",
			toFootnote: "To Footnote",
			description: "Description",
			method: "Method",
			action: "Action",
			visible: "Visible",
			hideZone: "Hide Zone",
			descriptionAlt: "Description (Alt)",
			longDescription: "Long Description",
			level: "Level",
			useAsTemplate: "Use as template",
			enabled: "Enabled",
			removeUnderlyingZones: "Remove underlying zone(s)",
			title: "Title",
			toAnchor: "URL/Anchor",
			caption: "Caption",
			summary: "Summary",
			listType: "List Type",
			listUnordered: "Unordered",
			listOrdered: "Ordered",
			styleType: "Style Type",
			startAt: "Start At",
			listDetector: "List Detector",
			tocDetector: "TOC Detector",
			listSensitivity: "Sensitivity",
			listItems: "List Items",
			tocItems: "TOC Items",
			showListLabel: "Show Labels",
			showArtifacts: "Show Artifacts",
			listLabel: "List Label",
			pdfAllItems: "PDF all list items",
			ocrAllItems: "OCR all list items",
			customAllItems: "Custom all list items",
			tableEditor: "Table Editor",
			formProperties: "Form Properties",
			graphicProperties: "Graphic Properties",
			headingProperties: "Heading Properties",
			ignoreProperties: "Ignore Properties",
			imageProperties: "Image Properties",
			linkProperties: "Link Properties",
			listProperties: "List Properties",
			quoteProperties: "Quote Properties",
			sensitivityProperties: "Sensitivity Properties",
			tableProperties: "Table Properties",
			textProperties: "Text Properties",
			tocProperties: "Table of content Properties",
			zoneSource: "Zone Source",
			source: "Source",
			zonePosition: "Zone Position",
			left: "Left",
			width: "Width",
			top: "Top",
			height: "Height",
			ocrConvert: "Convert to text",
			list: "List",
			toc: "TOC",
			with: "With",
			zone: "Zone",
			page: "Page",
			mergeItem: "Merge Item",
			linkToPage: "Link to Page",
			pageNumber: "Page Number",
			artifactPosition: "Artifact Position",
			msg: {
				listItemSourcePDF: "List items source are now set to PDF",
				listItemSourceCustom: "List items source are now set to Custom",
				unableDetectOCR: "Unable to detect ocr",
				ocrResultsReturned: "OCR results returned",
				//zone reorder under div
				zonesReordered2Col: "Zones under this div were reordered - 2 columns",
				zonesReordered3Col: "Zones under this div were reordered - 3 columns",
				zonesReordered4Col: "Zones under this div were reordered - 4 columns",
				zonesReorderedDefault: "Zones under this div were reordered - default",
			}
		},
		msg: {
			saved: "Page was saved"
		}
	},
	settings: {
		tabLabels: {
			tabTitle: "Labels",
			headerColor: "Color",
			headerName: "Name",
			headerDescription: "Description",
			headerGlobal: "Global",
			headerCreateNewLabel: "Create a new label",
			headerEditDelete: "Edit or Delete Label",
			headerExistingLabels: "Existing Labels",
			buttonNewLabel: "New Label",
			buttonCancel: "Cancel",
			buttonSave: "Save",
			buttonReset: "Reset",
			buttonOk: "OK",
			newLabel: "New Label",
			editLabel: "Edit Label",
			required: "Required.",
			max20Chars: "Max 20 characters",
			max40Chars: "Max 40 characters",
			min1Char: "Min 1 character",
			headerConfirmDelete: "Are you sure you want to delete this label?",
			labelName: "Name",
			labelDescription: "Description",
			labelColor: "Color",
			labelColorPicker: "Label Color Picker",
			edit: "Edit",
			delete: "Delete",
			msg: {
				labelDeleted: "Label Deleted",
				labelUpdated: "Label Updated",
				labelCreated: "Label Created",
				errorSavingLabel: "Error saving label: {0}",
				unableToGetLabels: "Unable to get labels",
			}
		},
		tabGroups: {
			tabTitle: "Groups",
			headerName: "Name",
			headerDescription: "Description",
			headerUserCount: "User Count",
			headerUsers: "Users",
			headerCreateNewGroup: "Create a new group",
			headerEditDelete: "Edit or Delete Group",
			headerExistingGroups: "Existing Groups",
			buttonNewGroup: "New Group",
			buttonAddUser: "Add User",
			buttonCancel: "Cancel",
			buttonSave: "Save",
			buttonReset: "Reset",
			buttonOk: "OK",
			headerConfirmDelete: "Are you sure you want to delete this group?",
			labelName: "Name",
			labelDescription: "Description",
			labelUser: "User",
			msg: {
				groupDeleted: "Group Deleted",
				groupUpdated: "Group Updated",
				groupCreated: "Group Created",
				errorSavingGroup: "Error saving group: {0}",
				unabletoGetGroups: "Unable to get groups",
			}
		},
		tabAccounts: {
			tabTitle: "Accounts",
			headerName: "Name",
			headerCreateNewAccount: "Create a new account",
			headerAccounts: "Accounts",
			headerEditDelete: "Edit or Delete Account",
			headerExistingAccounts: "Existing Accounts",
			buttonNewAccount: "New Account",
			buttonAddAccount: "Add Account",
			buttonCancel: "Cancel",
			buttonSave: "Save",
			buttonReset: "Reset",
			buttonOk: "OK",
			headerConfirmDelete: "Are you sure you want to delete this account?",
			headerEmail: "Email",
			headerGroup: "Group",
			headerActivated: "Activated",
			headerGoogleAccount: "Google Account",
			headerAdministrator: "Administrator",
			headerResetPasswordFor: "Reset Password for",
			headerResetPassword: "Reset Password",
			labelPassword: "Password",
			labelConfirmPassword: "Confirm Password",
			labelEmail: "Email",
			labelGroup: "Group",
			msg: {
				accountDeleted:  "Account Deleted",
				accountUpdated: "Account updated",
				accountAdded: "Account added",
				passwordChanged: "Password Changed",
				errorAddingAccount: "Error adding account: {0}",
				errorSavingAccount:  "Error saving account: {0}",
				errorSavingPassword: "Error saving password: {0}",
				unableToGetAccount: "Unable to get accounts",
				unableToGetGroups: 'Unable to get groups',
			}
		},
		tabCss: {
			tabTitle: "CSS",
			headerName: "Name",
			headerUrl: "Url",
			headerDefault: "Default",
			headerCreateCss: "Create a new css",
			headerEditDelete: "Edit or Delete CSS",
			headerExistingCss: "Existing CSS",
			buttonNewCss: "New CSS",
			buttonCancel: "Cancel",
			buttonSave: "Save",
			buttonReset: "Reset",
			buttonOk: "OK",
			headerConfirmDelete: "Are you sure you want to delete this css?",
			labelName: "Name",
			labelUrl: "Url",
			msg: {
				deleted: "CSS Deleted",
				updated: "CSS Updated",
				created: "CSS Created",
				errorSaving: "Error saving css: {0}",
				errorCreating: "Error creating css: {0}",
				unableToGetCSS: "Unable to get css list",
			}
		},
		tabDocumentExport: {
			tabTitle: "Document Export"
		},
		tabZoneTransfer: {
			tabTitle: "Zone Transfer",
			headerDownloadDocumentZones: "Download Document Zones",
			headerUploadDocumentZones: "Upload Document Zones",
			headerDocumentZoneTransfer: "Document Zone Transfer",
			headerPageZoneTransfer: "Page Zone Transfer",
			buttonTransferDocumentZones: "Transfer Document Zones",
			buttonCancel: "Cancel",
			buttonClose: "Close",
			buttonBack: "Back",
			buttonLoadPageOptions: "Load Page Options",
			buttonTransferPageZones: "Transfer Page Zones",
			buttonUploadZones: "Upload Zones",
			buttonDownloadZones: "Download Zones",
			documentId: "Document ID",
			labelSourceDocumentId: "Source Document ID",
			labelDestinationDocumentId: "Destination Document ID",
			labelZoneTransferType: "Zone Transfer Type",
			labelSourcePage: "Source Page",
			labelDestinationPages: "Destination Pages",
			labelChooseFile: "Choose File",
			labelSelectSourcePage: "Select Source Page",
			labelSelectDestinationPage: "Select Destination Page(s)",
			labelSelectLayoutZipFile: "Select Layout Zip File",
			labelDestinationDocumentName: "Destination Document Name",
			msg: {
				docInitiated: "Document Zone Transfer Initiated",
				errorDocTransferring: "Error Transferring Document Zones: {0}",
				pageInitiated: "Page Zone Transfer Initiated",
				errorPageTransferring: "Error Transferring Page Zones: {0}",
				zonesInitiated: "Uploaded Zones Transfer Initiated",
				errorZonesTransferring: "Error Transferring Uploaded Zones: {0}",
				downloadInitiated: "Download Zones Initiated",
				errorDownload: "Error Downloading Zones: {0}"
			}
		},
	},
	dashboard: {
		groups: "Groups",
		allGroups: "All groups",
		activities: "Activities",
		users: "Users",
		usersActive: "Active",
		documents: "Documents",
		documentsImported: "Imported",
		documentsActive: "Active",
		documentsDeleted: "Deleted",
		pages: "Pages",
		pagesActive: "Active",
		pagesModified: "Modified",
		pagesValidated: "Validated",
		progress: "Progress",
		exportedPDF: "Exported PDFs",
		validatedPercentage: "Validated Percentage",
		reportTypes: {
			thisWeek: "This Week",
			lastWeek: "Last Week",
			thisMonth: "This Month",
			lastMonth: "Last Month",
			thisYear: "This Year",
			lastYear: "Last Year",
			last30Days: "Last 30 Days",
			last60Days: "Last 60 Days",
			last90Days: "Last 90 Days",
			last180Days: "Last 180 Days",
			last365Days: "Last 365 Days",
			sinceOnline: "Since Online",
		},
		months: {
			jan: "Jan",
			feb: "Feb",
			mar: "Mar",
			apr: "Apr",
			may: "May",
			jun: "Jun",
			jul: "Jul",
			aug: "Aug",
			sep: "Sep",
			oct: "Oct",
			nov: "Nov",
			dec: "Dec"
		}
	}
}