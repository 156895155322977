import Vue from "vue"
import VueLocalStorage from "vue-localstorage"

Vue.use(VueLocalStorage)

const supportedLanguages = [
  {
    title: "English",
    locale: "en",
  },
  {
    title: "French",
    locale: "fr",
  },
  {
    title: "Spanish",
    locale: "es",
  },  
]

export default {
  namespaced: true,
  state: {
    locale: Vue.localStorage.get("language") || "en",
  },
  getters: {
    getLanguage(state) {
      return state.locale
    },
    supportedLanguages(state) {
      return supportedLanguages
    },
  },
  mutations: {
    setLanguage(state, lang) {
      state.locale = lang
      Vue.localStorage.set("language", lang)
    },
  },
  actions: {
    setLanguage({ commit }, languages) {
      commit("setLanguage", languages)
    },
  },
}
