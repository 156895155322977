<template>
	<v-menu left bottom>
		<template v-slot:activator="{ on, attrs }">
			<v-btn icon v-bind="attrs" v-on="on">
				<v-avatar color="primary" size="38">
					<v-img :src="user.photoURL"></v-img>
				</v-avatar>
			</v-btn>
		</template>

		<v-list>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="title user-title">
						<v-avatar color="primary" size="38">
							<v-img :src="user.photoURL"></v-img>
						</v-avatar>
						{{ user.displayName }}
					</v-list-item-title>

					<v-list-item-subtitle class="pl-10">
						{{ user.email }}
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>

			<v-divider></v-divider>

			<v-list-item link to="/profile">
				<v-list-item-title>{{ $t("userMenu.profile") }}</v-list-item-title>
			</v-list-item>

			<v-divider></v-divider>

			<v-list-group @click.stop>
				<template v-slot:activator>
					<v-list-item-title>{{ $t("userMenu.language") }}</v-list-item-title>
				</template>

				<v-list class="pl-10">
					<v-list-item v-for="n in supportedLanguages" :key="n.locale" @click="changeLanguage(n.locale)">
						<v-list-item-title>
							{{ n.title }}
							<v-icon v-if="language == n.locale" color="primary">mdi-check</v-icon>
						</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-list-group>

			<v-divider></v-divider>

			<!-- <v-list-group @click.stop>
				<template v-slot:activator>
					<v-list-item-title>Button Playground</v-list-item-title>
				</template>
				<v-list-item dense>
					<v-switch @click.stop v-model="iconVisible" label="icon visible" inset :disabled="ideal == true">
					</v-switch>
				</v-list-item>
				<v-list-item dense>
					<v-switch @click.stop v-model="labelVisible" label="label visible" inset :disabled="ideal == true">
					</v-switch>
				</v-list-item>
				<v-list-item dense>
					<v-switch @click.stop v-model="text" label="text type" inset :disabled="ideal == true">
					</v-switch>
				</v-list-item>
				<v-list-item dense>
					<v-switch @click.stop v-model="outlined" label="outlined" inset :disabled="ideal == true">
					</v-switch>
				</v-list-item>
				<v-list-item dense>
					<v-switch @click.stop v-model="small" label="small" inset :disabled="ideal == true">
					</v-switch>
				</v-list-item>
				<v-list-item dense>
					<v-switch @click.stop v-model="elevation" label="elevation" inset :disabled="ideal == true">
					</v-switch>
				</v-list-item>
				<v-list-item dense>
					<v-switch @click.stop v-model="ideal" label="ideal" inset>
					</v-switch>
				</v-list-item>
			</v-list-group>

			<v-divider></v-divider> -->

			<v-list-item>
				<v-switch 
					@click="toggleDarkMode"
					v-model="darkTheme" 
					:hint="$t('userMenu.darkThemeHint')" inset
					:label="$t('userMenu.darkTheme')" persistent-hint></v-switch>
			</v-list-item>

			<v-divider></v-divider>
			<v-list-item @click="logout()">
				Logout
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import i18n from "@/plugins/i18n"
import { mapGetters, mapActions, mapMutations } from "vuex"

export default {
	name: "UserMenu",
	data() {
		return {
			iconVisible: true,
			labelVisible: true,
			text: true,
			outlined: false,
			small: false,
			elevation: true,
			ideal: false,
			darkTheme: false
		}
	},
	computed: {
		...mapGetters({
			user: "user/userProfile",
			supportedLanguages: "language/supportedLanguages",
			language: "language/getLanguage",
		}),
	},
	watch: {
		'iconVisible': function (newVal, oldVal) {
			console.log('icon visible: ' + newVal)
			this.setIconVisible(newVal)
			if(newVal == true){
				this.setIconClass = "mr-3"
			}
			this.$forceUpdate()
		},
		'labelVisible': function (newVal, oldVal) {
			console.log('label visible: ' + newVal)
			this.setLabelVisible(newVal)
			this.$forceUpdate()
		},
		'text': function (newVal, oldVal) {
			var obj = { text: newVal, outlined: this.outlined, small: this.small, elevation: this.elevation == true ? "1" : "0"}
			this.setButtonStyle(obj)
		},
		'outlined': function (newVal, oldVal) {
			var obj = { text: this.text, outlined: newVal, small: this.small, elevation: this.elevation == true ? "1" : "0" }
			this.setButtonStyle(obj)
		},
		'small': function (newVal, oldVal) {
			var obj = { text: this.text, outlined: this.outlined, small: newVal, elevation: this.elevation == true ? "1" : "0" }
			this.setButtonStyle(obj)
		},
		'elevation': function (newVal, oldVal) {
			var obj = { text: this.text, outlined: this.outlined, small: this.small, elevation: this.elevation == true ? "1" : "0"}
			this.setButtonStyle(obj)
		},
		'ideal': function (newVal, oldVal) {
			console.log('ideal: ' + newVal)
			var obj, iconObj
			if(newVal == true){
				this.text = true
				this.outlined = false
				this.small = true
				this.elevation = ""
				var color = ""
				obj = { text: this.text, outlined: this.outlined, small: this.small, elevation: this.elevation, color: ''}
				this.setButtonStyle(obj)
				iconObj = {display: true, class: "mr-0", small: true}
				this.setIconStyle(iconObj)
				this.setLabelVisible(false)
				this.setIdeal(true)
			}
			else{
				this.text = true
				this.outlined = false
				this.elevation = "1"
				this.small = false
				obj = { text: this.text, outlined: this.outlined, small: this.small, elevation: this.elevation == true ? "1" : "0"}
				this.setButtonStyle(obj)
				iconObj = {display: true, class: "mr-3"}
				this.iconVisible = true
				this.setIconStyle(iconObj)
				this.labelVisible = true
				this.setLabelVisible(true)
				this.setIdeal(false)
			}
			
		},

	
	},
	methods: {
		...mapActions({
			logoutUser: "user/logout",
		}),
		...mapMutations({
			setIconVisible: "application/SET_ICON_VISIBLE",
			setButtonStyle: "application/SET_BUTTON_STYLE",
			setLabelVisible: "application/SET_LABEL_VISIBLE",
			setIconClass: "application/SET_ICON_CLASS",
			setIconStyle: "application/SET_ICON_STYLE",
			setIdeal: "application/SET_IDEAL",
		}),
		changeLanguage(locale) {
			this.$store.dispatch("language/setLanguage", locale).then(() => {
				i18n.locale = this.$store.state.language.locale
			})
		},
		toggleDarkMode() {
			console.log("Toggling dark")
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark
			this.darkTheme = this.$vuetify.theme.dark
			localStorage.setItem("dark_theme", this.darkTheme)
		},
		logout() {
			this.logoutUser()
		},
	},
	mounted() {
		const theme = localStorage.getItem("dark_theme")
		
		if (theme) {
			if (theme === "true") {
				this.$vuetify.theme.dark = true
				this.darkTheme = true
			} else {
				this.$vuetify.theme.dark = false
				this.darkTheme = false
			}
		} else if (
			window.matchMedia &&
			window.matchMedia("(prefers-color-scheme: dark)").matches) {
			this.$vuetify.theme.dark = true
			this.darkTheme = true
			localStorage.setItem("dark_theme", true)
		}
	}
}
</script>
<style lang="scss" scoped>
.user-title {
	margin-top: 0;
    margin-left: 0;
    text-align: left;
}
</style>