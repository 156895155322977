import Page from '../components/Page'

export default [
    {
        path: '/document/:key/page/:pageno',
        name: 'Page',
        component: Page,
        meta: {
            requiresAuth: true,
            i18Msg: 'barTitle.editor'
        }
    },
]