<template>
  <v-container class="pa-5 ma-5">
    <v-row>
      <v-col cols="2">
        <v-img class="profile-image"  :src="user.photoURL" max-width="200"></v-img>
      </v-col>
      <v-col cols="5" class="pa-5 ma-5"> 
        <v-row>
            <v-subheader class="fieldLabel"> {{ $t("profile.firstName") }} </v-subheader>
             <v-text-field
                outlined
                dense
                v-model="user.name">
            </v-text-field>
        </v-row>
        <v-row>
            <v-subheader class="fieldLabel"> {{ $t("profile.lastName") }} </v-subheader>
             <v-text-field
                outlined
                dense
                v-model="user.last_name">
            </v-text-field>
        </v-row>        
        <v-row>
            <v-subheader class="fieldLabel"> {{ $t("profile.email") }} </v-subheader>
             <v-text-field 
                outlined
                dense
                v-model="user.email">
            </v-text-field>
        </v-row>    
        <v-row>
             <v-subheader class="fieldLabel"> {{ $t("profile.phone") }} </v-subheader>
             <v-text-field
                outlined
                dense
                v-model="user.phone">
            </v-text-field>
        </v-row>        
      </v-col>
    </v-row>
    
    <v-row>
      <v-col cols="8">
        <span  class="fieldLabel">{{ $t("profile.address") }}</span>
         <v-text-field
                outlined
                dense
                v-model="user.location">
            </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="8">
        <span  class="fieldLabel">{{ $t("profile.description") }}</span>
        <v-textarea
          outlined
          name="input-7-4"
          :label="$t('profile.description')"
          v-model="user.description">
          </v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
          <span  class="fieldLabel">{{ $t("profile.zoneHighlight") }} </span>
          <v-select 
              outlined 
              dense
              v-model="user.ring_width"
              :items="ring_width_options"
              item-text="value"
              item-value="key"
              >
          </v-select>
      </v-col>

      <v-col cols="4">
          <span  class="fieldLabel">{{ $t("profile.autosummarizeMode") }}</span>
          <v-select 
              outlined 
              dense
              v-model="user.autosummarize_default"
              :items="autosummarize_options"
              item-text="value"
              item-value="key">
          </v-select>
      </v-col>

    </v-row>

    <v-row>
      <v-col cols="4">
          <span  class="fieldLabel">{{ $t("profile.outputDefault") }}  </span>
          <v-select 
              outlined 
              dense
              v-model="user.output_default"
              :items="output_options"
              item-text="value"
              item-value="key"
              >
          </v-select>
      </v-col>

      <v-col cols="4">
          <span  class="fieldLabel">{{ $t("profile.customerInstance") }} </span>
          <v-select 
              outlined
              dense
              v-model="user.customer_key"
              :items="user.customers"
              item-text="name"
              item-value="key"
              >
          </v-select>
      </v-col>

    </v-row>

    <v-btn color="primary" class="mr-0" @click="clickSaveUserProfile">
      {{ $t("profile.save") }}
    </v-btn>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import user from "../../../store/modules/user"
import EventBus from "@/eventBus"

export default {
  data() {
    return {  
      autosummarize_options:[{key: 0, value: 'Hide'},
                             {key: 1, value: 'Default to disabled'},
                             {key: 2, value: 'Default to enabled'}],

      ring_width_options:[{key: 'Large', value: 'Large'},
                          {key: 'Medium', value: 'Medium'},
                          {key: 'Small', value: 'Small'},
                          {key: 'Off', value: 'Off'}],

      output_options: [{key: 0, value: 'PDF'},
                       {key: 1, value: 'HTML'},
                       {key: 2, value: 'EPUB'}]
    }
  },
  computed: {
    ...mapGetters({
      user: "user/userProfile",
    }),
  },
  methods: {
    ...mapActions({
      saveUserProfile: "user/saveUserProfile",
      fetchUserProfile: "user/fetchUserProfile"
    }),
    clickSaveUserProfile(){
      console.log('csrf: ' + this.csrf_token)
      this.saveUserProfile({
        payload: {
          name: this.user.name,
          last_name: this.user.last_name,
          description: this.user.description,
          location: this.user.location,
          phone: this.user.phone,
          customer_key: this.user.customer_key,
          ring_width: this.user.ring_width,
          autosummarize_default: this.user.autosummarize_default,
          output_default: this.user.output_default
        },
      }).then((response) => {
        this.$snackbar.showMessage({ content: this.$t("profile.msg.profileSaved"), color: 'success' }) 
      })
    }
  },
  created(){
    this.fetchUserProfile()
  }
}
</script>
<style lang="scss" scoped>

.fieldLabel {
  font-size: 20px;
  font-weight: 700;
  color: var(--v-primary-base) !important;
  width: 150px;
}
.profile-image {
  border: 1px solid;
}
</style>
