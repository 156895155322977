import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { auth } from './firebase'
import vuetify from './plugins/vuetify'
import i18n from "@/plugins/i18n"


// import Konva from "konva"
// import VueKonva from 'vue-konva'
import snackbarPlugin from './plugins/snackbar'
// import svgJs from "./plugins/vuesvg"

Vue.config.productionTip = false
i18n.locale = store.state.language.locale

// Konva.pixelRatio = 3
// Vue.use(VueKonva)
// Vue.use(svgJs)
Vue.use(snackbarPlugin, { store })

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

let app

// console.log('**** in main ****')

//signout  RJR: we don't want to do this here so the user can refresh their page and get back in
//auth.signOut()

//this is for firebase login 
auth.onAuthStateChanged((authState) => {
	// console.log('auth state changed', authState)
	if (authState) {

		// Got a state, now get the token
		authState.getIdToken(true).then((token) => {

			// Validate the token with the backend
			store.dispatch('user/backendLogin', { user: auth.currentUser, token: token })
				.then(() => {
					// console.log('completed backend login')
					if (!app) {
						app = new Vue({
							router,
							store,
							vuetify,
							i18n,
							beforeCreate() {
								this.$store.commit('application/INIT_STORE')
								this.$store.commit('documents/INIT_STORE')
								this.$store.dispatch('documents/StartImportingEvents')

								//testing
								this.$store.commit('user/SET_UID_LOGIN', false)

								//save fbAuthState
								console.log('authstate is: ', authState)
								this.$store.commit('user/SET_FB_AUTH_STATE', authState)
							},
							render: h => h(App)
						}).$mount("#app")
					}
				})
		})
	}
	else {
		/*
			this means that the user either did not login with firebase, or logged in with firebase and then logged out.
			or possibly firebase login expired?  not sure on the timeframe.
			IMPORTANT: this will happen when the app loads, main is invoked, and there is no FB login active
			so, when we reload the app, let's try to check the users auth to prevent going to the login screen again if
			the user has a session on the server
		*/
		// console.log('not logged in w/ firebase')
		//check if there's a session on server
		// console.log('lets see if there is a user session')
		/*
			can't try to log in if a logout is in progress.
			when a logout happens, the fb auth state changes to null causing this section of code to execute
		*/

		if(! store.state.user.logoutInProcess){
			store.dispatch('user/checkAuth')
			.then((results) => {
				// console.log('results of checklogin: ', results)
				//now lets get the user options
				store.dispatch('application/fetchOptions')
					.then((results) => {
						console.log('got user options')
					}).catch((error) => {
						console.log('error getting user options')
					})
			}).catch((error) => {
				console.log('error for check login: ', error)
			}).finally(() => {
				//in either above outcome, go to app
				if (!app) {
					app = new Vue({
						router,
						store,
						vuetify,
						i18n,
						render: h => h(App)
					}).$mount("#app")
				}
			})
		}
		else{
			console.log('login prevented because logout is in process')
		}
		
	}
})
