export default {
    labels: [],
    label: null,
    //using suffix list for plural css
    cssList: [],
    css: null,
    groups: [],
    group: null,
    customerUsers: [],
    accounts: [],
    account: null,
    sourcePageCount: null,
    destinationPageCount: null,
    document: null,
}