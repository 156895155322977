<template>
<v-sheet class="pa-3 mt-5 fill-height">
  <v-card>
    <v-tabs
      v-model="selectedTab"
      center-active
      show-arrows
      class="tab-headers" grow
    >
      <v-tab key="tabLabels">{{ $t("settings.tabLabels.tabTitle") }}</v-tab>
			<v-tab key="tabCss" v-if="options.is_admin">{{ $t("settings.tabCss.tabTitle") }}</v-tab>
      <v-tab key="tabGroups" v-if="options.is_admin">{{ $t("settings.tabGroups.tabTitle") }}</v-tab>
      <v-tab key="tabAccounts" v-if="options.is_admin">{{ $t("settings.tabAccounts.tabTitle") }}</v-tab>
			<v-tab key="tabZoneTransfer" v-if="options.zone_transfer_enabled" >{{ $t("settings.tabZoneTransfer.tabTitle") }}</v-tab>    
      <!--<v-tab key="tabDocumentExport">{{ $t("settings.tabDocumentExport.tabTitle") }}</v-tab>-->
    </v-tabs>

    <v-tabs-items v-model="selectedTab" >
      <TabLabels />
			<TabCss v-if="options.is_admin"/>
      <TabGroups v-if="options.is_admin"/>
      <TabAccounts v-if="options.is_admin"/>
			<TabZoneTransfer v-if="options.zone_transfer_enabled"  v-bind:parentTab="parentTab"/>
      <!--<TabDocumentExport />-->
    </v-tabs-items>

  </v-card>
</v-sheet>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import il8n from "@/plugins/i18n"
import TabLabels from "./tabs/TabLabels"
import TabGroups from "./tabs/TabGroups"
import TabAccounts from "./tabs/TabAccounts"
import TabCss from "./tabs/TabCss"
//import TabDocumentExport from "./tabs/TabDocumentExport"
import TabZoneTransfer from "./tabs/TabZoneTransfer"

export default {
  data() {
    return {
      selectedTab: null,
			hasMounted: false,
			parentTab: "settings",
    }
  },
	watch: {
		selectedTab(val){
			console.log('selectedTab:', val)
			//if the selected tab is 3 (accts) and the user is admin and component already mounted, reload
			if(val ===3 && this.options.is_admin && this.mounted == true){ 
				console.log('reloading accounts data')
				this.fetchAccounts()
				this.fetchGroups()
			}
		},
	},
	methods: {
		...mapActions({
			fetchAccounts: "settings/fetchAccounts",
			fetchGroups: "settings/fetchGroups",
		})
		
	},
  components: {
    TabLabels,
		TabGroups,
		TabCss,
		TabAccounts,
		//TabDocumentExport,
		TabZoneTransfer		
  },
  computed: {
    ...mapGetters({
      user: "user/userProfile",
			options: "application/getOptions"
    }),
		
	
  },
	mounted(){
		this.mounted = true
		if(this.$route.params != null && this.$route.params.key != null){
			console.log('params: ' + this.$route.params.key)
			//an admin can see all tabs, so zone transfer is 4
			if(this.options.is_admin){
					this.selectedTab = 4
			}
			//non admin can only see labels, so zone transfer is 1
			else{
				this.selectedTab = 1
			}
		
		}

	}
}
</script>

<style lang="scss">
.tab-settings {
	max-width: none !important;
}
</style>
