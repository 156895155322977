import Vue from "vue"

function initProgressDocument(document) {
    return {progress : 0,
            percent : 0,
            currentStep : 0,
            upload_progress : 0,
            last_msg : '',
            liveUpdates : [],
            totalPages : 0,
            filename : '',
            importedDate: Date.now(),
            document : document}
}

export default {
    INIT_STORE(state) {
        let importedDocuments = localStorage.getItem('importedDocuments')
        if (importedDocuments) {
            state.importedDocuments = JSON.parse(importedDocuments)
            console.log("Loading imported documents ", state.importedDocuments)
        }  

        let importingDocuments = localStorage.getItem('importingDocuments')
        if (importingDocuments) {
            importingDocuments = JSON.parse(importingDocuments)
            
            let changes = false
            for (var ii in importingDocuments) {
                let timePeriod = Math.floor((Date.now() - importingDocuments[ii].importedDate)/(60*60*1000)) // Hours

                if (importingDocuments[ii].ready) {
                    // Document is ready add it to the recentrly imported
                    const doc = JSON.parse(JSON.stringify(importingDocuments[ii]))
                    if (state.importedDocuments.indexOf(doc) === -1) {
                        state.importedDocuments.push(JSON.parse(JSON.stringify(importingDocuments[ii])))
                        localStorage.setItem('importedDocuments', JSON.stringify(state.importedDocuments))                            
                    }
                    delete importingDocuments[ii]
                    changes = true
                } 
                else if(timePeriod > 1) 
                {
                    // Delete failed import after a 24hrs period
                    console.log("Removing item from failed imports", importingDocuments[ii])
                    delete importingDocuments[ii]
                    changes = true
                }
            }
            state.importingDocuments = importingDocuments
            state.inProgressDocuments = Object.entries(state.importingDocuments).length
            if (state.inProgressDocuments < 0) {
                state.inProgressDocuments = 0;
            }
            if (changes) {
                localStorage.setItem('importingDocuments', JSON.stringify(state.importingDocuments))
            }
        }    
        
        if (localStorage.getItem('importing')) {
            state.importing = localStorage.getItem('importing')
        }
    },
    RESET_STORE(state) {
        console.log("Resetting local values")
        localStorage.removeItem('importingDocuments')
        localStorage.removeItem('importedDocuments')
        localStorage.removeItem('importing')
    },
    /*
    Removes the doc with doc_key from documents and recent documents
    */  
    DELETE_DOCUMENT(state, doc_key){
        //documents
        for(var i=0; i<state.documents.length; i++){
            if(state.documents[i].key == doc_key){
                state.documents.splice(i, 1)
                break;
            }
        }
        //recent documents
        for(var j=0; j<state.recentDocuments.length; j++){
            if(state.recentDocuments[j].key == doc_key){
                state.recentDocuments.splice(j, 1)
                break;
            }
        }
    },
    SET_LABEL_FILTERS(state, val){
        state.labelFilters = val
    },
    SET_RECENT_DOCUMENTS(state, val){
        state.recentDocuments = val
    },
    SET_DOCUMENTS(state, val) {
        state.documents = val
    },
    SET_IMPORTING(state, val) {
        state.importing = val
        localStorage.setItem('importing', state.importing)
    },
    SET_IMPORTING_EVENTS(state, val) {
        state.importingEventsOn = val
        console.log("Setting importing events ==>", val)
    },

    SET_IMPORTING_DOCUMENT(state, payload) {
        console.log("Setting importing document", payload)
        var newDoc = initProgressDocument(payload.document)
        newDoc.filename = payload.filename
        newDoc.group_key = payload.group_key
        state.inProgressDocuments++
        Vue.set(state.importingDocuments, payload.tracking, newDoc)
        localStorage.setItem('importingDocuments', JSON.stringify(state.importingDocuments))
    },
    SET_IMPORTING_DOCUMENT_ATT(state, payload) {
        // console.log("SET_IMPORTING_DOCUMENT_ATT = ", payload)
        if (!state.importingDocuments[payload.tracking]) {
            // state.importingDocuments[payload.tracking] = initProgressDocument({})
            Vue.set(state.importingDocuments, payload.tracking, initProgressDocument({}))
        }
        Vue.set(state.importingDocuments[payload.tracking], payload.att, payload.value)

        if (payload.att == 'ready') {
            if (payload.value) {
                state.inProgressDocuments--
                if (state.inProgressDocuments < 0) {
                    state.inProgressDocuments = 0;
                }
                var exists = false;
                for (var i = 0; i < state.importedDocuments.length; i++) {
                    if (state.importedDocuments[i].doc_key === state.importingDocuments[payload.tracking].doc_key) {
                        exists = true;
                    }
                }

                if (!exists) {
                    var objCopy = JSON.parse(JSON.stringify(state.importingDocuments[payload.tracking]))
                    state.importedDocuments.push(objCopy)
                    localStorage.setItem('importedDocuments', JSON.stringify(state.importedDocuments))    
                }
                // Vue.set(state.importedDocuments, payload.tracking, JSON.parse(JSON.stringify(state.importingDocuments[payload.tracking])) )
                setTimeout(() => {
                    delete state.importingDocuments[payload.tracking]
                    localStorage.setItem('importingDocuments', JSON.stringify(state.importingDocuments))
                }, 1000)
            }
        } else {
            localStorage.setItem('importingDocuments', JSON.stringify(state.importingDocuments))
        }
    },
    REMOVE_IMPORTING_DOCUMENT(state, document) {
        for (var i = 0; i < state.importingDocuments.length; i++) {
            if (state.importingDocuments[i] === document) {
                state.importingDocuments.splice(i, 1)
            }
        }
  
    },
    REMOVE_IMPORTED_DOCUMENT(state, document_key) {
        for (var i = 0; i < state.importedDocuments.length; i++) {
            if (state.importedDocuments[i].document.doc_key === document_key) {
                state.importedDocuments.splice(i, 1)
                localStorage.setItem('importedDocuments', JSON.stringify(state.importedDocuments))
                break
            }
        }
    },
    //update a doc in the recent docs list if it was updated in the docs list
    UPDATE_RECENT_DOCS_LIST(state, document){
        for (var i = 0; i < state.recentDocuments.length; i++) {
                if (state.recentDocuments[i].key === document.key) {
                        state.recentDocuments.splice(i, 1, document)
                        break
                }
        }

    },

    //update a doc in the docs list if it was updated in the recent docs list
    UPDATE_DOCS_LIST(state, document){
        for (var i = 0; i < state.documents.length; i++) {
                if (state.documents[i].key === document.key) {
                        state.documents.splice(i, 1, document)
                        break
                }
        }

    },

		
}  