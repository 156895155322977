<!-- This is the left hand sidebar Table Tab for the table editor.  This goes in TableEditorSideBar -->
<template>

	<v-tab-item key="Table" class="tab-item-wrapper">
		<v-card fluid fill-height class="side-bar-tab side-bar-bg scrolling-tab">
			<div class="room-for-scrollbar pl-2">
				<SectionTitle :title="$t('page.sideBarTable.tableInformation')" />

				<v-row>
					<v-col cols="3" class="section-label">{{ $t("page.sideBarTable.name") }}</v-col>
					<v-col>
						<v-text-field dense solo v-model="tableZone.name"></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="3" class="section-label">{{ $t("page.sideBarTable.caption") }}</v-col>
					<v-col>
						<v-text-field dense solo v-model="tableZone.caption"></v-text-field>
					</v-col>
				</v-row>



				<v-row>
					<v-col cols="3" class="section-label">{{ $t("page.sideBarTable.summary") }}</v-col>
					<v-col>
						<v-textarea v-show="!tableZone.merge" solo color="white" auto-grow v-model="tableZone.summary"
							@change="tableZone.autoSummarize = false"></v-textarea>

						<v-textarea v-show="tableZone.merge" solo color="white" auto-grow disabled>Merged</v-textarea>
					</v-col>
				</v-row>

				<v-row v-show="options.autosummarize_default > 0">
					<v-col cols="4" class="section-label">
						{{ $t('page.sideBarTable.autoSummarize') }}
					</v-col>
					<v-col>
						<v-checkbox class="mt-1 large-check" v-model="tableZone.autoSummarize"
							@click="tableZone.autoSummarize == true ? checkAndSummarize : null"></v-checkbox>
					</v-col>
				</v-row>


				<v-row>
					<v-col cols="3" class="section-label">{{ $t("page.sideBarTable.columnHeader") }}</v-col>
					<v-col>
						<v-text-field class="float-right mr-15" dense solo style="max-width: 65px;text-align:right;"
							v-model="tableZone.colHeader" type="number" min=0 max=100 @change="changeColHeader"></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="3" class="section-label">{{ $t("page.sideBarTable.rowHeader") }}</v-col>
					<v-col>
						<v-text-field class="float-right mr-15" dense solo style="max-width: 65px;text-align:right;"
							v-model="tableZone.rowHeader" type="number" min=0 max=100 @change="changeRowHeader"></v-text-field>
					</v-col>
				</v-row>

				<SectionTitle :title="$t('page.sideBarTable.tableDetector')" />

				<v-row v-show="options.ml_table">
					<v-col cols="4" class="section-label">
						{{ $t("page.sideBarTable.column") }} {{ autoDetect.columnRadius == 0 ? "Off" : autoDetect.columnRadius }}
					</v-col>
					<v-col>
						<v-slider min="0" max="5" step="1" v-model="autoDetect.columnRadius" @change="onRadiusMLTableChange"
							@click="onRadiusMLTableChange"></v-slider>
					</v-col>
				</v-row>

				<v-row v-show="options.ml_table">
					<v-col cols="4" class="section-label">
						{{ $t("page.sideBarTable.row") }} {{ autoDetect.rowRadius == 0 ? "Off" : autoDetect.rowRadius }}
					</v-col>
					<v-col>
						<v-slider min="0" max="5" step="1" v-model="autoDetect.rowRadius" @change="onRadiusMLTableChange"
							@click="onRadiusMLTableChange"></v-slider>
					</v-col>
				</v-row>

				<v-row v-show="!options.ml_table">
					<v-col cols="4" class="section-label">
						{{ $t("page.sideBarTable.sensitivity") }} {{ autoDetect.tableRadius }}
					</v-col>
					<v-col>
						<v-slider min="1" max="20" step="1" v-model="autoDetect.tableRadius" @change="setTableSensitivity"
							@click="setTableSensitivity"></v-slider>
					</v-col>
				</v-row>


				<SectionTitle :title="$t('page.sideBarTable.opticalCharacterRecognition')" />

				<v-row v-show="!ocrInProgress">
					<v-col align="center">
						<v-btn color="primary" class="pt-4 pb-4" x-small dense elevation="2" @click="ocrAllCells">
							{{ $t("page.sideBarTable.ocrAllCells") }}
						</v-btn>

						<v-btn color="primary" class="pt-4 pb-4" x-small elevation="2" @click="customAllCells">
							{{ $t("page.sideBarTable.customAllCells") }}
						</v-btn>
					</v-col>
				</v-row>

				<v-row v-show="ocrInProgress">
					<v-col cols="4" class="section-label">
						{{ ocrPageProgress_msg }}
					</v-col>
				</v-row>
				<v-row v-show="ocrInProgress">
					<v-col>
						<v-progress-linear :value="progress"></v-progress-linear>
					</v-col>
				</v-row>

				<SectionTitle :title="$t('page.sideBarTable.htmlGeneration')" />
				<div>{{ $t("page.sideBarTable.rowscolsAttributes") }}</div>
				<v-radio-group row v-model="tableZone.rowcolAttribute">
					<v-radio label="Scope" value="scope"></v-radio>
					<v-radio label="ids" value="ids"></v-radio>
				</v-radio-group>

			</div>
		</v-card>
	</v-tab-item>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import i18n from "@/plugins/i18n"
import StaticDataMixin from "@/mixins/staticData"
import SectionTitle from "./../SectionTitle.vue"
import EventBus from "@/eventBus"

export default {
	name: "SideBarTable",
	props: ["tableZone", "autoDetect", "ocrInProgress",
		"ocrPageProgress", "ocrPageProgress_msg", "ocrCellProcessed",
		"ocrTotalCells"],
	mixins: [StaticDataMixin],
	data() {
		return {

		}
	},
	computed: {
		...mapGetters({
			selectedZone: "page/getSelectedZone",
			language: "language/getLanguage",
			options: "application/getOptions",
		}),
		totalCells() {
			var totalCells = 1
			if (this.tableZone != null && this.tableZone.rows !== undefined && this.tableZone.cols !== undefined) {
				totalCells = this.tableZone.rows.length * this.tableZone.cols.length
				console.log('totalCells: ', totalCells)
			}
			return totalCells
		},
		progress() {
			var progress = (this.ocrPageProgress / this.totalCells) * 100
			return progress
		}

	},
	components: {
		SectionTitle,
	},

	watch: {
		'ocrTotalCells': function (newVal, oldVal) {
			console.log('ocrTotalCells changed:', newVal)
		},
		'ocrPageProgress': function (newVal, oldVal) {
			console.log('ocrPageProgress changed:', newVal)
		},

	},

	methods: {
		...mapMutations({
			setTableZone: "page/SET_TABLE_ZONE"
		}),
		onRadiusMLTableChange() {
			EventBus.$emit("ml-table-radius-change", {})
		},
		ocrAllCells() {
			EventBus.$emit("ocr-all-cells")
		},
		customAllCells() {
			EventBus.$emit("custom-all-cells")
		},
		setTableSensitivity() {
			EventBus.$emit("table-sensitivity-change")
		},
		checkAndSummarize() {
			console.log('calling check and summarize')
			EventBus.$emit("check-and-summarize")
		},
		changeColHeader(){
			this.tableZone.colHeader = parseInt(this.tableZone.colHeader)
		},
		changeRowHeader(){
			this.tableZone.rowHeader = parseInt(this.tableZone.rowHeader)
		},

	},
	mounted() {
		console.log('options: ', this.options)

	},
}

</script>

<style lang="scss" scoped>
.short-text {
	width: 65px;
	max-width: 65px;
}

.side-bar {
	position: absolute;
	top: 5px;
	left: 5px;
	width: 300px;
	padding: 1px;
}

.side-bar-tab {
	padding: 6px;

	&>* {
		font-size: 14px;

		.col {
			padding: 2px 10px;
			min-height: 55px;
		}

		.v-btn {
			margin: 10px;
		}
	}
}

.room-for-scrollbar {
	width: 97%;
}

.section-label {
	white-space: nowrap !important;
	margin-top: 10px;
}

.v-btn.v-size--small {
	margin-top: 1px;
	margin-right: 0px;
	height: 38px;
}

.theme--light.v-card.side-bar-bg {
	background-color: #f3f3f3;
}

.theme--dark.v-card.side-bar-bg {
	background-color: #3c3c3c;
}

.scrolling-tab {
	overflow-y: scroll;
	height: 125vh;
}

.scroll-margin-bottom {
	margin-bottom: 100px;
}

.btn-group-item {
	margin: 0 !important;
	padding: 0 !important;
}

.section-divider {
	margin-bottom: 5px;
	margin-top: -31px;
	padding-bottom: 20px;
}
</style>