import HTTP from "@/helpers/http-common"

export default {
  async fetchDayStats({ commit }, { payload }) {

    return HTTP.get("api/v1/dayStats/" + payload.reportType, {params: {group: payload.group}})
      .then((response) => {
        console.log("dayStats Stats=>", response)
        if (response.data) {
          commit("SET_STATS_DATA", response.data.data)
        }
      })
      // .catch((error) => {
      //   console.log(error);

      //   if (error.response !== undefined) {
      //     // return Promise.reject(error)
      //     return error.response.status;
      //   } else {
      //     return 500;
      //     // return Promise.reject(500)
      //   }
      // });
  },
  async fetchWeekStats({ commit }, { payload }) {

    return HTTP.get("api/v1/weekStats/" + payload.reportType, {params: {group: payload.group}})
      .then((response) => {
        console.log("Week Stats=>", response)
        if (response.data) {
          commit("SET_STATS_DATA", response.data.data)
        }
      })
      // .catch((error) => {
      //   console.log(error);

      //   if (error.response !== undefined) {
      //     // return Promise.reject(error)
      //     return error.response.status;
      //   } else {
      //     return 500;
      //     // return Promise.reject(500)
      //   }
      // });
  },
  
  async fetchMonthStats({ commit }, { payload }) {

    return HTTP.get("api/v1/monthStats/" + payload.reportType, {params: {group: payload.group}})
      .then((response) => {
        console.log("monthStats Stats=>", response)
        if (response.data) {
          commit("SET_STATS_DATA", response.data.data)
        }
      })
      // .catch((error) => {
      //   console.log(error);

      //   if (error.response !== undefined) {
      //     // return Promise.reject(error)
      //     return error.response.status;
      //   } else {
      //     return 500;
      //     // return Promise.reject(500)
      //   }
      // });
  },  
  async fetchYearStats({ commit }, { payload }) {

    return HTTP.get("api/v1/yearStats/" + payload.reportType, {params: {group: payload.group}})
      .then((response) => {
        console.log("yearStats Stats=>", response)
        if (response.data) {
          commit("SET_STATS_DATA", response.data.data)
        }
      })
      // .catch((error) => {
      //   console.log(error);

      //   if (error.response !== undefined) {
      //     // return Promise.reject(error)
      //     return error.response.status;
      //   } else {
      //     return 500;
      //     // return Promise.reject(500)
      //   }
      // });
  },    
  async fetchAllStats({ commit }, { payload }) {

    return HTTP.get("api/v1/allStats")
      .then((response) => {
        console.log("allStats Stats=>", response)
        if (response.data) {
          commit("SET_STATS_DATA", response.data.data)
        }
      })
      // .catch((error) => {
      //   console.log(error);

      //   if (error.response !== undefined) {
      //     // return Promise.reject(error)
      //     return error.response.status;
      //   } else {
      //     return 500;
      //     // return Promise.reject(500)
      //   }
      // });
  },    
}
